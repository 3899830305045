import Icon from '../Icon';
import { Button } from '../Button';
import styles from './EventImageForm.module.scss';
import { Container, Description, InputContainer } from './EventImageForm.styles';
import { getBase64ImageForm } from '../../../utils/utils';

export default function EventImageForm({ value, setValue, setEventImage, eventImage }) {
  function imageUpload() {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg';
    fileInput.name = 'image-upload';
    fileInput.id = 'hidden-image-upload';
    fileInput.style.position = 'fixed';
    fileInput.style.top = '-100vh';
    fileInput.addEventListener('change', async () => {
      getBase64ImageForm(fileInput.files[0], setValue, value);
      setEventImage(URL.createObjectURL(fileInput.files[0]));
      fileInput.remove();
    });
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  return (
    <Container>
      <Description>
        Event images are used for advertising and site banners. Users will see these images while
        buying tickets
      </Description>
      <div>
        <InputContainer>
          <label htmlFor='event-image'> event image (optional) </label>
          {eventImage ? (
            <>
              {eventImage === 'loading' ? (
                <div className={`load-container ${styles.image}`}>
                  <Icon className='fill-primary' height='64' icon='loading' />
                </div>
              ) : (
                <img className={styles.image} src={eventImage} alt={`event-logo`} />
              )}
              <div className={styles['button-container']}>
                <Button
                  status='danger'
                  shape='square'
                  className='black'
                  onClick={() => setEventImage(null)}
                >
                  Delete
                </Button>
                <Button
                  id='event-image'
                  onClick={() => imageUpload()}
                  shape='square'
                  className='black'
                  decoratorRight={<Icon icon='upload' height='24' className='fill-text-button' />}
                >
                  Replace
                </Button>
              </div>
            </>
          ) : (
            <Button
              id='event-image'
              onClick={() => imageUpload()}
              shape='square'
              className='black'
              decoratorRight={<Icon icon='upload' height='24' class='fill-text-button' />}
            >
              Upload Image
            </Button>
          )}
        </InputContainer>
      </div>
    </Container>
  );
}
