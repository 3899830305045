import { ReactComponent as LoadingIcon } from '../../../static/icons/loading.svg';
import { ReactComponent as ArrowRightIcon } from '../../../static/icons/tix-arrow-right.svg';
import { ReactComponent as CalendarIcon } from '../../../static/icons/tix-calendar.svg';
import { ReactComponent as CheckIcon } from '../../../static/icons/tix-check.svg';
import { ReactComponent as ChevronUpIcon } from '../../../static/icons/tix-chevron-up.svg';
import { ReactComponent as EditIcon } from '../../../static/icons/tix-edit.svg';
import { ReactComponent as MinusIcon } from '../../../static/icons/tix-minus.svg';
import { ReactComponent as PlusIcon } from '../../../static/icons/tix-plus.svg';
import { ReactComponent as TriangleDownIcon } from '../../../static/icons/tix-triangle-down.svg';
import { ReactComponent as UploadIcon } from '../../../static/icons/tix-upload.svg';
import { ReactComponent as XIcon } from '../../../static/icons/tix-x.svg';
import { ReactComponent as GoogleGIcon } from '../../../static/icons/google-g.svg';
import { ReactComponent as Trash } from '../../../static/icons/tix-trash.svg';
import { ReactComponent as Pencil } from '../../../static/icons/tix-pencil.svg';
import { ReactComponent as Avatar } from '../../../static/icons/tix-avatar.svg';
import { ReactComponent as WhiteTriangleDown } from '../../../static/icons/white-down-triangle.svg';
import { ReactComponent as WhiteTriangleUp } from '../../../static/icons/white-up-triangle.svg';
import { ReactComponent as GreenCalendar } from '../../../static/icons/calendar.svg';
import { ReactComponent as WhiteCalendar } from '../../../static/icons/calendar-white.svg';
import { ReactComponent as ThreeDots } from '../../../static/icons/three-dots.svg';
import { ReactComponent as Add } from '../../../static/icons/add.svg';
import { ReactComponent as TickIcon } from '../../../static/icons/tick.svg';
import { ReactComponent as Ticket } from '../../../static/icons/ticket.svg';
import { ReactComponent as ArrowButtonDown } from '../../../static/icons/arrow-button-down.svg';
import { ReactComponent as ArrowButtonUp } from '../../../static/icons/arrow-button-up.svg';
import { ReactComponent as EditButton } from '../../../static/icons/edit-button.svg';
import { ReactComponent as ArrowLeftIcon } from '../../../static/icons/arrow-left.svg';
import { ReactComponent as ArrowLeftBackground } from '../../../static/icons/arrow-left-background.svg';
import styles from './Icon.module.scss';
import { ReactComponent as Close } from '../../../static/icons/close.svg';
import { ReactComponent as Cancel } from '../../../static/icons/cancel.svg';
import { ReactComponent as Copy } from '../../../static/icons/copy.svg';
import { ReactComponent as CopyWhite } from '../../../static/icons/copyWhite.svg';
import { ReactComponent as ArrowContinue } from '../../../static/icons/arrowContinue.svg';

const ICON_MAPPING = {
  avatar: Avatar,
  'arrow-button-down': ArrowButtonDown,
  'arrow-button-up': ArrowButtonUp,
  'arrow-left': ArrowLeftIcon,
  'arrow-left-background': ArrowLeftBackground,
  'arrow-continue': ArrowContinue,
  loading: LoadingIcon,
  'arrow-right': ArrowRightIcon,
  calendar: CalendarIcon,
  close: Close,
  copy: Copy,
  copyWhite: CopyWhite,
  cancel: Cancel,
  'green-calendar': GreenCalendar,
  'white-calendar': WhiteCalendar,
  check: CheckIcon,
  'chevron-up': ChevronUpIcon,
  edit: EditIcon,
  'edit-button': EditButton,
  minus: MinusIcon,
  plus: PlusIcon,
  tick: TickIcon,
  'triangle-down': TriangleDownIcon,
  upload: UploadIcon,
  x: XIcon,
  'google-g': GoogleGIcon,
  trash: Trash,
  'three-dots': ThreeDots,
  pencil: Pencil,
  'white-triangle-down': WhiteTriangleDown,
  'white-triangle-up': WhiteTriangleUp,
  addIcon: Add,
  ticket: Ticket,
};

export default function Icon({
  icon = 'none',
  onClick = () => {},
  rotate = 0,
  height = icon === 'none' ? 0 : 24,
  width = height,
  className = '',
}) {
  const Icon = ICON_MAPPING[icon];

  return (
    <div
      onClick={onClick}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        transform: `rotate(${rotate}deg)`,
      }}
      className={`${styles.svg} ${styles[className]}`}
    >
      <Icon />
    </div>
  );
}
