import styled from 'styled-components';

import { white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-top: 20px;
`;

export const Img = styled.img`
  opacity: 0.7;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const InnerContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.width70 {
    width: 70%;
  }
`;
