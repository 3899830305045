import React, { useState } from 'react';
import moment from 'moment';

import { TicketsType } from '../../../../../types';
import { editGrayPencil } from '../../../../../static/icons';
import { TimeSelectorModal } from './TimeSelectorModal';
import { Column, Label, InputContainer, Img, Text } from '../styles';

interface Props {
  label: string;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
  checked: boolean;
  eventOnSaleTime: Date;
}

export const TimeSelector = ({
  label,
  tickets,
  index,
  setTickets,
  checked,
  eventOnSaleTime,
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Column className='middle relative'>
      <Label className='s'>{label}</Label>
      <InputContainer className='gap'>
        <Text>
          {moment(checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime).format('LT')}
        </Text>
        <Img src={editGrayPencil} className='gray' onClick={() => setIsOpen(true)} />
      </InputContainer>
      {isOpen && (
        <TimeSelectorModal
          hour={
            moment(checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime)
              .format('LT')
              .split(':')[0]
          }
          min={
            moment(checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime)
              .format('LT')
              .split(':')[1]
              .split(' ')[0]
          }
          period={
            moment(checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime)
              .format('LT')
              .split(':')[1]
              .split(' ')[1]
          }
          setIsOpen={setIsOpen}
          index={index}
          tickets={tickets}
          setTickets={setTickets}
        />
      )}
    </Column>
  );
};
