import React, { useState } from 'react';

import { GATE_TIME } from '../../../utils/strings';
import { Input } from './Input';
import { DropDown } from './DropDown';
import { DateSelector } from './DateSelector';
import { TimeSelector } from './TimeSelector';
import { addGreen, editGreenPencil } from '../../../static/icons';
import { queryClient } from '../../../App';
import { NewSupport } from './NewSupport';
import { SaleText } from './SaleText';
import { handleBottomInfo, handleTopInfoMultiDay, handleTopInfoOneDay } from '../../../utils';
import { Information, Text, Column, AddButton, Img, Grid } from './styles';
import { Day } from './Day';
import { Line } from '../PromoCodes/styles';
import RecurringEventModal from './RecurringEventModal.tsx';
import { TextArea } from './TextArea';

interface Props {
  event: any;
  support: string[];
}

const optionsGate = [
  { label: '3 hours before event start', value: -3 },
  { label: '2 hours before event start', value: -2 },
  { label: '1 hour before event start', value: -1 },
  { label: '45 minutes before event start', value: -0.75 },
  { label: '30 minutes before event start', value: -0.5 },
  { label: '15 minutes before event start', value: -0.25 },
];

const optionsType = [
  { label: 'Sport Event', value: 'sports' },
  { label: 'Concert', value: 'concert' },
  { label: 'Festival', value: 'festival' },
  { label: 'Conference', value: 'conference' },
  { label: 'Comedy Show', value: 'comedy' },
  { label: 'Speaker', value: 'speaker' },
  { label: 'Other', value: 'other' },
];

export const EventInformation = ({ event, support }: Props) => {
  const [isEditRecurring, setIsEditRecurring] = useState<boolean>(false);

  return (
    <Column>
      <Information multiday={event.event_days.length !== 1 || event.time_slots}>
        {event.event_days.length === 1 && !event.time_slots
          ? handleTopInfoOneDay(event).map(
              ({
                title,
                description,
                item,
                input,
                dropdown,
                date,
                time,
                dayId,
                startDate,
                endDate,
              }) => (
                <div key={title}>
                  <Text className='title'>{title}</Text>
                  {input ? (
                    <Input description={description} item={item} eventId={event.ID} />
                  ) : dropdown ? (
                    <DropDown
                      description={description}
                      options={title === GATE_TIME ? optionsGate : optionsType}
                      item={item}
                      id={event.ID}
                    />
                  ) : date ? (
                    <DateSelector
                      description={description}
                      eventId={event.ID}
                      dayId={dayId}
                      index={0}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ) : time ? (
                    <TimeSelector
                      description={description}
                      item={item}
                      eventId={event.ID}
                      title={title}
                      timezone={event.time_zone}
                      dayId={dayId}
                      index={0}
                    />
                  ) : (
                    <SaleText description={description} isTime={title.includes('Time')} />
                  )}
                </div>
              )
            )
          : handleTopInfoMultiDay(event).map(({ title, description, item, input, dropdown }) => (
              <div key={title}>
                <Text className='title'>{title}</Text>
                {input ? (
                  <Input description={description} item={item} eventId={event.ID} />
                ) : dropdown ? (
                  <DropDown
                    description={description}
                    options={title === GATE_TIME ? optionsGate : optionsType}
                    item={item}
                    id={event.ID}
                  />
                ) : (
                  <SaleText description={description} isTime={title.includes('Time')} />
                )}
              </div>
            ))}
      </Information>
      {(event.event_days.length > 1 || (event.event_days.length > 0 && event.time_slots)) && (
        <Column>
          <Grid>
            {event.event_days.map(
              ({ start_date, end_date, event_id, ID, quantity, sold }, index) => (
                <Day
                  key={ID}
                  startDate={start_date}
                  endDate={end_date}
                  index={index}
                  timezone={event.time_zone}
                  eventId={event_id}
                  dayId={ID}
                  isTimeSlot={event.time_slots}
                  quantity={quantity}
                  eventDaysArray={event.event_days}
                  sold={sold}
                />
              )
            )}
          </Grid>
          <Line className='margin' />
        </Column>
      )}
      {handleBottomInfo(event, support).map(({ title, description, item, input, recurring }) => (
        <div key={title}>
          {!recurring && <Text className='title'>{title}</Text>}
          {input ? (
            <TextArea description={description} item={item} eventId={event.ID} />
          ) : recurring ? (
            event.recurrence_id > 0 && (
              <>
                <Text className='title'>{title}</Text>
                <AddButton className='margin-left' onClick={() => setIsEditRecurring(true)}>
                  <Img src={editGreenPencil} className='add' />
                  Modify
                </AddButton>
              </>
            )
          ) : (
            <>
              {description?.map(({ contact, ID, pressed }) => {
                if (pressed) return <NewSupport eventId={event.ID} />;
                else
                  return (
                    <Input description={contact} supportId={ID} fromSupport eventId={event.ID} />
                  );
              })}
              <AddButton
                onClick={() => {
                  queryClient.setQueryData(['getSupport'], (oldData: Array<any>) => [
                    ...oldData,
                    {
                      contact: '',
                      pressed: true,
                    },
                  ]);
                }}
              >
                <Img src={addGreen} className='add' />
                Add new support
              </AddButton>
            </>
          )}
        </div>
      ))}
      {isEditRecurring && (
        <RecurringEventModal
          setIsOpen={setIsEditRecurring}
          rrule={event.rrule}
          date={event.event_days[0].start_date}
        />
      )}
    </Column>
  );
};
