import { useState } from 'react';
import { useQuery } from 'react-query';

import { FAN_DATABASE } from '../../utils/strings';
import searchIcon from '../../static/icons/searchIcon.svg';
import { Container, InputContainer, Title, Search, Input } from './style';
import { RowFanDatabase } from './RowFanDatabase';
import {
  STableHead,
  STableCell,
  STableContainer,
  STableRow,
  STableBody,
  STable,
} from './tableStyle';
import { getData } from '../../api/api';

export default function FanDatabase({ value, setValue }) {
  const [query, setQuery] = useState<string>('');
  const partner_id = localStorage.getItem('partnerId');

  const { data: getPartnerDataFan } = useQuery('getPartnerDataFan', () =>
    getData(`/partners/${partner_id}/fans`)
  );
  const title = ['Email', 'First Name', 'Last Name', 'Options'];

  return (
    <Container>
      <Title>{FAN_DATABASE}</Title>
      <InputContainer>
        <Search src={searchIcon} alt='search' />
        <Input placeholder='filter by fan' value={query} onChange={e => setQuery(e.target.value)} />
      </InputContainer>
      <STableContainer>
        <STable>
          <STableHead>
            <STableRow index>
              {title.map(title => (
                <STableCell>{title}</STableCell>
              ))}
            </STableRow>
          </STableHead>
          <STableBody>
            {getPartnerDataFan
              ?.filter(e => e.full_name.toLowerCase().includes(query.toLowerCase()))
              ?.map(({ ID, full_name, email, phone_number }, index) => (
                <RowFanDatabase
                  ID={ID}
                  index={index}
                  email={email}
                  name={full_name}
                  phone_number={phone_number}
                />
              ))}
          </STableBody>
        </STable>
      </STableContainer>
    </Container>
  );
}
