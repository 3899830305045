import React from 'react';

import { Text } from '../../PromoCodes/styles';
import { Column, Row, DayCircle, DayText } from './styles';

const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

interface Props {
  daysSelected: string[];
  setDaysSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Weekly = ({ daysSelected, setDaysSelected }: Props) => (
  <Column>
    <Text className='small repeat'>Repeat on</Text>
    <Row>
      {days.map(day => (
        <DayCircle
          className={daysSelected.includes(day) ? 'active' : ''}
          onClick={() => {
            if (daysSelected.includes(day)) {
              const newDaysSelected = [...daysSelected];
              newDaysSelected.splice(daysSelected.indexOf(day), 1);
              setDaysSelected(newDaysSelected);
            } else {
              setDaysSelected([...daysSelected, day]);
            }
          }}
        >
          <DayText>{day.charAt(0)}</DayText>
        </DayCircle>
      ))}
    </Row>
  </Column>
);
