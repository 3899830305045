const API_URL = "http://localhost:8001";

class TixologiApi {
  token: string;
  headers: any;

  constructor(token = null) {
    this.token = token;
    this.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      authorization: null
    }
  }

  setToken(token) {
    this.token = token;
  }

  hasToken() {
    return this.token != null;
  }

  makeHeaders() {
    if (this.token) {
      this.headers.authorization = `Bearer ${this.token}`;
    }
    return this.headers;
  }

  async get(path, params={}) {
    const res = await (
      await fetch(
        `${API_URL}${path}?${new URLSearchParams(params).toString()}`,
        {
          method: "GET",
          headers: await this.makeHeaders(),
        }
      )
    ).json();
    if (res.success) {
      return res.payload;
    } else {
      throw res.error;
    }
  }

  async delete(path, params) {
    const res = await (
      await fetch(
        `${API_URL}${path}?${new URLSearchParams(params).toString()}`,
        {
          method: "DELETE",
          headers: await this.makeHeaders(),
        }
      )
    ).json();
    if (res.success) {
      return res.payload;
    } else {
      throw res.error;
    }
  }

  async post(path, body) {
    const res = await (
      await fetch(`${API_URL}${path}`, {
        method: "POST",
        headers: await this.makeHeaders(),
        body: JSON.stringify(body),
      })
    ).json();
    if (res.success) {
      return res.payload;
    } else {
      throw res.error;
    }
  }

  async postFile(path, file) {
    const data = new FormData();
    data.append("file", file);

    const res = await (
      await fetch(`${API_URL}${path}`, {
        method: "POST",
        body: data,
      })
    ).json();
    if (res.success) {
      return res.payload;
    } else {
      throw res.error;
    }
  }

  async put(path, body) {
    const res = await (
      await fetch(`${API_URL}${path}`, {
        method: "PUT",
        headers: await this.makeHeaders(),
        body: JSON.stringify(body),
      })
    ).json();
    if (res.success) {
      return res.payload;
    } else {
      throw res.error;
    }
  }
}

export default new TixologiApi();
