import { useMutation, useQuery } from 'react-query';

import { getData, post } from '../../../../api/api';
import { approve } from '../../../../static/img';
import { Container, Img, Member, MembersContainer, Text, Icon } from './styles';

type Props = {
  partners?: any;
  setTeamsOpen: React.Dispatch<React.SetStateAction<any>>;
};

export default function SwitchTeams({ partners, setTeamsOpen }: Props) {
  const partner_id = localStorage.getItem('partnerId');
  const userId = localStorage.getItem('userId');

  const { mutate: loginPartner } = useMutation(
    (ID: number) =>
      post('/login/partner', {
        partner_id: parseInt(ID.toString()),
      }),
    {
      onSuccess: data => {
        localStorage.setItem('accessToken', data);
        setTimeout(() => {
          window.location.reload();
        }, 0);
      },
    }
  );

  const { data: users } = useQuery('getUsers', () => getData(`/users`));

  return (
    <Container>
      <MembersContainer onMouseLeave={() => setTeamsOpen(false)}>
        {users
          ?.find(u => u?.ID.toString() === userId)
          .Partners?.map(({ name, ID, image }) => (
            <div key={ID}>
              <Member
                onClick={() => {
                  loginPartner(ID.toString());
                  localStorage.setItem('partnerId', ID.toString());
                  localStorage.setItem('partnerLogo', image);
                }}
              >
                <Img src={image}></Img>
                <Text>{name}</Text>
                {ID.toString() === partner_id ? <Icon src={approve} /> : ''}
              </Member>
            </div>
          ))}
      </MembersContainer>
    </Container>
  );
}
