import { useMutation } from 'react-query';

import { put } from '../../api/api';
import { queryClient } from '../../App';
import { WEBSITE_ADRESS, WEBSITE_URL } from '../../utils/strings';
import { ContentContainer, Span, Input } from './styles';

interface Props {
  refetchWidget: any;
}

export const Website = ({ refetchWidget }: Props) => {
  const data: any = queryClient.getQueryData('getWidgetCustomization');
  const partnerId = localStorage.getItem('partnerId');

  const { mutate: handleMutation } = useMutation(
    (website_url: string) =>
      put(`partners/${partnerId}/widget`, {
        website_url,
      }),
    { onSuccess: refetchWidget }
  );

  return (
    <ContentContainer>
      <Span>{WEBSITE_ADRESS}</Span>
      <Input
        onBlur={() => handleMutation(data?.website_url)}
        placeholder={WEBSITE_URL}
        value={data?.website_url}
        onChange={e =>
          queryClient.setQueryData(
            ['getWidgetCustomization'],
            (oldData: Object) => ({
              ...oldData,
              website_url: e.target.value,
            })
          )
        }
      />
    </ContentContainer>
  );
};
