import React from 'react';
import { Modal } from '@mui/material';

import { Container, Row, Button, Line, Text, Column } from './stylesModal';
import { modalStyle } from '../../../../pages/WidgetCustomization/styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  title?: string;
  handleClick?: () => void;
  handleClickRecurrent?: () => void;
}

export default function RecurringEventModal({
  isOpen,
  setIsOpen,
  label,
  handleClick,
  handleClickRecurrent,
  title,
}: Props) {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column className='top'>
          <Text>
            Do you want to delete only this event or all instances of this event: {title}?
          </Text>
          <Text className='bold'>{label}</Text>
        </Column>
        <Line />
        <Row className='bottom'>
          <Button
            className='cancel'
            onClick={() => {
              handleClickRecurrent();
              setIsOpen(false);
            }}
          >
            Delete All
          </Button>
          <Button
            onClick={() => {
              handleClick();
              setIsOpen(false);
            }}
            className='remove'
          >
            Delete Event
          </Button>
        </Row>
      </Container>
    </Modal>
  );
}
