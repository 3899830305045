import { useState } from 'react';
import { useMutation } from 'react-query';
import { useFormik } from 'formik';

import { Input } from '../../../../lib/components/Inputs/Input';
import { Modal } from '../../../../lib/components/Modal';
import RolesDropdown from '../RolesDropdown/RolesDropdown';
import { Form, InformationSection, RoleSection, Wrapper } from './AddMember.style';
import { inviteMemberInputData, InviteMemberSchema, INVITE_MEMBER_INIT_VALUES } from './utils';
import { post } from '../../../../api/api';
import { queryClient } from '../../../../App';

export default function AddMember({ showModal, onSuccess, onClose, roles }) {
  const partner_id = localStorage.getItem('partnerId');
  const user_id = localStorage.getItem('userId');
  const [role, setRole] = useState(null);
  const { handleChange, handleSubmit, values, errors, touched, resetForm } = useFormik({
    initialValues: INVITE_MEMBER_INIT_VALUES,
    validationSchema: InviteMemberSchema,
    onSubmit: () => {
      mutateCreateMember();
    },
  });

  const { mutate: mutateCreateMember } = useMutation(
    () =>
      post('/members', {
        full_name: values.name,
        email: values.email,
        roles: [role],
        partner_id,
        user_id,
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['getMembers'], (oldData: Array<any>) => [
          ...oldData,
          {
            MemberID: data.member_id,
            PartnerID: data.partner_id,
            User: { full_name: values.name },
            email: values.email,
            Roles: [roles.find(({ ID }) => ID === role)],
            status: false,
          },
        ]);
        resetForm();
        onSuccess();
      },
    }
  );

  return (
    <>
      <Modal
        isShowing={showModal}
        title='Add team member'
        textClose='Cancel'
        textSuccess='Send Invite'
        onCancel={onClose}
        onSuccess={handleSubmit}
        hide={() => {
          if (!!values.name && !!values.email) onClose();
        }}
      >
        <Wrapper>
          <Form>
            <InformationSection>
              <h3>Member information</h3>
              <p>
                Team members will recieve an email invitation to create an account and join your
                team.
              </p>
              {inviteMemberInputData.map(({ placeholder, name, label }) => (
                <Input
                  key={name}
                  value={values[name]}
                  setValue={
                    handleChange(name) as unknown as (e: string | React.ChangeEvent<any>) => void
                  }
                  id={name}
                  placeholder={placeholder}
                  label={label}
                  type={'text'}
                  error={errors[name]}
                  touched={touched[name]}
                />
              ))}
            </InformationSection>
            <RoleSection>
              <h3>Member Role</h3>
              <RolesDropdown setRole={setRole} role={role} roles={roles} />
            </RoleSection>
          </Form>
        </Wrapper>
      </Modal>
    </>
  );
}
