import { Wrapper } from './styles';

type Props = {
  active?: boolean;
  children?: any;
  onClick: () => void;
};
export default function NavLink({
  active = false,
  children = null,
  onClick,
}: Props) {
  return (
    <>
      <Wrapper onClick={onClick} active={active}>
        {children}
      </Wrapper>
    </>
  );
}
