import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../Button';
import { ButtonVariants } from '../Button/Button';
import {
  ModalChildren,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalWrapper,
} from './styles';

type Props = {
  isShowing: boolean;
  hide: Function;
  title?: string;
  children?: any;
  textClose?: string;
  textSuccess?: string;
  onSuccess?: Function;
  onCancel?: Function;
  className?: string;
};

const Modal = ({
  isShowing,
  hide,
  title,
  children,
  textClose,
  textSuccess = 'Save changes',
  onSuccess = () => {},
  onCancel = () => {},
  className,
}: Props) => {
  const handleSuccess = () => {
    onSuccess();
    hide();
  };

  const handleClose = () => {
    onCancel();
    hide();
  };

  return (
    isShowing &&
    ReactDOM.createPortal(
      <React.Fragment>
        <ModalOverlay />
        <ModalWrapper className={className}>
          <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalChildren>{children}</ModalChildren>
            <ModalFooter>
              {textClose && (
                <Button
                  onClick={handleClose}
                  variant={ButtonVariants.outlined}
                  size='large'
                >
                  {textClose}
                </Button>
              )}
              <Button size='large' onClick={handleSuccess}>
                {textSuccess}
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalWrapper>
      </React.Fragment>,
      document.body
    )
  );
};
export default Modal;
