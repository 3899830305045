import styled from 'styled-components';

import { remove } from '../../../../utils/theme';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  position: relative;
  height: 90px;

  &.no-space {
    margin: 0;
  }
`;

export const TextareaStyled = styled.textarea`
  width: 100%;
  border-radius: 0.25rem;
  background-color: var(--color-bg-input);
  resize: none;

  position: relative;
  display: block;
  outline: none;
  border: solid 2px var(--color-bg-input) !important;
  background-color: var(--color-bg-input) !important;
  padding: 0.5rem 0.5rem;
  color: var(--color-text-input);
  -webkit-text-fill-color: var(--color-text-input);
  -webkit-text-security: none !important;
  caret-color: var(--color-text-input);
  box-shadow: 0 0 0 1000px var(--color-bg-input) inset;
  -webkit-box-shadow: 0 0 0 1000px var(--color-bg-input) inset;
  transition: background-color 5000s ease-in-out 0s;
  filter: none;
  width: calc(100% - 1rem - 4px);
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: -0.02em;
  font-family: 'Open Sans', sans-serif;

  &.focus {
    border: 2px solid var(--color-info-default) !important;
  }
`;

export const InputStyled = styled.input`
  width: 100%;
  border-radius: 0.5rem;
  background-color: var(--color-bg-input);

  position: relative;
  display: block;
  outline: none;
  border: solid 2px var(--color-bg-input);
  background-color: var(--color-bg-input) !important;
  padding: 0.5rem 0.5rem;
  color: var(--color-text-input);
  -webkit-text-fill-color: var(--color-text-input);
  -webkit-text-security: none !important;
  caret-color: var(--color-text-input);
  box-shadow: 0 0 0 1000px var(--color-bg-input) inset;
  -webkit-box-shadow: 0 0 0 1000px var(--color-bg-input) inset;
  transition: background-color 5000s ease-in-out 0s;
  filter: none;
  width: calc(100% - 1rem - 4px);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  height: 1.75rem;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  &[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  &:focus {
    border: solid 2px var(--color-primary-default) !important;
  }

  &.focus {
    border: 2px solid var(--color-info-default) !important;
  }

  &.error {
    border: 1px solid ${remove} !important;
  }

  &.center {
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.75) !important;
    border-radius: 4px !important;
  }

  &.centerInfo {
    text-align: center;
    border: 1px solid var(--color-bg-input) !important;
  }

  &.border {
    border: 1px solid rgba(255, 255, 255, 0.75) !important;
    border-radius: 4px !important;
  }
  &.info {
    border: 1px solid var(--color-bg-input);
  }
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 0.25rem;
  display: block;
`;

export const ErrorMessage = styled.label`
  font-size: 12px;
  display: block;
  color: red;
`;

export const Button = styled.button`
  all: unset;
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 33px;
`;

export const EyeImg = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
