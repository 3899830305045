import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';

import {
  ADD_DEFAULT_ROYALITIES,
  ADD_ROYALITY,
  CANCEL,
  SECONDARY_ROYALITY,
} from '../../utils/strings';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../WidgetCustomization/styles';
import { Column, Input, Span } from './styles';
import { updatePartner } from './services';
import { queryClient } from '../../App';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  royalty: string;
}

const partnerId = localStorage.getItem('partnerId');

export const RoyaltiesModal = ({ isOpen, setIsOpen, royalty }: Props) => {
  const [value, setValue] = useState<string>(royalty === '-1' ? '' : royalty);
  const [error, setError] = useState<boolean>(false);

  const handleClose = () => setIsOpen(false);

  const { mutate: mutateUpdateRoaylty } = useMutation(updatePartner, {
    onSuccess: () => {
      queryClient.setQueryData(['getPartner'], (oldData: any) => ({
        ...oldData,
        secondary_royalty: value,
      }));
    },
  });
  const handleRoyalty = () => {
    if (0 <= Number(value) && Number(value) <= 100) {
      setError(false);
      mutateUpdateRoaylty({
        royalty: Number(value),
        id: partnerId,
      });
      setIsOpen(false);
    } else setError(true);
  };

  return (
    <Modal open={isOpen} onClose={handleClose} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>{ADD_DEFAULT_ROYALITIES}</Title>
        </Row>
        <Column className='royality'>
          <Column className='width'>
            <Span className='title'>{SECONDARY_ROYALITY}</Span>
            <Row>
              <Input
                type='number'
                min={0}
                max={100}
                value={value}
                onChange={e => setValue(e.target.value)}
              />
              <Span className='char'>%</Span>
            </Row>
            <Row className='width'>
              {error && (
                <Span className='error'>* Value must be between 0 and 100</Span>
              )}
            </Row>
          </Column>
        </Column>
        <Row className='center'>
          <ButtonModal className='cancel' onClick={handleClose}>
            {CANCEL}
          </ButtonModal>
          <ButtonModal onClick={handleRoyalty}>{ADD_ROYALITY}</ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
