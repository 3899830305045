import styled from 'styled-components';

import { grayRGBAopacity, inputBackgroundSecondary, white } from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  align-items: center;
  color: ${white};
  width: 50%;
  text-transform: capitalize;

  &.two_factor {
    font-size: 15px;
    text-transform: uppercase;
    margin-left: 32px;
  }

  &.subtitle {
    font-size: 15px;
  }

  &.title {
    font-size: 15px;
    text-transform: uppercase;
  }
`;

export const Input = styled.input`
  background: ${inputBackgroundSecondary};
  border: 1px solid ${grayRGBAopacity};
  border-radius: 4px;
  outline: none;
  padding: 10px;
  width: 50%;

  &.two_factor {
    width: 90%;
  }
`;

export const InputContainer = styled.div`
  padding: 10px 0;
  input {
    width: 487px;
    color: var(--color-text);
    font-size: 1.5rem;
    text-align: center;
    background-color: var(--color-level-1);
    cursor: pointer;
  }
`;
