import { CheckIcon, Circle, Wrapper } from './Radio.style';

type Props = {
  text?: string;
  textSize?: number;
  onSelect?: Function;
  checked?: boolean;
  setChecked?: Function;
  noMargin?: boolean;
  padding?: boolean;
};

export default function Radio({
  text,
  textSize = 12,
  onSelect,
  checked = false,
  setChecked,
  noMargin,
  padding,
}: Props) {
  const onClick = () => {
    setChecked?.(!checked);
  };

  return (
    <>
      <Wrapper className={noMargin ? 'no-margin' : padding ? 'padding' : ''}>
        <CheckIcon height={textSize * 1} onClick={onClick} className={checked ? 'checked' : ''}>
          {checked && <Circle height={textSize} />}
        </CheckIcon>
        <span style={{ fontSize: textSize }}>{text}</span>
      </Wrapper>
    </>
  );
}
