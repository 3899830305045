import styled from 'styled-components';

export const ContainerOrganization = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 50%;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 16px;

  &.title {
    font-weight: 600;
    font-size: 24px;
  }
`;

export const Image = styled.div`
  background-color: #3d3c3c;
  padding: 20px;
  width: 165px;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Img = styled.img`
  width: 80%;
  border-radius: 50%;
`;

export const Profile = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;
