import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { ErrorMessage } from '../../../../pages/Login/styles';
import { eyeOff, eyeOn } from '../../../../static/icons';
import { TextareaStyled, InputStyled, Label, Wrapper, Button, EyeImg } from './styles';

type Props = {
  id?: string;
  type?: string;
  value: any;
  setValue: Function;
  placeholder?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  label?: string;
  error?: any;
  className?: string;
  textarea?: boolean;
  touched?: boolean;
  setError?: Function;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export default function Input({
  id = uuid(),
  type = 'text',
  value,
  setValue,
  placeholder = '',
  disabled = false,
  min = undefined,
  max = undefined,
  label = '',
  error,
  setError = () => null,
  touched,
  className = '',
  textarea = false,
  onBlur,
}: Props) {
  const [secureEntry, setSecureEntry] = useState(type);
  const handleInput = event => {
    if (type === 'number' && min && max) {
      const newValue = Math.min(max, Math.max(min, Number(event.target.value)));
      event.target.value = newValue;
      setValue(newValue);
    } else if (type === 'decimal') {
      const newValue = parseFloat(event.target.value);
      event.target.value = newValue;
      setValue(newValue);
    } else {
      setValue(event.target.value);
    }
  };
  return (
    <Wrapper className={className}>
      <Label htmlFor={id}>{label.toUpperCase()}</Label>
      {textarea ? (
        <TextareaStyled
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          value={value}
          attr-type={type}
          onInput={handleInput}
          className={`${className} ${error && touched ? 'error' : ''}`}
        />
      ) : (
        <InputStyled
          disabled={disabled}
          placeholder={placeholder}
          id={id}
          type={secureEntry}
          value={value}
          attr-type={secureEntry}
          onInput={handleInput}
          className={`${className} ${error && touched ? 'error' : ''}`}
          onBlur={onBlur}
        />
      )}
      {type === 'password' && (
        <Button onClick={() => setSecureEntry(secureEntry === 'password' ? 'text' : 'password')}>
          <EyeImg src={secureEntry === 'password' ? eyeOff : eyeOn} />
        </Button>
      )}
      <ErrorMessage htmlFor={id}>{touched && error}</ErrorMessage>
    </Wrapper>
  );
}
