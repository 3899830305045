import styled from 'styled-components';

export const Wrapper = styled.div``;
export const TitleSection = styled.div`
  margin-bottom: 20px;
  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
  }
`;

export const SlidesSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 700px;
  position: relative;
`;

const Slide = styled.div`
  min-height: 400px;
  background: #1f1f1f;
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
`;
export const MainSlide = styled(Slide)`
  margin: auto auto 147px auto;
  padding: 64px;
  width: 50%;
  height: 62%;
  min-height: 400px;
  display: grid;
  justify-items: center;

  &.full {
    height: calc(100vh - 318px);
    min-height: 572px;
  }
`;

export const LeftSlide = styled(Slide)`
  position: relative;
  top: -9%;
  left: -30px;
  height: 300px;
  width: 20%;
  &.hide {
    opacity: 0;
  }
`;

export const RightSlide = styled(Slide)`
  position: relative;
  margin-left: auto;
  top: -9%;
  right: -30px;
  height: 300px;
  width: 20%;
  &.hide {
    opacity: 0;
  }
`;
export const ButtonsSection = styled.div`
  width: 100%;
  position: absolute;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;

  button {
    margin: 0 10px;
  }
`;
