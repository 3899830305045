import { ChildrenContainer, Decorator, Wrapper } from './styles';

export enum ButtonVariants {
  'outlined' = 'outlined',
  'block' = 'block',
  'greenOutlined' = 'greenOutlined',
}

type Props = {
  id?: number;
  children?: any;
  disabled?: boolean;
  onClick?: any;
  status?: string;
  decoratorLeft?: any;
  decoratorRight?: any;
  height?: number;
  isBlock?: boolean;
  variant?: ButtonVariants;
  size?: 'small' | 'medium' | 'large';
  shape?: 'block' | 'pill';
  color?: 'primary' | 'gray' | 'danger';
  className?: string;
};

export default function Button({
  id = null,
  children = null,
  disabled = false,
  onClick = () => {},
  decoratorLeft = null,
  decoratorRight = null,
  height = 58,
  isBlock = false,
  variant = ButtonVariants.block,
  size = 'medium',
  shape = 'pill',
  color = 'primary',
  className = '',
}: Props) {
  let textSize = 16;

  switch (size) {
    case 'large':
      height = 58;
      textSize = 20;
      break;

    case 'medium':
      height = 40;
      textSize = 16;
      break;

    case 'small':
      height = 58;
      textSize = 20;
      break;

    default:
      height = 58;
      textSize = 20;
      break;
  }
  return (
    <>
      <Wrapper
        id={`${id}`}
        onClick={onClick}
        variant={variant}
        height={height}
        isBlock={isBlock}
        shape={shape}
        textSize={textSize}
        color={color}
        className={className}
        disabled={disabled}
      >
        <Decorator>{decoratorLeft}</Decorator>
        <ChildrenContainer>{children}</ChildrenContainer>
        <Decorator>{decoratorRight}</Decorator>
      </Wrapper>
    </>
  );
}
