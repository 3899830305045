import React, { useState } from 'react';

import { promoCodeFormType } from '../../../../../../types';
import DaysAndHoursForm from './DaysAndHoursForm';
import { Container, Column, Label, Button, Row } from './styles';

type Props = {
  promoCodeForm: promoCodeFormType;
  setPromoCodeForm: Function;
};

export const PCActiveDays = ({ promoCodeForm, setPromoCodeForm }: Props) => {
  const [isLimited, setIsLimited] = useState<boolean>(false);
  const today = new Date();
  const [startTime, setStartTime] = useState({
    date: today,
    hour: 12,
    min: 0,
    period: 'pm',
  });
  const [endTime, setEndTime] = useState({
    date: today,
    hour: 12,
    min: 0,
    period: 'pm',
  });

  return (
    <Container>
      <Column>
        <Label>Active days</Label>
        <Row className='width margin-bottom'>
          <Button
            onClick={() => setIsLimited(true)}
            className={`left ${isLimited ? 'active' : ''}`}
          >
            Limited days
          </Button>
          <Button onClick={() => setIsLimited(false)} className={isLimited ? '' : 'active'}>
            Unlimited
          </Button>
        </Row>
        {isLimited && (
          <DaysAndHoursForm
            value={promoCodeForm}
            setValue={setPromoCodeForm}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            setEndTime={setEndTime}
          />
        )}
      </Column>
    </Container>
  );
};
