import React from 'react';
import { Modal } from '@mui/material';

import { alertCircle } from '../../static/img';
import { modalStyle } from '../WidgetCustomization/styles';
import { ContainerModal, Column, Text, Line, Row, Button, Img } from './stylesModal';

interface Props {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

const ModalEventCreation = ({ isOpen, setIsOpen, setActiveStep }: Props) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <ContainerModal className='slot'>
      <Column className='top delete'>
        <Img src={alertCircle} />
        <Text>Something went wrong! We were unable to perform this action</Text>
      </Column>
      <Line />
      <Row className='bottom'>
        <Button
          onClick={() => {
            setIsOpen(false);
            setActiveStep(1);
          }}
          className='correct'
        >
          Continue
        </Button>
      </Row>
    </ContainerModal>
  </Modal>
);

export default ModalEventCreation;
