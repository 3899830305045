import { useMemo } from 'react';
import { useIsFetching, useIsMutating } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../stores';

const useLoading = (): boolean => {
  const isMutating = useIsMutating();
  const isFetching = useIsFetching();
  const { loading } = useSelector(({ user }: RootState) => user);

  const isLoading = useMemo(
    () => loading || !!isFetching || !!isMutating,
    [isFetching, isMutating, loading]
  );

  return isLoading;
};

export default useLoading;
