import styled from 'styled-components';

import {
  gradient,
  palette,
  shadowContainer,
  transparent,
} from '../../../utils/theme';

export const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  margin: 0;

  &.title {
    font-size: 48px;
  }

  &.link {
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  &.gradient {
    background: ${gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${transparent};
  }
`;

export const Container = styled.div`
  background-color: ${palette.white};
  box-shadow: 12px 20px 25px ${shadowContainer};
  border-radius: 16px;
  font-weight: 300;
  width: 90%;
  height: 75%;
  overflow-y: scroll;
`;

export const Header = styled.div<{
  secondaryColor: string;
  primaryFontColor: string;
}>`
  position: relative;
  color: ${props => props.primaryFontColor};
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding: 30px;
  background: ${props => props.secondaryColor};
`;
