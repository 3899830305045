import React, { useState } from 'react';

import { TicketsType } from '../../../../../types';
import { DateSelector } from './DateSelector';
import { DropDown } from './DropDown';
import { TimeSelector } from './TimeSelector';
import Icon from '../../../Icon/Icon';
import { Container, Label, Row, ContainerCheckbox } from '../styles';

interface Props {
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
  eventOnSaleTime: Date;
}

export const OnSale = ({ tickets, setTickets, index, eventOnSaleTime }: Props) => {
  const [checked, setChecked] = useState<boolean>(false);

  return (
    <Container className='no-input'>
      <Label>On sale time</Label>
      <Row className='gap width'>
        <DateSelector
          tickets={tickets}
          setTickets={setTickets}
          index={index}
          label='sale date'
          checked={checked}
          eventOnSaleTime={eventOnSaleTime}
        />
        <TimeSelector
          tickets={tickets}
          setTickets={setTickets}
          index={index}
          label='sale time'
          checked={checked}
          eventOnSaleTime={eventOnSaleTime}
        />
        <DropDown label='timezone' />
      </Row>
      <Row className='gap margin-top'>
        <ContainerCheckbox checked={checked} onClick={() => setChecked(!checked)}>
          {checked && <Icon icon='check' width={20} />}
        </ContainerCheckbox>
        <Label className='s no-uppercase'>Use default on sale time</Label>
      </Row>
      <Label className='s no-uppercase'>
        Your tickets will officially go on sale at the above time and date.
      </Label>
    </Container>
  );
};
