import React from 'react';

import { EventFormType } from '../../../types';
import { StyledInput, Column, Label } from './TicketTypes/styles';

interface Props {
  label: string;
  item: string;
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  placeholder?: string;
  width?: string | number;
  error?: any;
  setError?: Function;
}

export const Input = ({
  label,
  item,
  placeholder,
  width,
  value,
  setValue,
  error,
  setError = () => null,
}: Props) => (
  <Column style={{ width, position: 'relative' }}>
    <Label className='m'>{label}</Label>
    <StyledInput
      value={value[item]}
      onChange={e => {
        setValue({ ...value, [item]: e.target.value });
        setError({ ...error, [item]: false });
      }}
      className={`info event ${!!error && !!error[item] ? 'error' : ''}`}
      placeholder={placeholder}
    />
  </Column>
);
