import styled from 'styled-components';
export const Wrapper = styled.div`
  & {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  &::after,
  &::before {
    content: '';
    border: 1px solid gray;
    flex: 1;
  }

  &:not(:empty)::before {
    margin-right: 1rem;
  }

  &:not(:empty)::after {
    margin-left: 1rem;
  }
`;
