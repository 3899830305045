import React, { useEffect, useState } from 'react';
import DatePicker from 'react-date-picker';

import Icon from '../../Icon';
import { DateInput, Wrapper, DateBox, IconContainer } from '../../Inputs/DatePicker/styles';
import { EventFormType } from '../../../../types';

type Props = {
  date?: Date;
  handleChange?: (date: Date) => void;
  className?: string;
  error?: boolean;
  activeDays?: boolean;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  startTime?: any;
  endTime?: any;
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  index?: number;
  isTimeSlot?: boolean;
  isSameAsStartDate?: boolean;
  setSameDate?: React.Dispatch<React.SetStateAction<Date>>;
  item?: string;
};

const twoDigits = (num: number) => ('0' + num).slice(-2);

const inputIndexes = ['month', 'day', 'year'];

export default function DatePickerComponent({
  date,
  handleChange,
  className,
  error,
  activeDays,
  setDate,
  startTime,
  endTime,
  value,
  setValue,
  index,
  isTimeSlot,
  isSameAsStartDate,
  setSameDate,
  item,
}: Props) {
  const [inputs, setInputs] = useState({
    month: '',
    day: '',
    year: '',
  });

  const handleDateChange = (e, i) => {
    const { hour, min, period } = item === 'startTime' ? startTime : endTime;
    const newTime = `${hour}:${min} ${period}`;
    const dateArray = date.toString().split(' ');
    dateArray.splice(i, 1, e);
    setDate(new Date(dateArray.join(' ')));
    const finalDate = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
    let event = { ...value };
    event.dates[index][item] = new Date(finalDate);
    setValue(event);
  };

  const handleSameDateChange = (e, i) => {
    const { hour, min, period } = startTime;
    const { hour: endHour, min: endMin, period: endPeriod } = endTime;
    const newStartTime = `${hour}:${min} ${period}`;
    const newEndTime = `${endHour}:${endMin} ${endPeriod}`;
    const dateArray = date.toString().split(' ');
    dateArray.splice(i, 1, e);
    setDate(new Date(dateArray.join(' ')));
    setSameDate(new Date(dateArray.join(' ')));
    const finalStartDate = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newStartTime}`;
    const finalEndDate = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newEndTime}`;
    let event = { ...value };
    event.dates[index].startDate = new Date(finalStartDate);
    event.dates[index].endDate = new Date(finalEndDate);
    setValue(event);
  };

  const handleChangeSingleDate = (e, i) => {
    const dateArray = date.toString().split(' ');
    dateArray.splice(i, 1, e);
    setDate(new Date(dateArray.join(' ')));
    const updatedDate = new Date(dateArray.join(' '));
    const updatedDateArray = updatedDate.toString().split(' ');
    const newDate = `${updatedDateArray[0]} ${updatedDateArray[1]} ${updatedDateArray[2]} ${updatedDateArray[3]}`;
    let event = { ...value };
    event.dates.map(({ startDate, endDate }, index) => {
      const startDateArray = startDate.toString().split(' ');
      const endDateArray = endDate.toString().split(' ');
      event.dates[index].startDate = new Date(`${newDate} ${startDateArray[4]}`);
      event.dates[index].endDate = new Date(`${newDate} ${endDateArray[4]}`);
    });
    setValue(event);
  };

  useEffect(() => {
    setInputs({
      month: twoDigits(date.getMonth() + 1),
      day: twoDigits(date.getDate()),
      year: date.getFullYear().toString(),
    });
  }, [date]);

  return (
    <Wrapper className={className}>
      <DateInput
        className={`modal ${error ? 'error' : ''}`}
        value={inputs.month}
        onChange={e => {
          const isValid = Number(e.target.value) <= 12 && Number(e.target.value) >= 0;
          if (isValid) setInputs({ ...inputs, [inputIndexes[0]]: e.target.value });
        }}
        onBlur={() => {
          const isValid = Number(inputs.month) <= 12 && Number(inputs.month) >= 1;
          if (isTimeSlot) handleChangeSingleDate(isValid ? inputs.month : '01', 1);
          else if (isSameAsStartDate) handleSameDateChange(isValid ? inputs.month : '01', 1);
          else handleDateChange(isValid ? inputs.month : '01', 1);
        }}
        type='number'
        min={1}
        max={12}
      />
      <DateInput
        className={`modal ${error ? 'error' : ''}`}
        value={inputs.day}
        onChange={e => {
          const isValid = Number(e.target.value) <= 31 && Number(e.target.value) >= 0;
          if (isValid) setInputs({ ...inputs, [inputIndexes[1]]: e.target.value });
        }}
        onBlur={() => {
          const isValid = Number(inputs.day) <= 31 && Number(inputs.day) >= 1;
          if (isTimeSlot) handleChangeSingleDate(isValid ? inputs.day : '01', 2);
          else if (isSameAsStartDate) handleSameDateChange(isValid ? inputs.day : '01', 2);
          else handleDateChange(isValid ? inputs.day : '01', 2);
        }}
        type='number'
        min={1}
        max={31}
      />
      <DateInput
        className={`modal ${error ? 'error' : ''}`}
        value={inputs.year}
        onChange={e => setInputs({ ...inputs, [inputIndexes[2]]: e.target.value })}
        onBlur={() => {
          const isValid = Number(inputs.year) >= Number(new Date().getFullYear());
          if (isTimeSlot)
            handleChangeSingleDate(isValid ? inputs.year : new Date().getFullYear().toString(), 3);
          else if (isSameAsStartDate)
            handleSameDateChange(isValid ? inputs.year : new Date().getFullYear().toString(), 3);
          else handleDateChange(isValid ? inputs.year : new Date().getFullYear().toString(), 3);
        }}
        type='number'
      />
      <DateBox className='picker height'>
        <IconContainer>
          <Icon icon={activeDays ? `${'white-calendar'}` : `${'green-calendar'}`} />
        </IconContainer>
        <DatePicker value={date} onChange={handleChange} minDetail='month' />
      </DateBox>
    </Wrapper>
  );
}
