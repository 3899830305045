import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { useQuery } from 'react-query';

import { getData } from '../../../../api/api';
import {
  modalStyle,
  ModalWrapper,
  Row,
  Title,
  ButtonModal,
  Column,
  Img,
} from '../../../../pages/WidgetCustomization/styles';
import { ticketBorder } from '../../../../utils/theme';
import { Line } from '../../../../pages/Profile/Terms/styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tickets: any;
  setTickets: any;
  index: number;
}

export const LibraryModal = ({ isOpen, setIsOpen, tickets, setTickets, index }: Props) => {
  const partner_id = localStorage.getItem('partnerId');
  const [selected, setSelected] = useState<number>(null);
  const { data: templates } = useQuery('getTemplates', () =>
    getData(`/templates?partner_id=${partner_id}`)
  );

  const handleSelected = () => {
    let newTickets = [...tickets];
    newTickets[index].templateId = selected;
    setTickets(newTickets);
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalWrapper className='library'>
        <Row className='centerBorder'>
          <Title className='modal'>Ticket library</Title>
        </Row>
        <Row className='centerBorder overflow'>
          <Column className='start'>
            {templates?.map(
              ({ image, ID }) =>
                image && (
                  <>
                    <Img
                      src={image}
                      className='ticket'
                      onClick={() => setSelected(ID)}
                      style={{
                        border: selected === ID ? `2px solid ${ticketBorder}` : 'none',
                      }}
                    />
                    <Line className='library' />
                  </>
                )
            )}
          </Column>
        </Row>
        <Row className='center'>
          <ButtonModal className='cancel' onClick={() => setIsOpen(false)}>
            Close
          </ButtonModal>
          <ButtonModal onClick={handleSelected}>Select Ticket</ButtonModal>
        </Row>
      </ModalWrapper>
    </Modal>
  );
};
