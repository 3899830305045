import moment from 'moment';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { getData, post } from '../../api/api';
import Button from '../../lib/components/Button/Button';
import Icon from '../../lib/components/Icon';
import {
  ADD_NEW,
  CANCEL,
  CONFIRM_IDENTITY,
  NOTES_HISTORY,
  PERSONAL_INFORMATION,
  RECENTS,
  REFUND_TICKET,
  RESET_PASSWORD,
  SUBMIT,
  VIEW_MORE,
} from '../../utils/strings';
import { ConfirmIdentityModal } from './ConfirmIdentity/ConfirmIdentityModal';
import { ConfirmSuccessModal } from './ConfirmIdentity/ConfirmSuccessModal';
import { PinRequestModal } from './ConfirmIdentity/PinRequestModal';
import NotesModal from './NotesModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import {
  ButtonContainer,
  Container,
  Column,
  Row,
  Text,
  Wrapper,
  Elements,
  TextArea,
  ContainerNote,
  Wrap,
  ButtonFanDatabase,
} from './style';
import { TicketHistory } from './TicketHistory';

export default function FanHistory() {
  const [isNotesOpen, setIsNotesOpen] = useState<boolean>(false);
  const history = useHistory();
  const partner_id = localStorage.getItem('partnerId');
  const member_id = localStorage.getItem('userId');
  const [valueNotes, setValueNotes] = useState<string>('');
  const [isResetOpen, setIsResetOpen] = useState<boolean>(false);
  const [isConfirmIdentity, setConfirmIdentity] = useState<boolean>(false);
  const [isRequiredPin, setIsRequiredPin] = useState(false);
  const [isSuccessConfirmation, setSuccessConfirmation] = useState<boolean>(false);
  const [isPressButton, setIsPressButton] = useState(false);
  const [isSelectEmail, setIsSelectEmail] = useState(true);

  const { pathname } = useLocation();
  const pathnameSplit = pathname.split('/');
  const userId = pathnameSplit[2];

  const { data: notes } = useQuery('getNotes', () =>
    getData(`/fan_notes/partners/${partner_id}/${userId}`)
  );
  const { data: fanData } = useQuery('getFanData', () =>
    getData(`/analytics/fandata/${partner_id}/${userId}`)
  );

  const { data: getUserInfo } = useQuery('getUserInfo', () => getData(`/users/${userId}`));

  const firstName = fanData?.PersonalInformation?.full_name?.split(' ');
  const info = [
    { title: 'First Name', information: firstName?.[0] },
    { title: 'Last Name', information: firstName?.[1] },
    { title: 'Billing Address', information: fanData?.PersonalInformation?.street_address },
    { title: 'City', information: fanData?.PersonalInformation?.city },
    { title: 'ZipCode', information: fanData?.PersonalInformation?.postal_code },
    { title: 'Country', information: fanData?.PersonalInformation?.country },
  ];

  const renderInfo = (data, className?: string) => (
    <Column className={className}>
      <Text className='titleElements'>{data.title}</Text>
      <Text className='elements'>{data.information}</Text>
    </Column>
  );

  const { mutate: handleMutationNotes } = useMutation(() =>
    post(`fan_notes`, {
      user_id: parseInt(userId),
      member_id: parseInt(member_id),
      partner_id: parseInt(partner_id),
      note: valueNotes,
    })
  );
  const { mutate: sendResetPasswordEmail } = useMutation(() =>
    post('/public/users/forgot', {
      email: fanData?.PersonalInformation?.email,
    })
  );

  const today = new Date();

  const filter = notes?.filter(
    note => moment(note?.CreatedAt).isoWeeks() === moment(today).isoWeek()
  );

  const filterLastWeeks = notes?.filter(
    note => moment(note?.CreatedAt).isoWeeks() !== moment(today).isoWeek()
  );

  return (
    <Container className='history'>
      <Wrapper className='section1'>
        <Wrap className='text'>
          <Icon
            icon={'arrow-left-background'}
            onClick={() =>
              history.push('/fan-database', { name: fanData?.PersonalInformation?.full_name })
            }
            className='cursor'
            height={50}
            width={35}
          />
          <Text>{fanData?.PersonalInformation?.email}</Text>
        </Wrap>
        <ButtonContainer className='ButtonOptions'>
          <Button
            color='gray'
            onClick={() => {
              setIsResetOpen(true);
              sendResetPasswordEmail();
            }}
          >
            {RESET_PASSWORD}
          </Button>
          <ResetPasswordModal isOpen={isResetOpen} setIsOpen={setIsResetOpen} />
          <Button color='gray' onClick={() => setConfirmIdentity(true)}>
            {CONFIRM_IDENTITY}
          </Button>
          <ConfirmIdentityModal
            email={fanData?.PersonalInformation?.email}
            phone_number={getUserInfo?.phone_number}
            isOpen={isConfirmIdentity}
            setIsOpen={setConfirmIdentity}
            setIsRequiredPin={setIsRequiredPin}
            setIsPressButton={setIsPressButton}
            setIsSelectEmail={setIsSelectEmail}
            isSelectEmail={isSelectEmail}
          />
          <PinRequestModal
            ID={userId}
            isOpen={isRequiredPin}
            setIsOpen={setIsRequiredPin}
            setSuccessConfirmation={setSuccessConfirmation}
            isSelectEmail={isSelectEmail}
            isPressButton={isPressButton}
          />
          <ConfirmSuccessModal isOpen={isSuccessConfirmation} setIsOpen={setSuccessConfirmation} />
          <Button
            color='gray'
            onClick={() =>
              history.push(`/fan-refund/${userId}`, {
                name: fanData?.PersonalInformation?.full_name,
              })
            }
          >
            {REFUND_TICKET}
          </Button>
        </ButtonContainer>
      </Wrapper>
      <Text className='title_section'>{PERSONAL_INFORMATION}</Text>
      <Wrapper className='section'>
        <Row>
          {renderInfo(info[0])}
          {renderInfo(info[1], 'column2')}
        </Row>
        <Row className='row2'>
          {info?.map((element, index) => index > 1 && renderInfo(element))}
        </Row>
      </Wrapper>
      <Text className='title_section'>{NOTES_HISTORY}</Text>
      <Wrapper className='section'>
        <Text>{ADD_NEW}</Text>
        <ContainerNote>
          <TextArea
            className='AddNotes'
            placeholder='Start writing your notes..'
            onChange={e => {
              setValueNotes(e.target.value);
            }}
          />
          <ButtonContainer className='addNotesButtons'>
            <ButtonFanDatabase disabled={valueNotes === ''} className='cancel'>
              {CANCEL}
            </ButtonFanDatabase>
            <ButtonFanDatabase
              disabled={valueNotes === ''}
              className='submit'
              onClick={() => {
                handleMutationNotes();
              }}
            >
              {SUBMIT}
            </ButtonFanDatabase>
          </ButtonContainer>
        </ContainerNote>
        <Text>{RECENTS}</Text>
        {filter?.map(element => (
          <Row>
            <Elements className='elements1'>{element?.member_email}</Elements>
            <Elements className='elements2'>{element?.note}</Elements>
            <Elements className='elements3'>
              {moment(element?.CreatedAt).format('MMM, DD, YYYY, HH:MM A')}
            </Elements>
          </Row>
        ))}
        <ButtonContainer>
          <Button color='gray' onClick={() => setIsNotesOpen(true)}>
            {VIEW_MORE}
          </Button>
        </ButtonContainer>
      </Wrapper>
      <TicketHistory fanData={fanData} />
      <NotesModal
        isNotesOpen={isNotesOpen}
        setIsNotesOpen={setIsNotesOpen}
        notesRecents={filter}
        notesLastWeeks={filterLastWeeks}
        userId={userId}
        memberId={member_id}
        partnerId={partner_id}
      />
    </Container>
  );
}
