import React from 'react';

import {
  Column,
  PaletteContainer,
  Text,
  AddImg,
  Row,
  DeletePalette,
} from '../WidgetCustomization/styles';
import { PALETTE } from '../../utils/strings';
import { deletePalette, editPalette, plusIcon } from '../../static/icons';
import { paletteBorder } from '../../utils/theme';

interface Props {
  title: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  color: string;
  handleDelete: (toDelete: any) => void;
  label: string;
}

export const RenderPalette = ({
  title,
  setIsOpen,
  color,
  handleDelete,
  label,
}: Props) => {
  return (
    <Column className='margin' key={title}>
      <Text className='palette'>
        {title} {PALETTE}
      </Text>
      <Column className='fit'>
        <PaletteContainer
          className='large'
          style={{
            backgroundColor: color || 'transparent',
            border: color ? 'none' : `1.5px dashed ${paletteBorder}`,
          }}
          onClick={() => setIsOpen(true)}
        >
          {!color ? (
            <AddImg src={plusIcon} alt='add' />
          ) : (
            <AddImg src={editPalette} alt='edit' />
          )}
        </PaletteContainer>
        {color && (
          <Row className='margin'>
            <DeletePalette
              src={deletePalette}
              alt='delete'
              onClick={() => handleDelete({ [label]: '' })}
            />
          </Row>
        )}
      </Column>
    </Column>
  );
};
