import { useHistory } from 'react-router';

import { Button } from '../../lib/components/Button';
import { ButtonVariants } from '../../lib/components/Button/Button';
import { ButtonContainer, ButtonsContainer } from './styles';

export default function Buttons({ handleSubmit }) {
  const history = useHistory();
  return (
    <ButtonsContainer>
      <ButtonContainer>
        <Button
          onClick={() => history.push('login')}
          className='full-width'
          variant={ButtonVariants.outlined}
        >
          Cancel
        </Button>
      </ButtonContainer>
      <ButtonContainer>
        <Button onClick={handleSubmit} className='full-width'>
          Reset password
        </Button>
      </ButtonContainer>
    </ButtonsContainer>
  );
}
