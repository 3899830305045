import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { getData } from '../../../api/api';
import ExistingPromoCodes from '../../../lib/components/EventCreation/PromoCodes/ExistingPromoCodes';
import SearchBar from '../../../lib/components/EventCreation/PromoCodes/SearchBar';
import { TicketsType } from '../../../types';
import { Container, Column, Row, Title, Line, Button } from './styles';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  index: number;
}

export const ExistingPCPopup = ({ setIsOpen, setTickets, tickets, index }: Props) => {
  const [search, setSearch] = useState<string>('');
  const partner_id = localStorage.getItem('partnerId');

  const { data: promoCodes } = useQuery('getAllPromoCodes', () =>
    getData(`promocodes?partner_id=${partner_id}`)
  );

  return (
    <Container>
      <Column>
        <Column className='top'>
          <Title>Select Promo Codes to apply</Title>
          <SearchBar setValue={setSearch} value={search} fromPopUp />
        </Column>
        <Line />
        <ExistingPromoCodes
          promoCodes={promoCodes}
          setTickets={setTickets}
          tickets={tickets}
          index={index}
          search={search}
        />
        <Line />
        <Row className='center bottom'>
          <Button className='cancel' onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={() => setIsOpen(false)}>Attach</Button>
        </Row>
      </Column>
    </Container>
  );
};
