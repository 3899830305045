import * as Yup from 'yup';

import {
  EMAIL,
  EMAIL_IS_INVALID,
  EMAIL_IS_REQUIRED,
} from '../../utils/strings';

export const FORGOT_PASSWORD_FIELDS = {
  email: 'email',
};

export const FORGOT_PASSWORD_INITIAL_VALUES = {
  email: '',
};

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
});

export const forgotPasswordInputData = [
  {
    name: FORGOT_PASSWORD_FIELDS.email,
    label: EMAIL,
    placeholder: 'john.smith@gmail.com',
  },
];
