import moment from 'moment';

import {
  RowWrapper,
  STable,
  STableBody,
  STableCell,
  STableContainer,
  STableHead,
  STableRow,
} from './tableStyle';

type Props = {
  info: any;
};
export function TableAttendance({ info }: Props) {
  const title = ['Event', 'Date', 'Ticket type', 'Primary or Secondary'];
  return (
    <STableContainer>
      <STable className='tableTicket'>
        <STableHead>
          <STableRow index>
            {title.map(title => (
              <STableCell>{title}</STableCell>
            ))}
          </STableRow>
        </STableHead>
        <STableBody>
          {info?.map(({ index, Event, EventDate, TicketType, PrimaryOrSecondary }) => (
            <RowWrapper index={index}>
              <STableCell>{Event}</STableCell>
              <STableCell> {moment(EventDate).format('MMMM DD, YYYY')}</STableCell>
              <STableCell>{TicketType}</STableCell>
              <STableCell>{PrimaryOrSecondary}</STableCell>
            </RowWrapper>
          ))}
        </STableBody>
      </STable>
    </STableContainer>
  );
}
