import styled from 'styled-components';

import { white, green } from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  &.space-between {
    justify-content: space-between;
    padding: 0px 40px;
  }
  &.center {
    align-items: center;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  letter-spacing: -0.03em;
  color: ${white};
`;

export const Img = styled.img`
  margin-left: 6px;
`;

export const PdfButton = styled.div`
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${green};
  padding: 8px 16px;
  justify-content: space-evenly;
  cursor: pointer;
`;

export const csvStyled = {
  display: 'flex',
  textDecoration: 'none',
};
