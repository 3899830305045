import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { getData } from '../../api/api';
import {
  completeStatus,
  enabledStatus,
  pendingStatus,
  rejectedStatus,
  restrictedSoonStatus,
  restrictedStatus,
} from '../../static/img';
import { ADD_BANK_INFO, BANK_INFO } from '../../utils/strings';
import { StatusCode } from './StatusCode';
import { Column, Title, AddInfoButton, Status, Row, Img } from './styles';

const partnerId = localStorage.getItem('partnerId');

interface Props {
  status: string;
}

export const BankInfo = ({ status }: Props) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const { refetch: fetchURL } = useQuery(
    'getStripe',
    () => getData(`/partners/${partnerId}/stripe_link`),
    {
      onSuccess: data => (window.location.href = data?.AccountLink?.url),
      enabled: false,
    }
  );

  const handleConnectStripe = () => fetchURL();

  const handleStatus = () => {
    switch (status) {
      case 'enabled':
        return enabledStatus;
      case 'pending':
        return pendingStatus;
      case 'restricted soon':
        return restrictedSoonStatus;
      case 'restricted':
        return restrictedStatus;
      case 'complete':
        return completeStatus;
      case 'rejected':
        return rejectedStatus;
      default:
        return pendingStatus;
    }
  };

  return (
    <Column className='wrapper'>
      <Title>{BANK_INFO}</Title>
      {!status ? (
        <AddInfoButton onClick={handleConnectStripe}>{ADD_BANK_INFO}</AddInfoButton>
      ) : (
        <Column>
          <Row
            onMouseOver={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            className='fitContent'
          >
            <Status>{status === 'complete' ? 'Account Connected' : status}</Status>
            <Row>
              <Img className='status' src={handleStatus() as any} />
              {isHover && status !== 'complete' && (
                <StatusCode info={status === 'restricted soon' ? 'restrictedSoon' : status} />
              )}
            </Row>
          </Row>
          {/* fix when backend is ready */}
          {/* <AddInfoButton className='manage' onClick={handleConnectStripe}>
            Manage Account
          </AddInfoButton> */}
        </Column>
      )}
    </Column>
  );
};
