import React from 'react';
import { Modal } from '@mui/material';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { CANCEL, SAVE_CHANGES, SELECT_COLOR } from '../../utils/strings';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../WidgetCustomization/styles';

interface PaletteProps {
  data: string;
  label: string;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSave: any;
}

export const PaletteModal = ({
  data,
  isOpen,
  setOpen,
  handleSave,
  label,
}: PaletteProps) => {
  const [color, setColor] = useColor('hex', data);

  return (
    <Modal open={isOpen} onClose={() => setOpen(false)} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>{SELECT_COLOR}</Title>
        </Row>
        <Row className='centerBorder'>
          <ColorPicker
            width={456}
            height={228}
            color={color}
            onChange={setColor}
            hideHSV
            dark
          />
        </Row>
        <Row className='center'>
          <ButtonModal className='cancel' onClick={() => setOpen(false)}>
            {CANCEL}
          </ButtonModal>
          <ButtonModal
            onClick={() => {
              handleSave({ [label]: color.hex });
              setOpen(false);
            }}
          >
            {SAVE_CHANGES}
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
