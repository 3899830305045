import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button } from '../../lib/components/Button';
import { Input } from '../../lib/components/Inputs/Input';
import { Divider } from '../../lib/components/Divider';
import {
  Background,
  ButtonContainer,
  ErrorMessage,
  Form,
  Wrapper,
  PartnerLogo,
  Label,
  Email,
  Title,
} from './styles';
import { logoBackground } from '../../static/img';
import { memberLoginInputData, LoginSchema, LOGIN_INITIAL_VALUES } from './utils';
import { updatePassword } from 'firebase/auth';
import { auth } from '../../api/auth';
import { useMutation } from 'react-query';
import { put } from '../../api/api';
import { Div, Loading } from '../../lib/components/layout/Navigation/Navbar/styles';
import { loading } from '../../static/animations';
import useLoading from '../../hooks/useLoading';
import { request, success } from '../../stores/userSlice';
import useMobileView from '../../hooks/useMobileView';

export default function MemberLogin() {
  const history = useHistory();
  const isLoading = useLoading();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { error } = useSelector(store => store['user']);
  const email = new URLSearchParams(search).get('email');
  const isMobile = useMobileView();

  const { mutate: updateMemberMutation } = useMutation(() =>
    put(`/users/${new URLSearchParams(search).get('user_id')}`, {
      status: true,
      firebase_id: localStorage.getItem('uid'),
    })
  );

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: LoginSchema,
    onSubmit: values => {
      dispatch(request());
      updatePassword(auth.currentUser, values.password).then(() => {
        dispatch(success());
        window.open('https://producer.tixologi.com/login', '_self');
      });
    },
  });

  useEffect(() => {
    updateMemberMutation();
  }, [email, search, updateMemberMutation]);

  return (
    <>
      <Background src={logoBackground} />
      <Wrapper>
        <Title>Welcome!</Title>
        <Divider />
        <Form isMobile={isMobile}>
          <PartnerLogo src={new URLSearchParams(search).get('partner_logo')} />
          <div>
            You have been invited by{' '}
            <strong>{new URLSearchParams(search).get('invited_by')}</strong> to join the{' '}
            <strong>{new URLSearchParams(search).get('partner_name')}</strong>
          </div>
          <div>Please add a password to log into your account</div>
          <Label>Email (to be used for login)</Label>
          <Email>{email}</Email>
          {memberLoginInputData.map(({ name, label }) => (
            <Input
              value={values[name]}
              setValue={
                handleChange(name) as unknown as (e: string | React.ChangeEvent<any>) => void
              }
              id={name}
              label={label}
              type='password'
              error={errors[name]}
              touched={touched[name]}
            />
          ))}
          <ErrorMessage>{error}</ErrorMessage>
        </Form>
        <Divider />
        <Form isMobile={isMobile}>
          <ButtonContainer>
            <Button onClick={handleSubmit} className='full-width'>
              Create Account
            </Button>
          </ButtonContainer>
        </Form>
      </Wrapper>
      {!!isLoading && (
        <>
          <Div />
          <Loading src={loading} alt='loading...' />
        </>
      )}
    </>
  );
}
