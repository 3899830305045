import { useEffect, useState } from 'react';

import {
  ALL_TICKETS,
  ALL_TICKETS_FOR_EVENTS,
  CANCEL,
  CONFIRM,
  COPY_PROMOCODE,
} from '../../../utils/strings';
import Icon from '../Icon/Icon';
import Radio from '../Inputs/Radio/Radio';
import { Button, ButtonContainer, ContainerText, ModalContainer, ModalRow, Text } from './styles';
import { EventFromBack } from '../../../types';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvents: number[];
  newEvents: EventFromBack[];
  setNewEvents: React.Dispatch<React.SetStateAction<EventFromBack[]>>;
  eventId: number;
  eventIndex: number;
  index: number;
}

export const CopyPromoModal = ({
  setIsOpen,
  selectedEvents,
  newEvents,
  setNewEvents,
  eventId,
  eventIndex,
  index,
}: Props) => {
  const handleClose = () => setIsOpen(false);
  const [quantityInOneEvent, setQuantityInOneEvent] = useState(false);
  const [quantityInAllEvents, setQuantityInAllEvents] = useState(false);

  useEffect(() => {
    if (quantityInAllEvents) {
      setNewEvents(
        newEvents.reduce((accumulated, current) => {
          selectedEvents.forEach(eventId => {
            if (eventId === current.ID) {
              const newTicketTypes = current.ticket_types?.map(({ ID, code, checked }) => ({
                ID,
                code,
                quantity: newEvents[eventIndex].ticket_types[index].quantity,
                checked,
              }));
              current.ticket_types = newTicketTypes;
            }
          });
          accumulated.push(current);
          return accumulated;
        }, [])
      );
    }
  }, [quantityInAllEvents]);

  useEffect(() => {
    if (quantityInOneEvent) {
      setNewEvents(
        newEvents.reduce((accumulated, current) => {
          if (current.ID === eventId) {
            const newTicketTypes = current.ticket_types?.map(({ ID, code, checked }) => ({
              ID,
              code,
              quantity: newEvents[eventIndex].ticket_types[index].quantity,
              checked,
            }));
            current.ticket_types = newTicketTypes;
          }
          accumulated.push(current);
          return accumulated;
        }, [])
      );
    }
  }, [quantityInOneEvent]);

  return (
    <ModalContainer>
      <ModalRow className='centerBorder'>
        <ContainerText>
          <Icon icon='copyWhite' className='cursor' />
          <Text>{COPY_PROMOCODE}</Text>
        </ContainerText>
        <ContainerText>
          <Radio
            checked={quantityInOneEvent}
            textSize={10}
            setChecked={checked => setQuantityInOneEvent(checked)}
          />
          <Text>{ALL_TICKETS}</Text>
        </ContainerText>
        <ContainerText>
          <Radio
            checked={quantityInAllEvents}
            textSize={10}
            setChecked={checked => setQuantityInAllEvents(checked)}
          />
          <Text>{ALL_TICKETS_FOR_EVENTS} </Text>
        </ContainerText>

        <ButtonContainer className='copy'>
          <Button className='cancel' onClick={handleClose}>
            {CANCEL}
          </Button>
          <Button className='confirm' onClick={handleClose}>
            {CONFIRM}
          </Button>
        </ButtonContainer>
      </ModalRow>
    </ModalContainer>
  );
};
