import Input from '../Inputs/Input/Input';
import Icon from '../Icon';
import {
  CardContainer,
  SponsorCard,
  RemoveSponsorCard,
  SponsorInput,
  ButtonContainer,
  Container,
  Image,
  Description,
  AddSponsor,
  Img,
} from './styles';
import { SquareButton } from '../Button';
import { SquareButtonVariants } from '../Button/SquareButton';
import { getBase64Sponsors } from '../../../utils/utils';
import { addGreen } from '../../../static/icons';

export default function AddSponsorsForm({ value, setValue }) {
  async function imageUpload(index) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg';
    fileInput.name = 'image-upload';
    fileInput.id = 'hidden-image-upload';
    fileInput.style.position = 'fixed';
    fileInput.style.top = '-100vh';
    fileInput.addEventListener('change', async () => {
      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        getBase64Sponsors(file, index, setValue);
      }
      fileInput.remove();
    });
    document.body.appendChild(fileInput);
    fileInput.click();
  }

  return (
    <Container>
      <Description>
        Sponsor’s will appear on your event’s page as well as in your media library when creating
        tickets.
      </Description>
      <CardContainer>
        {value?.sponsors?.map((sponsor, i) => (
          <SponsorCard key={i}>
            {i !== 0 && (
              <RemoveSponsorCard
                onClick={() => {
                  setValue({
                    ...value,
                    sponsors: value.sponsors.filter((_, index) => index !== i),
                  });
                }}
              >
                <Icon icon='x' height={26} className='fill-info' />
              </RemoveSponsorCard>
            )}
            <SponsorInput>
              <label htmlFor={`event-sponsor-${i}`}> sponsor name (optional) </label>
              <Input
                value={sponsor.name}
                setValue={name => (sponsor.name = name)}
                id={`event-sponsor-${i}`}
                placeholder='Enter sponsor name'
                touched={false}
              />
            </SponsorInput>
            <SponsorInput>
              <label htmlFor={`${sponsor.name}-logo`}> sponsor logo (optional) </label>
              {sponsor.image !== null ? (
                <>
                  <Image src={sponsor.image} alt={`${sponsor.name}-logo`} />

                  <ButtonContainer>
                    <SquareButton
                      variant={SquareButtonVariants.red}
                      onClick={() =>
                        setValue({
                          ...value,
                          sponsors: value.sponsors.map((aSponsor, index) => {
                            if (index === i) return { ...aSponsor, image: null };
                            else return aSponsor;
                          }),
                        })
                      }
                    >
                      Delete
                    </SquareButton>
                    <SquareButton
                      onClick={() => imageUpload(i)}
                      variant={SquareButtonVariants.green}
                      decoratorRight={
                        <Icon icon='upload' height={24} className='fill-text-button' />
                      }
                    >
                      Replace
                    </SquareButton>
                  </ButtonContainer>
                </>
              ) : (
                <SquareButton
                  onClick={() => imageUpload(i)}
                  variant={SquareButtonVariants.green}
                  decoratorRight={<Icon icon='upload' height={24} />}
                >
                  Upload Image
                </SquareButton>
              )}
            </SponsorInput>
          </SponsorCard>
        ))}
        <AddSponsor
          onClick={() =>
            setValue({
              ...value,
              sponsors: [...value.sponsors, { name: null, image: null }],
            })
          }
        >
          <Img src={addGreen} />
          add sponsor
        </AddSponsor>
      </CardContainer>
    </Container>
  );
}
