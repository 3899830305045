import styled from 'styled-components';

export const Container = styled.div`
  width: calc(100% - 4px);
  border-radius: 0.25rem;
  background-color: var(--color-bg-input);
  position: relative;
  border: solid 2px var(--color-bg-input);
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &.error {
    outline: 1px solid var(--color-danger-default);
    border: 1px solid var(--color-bg-input) !important;
  }
  &.selected {
    border: solid 2px var(--color-text-input);
    border-radius: 0.25rem;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  margin-bottom: 0.25rem;
  display: block;
`;

export const Span = styled.span`
  display: block;
  padding: 0.5rem;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  min-height: 1.7rem;
  color: var(--color-text-input);

  &.option {
    color: var(--color-text);
    border-top: 1px solid var(--color-text);
    &:first-child {
      border: none;
    }
    &:hover {
      background-color: var(--color-level-2);
    }
  }
`;

export const DropdownContainer = styled.span`
  position: absolute;
  width: calc(100% + 4px);
  left: -2px;
  background-color: var(--color-bg-1);
  top: calc(100% + 2px);
  border-radius: 0 0 1rem 1rem;
  z-index: 100;
  overflow: auto;
  max-height: 20rem;
`;

export const Button = styled.button`
  position: absolute;
  border-radius: 0 0.25rem 0.25rem 0;
  top: -2px;
  right: -2px;
  height: calc(100% + 4px);
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-text-input);
  border: solid 2px var(--color-text-input);

  &:focus-visible {
    outline: none;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;
