import styled from 'styled-components';

import { emailBackground } from '../../../../utils/theme';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;

  &.margin-top {
    margin-top: 1rem;
  }
  &.margin-top-same {
    margin-top: 6px;
  }
  &.activeDays {
    width: 100%;
  }
`;

export const DateBox = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: var(--color-bg-input);
  border-radius: 0.25rem;
  border: 2px solid var(--color-bg-input);
  padding: 0.5rem;
  width: 100%;
  font-size: 1.25rem;
  letter-spacing: -0.03em;
  color: var(--color-text-input);

  &.picker {
    border: none;
    background-color: transparent;
    position: relative;
    cursor: pointer;
    width: fit-content;
    padding: 0;
  }
  &.summary {
    margin-left: 10px;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
  &.height {
    height: 47px;
  }
`;

export const IconContainer = styled.div`
  position: absolute;

  &.calendar {
    margin-left: 80px;
  }
`;

export const DateInput = styled.input`
  border-radius: 4px;
  background: ${emailBackground};
  border: none;
  padding: 14px 65px;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.modal {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;
