import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { TextareaAutosize } from '@mui/base';

import { editField } from './services';
import { StyledTextArea } from './styles';

interface Props {
  description: string;
  item?: string;
  eventId?: number;
}

export const TextArea = ({ description, item, eventId }: Props) => {
  const [value, setValue] = useState<string>(description);
  const [hover, setHover] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(false);

  const { mutate: mutateEdit } = useMutation(editField);

  return (
    <TextareaAutosize
      onFocus={() => setFocus(true)}
      onBlur={() => {
        mutateEdit({ value, item, id: eventId });
        setFocus(false);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      value={value}
      onChange={e => setValue(e.target.value)}
      style={{
        ...StyledTextArea,
        background: hover || focus ? 'black' : 'none',
      }}
    />
  );
};
