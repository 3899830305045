import { useState } from 'react';

import { InputContainer, Section, ButtonContainer, Text, Button, ContainerInfo } from './styles';
import { promoCodeFormType, TimeType } from '../../../types';
import { ACTIVE_DAYS, LIMITED_DAYS, UNLIMITED } from '../../../utils/strings';
import DaysAndHoursForm from '../EventCreation/PromoCodes/CreatePromoCode/Components/DaysAndHoursForm';

type Props = {
  value: promoCodeFormType;
  setValue: Function;
  startTime: TimeType;
  setStartTime: Function;
  endTime: TimeType;
  setEndTime: Function;
};

const ActiveDaysForm = ({
  value,
  setValue,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}: Props) => {
  const [limitedDays, setLimitedDays] = useState(false);
  const [greenButton, setGreenButton] = useState(true);
  return (
    <Section className='section2'>
      <InputContainer className='width50'>
        <ContainerInfo>
          <Text className='title'> {ACTIVE_DAYS}</Text>
          <ButtonContainer className='container'>
            <Button
              className='primary'
              onClick={() => {
                setLimitedDays(true);
                setGreenButton(false);
              }}
              greenButton={greenButton}
            >
              {LIMITED_DAYS}
            </Button>
            <Button
              className='secondary'
              onClick={() => {
                setLimitedDays(false);
                setGreenButton(true);
                setValue({ ...value, end_date: new Date('1/1/1970') });
              }}
              greenButton={greenButton}
            >
              {UNLIMITED}
            </Button>
          </ButtonContainer>
          {limitedDays && (
            <DaysAndHoursForm
              value={value}
              setValue={setValue}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
            />
          )}
        </ContainerInfo>
      </InputContainer>
    </Section>
  );
};

export default ActiveDaysForm;
