import React from 'react';

import { searchIcon } from '../../../../../static/icons';
import { Container, Img, StyledInput } from './styles';

interface Props {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  fromPopUp?: boolean;
}

const SearchBar = ({ value, setValue, fromPopUp }: Props) => (
  <Container fromPopUp={fromPopUp}>
    <Img src={searchIcon} alt='search' />
    <StyledInput
      value={value}
      onChange={(e: any) => setValue(e.target.value)}
      placeholder='Search'
    />
  </Container>
);

export default SearchBar;
