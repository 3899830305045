import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import { Button } from '../../lib/components/Button';
import { Input } from '../../lib/components/Inputs/Input';
import { Divider } from '../../lib/components/Divider';
import GoogleButton from './GoogleButton';
import {
  Background,
  ButtonContainer,
  ErrorMessage,
  Form,
  Wrapper,
  ForgotPasswordLink,
} from './styles';
import { logoBackground } from '../../static/img';
import { loginInputData, LoginSchema, LOGIN_FIELDS, LOGIN_INITIAL_VALUES } from './utils';
import { reset } from '../../stores/userSlice';
import useLoading from '../../hooks/useLoading';
import { Div, Loading } from '../../lib/components/layout/Navigation/Navbar/styles';
import { loading } from '../../static/animations';
import { TwoFactorModal } from '../Profile/TwoFactorAuthentication/TwoFactorModal';
import { googleSignin, signin } from '../../api/auth';
import { ConfirmCode } from '../Profile/TwoFactorAuthentication/ConfirmCode';
import useMobileView from '../../hooks/useMobileView';

export default function Login() {
  const history = useHistory();
  const isLoading = useLoading();
  const dispatch = useDispatch();
  const isAuthenticated = localStorage.getItem('accessToken');
  const isMobile = useMobileView();

  const { error } = useSelector(store => store['user']);

  //two factor authentication
  // const [isOpenLoginFirstTime, setIsOpenLoginFirstTime] = useState<boolean>(false);
  // const [isEmailAuthentication, setIsEmailAuthentication] = useState<boolean>(false);
  // const [isOpenModalAuthentication, setIsOpenModalAuthentication] = useState<boolean>(false);

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: LOGIN_INITIAL_VALUES,
    validationSchema: LoginSchema,
    onSubmit: values => {
      handleSignIn(values);
    },
  });

  // two factor: add arguments to sign in: setIsOpenLoginFirstTime, setIsOpenModalAuthentication,
  const handleSignIn = ({ email, password }) =>
    dispatch(signin(email, password, () => history.push('/organization')));

  useEffect(() => {
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  return (
    <>
      <Background src={logoBackground} />
      <Wrapper isMobile={isMobile}>
        <h1>Login</h1>
        <Form isMobile={isMobile}>
          {loginInputData.map(({ placeholder, name, label }) => (
            <Input
              value={values[name]}
              setValue={
                handleChange(name) as unknown as (e: string | React.ChangeEvent<any>) => void
              }
              id={name}
              placeholder={placeholder}
              label={label}
              type={name === LOGIN_FIELDS.password ? 'password' : 'text'}
              error={errors[name]}
              touched={touched[name]}
            />
          ))}
          <ErrorMessage>{error}</ErrorMessage>
          <ForgotPasswordLink onClick={() => history.push('/forgot-password')}>
            Forgot password?
          </ForgotPasswordLink>
          <ButtonContainer>
            <Button onClick={handleSubmit} className='full-width'>
              Log in
            </Button>
            {/* two factor authentication */}
            {/* {isOpenLoginFirstTime && (
              <TwoFactorModal isOpen={isOpenLoginFirstTime} setIsEmail={setIsEmailAuthentication} />
            )}
            {isOpenModalAuthentication && (
              <ConfirmCode
                isOpen={true}
                setIsOpen={setIsOpenModalAuthentication}
                isEmail={isEmailAuthentication}
              />
            )} */}
          </ButtonContainer>
          <Divider>OR</Divider>
          <ButtonContainer>
            <GoogleButton onClick={() => dispatch(googleSignin(() => history.push('/event-list')))}>
              Continue with Google
            </GoogleButton>
          </ButtonContainer>
        </Form>
      </Wrapper>
      {!!isLoading && (
        <>
          <Div />
          <Loading src={loading} alt='loading...' />
        </>
      )}
    </>
  );
}
