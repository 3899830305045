import styled from 'styled-components';

export const Wrapper = styled.div``;
export const Circle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 216px;
  align-self: center;
  overflow: hidden;

  background: var(--color-level-2);
  border-radius: 100%;
  margin-bottom: 30px;
  padding: 2px;
`;

export const Image = styled.img<{ src: string }>`
  border-radius: 50%;
  background: var(--color-level-2);
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
