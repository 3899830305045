import styled, { css } from 'styled-components';
import { borderLine, green, headerCell, lightGray, veryDarkGray } from '../../../utils/theme';
import { ButtonVariants } from './Button';
import { SquareButtonVariants } from './SquareButton';

export const Wrapper = styled.button<{
  variant: ButtonVariants;
  height: number;
  isBlock: boolean;
  shape: string;
  textSize: number;
  color: string;
}>`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  width: fit-content;
  border-radius: 8px;
  width: 300px;
  --btn-bg: var(--color-primary-default);
  --btn-hov: var(--color-primary-dark);
  --btn-txt: var(--color-text-button-inverse);

  background-color: var(--btn-bg);
  color: var(--btn-txt);
  border: solid 1px var(--btn-bg);

  &:hover {
    background-color: var(--btn-hov);
    border: solid 1px var(--btn-hov);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &.large-width {
    width: 192px;
  }

  &.full-width {
    width: 100%;
  }

  &.black {
    color: var(--color-bg-2);
    font-weight: 600;
  }
  &.submit {
    background-color: ${green};
    border-radius: 0px 0px 16px 0px;
    &:disabled {
      opacity: 0.5;
    }
  }
  &.cancel {
    border-radius: 0px;
    background-color: ${lightGray};
    color: white;
    &:disabled {
      opacity: 0.5;
    }
    &:hover {
      background-color: #9b9b9b;
    }
  }
  &.option {
    border-radius: 4px;
    width: 315.33px;
    height: 44px;
    background: ${headerCell};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    &:hover {
      background: ${veryDarkGray};
      border-color: ${borderLine};
    }
  }

  ${props =>
    props.textSize &&
    css`
      font-size: ${props.textSize}px;
    `};
  ${props =>
    props.height &&
    css`
    height: ${props.height}px;
    padding: {0.5 * props.height}px;
  `};

  ${props =>
    props.color &&
    css`
      border-color: var(--color-${props.color}-default);
      background-color: var(--color-${props.color}-default);
      &:hover {
        border-color: var(--color-${props.color}-dark);
        background-color: var(--color-${props.color}-dark);
      }
    `};

  ${props =>
    props.variant === ButtonVariants.outlined &&
    css`
      border: solid 1px var(--color-text);
      background-color: hsl(0, 0%, 0%, 0);

      &:hover {
        background-color: hsl(0, 0%, 100%, 0.1);
        border: solid 1px var(--color-text);
      }
    `};
  ${props =>
    props.variant === ButtonVariants.greenOutlined &&
    css`
      border: solid 1px var(--color-primary-default);
      background-color: hsl(0, 0%, 0%, 0);
      color: var(--color-primary-default);

      &:hover {
        background-color: hsl(0, 0%, 100%, 0.1);
      }
    `};
  ${props =>
    props.isBlock &&
    css`
      width: 100%;
    `};
  ${props =>
    props.shape === 'pill' &&
    css`
      border-radius: 10000rem;
    `};
  ${props =>
    props.shape === 'square' &&
    css`
      border-radius: 4px;
    `};
`;

export const SquareWrapper = styled.button<{
  variant: SquareButtonVariants;
  textSize: number;
  isBlock: boolean;
}>`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  height: 45px;
  width: 160px;
  border-radius: 4px;

  --btn-bg: var(--color-primary-default);
  --btn-hov: var(--color-primary-dark);
  --btn-txt: var(--color-text-button-inverse);

  background-color: var(--btn-bg);
  color: var(--btn-txt);

  &:hover {
    background-color: var(--btn-hov);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.large-width {
    width: 192px;
  }

  &.full-width {
    width: 100%;
  }

  ${props =>
    props.textSize &&
    css`
      font-size: ${props.textSize}px;
    `};
  ${props =>
    props.variant === SquareButtonVariants.green &&
    css`
      background-color: var(--color-primary-default);
      color: var(--color-bg-3);

      &:hover {
        background-color: var(--color-primary-dark);
      }
    `};
  ${props =>
    props.variant === SquareButtonVariants.red &&
    css`
      background-color: var(--color-danger-default);
      color: var(--color-text);

      &:hover {
        background-color: var(--color-danger-dark);
      }
    `};
  ${props =>
    props.variant === SquareButtonVariants.grey &&
    css`
      background-color: var(--color-gray-2);
      color: var(--color-text);
      font-weight: 400;
      text-transform: uppercase;

      &:hover {
        background-color: var(--color-gray-1);
      }
    `};
  ${props =>
    props.isBlock &&
    css`
      width: 100%;
    `};
  ${props =>
    !props.isBlock &&
    css`
      margin-right: 10px;
    `};
`;

export const ChildrenContainer = styled.div`
  margin: 0 0.5rem;
  white-space: nowrap;
`;

export const Decorator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    padding: 0 1rem 0 0;
  }
  &:first-child {
    padding: 0 0 0 1rem;
  }
`;
