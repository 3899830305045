const partnerId = localStorage.getItem('partnerId');

export const getBase64 = (file, setModalAvatar) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const baseURL = reader.result;
      setModalAvatar(baseURL);
    };
  });
};

export const getBase64Ticket = (file, tickets, setTickets, index, mutateTemplate) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      mutateTemplate({ image: reader.result, partnerId: Number(partnerId) });
    };
  });
};

export const getBase64Sponsors = (file, index, setValue) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const baseURL = reader.result;
      setValue(value => {
        const newSponsors = [...value.sponsors];
        newSponsors[index].image = baseURL;
        return { ...value, sponsors: newSponsors };
      });
    };
  });
};

export const getBase64ImageForm = (file, setValue, value) => {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const baseURL = reader.result;
      setValue({
        ...value,
        eventImage: baseURL,
      });
    };
  });
};

export const resetLocalStorage = () => {
  localStorage.clear();
  window.location.href = '/login';
};
