import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';

import { EventFromBack, promoCodeFormType, TicketPromoCode } from '../../../types';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import {
  ButtonContainer,
  CheckboxContainer,
  Container,
  Input,
  InputContainer,
  Search,
  Text,
} from './styles';
import { searchIcon } from '../../../static/icons';
import { getData } from '../../../api/api';
import EventsAndTicketsForm from './EventsAndTicketsForm';
import ButtonEventsForm from './ButtonEventsForm';
import { APPLY_ALL_EVENTS, SEARCH_EVENT_TO_VIEW, SELECT_ALL_EVENTS } from '../../../utils/strings';

type Props = {
  value: promoCodeFormType;
  setValue: Function;
  promoId: number;
  selectedEvents: number[];
  setSelectedEvents: React.Dispatch<React.SetStateAction<number[]>>;
  selectedTickets: TicketPromoCode[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<TicketPromoCode[]>>;
  fromEvent: boolean;
  eventId: number;
};

const ApplyToTicketsForm = ({
  selectedEvents,
  setSelectedEvents,
  selectedTickets,
  setSelectedTickets,
  fromEvent,
  eventId,
}: Props) => {
  const [allEvents, setAllEvents] = useState(false);
  const [allTickets, setAllTickets] = useState();
  const [eventsToSearch, setEventToSearch] = useState('');
  const [newEvents, setNewEvents] = useState<EventFromBack[]>([]);
  const [newEvent, setNewEvent] = useState<EventFromBack[]>([]);
  const partner_id = localStorage.getItem('partnerId');

  const { data: events } = useQuery('getEvents', () => getData(`events?partner_id=${partner_id}`), {
    enabled: !fromEvent,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      setNewEvents(
        data.reduce((accumulated, current) => {
          const newTicketTypes = current.ticket_types?.map(({ ID, name }) => ({
            ID,
            code: name,
            quantity: '-1',
            checked: false,
          }));
          current.ticket_types = newTicketTypes;
          accumulated.push(current);
          return accumulated;
        }, [])
      );
    },
  });

  const { data: event } = useQuery('getEvent', () => getData(`events/${eventId}`), {
    enabled: !!fromEvent,
    refetchOnWindowFocus: false,
    onSuccess: data => {
      let updateEvent = { ...data };
      const newTicketTypes = updateEvent.ticket_types?.map(({ ID, name }) => ({
        ID,
        code: name,
        quantity: '-1',
        checked: false,
      }));
      updateEvent.ticket_types = newTicketTypes;
      setNewEvent([updateEvent]);
    },
  });

  const subTitlesEvent = [event?.name, 'Promo code uses'];

  const subtitles = useMemo(
    () =>
      events?.map(({ name, ID }) => ({
        ID,
        table: {
          name,
          text: 'Promo Code Uses',
        },
      })),
    [events]
  );

  return (
    <InputContainer>
      {!fromEvent ? (
        <>
          <InputContainer className='checkboxContainer'>
            <CheckboxContainer>
              <Container>
                <Checkbox
                  checked={allEvents}
                  setChecked={checked => setAllEvents(checked)}
                  className='green'
                />
                <Text className='small'>{SELECT_ALL_EVENTS}</Text>
              </Container>
              <Container className='checkbox'>
                <div
                  onClick={() => {
                    let array = [];
                    if (!allTickets) {
                      newEvents?.forEach(({ ticket_types }) => {
                        ticket_types?.forEach(ticket => array.push(ticket));
                      });
                    }
                    setSelectedTickets(array);
                  }}
                >
                  <Checkbox
                    checked={allTickets}
                    setChecked={checked => {
                      setAllTickets(checked);
                      setNewEvents(
                        newEvents.reduce((accumulated, current) => {
                          const newTicketTypes = current.ticket_types?.map(
                            ({ ID, code, quantity }) => ({
                              ID,
                              code,
                              quantity,
                              checked,
                            })
                          );
                          current.ticket_types = newTicketTypes;
                          accumulated.push(current);
                          return accumulated;
                        }, [])
                      );
                      setAllEvents(checked);
                    }}
                    className='green'
                  />
                </div>
                <Text className='small'>{APPLY_ALL_EVENTS}</Text>
              </Container>
            </CheckboxContainer>
          </InputContainer>
          <InputContainer className='search'>
            <Search src={searchIcon} alt='search' />
            <Input
              placeholder='search for events'
              value={eventsToSearch}
              onChange={e => {
                setEventToSearch(e.target.value);
              }}
            />
          </InputContainer>
          <Text className='description'>{SEARCH_EVENT_TO_VIEW} </Text>
          <ButtonContainer className='eventsButton'>
            {!allEvents ? (
              newEvents
                ?.filter(e => e.name.includes(eventsToSearch))
                ?.map(({ name, ID }) => (
                  <ButtonEventsForm
                    key={ID}
                    allEvents={allEvents}
                    setAllEvents={setAllEvents}
                    name={name}
                    id={ID}
                    setSelectedEvents={setSelectedEvents}
                    selectedEvents={selectedEvents}
                  />
                ))
            ) : (
              <ButtonEventsForm allEvents={allEvents} setAllEvents={setAllEvents} />
            )}
          </ButtonContainer>
          <>
            {(!allEvents ? newEvents?.filter(e => selectedEvents.includes(e.ID)) : newEvents)?.map(
              ({ ticket_types, ID }) => {
                return (
                  <EventsAndTicketsForm
                    key={ID}
                    selectedTickets={selectedTickets}
                    setSelectedTickets={setSelectedTickets}
                    subTitles={subtitles.find(({ ID: id }) => id === ID).table}
                    allEvents={allEvents}
                    ticketTypes={ticket_types}
                    eventId={ID}
                    selectedEvents={selectedEvents}
                    eventIndex={newEvents.findIndex(({ ID: id }) => id === ID)}
                    setNewEvents={setNewEvents}
                    newEvents={newEvents}
                  />
                );
              }
            )}
          </>
        </>
      ) : (
        <EventsAndTicketsForm
          key={event?.ID}
          selectedTickets={selectedTickets}
          setSelectedTickets={setSelectedTickets}
          subTitles={subTitlesEvent}
          allEvents={allEvents}
          ticketTypes={newEvent[0]?.ticket_types}
          selectedEvents={selectedEvents}
          eventId={event?.ID}
          setNewEvents={setNewEvent}
          newEvents={newEvent}
          eventIndex={0}
          fromEvent={fromEvent}
        />
      )}
    </InputContainer>
  );
};

export default ApplyToTicketsForm;
