import styled from 'styled-components';
export const Wrapper = styled.header`
  background-color: var(--color-bg-1);
  display: flex;
  align-items: center;
  padding: 2.25rem 2rem;
  box-sizing: border-box;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
`;

export const ProfilePic = styled.img`
  display: flex;
  flex-direction: column;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  object-fit: cover;
`;

export const Loading = styled.img`
  position: absolute;
  width: 200px;
  height: 200px;
  object-fit: cover;
  left: 50%;
  margin-left: -100px;
  top: 50%;
  margin-top: -100px;
  z-index: 1000000;
  opacity: 1;
`;

export const ProfileCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 100px;
  &.active {
    background-color: var(--color-primary-default);
  }
`;

export const SwitchTeamText = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
`;

export const Div = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-bg-2);
  opacity: 0.8;
`;

export const Column = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.team {
    gap: 7px;
  }
`;
