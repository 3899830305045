import { api } from '../../../../api/api';

interface EditReccurence {
  id: number;
  rrule: string;
}

export const editReccurence = ({ id, rrule }: EditReccurence) =>
  api.put(`events/${id}`, {
    rrule,
  });
