import styled from 'styled-components';

import { palette } from '../../../../utils/theme';

export const Navigation = styled.div`
  padding: 30px;
  display: flex;
  gap: 30px;
`;

export const Label = styled.div<{
  primaryColor: string;
  secondaryFontColor: string;
}>`
  font-size: 18px;
  font-weight: 300;
  cursor: pointer;
  color: ${props => props.secondaryFontColor};
  &:hover {
    color: ${props => props.primaryColor};
  }
  &.selected {
    color: ${palette.pink};
    text-decoration-line: underline;
    font-weight: 600;
  }
`;
