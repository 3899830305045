import React from 'react';
import Select from 'react-select';

import { EventFormType } from '../../../../types';
import { TZ_LIST } from '../../../../utils/time';
import { customErrorStyles } from '../EventAndVenueForm.styles';
import { Label, customSelectStylesNoBorder } from '../TicketTypes/styles';
import { Column } from './styles';

interface Props {
  label: string;
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  error?: any;
  setError?: Function;
}

export const DropDown = ({ label, value, setValue, error, setError = () => null }: Props) => (
  <Column className='width70'>
    <Label>{label}</Label>
    <Select
      value={value.timezone}
      onChange={select => {
        setValue({ ...value, timezone: select });
        setError({ ...error, ['timezone']: false });
      }}
      options={TZ_LIST}
      styles={!!error && !!error['timezone'] ? customErrorStyles : customSelectStylesNoBorder}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder='Select timezone'
    />
  </Column>
);
