import styled from 'styled-components';
import { light, darkGray, green, gray } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Card = styled.div`
  position: relative;
  width: 29.5%;
  min-width: 380px;
  max-width: 480px;
  padding: 20px;
  background: ${gray};
  border-radius: 16px;
  &.analytic {
    cursor: pointer;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 6px;

  &.column {
    flex-direction: column;
  }
  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.icons {
    gap: 18px;
    align-items: center;
    height: fit-content;
  }

  &.full {
    width: 100%;
    height: 100%;
  }

  &.blur {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(71, 71, 71, 0.9);
    backdrop-filter: blur(4px);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
`;

export const Button = styled.button`
  border: 1px solid ${light};
  box-sizing: border-box;
  border-radius: 100px;
  background: transparent;
  width: 190px;
  height: 36px;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  color: ${light};

  &.go-back {
    position: absolute;
    top: 20px;
    left: 20px;
    border: none;
    width: fit-content;
    height: fit-content;
  }
`;

export const Text = styled.p`
  width: fit-content;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: -0.03em;
  padding: 4px 8px;
  opacity: 0.7;
  &.header {
    font-size: 20px;
    opacity: 1;
  }

  &.normal {
    font-weight: normal;
    line-height: 16px;
  }

  &.padding {
    padding-right: 20px;
  }

  &.content {
    font-size: 16px;
  }

  &.no-padding-top {
    padding-top: 0;
  }
  &.no-padding-bottom {
    padding-bottom: 0;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.green {
    color: ${green};
    opacity: 1;
  }

  &.description {
    height: 50px;
    display: -webkit-box;
    width: auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

export const Input = styled.input`
  width: 110px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.03em;
  padding: 4px 8px;
  background: ${darkGray};
  border-radius: 4px;
  border: none;

  &.header {
    font-size: 20px;
    width: 200px;
  }

  &:focus {
    outline: none;
  }
`;

export const TextArea = styled.textarea`
  width: auto;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.03em;
  padding: 4px 8px;
  background: #161616;
  border-radius: 4px;
  border: none;
  resize: none;

  &:focus {
    outline: none;
  }
`;

export const Circle = styled.div`
  width: 45px;
  height: 45px;
  display: grid;
  justify-items: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &.green {
    background: ${green};
  }
`;
