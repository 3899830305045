import { Dropdown } from '../Inputs/Dropdown';
import { Input } from '../Inputs/Input';
import { Container, InputContainer } from './EventInfoForm.styles';

export default function EventInfo({ value, setValue }) {
  return (
    <Container>
      <InputContainer>
        <Dropdown
          label='gate time'
          value={value.gateTime}
          setValue={gateTime => setValue({ ...value, gateTime })}
          placeholder='Select an event type'
          id='event-gate-time'
          options={[
            { label: '3 hours before event start', value: -3 },
            { label: '2 hours before event start', value: -2 },
            { label: '1 hour before event start', value: -1 },
            { label: '45 minutes before event start', value: -0.75 },
            { label: '30 minutes before event start', value: -0.5 },
            { label: '15 minutes before event start', value: -0.25 },
          ]}
          error=''
        />
      </InputContainer>
      <InputContainer>
        <Input
          label='support contact'
          value={value.support}
          setValue={support => setValue({ ...value, support })}
          id='event-support'
          placeholder='Phone or Email'
          className={value.support ? '' : 'focus'}
        />
      </InputContainer>
    </Container>
  );
}
