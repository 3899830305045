import { useEffect, useState } from 'react';
import React from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { CSVLink } from 'react-csv';

import {
  Wrapper,
  Title,
  Row,
  Text,
  InputContainer,
  Input,
  Search,
  TextContainer,
  Container,
  DropdownContainer,
  FilterDropdown,
  Img,
  CustomizedPagination,
  InputRow,
  PaginationContainer,
  RowPerPage,
  Line,
  PdfButton,
  csvStyled,
  RowTitle,
} from './styles';
import {
  ATTENDEE_NAME,
  ATTENDEE_EMAIL,
  EVENT,
  TICKET_TYPE,
  QUANTITY,
  VALUE,
  DATETIME,
  STATUS,
} from '../../utils/strings';
import { cellOne, cellTwo } from '../../utils/theme';
import searchIcon from '../../static/icons/searchIcon.svg';
import { RadioOptions } from './RadioOptions';
import { downloadGreen, whiteArrowDown } from '../../static/icons';
import { getData } from '../../api/api';
import FilterDay from './FilterDay';

const subTitles = [
  ATTENDEE_NAME,
  ATTENDEE_EMAIL,
  EVENT,
  TICKET_TYPE,
  QUANTITY,
  VALUE,
  DATETIME,
  STATUS,
];

export interface valueDate {
  start_date: Date;
  end_date: Date;
}

const Orders = () => {
  const partnerId = localStorage.getItem('partnerId');
  const [orderToSearch, setOrderToSearch] = useState('');
  const [dateFilter, setDateFilter] = useState('last_week');
  const [isOpenAllTime, setIsOpenAllTime] = useState(false);
  const [dateFilterOption, setDateFilterOption] = useState('Last Week');
  const [isOpenDropdown, setOpenDropdown] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalPages, setTotalPages] = useState(5);
  const today = new Date();
  const [filterByDateRange, setfilterByDateRange] = useState({
    start_date: today,
    end_date: today,
  });
  const [filterByDateRangeParam, setfilterByDateRangeParam] = useState({
    start_date: '',
    end_date: '',
  });

  const { data: orders } = useQuery(
    [
      'getOrders',
      page,
      rowsPerPage,
      orderToSearch,
      dateFilter,
      filterByDateRangeParam.start_date,
      filterByDateRangeParam.end_date,
    ],
    () =>
      getData(
        `orders?page=${page}&per_page=${rowsPerPage}&search=${orderToSearch}&date=${dateFilter}&start_date=${filterByDateRangeParam.start_date}&end_date=${filterByDateRangeParam.end_date}`
      )
  );

  useEffect(() => {
    const totalPages = Math.ceil(orders?.total / rowsPerPage);
    setTotalPages(totalPages);
  }, [orders, rowsPerPage]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
    setPage(0);
    const totalPages = Math.ceil(orders?.length / rowsPerPage);
    setTotalPages(totalPages);
  };

  useEffect(() => {
    if (filterByDateRange.start_date !== today && filterByDateRange.end_date !== today)
      setfilterByDateRangeParam({
        ...filterByDateRangeParam,
        start_date: moment(filterByDateRange.start_date).format('YYYY-MM-DD'),
        end_date: moment(filterByDateRange.end_date).format('YYYY-MM-DD'),
      });
  }, [filterByDateRange]);

  useEffect(() => {
    if (dateFilter === 'all_time') {
      setIsOpenAllTime(true);
    } else {
      setIsOpenAllTime(false);
    }
  }, [dateFilter]);

  const { data: csvData } = useQuery('getCSV', () =>
    getData(`/analytics/slot_quantity/${partnerId}}`)
  );

  return (
    <Wrapper>
      <RowTitle className='space-between'>
        <Title>Orders</Title>
        <RowTitle className='center'>
          <PdfButton>
            {csvData && (
              <CSVLink data={csvData} style={csvStyled}>
                Bookings CSV
                <Img src={downloadGreen} />
              </CSVLink>
            )}
          </PdfButton>
        </RowTitle>
      </RowTitle>
      <Container>
        <InputContainer>
          <Search src={searchIcon} alt='search' />
          <Input
            placeholder={'Sort by Attendee name, event or email...'}
            value={orderToSearch}
            onChange={e => {
              setOrderToSearch(e.target.value);
            }}
          />
        </InputContainer>
        <DropdownContainer>
          <FilterDropdown onClick={() => setOpenDropdown(true)}>
            <Text className='filter'> {dateFilterOption} </Text>
            <Img src={whiteArrowDown} />
          </FilterDropdown>
          {isOpenDropdown && (
            <RadioOptions
              setDateFilter={setDateFilter}
              dateFilter={dateFilter}
              isOpenDropdown={isOpenDropdown}
              setOpenDropdown={setOpenDropdown}
              setDateFilterOption={setDateFilterOption}
            />
          )}
        </DropdownContainer>
      </Container>
      <Row className='header'>
        {subTitles.map(title => (
          <Text key={title} className='header'>
            {title}
          </Text>
        ))}
      </Row>

      {orders?.data?.map(
        (
          { User, tickets_name, events_name, quantity, price, date_time, transaction_type },
          index
        ) => (
          <Row style={{ background: index % 2 === 0 ? cellOne : cellTwo }}>
            <Text>{User?.full_name}</Text>
            <Text className='ellipsis'>{User?.email}</Text>
            <Text>{events_name}</Text>
            <Text className='ellipsis'>{tickets_name}</Text>
            <Text>{quantity}</Text>
            <Text>{price}</Text>
            <Text>{moment(date_time).format('DD/MM/YYYY HH:MM A')}</Text>
            <TextContainer
              className={
                transaction_type === 'primary_sold'
                  ? 'completed'
                  : transaction_type === 'sale_canceled'
                  ? 'cancelled'
                  : 'refunded'
              }
            >
              <Text className='status'>
                {transaction_type === 'primary_sold'
                  ? 'completed'
                  : transaction_type === 'sale_canceled'
                  ? 'cancelled'
                  : 'refunded'}
              </Text>
            </TextContainer>
          </Row>
        )
      )}
      <Line className='table' />
      <PaginationContainer>
        <RowPerPage>
          <Text className='rows'> Rows per page</Text>
          <InputRow type='number' value={rowsPerPage} onChange={handleChangeRowsPerPage} />
        </RowPerPage>
        <CustomizedPagination
          count={totalPages}
          page={page || page + 1}
          onChange={handleChange}
          shape='rounded'
        />
      </PaginationContainer>
      {/* Filter by date range, change it when they ask for  */}
      {/* {isOpenAllTime && (
        <FilterDay
          setIsOpenAllTime={setIsOpenAllTime}
          isOpenAllTime={isOpenAllTime}
          value={filterByDateRange}
          setValue={setfilterByDateRange}
        ></FilterDay>
      )} */}
    </Wrapper>
  );
};

export default Orders;
