import React, { useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import { useMutation } from 'react-query';

import { editPencil, greenCheck } from '../../../static/icons';
import { editField, editQuantity, editSupport } from './services';
import { Img, InputContainer, StyledInput } from './styles';

interface Props {
  description: string;
  item?: string;
  eventId?: number;
  supportId?: number;
  fromSupport?: boolean;
  dayId?: number;
  fromQuantity?: boolean;
}

export const Input = ({
  description,
  item,
  eventId,
  supportId,
  fromSupport,
  dayId,
  fromQuantity,
}: Props) => {
  const [value, setValue] = useState<string>(description);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  const { mutate: mutateEdit } = useMutation(editField);
  const { mutate: mutateEditSupport } = useMutation(editSupport);
  const { mutate: mutateEditQuantity } = useMutation(editQuantity);

  return (
    <InputContainer
      className={isEdit ? 'edit' : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <AutosizeInput
        value={value}
        onChange={e => setValue(e.target.value)}
        inputStyle={{
          ...StyledInput,
          textTransform: item === 'description' || fromSupport ? 'none' : 'capitalize',
        }}
        disabled={!isEdit}
      />
      {(hover || isEdit) && (
        <Img
          className='edit'
          src={isEdit ? greenCheck : editPencil}
          onClick={() => {
            setIsEdit(!isEdit);
            if (isEdit && !fromSupport && !fromQuantity) mutateEdit({ value, item, id: eventId });
            else if (isEdit && fromSupport && !fromQuantity)
              mutateEditSupport({ eventId, contact: value, supportId });
            else if (isEdit && !fromSupport && fromQuantity)
              mutateEditQuantity({ quantity: Number(value), dayId });
          }}
        />
      )}
    </InputContainer>
  );
};
