import styled from 'styled-components';

import {
  emailBackground,
  green,
  inputBackgroundSecondary,
  white,
  select,
  grayRGBA,
} from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const customSelectStyles = width => ({
  control: (provided: any, state: any) => ({
    ...provided,
    background: inputBackgroundSecondary,
    border: `none`,
    borderRadius: 4,
    boxShadow: 'none',
    width,
    minWidth: 114,
    minHeight: 47,
    padding: 4,
    '&:hover': {
      border: `none`,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    opacity: 0.5,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: select,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: select,
    width,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
});

export const Container = styled.div`
  width: 100%;
`;

export const ContainerBox = styled.div`
  padding: 14px 50px;
  background: rgba(22, 22, 22, 0.5);
  border-radius: 16px;
  margin-top: 20px;
  &.noMargin {
    margin: 0;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  &.select {
    margin-left: 14px;
  }
  &.center {
    align-items: center;
  }
  &.top {
    margin-top: 14px;
  }
  &.bottom {
    margin-bottom: 14px;
  }
  &.done {
    width: 100%;
    justify-content: flex-end;
  }
`;

export const DayCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${emailBackground};
  border: 1px solid ${white};
  border-radius: 50%;
  margin-right: 10px;
  padding: 10px;
  width: 22px;
  cursor: pointer;
  &.active {
    background: ${green};
  }
`;

export const DayText = styled.div`
  text-transform: uppercase;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  opacity: 0.5;
`;

export const RepeatInput = styled.input`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 4px;
  padding: 0;
  background: ${emailBackground};
  border: none;
  outline: none;
  max-width: 78px;
  height: 47px;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.week {
    padding: 0 30.5px;
  }
`;

export const DateText = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.5;
`;

export const DateContainer = styled.div`
  background: ${emailBackground};
  border-radius: 4px;
  display: flex;
  padding: 0 16px;
  margin: 0 20px 0 20px;
  &.ocurrences {
    padding: 11.5px 16px;
  }
`;

export const Button = styled.div`
  background: ${green};
  border-radius: 6px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  width: fit-content;
  padding: 8px 22px;
  cursor: pointer;
  margin-top: 12px;
`;

export const StyledInput = {
  fontFamily: primaryFont,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '-0.03em',
  color: white,
  outline: 'none',
  border: 'none',
  background: 'none',
  opacity: 0.5,
};
