import React, { useState } from 'react';
import Select from 'react-select';

import { Label, Column } from './TicketTypes/styles';
import { customErrorStyles, customSelectStyles } from './EventAndVenueForm.styles';

interface Props {
  label: string;
  value: any;
  setValue: Function;
  item: string;
  placeholder?: string;
  error?: any;
  setError?: Function;
}

const options = [
  { label: 'Sports Event', value: 'sports' },
  { label: 'Concert', value: 'concert' },
  { label: 'Festival', value: 'festival' },
  { label: 'Conference', value: 'conference' },
  { label: 'Comedy Show', value: 'comedy' },
  { label: 'Speaker', value: 'speaker' },
  { label: 'Other', value: 'other' },
];

export const DropDown = ({ label, value, setValue, item, placeholder, error, setError }: Props) => (
  <Column>
    <Label className='m'>{label}</Label>
    <Select
      value={value[item]}
      onChange={select => {
        setValue({ ...value, [item]: select });
        setError({ ...error, [item]: false });
      }}
      options={options}
      styles={!!error && !!error[item] ? customErrorStyles : customSelectStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
      placeholder={placeholder}
    />
  </Column>
);
