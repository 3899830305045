import styled from 'styled-components';

import { cards, green, lightGray, remove, white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Container = styled.div`
  width: 45%;
  background: ${cards};
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
  outline: none;
  padding: 25px 10%;
`;

export const Row = styled.div`
  display: flex;
  &.center {
    justify-content: center;
  }
  &.bottom {
    gap: 10px;
    padding: 30px;
  }
  &.top {
    padding: 20px;
  }
  &.width {
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.center {
    align-items: center;
  }
`;

export const Button = styled.div`
  border-radius: 100px;
  background: ${green};
  justify-content: center;
  padding: 10px 16px;
  min-width: 122px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  cursor: pointer;
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
  }
  &.remove {
    background: ${remove};
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  &.title {
    font-weight: 300;
    font-size: 48px;
  }
  &.span {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.7;
  }
  &.uppercase {
    text-transform: uppercase;
  }
  &.margin12 {
    margin-bottom: 12px;
  }
  &.margin30 {
    margin-bottom: 30px;
  }
`;

export const InnerContainer = styled.div`
  background: #2d2d2d;
  border-radius: 8px;
  padding: 10px;
`;

export const StyledInput = styled.input`
  all: unset;
  background: #080808;
  border-radius: 4px;
  padding: 12px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-bottom: 10px;
  width: 100%;
`;

export const Img = styled.img`
  width: 150px;
  margin-bottom: 10px;
`;
