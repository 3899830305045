import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { getData } from '../../../api/api';
import { trashIcon } from '../../../static/img';
import { ADD_DOCUMENT, TERMS } from '../../../utils/strings';
import { deletePdf } from '../services';
import { Title, Column, AddFile, Link, Row, Img } from '../styles';
import { TermsModal } from './TermsModal';

export const Terms = () => {
  const [isOpenTerms, setIsOpenTerms] = useState<boolean>(false);
  const partnerId = localStorage.getItem('partnerId');

  const { data: terms, refetch } = useQuery('getTerms', () =>
    getData(`partners/${partnerId}/tos`)
  );

  const { mutate: mutatePdfDelete } = useMutation(deletePdf, {
    onSuccess: () => refetch(),
  });

  return (
    <Column className='wrapper'>
      <Title>{TERMS}</Title>
      {terms?.map(({ ID: id, file, file_name }) => (
        <Row className='terms' key={id}>
          <Link href={file} target='_blank'>
            {file_name || 'termsofservice.pdf'}
          </Link>
          <Img
            className='trash'
            src={trashIcon}
            alt='delete'
            onClick={() => mutatePdfDelete({ partnerId, id })}
          />
        </Row>
      ))}

      <AddFile onClick={() => setIsOpenTerms(true)}>{ADD_DOCUMENT}</AddFile>
      <TermsModal
        isOpen={isOpenTerms}
        setIsOpen={setIsOpenTerms}
        fetchFiles={refetch}
      />
    </Column>
  );
};
