import React from 'react';
import AutosizeInput from 'react-input-autosize';

import { DateContainer, DateText, StyledInput } from './styles';

interface Props {
  valueOcurrences: string;
  setValueOcurrences: React.Dispatch<React.SetStateAction<string>>;
}

export const Ocurrences = ({ valueOcurrences, setValueOcurrences }: Props) => (
  <DateContainer className='ocurrences'>
    <AutosizeInput
      value={valueOcurrences}
      onChange={e => setValueOcurrences(e.target.value)}
      type='number'
      inputStyle={StyledInput}
    />
    <DateText>ocurrences</DateText>
  </DateContainer>
);
