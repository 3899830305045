import React from 'react';

import { EventFormType } from '../../../../types';
import { DateContainer, TimeInput, TimeButton } from '../../PromoCodes/styles';

interface Props {
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  setTime: Function;
  time: any;
  item: string;
  date: Date;
  index: number;
  checked: boolean;
  error: boolean;
  setError: Function;
}

export const TimeRow = ({
  value,
  setValue,
  time,
  setTime,
  item,
  date,
  index,
  checked,
  error,
  setError,
}: Props) => {
  const handleChangeHour = e => {
    const { min, period } = time;
    if (Number(e.target.value) <= 12 && Number(e.target.value) >= 0) {
      const newTime = `${e.target.value}:${min} ${period}`;
      const dateArray = new Date(date).toString().split(' ');
      const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
      setError(false);
      setTime({
        ...time,
        hour: e.target.value === '' ? '' : Number(e.target.value),
      });
      let event = { ...value };
      if (checked)
        setValue({
          ...value,
          dates: event.dates.map(element => {
            const dateArray = new Date(element[item]).toString().split(' ');
            const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
            return { ...element, [item]: new Date(finalDate) };
          }),
        });
      else {
        event.dates[index][item] = new Date(finalDate);
        setValue(event);
      }
    }
  };

  const handleChangeMin = e => {
    const { hour, period } = time;
    if (Number(e.target.value) <= 59 && Number(e.target.value) >= 0) {
      const newTime = `${hour}:${e.target.value} ${period}`;
      const dateArray = new Date(date).toString().split(' ');
      const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
      setError(false);
      setTime({ ...time, min: Number(e.target.value) });
      let event = { ...value };
      if (checked)
        setValue({
          ...value,
          dates: event.dates.map(element => {
            const dateArray = new Date(element[item]).toString().split(' ');
            const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
            return { ...element, [item]: new Date(finalDate) };
          }),
        });
      else {
        event.dates[index][item] = new Date(finalDate);
        setValue(event);
      }
    }
  };

  const handleChangePeriod = () => {
    const { hour, min } = time;
    const newTime = `${hour}:${min} ${time.period === 'am' ? 'pm' : 'am'}`;
    const dateArray = new Date(date).toString().split(' ');
    const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
    setError(false);
    setTime({
      ...time,
      period: time.period === 'am' ? 'pm' : 'am',
    });
    let event = { ...value };
    if (checked)
      setValue({
        ...value,
        dates: event.dates.map(element => {
          const dateArray = new Date(element[item]).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          return { ...element, [item]: new Date(finalDate) };
        }),
      });
    else {
      event.dates[index][item] = new Date(finalDate);
      setValue(event);
    }
  };

  return (
    <DateContainer id='event-start-time'>
      <TimeInput
        value={time.hour}
        onChange={handleChangeHour}
        type='number'
        min={1}
        max={12}
        id='event-start-time-hour'
        className={`modal ${error ? 'error' : ''}`}
      />
      <TimeInput
        value={time.min}
        onChange={handleChangeMin}
        type='number'
        min={0}
        max={59}
        id='event-start-time-minute'
        className={`modal ${error ? 'error' : ''}`}
      />
      <TimeButton
        id='event-start-time-period'
        onClick={handleChangePeriod}
        className={`modal ${error ? 'error' : ''}`}
      >
        {time.period}
      </TimeButton>
      <div style={{ height: 47, width: 31, marginRight: 24 }} />
    </DateContainer>
  );
};
