import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { getData } from '../../api/api';
import SalesAnalytics from '../../lib/components/AnalysisSection/SalesAnalytics';
import AttendanceAnalytics from '../../lib/components/AnalysisSection/AttendanceAnalytics';
import Icon from '../../lib/components/Icon/Icon';

import {
  TICKETS_OPEN,
  TICKETS_SOLD,
  TICKET_COMPS,
  TOTAL_GROSS_POTENTIAL,
} from '../../utils/strings';
import {
  Wrapper,
  Title,
  Container,
  Information,
  Text,
  RowContainer,
} from './styles';
import InventoryAnalytics from '../../lib/components/AnalysisSection/InventoryAnalytics';

const EventAnalysis = () => {
  const { id }: any = useParams();
  const [isOpenSales, setIsOpenSales] = useState<boolean>(false);
  const [isOpenInventory, setIsOpenInventory] = useState<boolean>(false);
  const [isOpenAttendance, setIsOpenAttendance] = useState<boolean>(false);

  const { data: eventInfo } = useQuery('getEvent', () =>
    getData(`events/${id as string}`)
  );

  const { data: eventAnalytics } = useQuery('getEventAnalytics', () =>
    getData(`events/${id as string}/analytics`)
  );

  const { data: eventAnalyticsInfo } = useQuery('getEventAnalyticsFull', () =>
    getData(`events/${id as string}/analytics?full_info=true`)
  );

  const info = [
    {
      name: 'Sales',
      setState: setIsOpenSales,
      state: isOpenSales,
      Dropdown: () => <SalesAnalytics options={eventAnalyticsInfo} />,
    },
    {
      name: 'Inventory',
      setState: setIsOpenInventory,
      state: isOpenInventory,
      Dropdown: () => <InventoryAnalytics options={eventAnalyticsInfo} />,
    },
    {
      name: 'Attendance',
      setState: setIsOpenAttendance,
      state: isOpenAttendance,
      Dropdown: () => <AttendanceAnalytics options={eventAnalyticsInfo} />,
    },
  ];

  const DATA = [
    {
      title: TICKETS_SOLD,
      description: eventAnalytics?.tickets_sold,
      value: '($ ' + eventAnalytics?.ticket_sold_value + ')',
    },
    {
      title: TICKETS_OPEN,
      description: eventAnalytics?.tickets_open,
      value: '($ ' + eventAnalytics?.tickets_open_value + ')',
    },
    {
      title: TOTAL_GROSS_POTENTIAL,
      description: '$' + eventAnalytics?.total_gross_potential,
    },
    {
      title: TICKET_COMPS,
      description: eventAnalytics?.tickets_comps,
      value: '($ ' + eventAnalytics?.tickets_value + ')',
    },
  ];

  return (
    <Wrapper>
      <Container>
        <Title>{eventAnalytics?.name}</Title>
        <Wrapper className='infoDate'>
          <Text className='infoDateText'>
            {moment(eventInfo?.start_date).format('MMMM DD, YYYY')}
          </Text>
          <Text className='infoDateText'>
            {moment(eventInfo?.start_date).format('h:mm A')}
          </Text>
        </Wrapper>
      </Container>
      <Information>
        {DATA.map(({ title, value, description }) => (
          <div>
            <Text className='title'>{title}</Text>
            <Text className='elements'>
              {description} {value}
            </Text>
          </div>
        ))}
      </Information>
      {info.map(({ name, setState, state, Dropdown }) => (
        <>
          <RowContainer>
            <Text className='titleIcon'>{name}</Text>
            <Icon
              icon={state ? 'arrow-button-down' : 'arrow-button-up'}
              onClick={() => setState(!state)}
              className='cursor'
              height={30}
            />
          </RowContainer>
          {state && <Dropdown />}
        </>
      ))}
    </Wrapper>
  );
};

export default EventAnalysis;
