import { Modal } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { useMutation } from 'react-query';

import { post } from '../../api/api';
import Button from '../../lib/components/Button/Button';
import Icon from '../../lib/components/Icon/Icon';
import { ADD_NEW, CANCEL, LAST_WEEK, NOTES_HISTORY, RECENTS, SUBMIT } from '../../utils/strings';
import {
  TextArea,
  ModalContainer,
  modalStyle,
  RowModal,
  Text,
  Wrapper,
  WrapperElements,
  Title,
  ButtonContainer,
  ContainerNote,
  ButtonFanDatabase,
} from './style';

type Props = {
  isNotesOpen: boolean;
  setIsNotesOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notesRecents?: any[];
  notesLastWeeks?: any[];
  memberId?: string;
  partnerId?: string;
  userId?: string;
};

export default function NotesModal({
  isNotesOpen,
  setIsNotesOpen,
  notesRecents,
  memberId,
  partnerId,
  userId,
  notesLastWeeks,
}: Props) {
  const [value, setValue] = useState<string>();

  const { mutate: handleMutationNotes } = useMutation(() =>
    post(`fan_notes`, {
      user_id: parseInt(userId),
      member_id: parseInt(memberId),
      partner_id: parseInt(partnerId),
      note: value,
    })
  );

  return (
    <Modal open={isNotesOpen} onClose={() => setIsNotesOpen(false)} sx={modalStyle}>
      <ModalContainer>
        <Wrapper className='iconClose'>
          <Icon
            icon={'close'}
            onClick={() => setIsNotesOpen(false)}
            className='cursor'
            height={30}
          />
        </Wrapper>
        <Title className='titleNote'>{NOTES_HISTORY}</Title>
        <RowModal className='centerBorder'>
          <Text>{ADD_NEW}</Text>
        </RowModal>
        <ContainerNote>
          <TextArea
            className='notes'
            placeholder='start writing your notes'
            onChange={e => setValue(e.target.value)}
          />
          <ButtonContainer className='addNotes'>
            <ButtonFanDatabase disabled={value === ''} className='cancel'>
              {CANCEL}
            </ButtonFanDatabase>
            <ButtonFanDatabase
              className='submit'
              disabled={value === ''}
              onClick={() => {
                handleMutationNotes();
              }}
            >
              {SUBMIT}
            </ButtonFanDatabase>
          </ButtonContainer>
        </ContainerNote>
        <RowModal className='centerBorder'>
          <Text>{RECENTS}</Text>
        </RowModal>
        {notesRecents?.map(element => (
          <ModalContainer className='Note'>
            <Text className='notes'>{element?.note}</Text>
            <WrapperElements>
              <Text className='elementsNotes'>{element?.member_email}</Text>
              <Text className='elementsNotes'>
                {moment(element?.CreatedAt).format('MMM, DD, YYYY, HH:MM A')}
              </Text>
            </WrapperElements>
          </ModalContainer>
        ))}
        <RowModal className='centerBorder'>
          <Text>{LAST_WEEK}</Text>
        </RowModal>
        {notesLastWeeks?.map(element => (
          <ModalContainer className='Note'>
            <Text className='notes'>{element.note}</Text>
            <WrapperElements>
              <Text className='elementsNotes'>{element.email}</Text>
              <Text className='elementsNotes'>{element.date}</Text>
            </WrapperElements>
          </ModalContainer>
        ))}
      </ModalContainer>
    </Modal>
  );
}
