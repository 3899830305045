import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';

import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../../WidgetCustomization/styles';
import { ContainerCode, ContainerInfo, ContainerText, Text, ReactCodeInputStyled } from '../style';
import { useQuery } from 'react-query';
import { getData } from '../../../api/api';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessConfirmation?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
  ID: number | string;
  isPressButton: boolean;
  isSelectEmail: boolean;
}

export const PinRequestModal = ({
  isOpen,
  setIsOpen,
  index,
  setSuccessConfirmation,
  ID,
  isPressButton,
  isSelectEmail,
}: Props) => {
  const partnerId = localStorage.getItem('partnerId');
  const [isInputNumber, setIsInputNumber] = useState(0);
  const [isInputNumberArray, setIsInputNumberArray] = useState([]);

  const { data: confirmIdentity, refetch } = useQuery(
    'confirmIdentity',
    () =>
      getData(
        `/users/confirm_identity/${ID}/${partnerId}?${
          isSelectEmail ? 'channel=email' : 'channel=sms'
        }`
      ),
    {
      enabled: isPressButton,
      refetchOnWindowFocus: false,
      onSuccess: data => setIsInputNumber(data),
    }
  );

  useEffect(() => setIsInputNumberArray(('' + isInputNumber).split('')), [isInputNumber]);
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='twofactor'>
        <Row className='centerBorder'>
          <Title className='modal'>Confirm Identity</Title>
        </Row>
        <Row className='centerBorder overflow'>
          <ContainerInfo>
            <Text className='two_factor'>
              Please have your attendee read you the code sent to their email or mobile device.
            </Text>
            <ContainerText>
              <Text className='info'>PIN REQUESTED</Text>
              <ContainerCode>
                <ReactCodeInputStyled
                  placeholder={isInputNumberArray}
                  fields={6}
                  type='number'
                  className='custom-class'
                  disabled
                />
              </ContainerCode>
            </ContainerText>
          </ContainerInfo>
        </Row>
        <Row className='center'>
          <ButtonModal
            className='cancel'
            onClick={() => {
              refetch();
            }}
          >
            Send Another Pin
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
