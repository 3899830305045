import React, { useState } from 'react';

import { Button } from '../../../../../pages/EventSummary/EventInformation/TimeSelectorModal/styles';
import { TicketsType } from '../../../../../types';
import { TimeButton, TimeInput } from '../../styles';
import { ModalContainer, Label, Row } from '../styles';

interface Props {
  hour: string;
  min: string;
  period: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
}

export const TimeSelectorModal = ({
  hour,
  min,
  period,
  setIsOpen,
  tickets,
  index,
  setTickets,
}: Props) => {
  const [time, setTime] = useState({
    hour: Number(hour),
    min: Number(min),
    period,
  });

  const handleTime = () => {
    const { hour, min, period } = time;
    const newTime = `${hour}:${min} ${period}`;
    const dateArray = new Date(tickets[index].ticketOnSaleTime).toString().split(' ');
    const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;

    let newTickets = [...tickets];
    newTickets[index].ticketOnSaleTime = new Date(finalDate);
    setTickets(newTickets);
  };

  return (
    <ModalContainer>
      <Label className='s'>Sale time</Label>
      <Row className='space-between'>
        <TimeInput
          className='modal small'
          value={time.hour}
          onChange={e => {
            setTime({ ...time, hour: Number(e.target.value) });
          }}
          type='number'
          min={1}
          max={12}
          id='event-start-time-hour'
        />
        <TimeInput
          className='modal small'
          value={time.min}
          onChange={e => {
            setTime({ ...time, min: Number(e.target.value) });
          }}
          type='number'
          min={0}
          max={59}
          id='event-start-time-minute'
        />
        <TimeButton
          className='modal small'
          id='event-start-time-period'
          onClick={() => {
            setTime({
              ...time,
              period: time.period === 'am' ? 'pm' : 'am',
            });
          }}
        >
          {time.period}
        </TimeButton>
      </Row>
      <Row className='center margin-top2'>
        <Button className='cancel' onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleTime();
            setIsOpen(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    </ModalContainer>
  );
};
