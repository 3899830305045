import styled from 'styled-components';
import { cards, grayRGBA, white } from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 529px;
  right: 545px;
  z-index: 90;
  background: ${cards};
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
  padding: 36px 71px;
`;

export const ContainerModal = styled.div`
  background: ${cards};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  &.cancel {
    height: 356px;
  }
  &.slot {
    height: 330px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${cards};
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  width: 241px;
  height: 165px;
  z-index: 90;
  top: 0;
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grayRGBA};
`;

export const Row = styled.div`
  display: flex;
  &.bottom {
    justify-content: center;
    padding: 20px;
    gap: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.top {
    align-items: center;
    padding: 25px;
  }
  &.delete {
    gap: 50px;
    margin-top: 6px;
  }
`;

export const Img = styled.img`
  width: 20%;
`;

export const Button = styled.div`
  width: 170px;
  height: 44px;
  font-weight: 400;
  font-size: 20px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.remove {
    background: rgba(235, 87, 87, 0.21);
    border: 1px solid #eb5757;
    color: #eb5757;
  }

  &.cancel {
    color: #9b9b9b;
    border: 1px solid #9b9b9b;
  }
  &.correct {
    background: #474747;
    border-radius: 100px;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};

  &.italic {
    font-style: italic;
  }
`;
