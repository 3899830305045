import React from 'react';

import { currency, percent } from '../../../../../static/icons';
import { TicketsType } from '../../../../../types';
import { StyledInput, Column, Label, Img } from '../styles';

interface Props {
  label: string;
  item: string;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
  placeholder?: string;
  width?: string | number;
}

export const Input = ({ label, item, placeholder, width, tickets, setTickets, index }: Props) => (
  <Column style={{ width, position: 'relative' }}>
    <Label>{label}</Label>
    <StyledInput
      value={tickets[index][item]}
      onChange={e => {
        let newTickets = [...tickets];
        newTickets[index][item] = e.target.value;
        setTickets(newTickets);
      }}
      className='info'
      placeholder={placeholder}
    />
    {label.includes('secondary royalty') && <Img className='currency' src={percent} />}
    {label.includes('price') && <Img className='currency' src={currency} />}
  </Column>
);
