import React, { useState } from 'react';
import moment from 'moment';

import { TicketsType } from '../../../../types';
import { greenCheck } from '../../../../static/icons';
import {
  Row,
  Title,
  HorizontalLine,
  Column,
  Label,
  QuantityInput,
  QuantityButton,
  ContainerCheckbox,
  Img,
} from './styles';

interface Props {
  name: string;
  discount: number;
  expire: Date;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  id: number;
  attachedIndex: number;
  index: number;
}

export const ExistingPCRow = ({
  name,
  discount,
  expire,
  tickets,
  setTickets,
  id,
  attachedIndex,
  index,
}: Props) => {
  const [checked, setChecked] = useState<boolean>(false);

  const handleAttach = () => {
    let newTickets = [...tickets];
    if (!checked) {
      newTickets[index].attachedPromoCodes = [
        ...tickets[index]?.attachedPromoCodes,
        { ID: id, quantity: 100, code: name, checked: false },
      ];
    } else {
      newTickets[index].attachedPromoCodes = tickets[index].attachedPromoCodes.filter(
        item => item.ID !== id
      );
    }
    setTickets(newTickets);
  };

  const handleUnlimited = () => {
    if (checked) {
      let newTickets = [...tickets];
      newTickets[index].attachedPromoCodes[attachedIndex].quantity = -1;
      setTickets(newTickets);
    }
  };

  return (
    <Row className='existingPC'>
      <Row className='a-center start gap2 margin-left'>
        <ContainerCheckbox
          onClick={() => {
            handleAttach();
            setChecked(!checked);
          }}
        >
          {checked && <Img src={greenCheck} className='checked' />}
        </ContainerCheckbox>
        <Title className='existingPC'>{name}</Title>
      </Row>
      <HorizontalLine className='center' />
      <Column>
        <Label className='sub-title'>Expires on</Label>
        <Label className='description'>{moment(expire).format('L')}</Label>
        <Label className='sub-title'>Discount</Label>
        <Label className='description'>{discount}</Label>
      </Column>
      <HorizontalLine className='center' />
      <Row className='a-center center'>
        <Row className='quantity'>
          <QuantityInput
            disabled={!checked}
            value={
              checked
                ? tickets[index].attachedPromoCodes[attachedIndex]?.quantity === -1
                  ? '#'
                  : tickets[index].attachedPromoCodes[attachedIndex]?.quantity
                : ''
            }
            onChange={(e: any) => {
              let newTickets = [...tickets];
              newTickets[index].attachedPromoCodes[attachedIndex].quantity = e.target.value;
              setTickets(newTickets);
            }}
            type='number'
          />
          <QuantityButton
            onClick={handleUnlimited}
            selected={tickets[index].attachedPromoCodes[attachedIndex]?.quantity === -1}
          >
            Unlimited
          </QuantityButton>
        </Row>
      </Row>
    </Row>
  );
};
