import styled from 'styled-components';
export const Wrapper = styled.footer<{ isMobile?: boolean }>`
  background-color: var(--color-bg-3);
  padding: ${props => (props.isMobile ? '40px 5px;' : '40px 150px')};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.h3``;

export const Link = styled.a`
  font-size: 1rem;
  display: block;
  color: var(--color-text);
  text-decoration: none;
  line-height: 2.5rem;

  &:hover {
    color: var(--color-primary-dark);
    cursor: pointer;
  }

  &:active {
    color: var(--color-primary-default);
  }
`;

export const ButtonLink = styled.button`
  all: unset;
  font-size: 1rem;
  display: block;
  color: var(--color-text);
  text-decoration: none;
  line-height: 2.5rem;

  &:hover {
    color: var(--color-primary-dark);
    cursor: pointer;
  }

  &:active {
    color: var(--color-primary-default);
  }
`;
