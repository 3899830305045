import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { addGreen } from '../../../static/icons';
import { TicketsType } from '../../../types';
import { AddBox, Grid, Img } from './styles';
import TicketTypeCard from './TicketTypeCard';
import TicketTypeModal from './TicketTypeModal';

interface Props {
  ticketTypes: any;
  eventOnSaleTime: Date;
}

export const TicketTypes = ({ ticketTypes, eventOnSaleTime }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ticketsType, setTicketsType] = useState<TicketsType[]>([
    {
      ticketPrice: '',
      ticketName: '',
      ticketDescription: '',
      ticketFile: '',
      ticketFileName: '',
      ticketId: uuid(),
      ticket64: '',
      templateId: null,
      ticketAmount: '1',
      ticketSecondaryRoyalty: '5',
      ticketOnSaleTime: new Date(),
      attachedPromoCodes: [],
    },
  ]);

  return (
    <Grid>
      <AddBox onClick={() => setIsOpen(true)}>
        <Img className='add' src={addGreen} />
        Add Ticket Type
      </AddBox>
      {ticketTypes
        ?.filter(
          (value, index, self) =>
            index === self.findIndex(t => t.name === value.name && t.image === value.image)
        )
        .map(ticket => (
          <TicketTypeCard ticket={ticket} eventOnSaleTime={eventOnSaleTime} />
        ))}
      {isOpen && (
        <TicketTypeModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          setTickets={setTicketsType}
          tickets={ticketsType}
          eventOnSaleTime={eventOnSaleTime}
        />
      )}
    </Grid>
  );
};
