import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 24px 0;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    width: 200px;
    border-color: var(--color-primary-default);
    color: var(--color-primary-default);
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  text-align: left;
  margin: 0;
  margin-bottom: 24px;
  font-weight: 400;
  color: white;
`;
