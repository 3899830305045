import styled from 'styled-components';

import {
  grayRGBA,
  green,
  lightblack,
  lightGray,
  remove,
  transparent,
  white,
} from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Button = styled.div`
  border-radius: 100px;
  background: ${green};
  justify-content: center;
  padding: 10px 16px;
  min-width: 122px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  cursor: pointer;
  &.disable {
    opacity: 0.5;
  }
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
  }
  &.remove {
    background: ${remove};
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grayRGBA};
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  &.existingPC {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.center {
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  &.gap2 {
    gap: 20px;
  }
  &.a-center {
    align-items: center;
  }
  &.center {
    justify-content: center;
  }
  &.start {
    justify-content: flex-start;
  }
  &.bottom {
    gap: 10px;
    padding: 30px;
  }
  &.top {
    padding: 20px;
  }
  &.existingPC {
    display: grid;
    grid-template-columns: 4fr 1fr 2fr 1fr 4fr;
    border-top: 1px solid #606060;
    padding: 10px;
    justify-content: space-around;
  }
  &.quantity {
    height: 40px;
  }
  &.margin-left {
    margin-left: 20px;
  }
`;

export const Container = styled.div`
  width: 60%;
  background: ${lightblack};
  border-radius: 16px;
  outline: none;
`;

export const Wrapper = styled.div`
  &.existingPC {
    padding: 0px 20px 20px 20px;
    overflow-y: scroll;
    height: 300px;
  }
`;

export const HorizontalLine = styled.div`
  border-right: 0.5px solid #606060;
  &.center {
    justify-self: center;
  }
`;

export const Label = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  &.sub-title {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.5;
  }
  &.description {
    font-weight: 600;
    font-size: 20px;
  }
  &.tag {
    font-weight: 600;
    font-size: 16px;
  }
`;

export const QuantityInput = styled.input`
  outline: none;
  background: #525252;
  border-radius: 4px 0px 0px 4px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  border: none;
  width: 100px;
`;

export const QuantityButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  background: #2e2e2e;
  border: ${props => (props.selected ? `1px solid ${white}` : 'none')};
  border-radius: 0px 4px 4px 0px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.5;
  width: 100px;
`;

export const ContainerCheckbox = styled.div`
  width: 24px;
  height: 24px;
  border: 1px ${green} solid;
  border-radius: 4px;
  background: ${transparent};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img`
  &.checked {
    width: 16px;
  }
  &.delete {
    position: absolute;
    top: -10px;
    right: -10px;
    width: 25px;
    cursor: pointer;
  }
  &.tag {
    width: 20px;
  }
`;

export const TagContainer = styled.div`
  position: relative;
  background: #2d2d2d;
  border-radius: 11px;
  padding: 7px 10px;
  display: flex;
  gap: 10px;
`;
