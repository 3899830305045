import { TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styled, { css } from 'styled-components';

import {
  cellOne,
  cellTwo,
  headerCell,
  lighterblack,
  lightGray,
  veryDarkGray,
  white,
} from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const ButtonWrapper = styled(TableCell)`
  display: flex;
  gap: 10px;
`;

export const RowWrapper = styled(TableRow)<{ index }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${cellTwo};
  height: 60px;
  padding: 10px 40px;
  cursor: pointer;

  &.no-pointer {
    cursor: default;
  }

  &.inactive {
    opacity: 0.5;
  }

  button {
    font-size: 14px;
    font-weight: 200px;
    width: 140px;
    color: ${lightGray};
    background-color: ${cellOne};
    border: solid 1px ${veryDarkGray};
    margin: 0px;
  }
  &:hover {
    background-color: ${lighterblack};
  }

  ${props =>
    props.index % 2 &&
    css`
      background-color: ${cellOne};
    `}
`;

export const Text = styled.p`
  color: ${white};
  font-weight: 400;
  margin: 0px;
`;

export const STableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: column;
`;

export const STableRow = styled(TableRow)<{ index }>`
  margin-top: 20px;
  display: grid;
  align-items: center;
  background: ${headerCell};
  grid-template-columns: 1fr 0.5fr 1fr 1fr;
  height: 40px;
  padding: 10px 40px;
`;

export const STableCell = styled(TableCell)`
  &.MuiTableCell-root {
    font-family: ${primaryFont};
    font-style: normal;
    color: ${white};
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    border-bottom: none;

    &.database {
      min-width: 190px;
    }

    &.elementsEmail {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90px;
      min-width: 190px;
      &:hover {
        text-decoration: underline;
      }
    }
    &.upperCase {
      text-transform: uppercase;
    }
  }

  &.buttons {
    gap: 5px;
    display: flex;
    font-size: 14px;
    font-weight: 200px;
    width: 100%;
    color: ${lightGray};
    margin: 0px;
    border-bottom: none;
    min-width: 190px;
  }
`;

export const STableBody = styled(TableBody)`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-right: 15px;
  width: fit-content;
  margin-bottom: 15px;
  margin-left: 20px;
`;

export const STable = styled.table`
  margin-top: 40px;
  border-spacing: 0px;
  &.tableTicket {
    margin-left: 20px;
    margin-right: 40px;
  }

  &.history {
    margin-right: 40px;
  }
`;

export const STableHead = styled(TableHead)`
  margin-top: 20px;
  display: grid;
  align-items: center;
  background: ${headerCell};
  grid-template-columns: 1fr 0.5fr 1fr 1fr;
  height: 40px;
  padding: 10px 40px;
`;
