import { OPENS, TICKETS_DISTRIBUTED } from '../../../utils/strings';
import { inventoryInfo } from './data';
import { Text, Wrapper, RowContainer, ItemContainer } from './styles';

interface RowType {
  options: any;
}

const InventoryAnalytics = ({ options }: RowType) => {
  return (
    <Wrapper>
      <RowContainer className='container'>
        <Text className={'title'}>{OPENS}</Text>
        {options &&
          Object.entries(options?.inventory?.opens)?.map((element, index) => (
            <ItemContainer style={{ marginLeft: index === 0 ? 0 : 25 }}>
              <Text className={'title-s'}>{element ? `${element[0]}` : '-'}</Text>
              <Text className={'data-s'}>{element ? `${element[1] as number}` : '-'}</Text>
            </ItemContainer>
          ))}
      </RowContainer>
      <RowContainer className='column'>
        <Text className={'title'}>{TICKETS_DISTRIBUTED}</Text>
        {options &&
          Object.entries(options?.inventory?.tickets_distributed)?.map((element, index) => (
            <ItemContainer style={{ marginLeft: index === 0 ? 0 : 25 }}>
              <Text className={'title-s'}>{element ? `${element[0]}` : '-'}</Text>
              <Text className={'data-s'}>{element ? `${element[1] as number}` : '-'}</Text>
            </ItemContainer>
          ))}
      </RowContainer>
      <RowContainer className='containerInventory'>
        {options &&
          inventoryInfo().infoInventory.map(element => (
            <RowContainer className='column'>
              <Text className='title'>{element.title}</Text>
              <Text className='data'>{element.information}</Text>
            </RowContainer>
          ))}
      </RowContainer>
    </Wrapper>
  );
};

export default InventoryAnalytics;
