import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(async config => {
  const headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  return { ...config, headers };
});

api.interceptors.response.use(
  response => response,
  error => {
    if ([400, 401].includes(error.response?.status)) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const getData = async (endpoint: string, options = {}) => {
  const { data } = await api.get(endpoint, options);
  return data;
};

export const post = async (endpoint: string, options = {}) => {
  const response = await api.post(endpoint, options);
  return response?.data;
};

export const put = async (endpoint: string, options = {}) => {
  const response = await api.put(endpoint, options);
  return response?.data;
};

export const deleteData = async (endpoint: string, options = {}) => {
  const { data } = await api.delete(endpoint, options);
  return data;
};

export default api;
