import styled from 'styled-components';

import { white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  width: 240px;
  height: 209px;
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: ${white};
  margin-top: 10px;
`;
