import { useEffect, useState } from 'react';

import { EventFromBack, TicketPromoCode } from '../../../types';
import { ContainerText, Img, InputContainer, Row, Text } from './styles';
import RowTickets from './RowTickets';
import { rectangle, rectangleDown } from '../../../static/img';

type Props = {
  allEvents: boolean;
  ticketTypes?: TicketPromoCode[];
  subTitles: any;
  selectedTickets: TicketPromoCode[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<TicketPromoCode[]>>;
  eventIndex: number;
  setNewEvents: React.Dispatch<React.SetStateAction<EventFromBack[]>>;
  newEvents: EventFromBack[];
  selectedEvents: number[];
  eventId: number;
  fromEvent?: boolean;
};

const EventsAndTicketsForm = ({
  allEvents,
  ticketTypes,
  subTitles,
  selectedTickets,
  setSelectedTickets,
  eventIndex,
  setNewEvents,
  newEvents,
  selectedEvents,
  eventId,
  fromEvent,
}: Props) => {
  const [selectAllInEvent, setSelectAllInEvent] = useState(false);
  const [closeEvent, setCloseEvent] = useState(true);

  useEffect(() => {
    newEvents.forEach(({ ID, ticket_types }) => {
      if (ID === eventId) {
        if (ticket_types?.every(({ checked }) => checked === true)) setSelectAllInEvent(true);
        else setSelectAllInEvent(false);
      }
    });
  }, [newEvents]);

  return (
    <InputContainer>
      <Row className='header'>
        <ContainerText className='icon'>
          {!fromEvent && (
            <>
              <Text className='header'> {subTitles.name}</Text>
              <Img
                className='close'
                src={closeEvent ? rectangle : rectangleDown}
                alt='close'
                onClick={() => setCloseEvent(!closeEvent)}
              />
            </>
          )}
        </ContainerText>
        <Text className='promo'> {subTitles.text}</Text>
        <Text
          onClick={() => {
            setSelectAllInEvent(prevState => !prevState);
            setNewEvents(
              newEvents.reduce((accumulated, current) => {
                if (current.ID === eventId) {
                  const newTicketTypes = current.ticket_types?.map(({ ID, code, quantity }) => ({
                    ID,
                    code,
                    quantity,
                    checked: !selectAllInEvent,
                  }));
                  current.ticket_types = newTicketTypes;
                }
                accumulated.push(current);
                return accumulated;
              }, [])
            );
            if (!selectAllInEvent) {
              let array = [...selectedTickets];
              newEvents?.forEach(({ ticket_types, ID }) => {
                if (ID === eventId)
                  ticket_types?.forEach(ticket => {
                    if (array?.every(({ ID }) => ID !== ticket.ID)) array.push(ticket);
                  });
                setSelectedTickets(array);
              });
            } else {
              let array = [...selectedTickets];
              newEvents?.forEach(({ ticket_types, ID }) => {
                if (ID === eventId)
                  ticket_types?.forEach(ticket => {
                    array?.forEach(({ ID }, index) => {
                      if (ID === ticket.ID) {
                        array.splice(index, 1);
                      }
                    });
                  });
              });
              setSelectedTickets(array);
            }
          }}
          className='green'
        >
          {!selectAllInEvent ? 'Select All in Event' : 'Unselect All in Event'}
        </Text>
      </Row>
      {closeEvent &&
        ticketTypes?.map(({ code, ID, quantity, checked }, index) => (
          <RowTickets
            selectedTickets={selectedTickets}
            setSelectedTickets={setSelectedTickets}
            allEvents={allEvents}
            name={code}
            id={ID}
            quantity={quantity as string}
            index={index}
            eventIndex={eventIndex}
            setNewEvents={setNewEvents}
            newEvents={newEvents}
            selectedEvents={selectedEvents}
            eventId={eventId}
            checked={checked}
          />
        ))}
    </InputContainer>
  );
};

export default EventsAndTicketsForm;
