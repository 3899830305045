import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useState } from 'react';

import { put } from '../../../api/api';
import useModal from '../../../hooks/useModal';
import {
  ACTIVATE,
  ACTIVE_DAYS,
  APPLIED_TO,
  DISABLE,
  DISCOUNT,
  VIEW_EDIT,
} from '../../../utils/strings';
import Icon from '../Icon';
import { Card, Container, Text, Button, ContainerText, ContainerCard } from './stylesInfo';
import { queryClient } from '../../../App';

type Props = {
  newPromo: boolean;
  code: string;
  percentage?: number;
  discount?: number;
  coupon_type: string;
  start_date: any;
  end_date: Date;
  TicketTypes?: any;
  active: boolean;
  ID: number;
  setPromoId: React.Dispatch<React.SetStateAction<number>>;
  fromEvent?: boolean;
  eventId?: number;
};

const PromoCodeCard = ({
  newPromo,
  code,
  percentage,
  discount,
  coupon_type,
  start_date,
  end_date,
  TicketTypes,
  ID,
  active,
  setPromoId,
  fromEvent,
  eventId,
}: Props) => {
  const [isShowing, toggle] = useModal();
  const history = useHistory();
  const [isEdit, setIsEdit] = useState(false);

  const { mutate: mutateActivePromo } = useMutation(
    ({ ID, active }: { ID: number; active: boolean }) =>
      put(`promocodes/${ID}`, {
        active,
      })
  );
  return (
    <ContainerCard>
      <Card className={!active ? 'disabled' : '' || newPromo ? 'newPromo' : ''}>
        <Container className={`column space-between`}>
          <Container className=' space-between'>
            <Container className='column'>
              <ContainerText>
                <Text className='header padding'>{code}</Text>
                {newPromo ? <Text className='activate'>NEW</Text> : ''}
              </ContainerText>
            </Container>
            <Container className='icons'>
              {active ? (
                <Icon icon='three-dots' className='cursor' onClick={() => toggle()} />
              ) : (
                <Text
                  onClick={() => {
                    mutateActivePromo({ ID, active: true });
                    queryClient.setQueryData(['getAllPromoCodes'], (oldData: any) => {
                      const newPromo = oldData.map((promo: any) => {
                        if (promo.ID === ID) promo.active = true;
                        return promo;
                      });
                      return newPromo;
                    });
                  }}
                  className='activate'
                >
                  {ACTIVATE}
                </Text>
              )}
            </Container>
          </Container>
          <Container className='column'>
            <Text className='title'>{DISCOUNT}</Text>
            <Text>{coupon_type === 'percentage' ? `${percentage} %` : `$ ${discount}`} </Text>
            <Text className='title'>{ACTIVE_DAYS}</Text>
            {moment(end_date).format('YYYY') < '1971' ? (
              <Text>Unlimited</Text>
            ) : (
              <Text>
                {' '}
                {moment(start_date).format('MM/DD/YYYY')} to {moment(end_date).format('MM/DD/YYYY')}
              </Text>
            )}
            {!fromEvent && (
              <>
                <Text className='title'>{APPLIED_TO}</Text>
                <ContainerText className='wrap ellipsis'>
                  {TicketTypes?.map(({ name }, index) => (
                    <Text>
                      {name}
                      {index === TicketTypes?.length - 1 ? '.' : ','}
                    </Text>
                  ))}
                </ContainerText>
              </>
            )}
            <Container className='iconTicket'>
              <Icon icon='ticket' className='cursor' />
            </Container>
          </Container>
        </Container>
        {isShowing && (
          <Container className='full blur'>
            <Text className='header padding'>{code}</Text>
            <Button
              onClick={() => {
                setPromoId(ID);
                setIsEdit(true);
                history.push('/promo-code-steps', {
                  active: 2,
                  ID,
                  fromEvent,
                  eventId,
                  isEdit: true,
                });
              }}
            >
              {VIEW_EDIT}
            </Button>
            <Button
              onClick={() => {
                toggle();
                queryClient.setQueryData(['getAllPromoCodes'], (oldData: any) => {
                  const newPromo = oldData.map((promo: any) => {
                    if (promo.ID === ID) promo.active = false;
                    return promo;
                  });
                  return newPromo;
                });
                mutateActivePromo({ ID, active: false });
              }}
            >
              {DISABLE}
            </Button>
            <Button className='go-back' onClick={() => toggle()}>
              <Icon icon='arrow-left' />
            </Button>
          </Container>
        )}
      </Card>
    </ContainerCard>
  );
};

export default PromoCodeCard;
