import React, { useState } from 'react';
import Select from 'react-select';

import { TZ_LIST } from '../../../../../utils/time';
import { Label, Column, customSelectStyles, customSelectStylesNoBorder } from '../styles';

interface Props {
  label: string;
  fromCreateEvent?: boolean;
}

export const DropDown = ({ label, fromCreateEvent }: Props) => {
  const [value, setValue] = useState<any>('');

  return (
    <Column className={fromCreateEvent ? '' : 'right'}>
      <Label className={fromCreateEvent ? '' : 's'}>{label}</Label>
      <Select
        value={value}
        onChange={setValue}
        options={TZ_LIST}
        styles={fromCreateEvent ? customSelectStylesNoBorder : customSelectStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
        placeholder='Select timezone'
      />
    </Column>
  );
};
