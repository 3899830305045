import React, { useState } from 'react';
import AutosizeInput from 'react-input-autosize';
import { useMutation } from 'react-query';

import { queryClient } from '../../../App';
import { greenCheck } from '../../../static/icons';
import { createSupport } from './services';
import { Img, InputContainer, StyledInput } from './styles';

interface Props {
  eventId: number;
}

export const NewSupport = ({ eventId }: Props) => {
  const [value, setValue] = useState<string>('');

  const { mutate: mutateCreateSupport } = useMutation(createSupport, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['getSupport'], (oldData: Array<any>) => {
        const newData = oldData.filter(item => !item.pressed);
        return [
          ...newData,
          {
            contact: data.contact,
            ID: data.ID,
          },
        ];
      });
    },
  });

  return (
    <InputContainer className='new'>
      <AutosizeInput
        value={value}
        onChange={e => setValue(e.target.value)}
        inputStyle={{
          ...StyledInput,
          textAlign: 'center',
          textTransform: value.length === 0 ? 'none' : 'capitalize',
          padding: '0px 10px',
        }}
        placeholder='Phone or Email'
      />
      {value.length > 0 && (
        <Img
          className='edit'
          src={greenCheck}
          onClick={() => mutateCreateSupport({ contact: value, eventId })}
        />
      )}
    </InputContainer>
  );
};
