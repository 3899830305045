import styled from 'styled-components';
import { gray, white } from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  &.padding {
    padding-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-left: 48px;
  &.sub {
    opacity: 0.7;
    font-size: 30px;
  }
`;

export const ItemTitle = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  opacity: 0.7;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  text-transform: uppercase;
  margin-left: 48px;
`;

export const ItemText = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  text-transform: uppercase;
  margin-left: 48px;
`;

export const EventItem = styled.div`
  font-family: ${primaryFont};
  background-color: ${gray};
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  padding: 10px;
  margin-left: 48px;
  border-radius: 8px;
  margin-top: 15px;
`;
