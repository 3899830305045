import {
  ContainerCheckbox,
  EventContainer,
  Text,
  Span,
  Row,
  Column,
  ToggleCustom,
  TextContainer,
} from './styles';
import Icon from '../../lib/components/Icon';
import { ALLOW_TRANSFERING } from '../../utils/strings';
import { useMutation } from 'react-query';
import { put } from '../../api/api';
import { queryClient } from '../../App';
import moment from 'moment';

interface EventProps {
  title: string;
  id: string;
  transfering_allowed: boolean;
  active_on_widget: boolean;
  selling_allowed: boolean;
  start_date: string;
  end_date: string;
}

const Event = ({
  title,
  id,
  transfering_allowed,
  active_on_widget,
  selling_allowed,
  start_date,
  end_date,
}: EventProps) => {
  const { mutate: mutateEventWidget } = useMutation(
    () =>
      put(`/events/${id}`, {
        active_on_widget: !active_on_widget,
      }),
    {
      onSuccess: () => {
        queryClient.setQueryData(['getEvents'], (oldData: any) => {
          oldData.map((element: any) => {
            if (element.ID === id) element.active_on_widget = !active_on_widget;
          });
          return oldData;
        });
      },
    }
  );

  const { mutate: mutateTransferAllowed } = useMutation(
    () =>
      put(`/events/${id}`, {
        transfering_allowed: !transfering_allowed,
      }),
    {
      onSuccess: () => {
        queryClient.setQueryData(['getEvents'], (oldData: any) => {
          oldData.map((element: any) => {
            if (element.ID === id) element.transfering_allowed = !transfering_allowed;
          });
          return oldData;
        });
      },
    }
  );

  const { mutate: mutateSellingAllowed } = useMutation(
    () =>
      put(`/events/${id}`, {
        selling_allowed: !selling_allowed,
      }),
    {
      onSuccess: () => {
        queryClient.setQueryData(['getEvents'], (oldData: any) => {
          oldData.map((element: any) => {
            if (element.ID === id) element.selling_allowed = !selling_allowed;
          });
          return oldData;
        });
      },
    }
  );

  return (
    <EventContainer>
      <Row>
        <ContainerCheckbox
          checked={active_on_widget}
          onClick={() => {
            mutateEventWidget();
          }}
        >
          {active_on_widget && <Icon icon='check' width={20} />}
        </ContainerCheckbox>
        <TextContainer>
          <Span className='left'>{title}</Span>
          {moment(start_date).format('MM/DD/YYYY') === moment(end_date).format('MM/DD/YYYY') ? (
            <Span className='date'>{moment(start_date).format('MM/DD/YYYY')} </Span>
          ) : (
            <Span className='date'>
              {moment(start_date).format('MM/DD/YYYY')} - {moment(end_date).format('MM/DD/YYYY')}
            </Span>
          )}
        </TextContainer>
      </Row>
      {active_on_widget && (
        <Column className='top'>
          <Row>
            <Text className='event'>{ALLOW_TRANSFERING}</Text>
            <Row className='align-center'>
              <ToggleCustom
                checked={transfering_allowed}
                icons={false}
                onChange={e => {
                  mutateTransferAllowed();
                }}
              />
            </Row>
          </Row>
          <Row>
            <Text className='event'>Allow secondary sales</Text>
            <Row className='align-cente'>
              <ToggleCustom
                icons={false}
                checked={selling_allowed}
                onChange={e => {
                  mutateSellingAllowed();
                }}
              />
            </Row>
          </Row>
        </Column>
      )}
    </EventContainer>
  );
};

export default Event;
