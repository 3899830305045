import styled from 'styled-components';

import { primaryFont } from '../../../utils/typography';
import { grayRGBA, green, lightblack, lightGray, remove, white } from '../../../utils/theme';

export const Button = styled.div`
  border-radius: 100px;
  background: ${green};
  justify-content: center;
  padding: 10px 16px;
  min-width: 122px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  cursor: pointer;
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
  }
  &.remove {
    background: ${remove};
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grayRGBA};
  &.margin {
    margin-top: 32px;
  }
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.top {
    padding: 30px 50px 0px 50px;
  }
`;

export const Row = styled.div`
  display: flex;
  &.center {
    justify-content: center;
  }
  &.bottom {
    gap: 10px;
    padding: 30px;
  }
  &.top {
    padding: 20px;
  }
`;

export const Container = styled.div`
  width: 647px;
  background: #1f1f1f;
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
  outline: none;
  position: absolute;
  top: 168px;
  right: 40px;
  z-index: 99;
`;
