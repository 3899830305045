import useMobileView from '../../../../hooks/useMobileView';
import { resetLocalStorage } from '../../../../utils/utils';
import { Column, Link, Row, Wrapper, ButtonLink } from './styles';

export default function Footer() {
  const isMobile = useMobileView();
  return (
    <Wrapper isMobile={isMobile}>
      <Row>
        <Column>
          <Link href=''>Upcoming Events</Link>
          <Link href='https://tixologi.com/press/'>Press Releases</Link>
          <Link href='https://tixologi.com/get-in-touch/'>Contact Us</Link>
        </Column>
        <Column>
          <Link href='https://twitter.com/tixologi'>Twitter</Link>
          <Link href='https://facebook.com/Tixologi-116083013993842'>Facebook</Link>
          <Link href='https://instagram.com/tixologi/'>Instagram</Link>
        </Column>
        <Column>
          <ButtonLink onClick={() => resetLocalStorage()}>Log in</ButtonLink>
          <ButtonLink onClick={() => resetLocalStorage()}>Log out</ButtonLink>
        </Column>
      </Row>
    </Wrapper>
  );
}
