import React, { useState } from 'react';

import { EventFormType } from '../../../../types';
import { Text } from '../../PromoCodes/styles';
import { DropDown } from '../TicketTypes/Components/DropDown';
import DatePickerComponent from './DatePicker';
import { TimeRow } from './TimeRow';
import { Span, Container, InnerContainer } from './styles';

interface Props {
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
}

const OnSaleTime = ({ value, setValue }: Props) => {
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState({
    hour: 10,
    min: '00',
    period: 'pm',
  });

  const handleDateChange = (newDate: Date) => {
    const { hour, min, period } = startTime;
    const newStartTime = `${hour}:${min} ${period}`;
    const dateArray = new Date(newDate).toString().split(' ');
    const finalStartDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newStartTime}`;
    setDate(newDate);
    let event = { ...value };
    event.onSaleTime = new Date(finalStartDate);
    setValue(event);
  };

  return (
    <Container>
      <Span>
        This date and time will be used as the default on sale time for your event tickets. You can
        always add custom schedules per ticket, during ticket creation.
      </Span>
      <InnerContainer>
        <DropDown label='timezone' fromCreateEvent />
        <Text className='small margin-bottom'>Sale date</Text>
        <DatePickerComponent
          activeDays={true}
          date={date}
          handleChange={handleDateChange}
          setDate={setDate}
          startTime={startTime}
          value={value}
          setValue={setValue}
        />
        <Text className='small margin-bottom'>Sale time</Text>
        <TimeRow
          value={value}
          setValue={setValue}
          time={startTime}
          setTime={setStartTime}
          date={date}
        />
      </InnerContainer>
    </Container>
  );
};

export default OnSaleTime;
