import React from 'react';
import Modal from '@mui/material/Modal';

import {
  ModalWrapper,
  Img,
  ModalText,
  Column,
  Row,
  ButtonModal,
  modalStyle,
} from './styles';
import confirmationTick from '../../static/icons/confirmationTick.svg';
import { CHANGES_ON_SITE, OKAY, SAVED_CHANGES } from '../../utils/strings';

interface ConfirmationModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isWidget?: boolean;
}

export const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  isWidget,
}: ConfirmationModalProps) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <ModalWrapper>
      <Column className='height space'>
        <Column className='height center'>
          <Column className='center'>
            <Img
              src={confirmationTick}
              alt='confirmation'
              className='confirmation'
            />
            <ModalText>{SAVED_CHANGES}</ModalText>
            {isWidget && (
              <ModalText className='span'>{CHANGES_ON_SITE}</ModalText>
            )}
          </Column>
        </Column>
        <Row className='borderTop width'>
          <ButtonModal
            className='okay'
            onClick={() => {
              setIsOpen(false);
              //TODO: backend call
            }}
          >
            {OKAY}
          </ButtonModal>
        </Row>
      </Column>
    </ModalWrapper>
  </Modal>
);
