import EventList from '../EventList';
import Navbar from '../Navbar';
import { ALL_EVENTS } from '../../../../utils/strings';
import { Header, Container } from '../styles';
import { queryClient } from '../../../../App';

export default function BuyTickets() {
  const data: any = queryClient.getQueryData('getWidgetCustomization');
  const primaryFontColor = data?.title_brand_color;
  const secondaryColor = data?.secondary_brand_color;

  return (
    <Container>
      <Navbar />
      <Header
        secondaryColor={secondaryColor}
        primaryFontColor={primaryFontColor}
        className='apply-font-0'
      >
        {ALL_EVENTS}
      </Header>
      <EventList />
    </Container>
  );
}
