import styled from 'styled-components';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import FontPicker from 'font-picker-react';

import {
  white,
  contentBackground,
  whiteRGBA,
  grayRGBAopacity,
  emailBackground,
  lightblack,
  grayRGBA,
  lightGray,
  transparent,
  darkerGray,
  green,
  lightGrayTwo,
  black,
} from '../../utils/theme';
import { primaryFont, secondaryFont } from '../../utils/typography';

export const ToggleCustom = styled(Toggle)`
  .react-toggle-thumb {
    box-shadow: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0px 0px 80px 0px;
  &.column {
    flex-direction: column;
    align-items: center;
    max-width: 677px;
    padding: 0px;
  }
`;

export const Container = styled.div`
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.margin {
    margin-right: 60px;
  }
  &.fit {
    width: fit-content;
  }
  &.top {
    margin-top: 16px;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.height {
    height: 100%;
  }
  &.space {
    justify-content: space-between;
  }
  &.width {
    width: 70%;
  }
  &.start {
    justify-content: flex-start;
    align-items: center;
  }
`;

export const Row = styled.div`
  display: flex;
  &.centerBorder {
    justify-content: center;
    padding: 24px 0px;
    border-bottom: 1px solid ${grayRGBA};
  }
  &.center {
    justify-content: center;
    padding: 24px 0px;
  }
  &.margin {
    justify-content: center;
    margin-top: 9px;
  }
  &.terms {
    margin-top: 16px;
  }
  &.top {
    margin-top: 26px;
  }
  &.space {
    justify-content: space-between;
  }
  &.width {
    width: 100%;
  }
  &.align-center {
    align-items: center;
  }
  &.min-width {
    min-width: 200px;
  }
  &.borderTop {
    justify-content: center;
    padding: 24px 0px;
    border-top: 1px solid ${grayRGBA};
  }
  &.padding {
    padding: 0 0 15px 0;
  }
  &.overflow {
    overflow-y: scroll;
    max-height: 500px;
  }
`;

export const ButtonModal = styled.div`
  background: ${green};
  border-radius: 100px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  padding: 15px 32px;
  cursor: pointer;
  &.cancel {
    padding: 15px 52px;
    margin-right: 10px;
    background: ${transparent};
    border: 1px solid ${grayRGBA};
    color: ${lightGray};
  }
  &.okay {
    padding: 15px 52px;
  }
  &.min {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const Button = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  cursor: pointer;
  &.border {
    border: 1px solid ${darkerGray};
    border-radius: 100px;
    padding: 14px 32px;
  }
  &.green {
    background: ${green};
    border: none;
    padding: 14px 52px;
    margin-left: 28px;
  }
`;

export const ContentContainer = styled.div`
  background: ${contentBackground};
  box-shadow: 0px 4px 4px ${whiteRGBA};
  border-radius: 4px;
  padding: 29px 60px;
  margin-top: 12px;
  &.event {
    background: ${transparent};
    box-shadow: none;
    padding: 0px;
    margin-top: 50px;
  }
`;

export const TextContainer = styled.div``;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
  &.margin {
    margin-top: 47px;
  }
  &.left {
    margin-left: 20px;
  }
  &.date {
    font-weight: normal;
    margin-left: 20px;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  &.subtitle {
    font-weight: normal;
    font-size: 24px;
    opacity: 0.7;
    margin-top: 16px;
  }
  &.modal {
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
  }
  &.widget {
    font-family: ${secondaryFont};
    font-weight: bold;
    font-size: 25px;
    letter-spacing: -0.02em;
    color: ${black};
  }
  &.refund {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: start;
    margin-bottom: 10px;
  }
  &.refundSubtitle {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
    text-align: start;
  }
  &.modal {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 20px;
    margin-top: 4px;
  }

  &.modalSubtitle {
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 4px;
  }
`;

export const Input = styled.input`
  background: ${emailBackground};
  border: 1px solid ${grayRGBAopacity};
  border-radius: 4px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.5;
  padding: 12px;
  width: -webkit-fill-available;
  margin-top: 12px;
  outline: none;
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: ${white};
  opacity: 0.7;
  &.palette {
    font-weight: 600;
    font-size: 12px;
  }
  &.event {
    font-weight: 600;
    font-size: 12px;
    margin-left: 40px;
    min-width: 200px;
  }
  &.widget {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: ${lightblack};
    padding: 20px 32px;
    border: 1px solid ${darkerGray};
    border-radius: 100px;
    cursor: pointer;
  }
`;

export const PaletteContainer = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 8px;
  &.large {
    width: 76px;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &.font {
    margin-left: 22px;
  }
`;

export const AddImg = styled.img`
  &.font {
    margin-left: 15px;
    cursor: pointer;
  }
`;

export const DeletePalette = styled.img`
  width: 24px;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 1009px;
  background: ${lightblack};
  border-radius: 16px;
  outline: none;

  &.twofactor {
    width: 800px;
  }

  &.refund {
    width: 800px;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: ${whiteRGBA};
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadFont = styled.div`
  padding: 10px 18px;
  background-color: ${transparent};
  border: 1px solid ${darkerGray};
  border-radius: 50px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  display: flex;
  align-items: center;
  color: ${darkerGray};
  cursor: pointer;
`;

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 21px 32px;
  background: ${contentBackground};
  box-shadow: 0px 4px 4px ${whiteRGBA};
  border-radius: 4px;
  margin-top: 16px;
`;

export const CheckBox = styled.input`
  border: 1px ${green} solid;
`;

export const ContainerCheckbox = styled.div<{ checked: boolean }>`
  width: 14px;
  height: 14px;
  border: 2px ${green} solid;
  background: ${props => (props.checked ? green : transparent)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Img = styled.img`
  &.confirmation {
    width: 207px;
    height: 207px;
  }
  &.ticket {
    width: 60%;
    border-radius: 18px;
  }
`;

export const ModalWrapper = styled.div`
  width: 55%;
  height: 70%;
  background: ${lightblack};
  border-radius: 16px;
  outline: none;
  &.library {
    height: auto;
    max-height: 100vh;
  }
`;

export const ModalText = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  margin-top: 10px;
  &.span {
    font-weight: normal;
    font-size: 18px;
  }
`;

export const WidgetPrevWrapper = styled.div`
  width: 70%;
  height: 90%;
  background: ${lightGrayTwo};
  border-radius: 17px;
  overflow-x: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  outline: none;
`;

export const MyFontPicker = styled(FontPicker)`
  width: 500px;
  height: 90%;
  background: ${lightGrayTwo};
  border-radius: 17px;
  overflow-x: hidden;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  outline: none;
`;

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
