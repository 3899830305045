import React, { useState } from 'react';
import moment from 'moment';

import { editPencil } from '../../../static/icons';
import { Img, InputContainer, Text } from './styles';
import Modal from './TimeSelectorModal';

interface Props {
  description: string;
  item: string;
  title: string;
  timezone: string;
  eventId: number;
  dayId: number;
  index: number;
}

export const TimeSelector = ({
  description,
  item,
  eventId,
  title,
  timezone,
  dayId,
  index,
}: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);

  return (
    <InputContainer
      className={isEdit ? 'edit relative' : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text className='date'>{moment(description).utc().format('LT')}</Text>
      {(hover || isEdit) && (
        <Img className='edit' src={editPencil} onClick={() => setIsEdit(true)} />
      )}
      {isEdit && (
        <Modal
          title={title}
          timezone={timezone}
          eventId={eventId}
          description={description}
          hour={moment(description).utc().format('LT').split(':')[0]}
          min={moment(description).utc().format('LT').split(':')[1].split(' ')[0]}
          period={moment(description).utc().format('LT').split(':')[1].split(' ')[1]}
          setIsOpen={setIsEdit}
          item={item}
          dayId={dayId}
          index={index}
        />
      )}
    </InputContainer>
  );
};
