import api from ".";

const BASE_URL = "/partner/v1/seatmaps";

export function getSeatmapById(id) {
  return api.get(`${BASE_URL}/seatmap`, { id });
}

export async function getSeatmapsByVenue() {
  return api.get(`${BASE_URL}/seatmaps`);
}

export async function listSeatmaps() {
  const names = ['Staples Basketball', 'Staples Hockey', 'Staples Concert', 'Staples Comedy Festival', 'Staples Arena Standard'];
  return names.map((name, index) => ({ id: index, name }));
}