import styled from 'styled-components';

import { grayRGBA, green, remove, select, transparent, white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#080808',
    border: '1px solid rgba(255, 255, 255, 0.75)',
    borderRadius: 4,
    boxShadow: 'none',
    width: '100%',
    minHeight: 0,
    padding: 5,
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.75)',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    opacity: 0.7,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: select,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: select,
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};

export const Wrapper = styled.div<{ fromEvent: boolean }>`
  padding: ${props => (props.fromEvent ? '36px' : '40px 18% 30px 18%')};
  ${props => (!props.fromEvent ? 'overflow-y: scroll; height: 400px;' : '')}
  position: relative;
`;

export const Container = styled.div`
  background: #1f1f1f;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 14px 14px 0px 14px;
  &.no-input {
    padding: 14px;
  }
  &.relative {
    position: relative;
  }
`;

export const StyledInput = styled.input`
  all: unset;
  &.info {
    background: #080808;
    border: 1px solid rgba(255, 255, 255, 0.75);
    border-radius: 4px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    color: ${white};
    padding: 12px;
    margin-bottom: 14px;
  }
  &.event {
    padding: 11px;
  }
  &.error {
    border: 1px solid ${remove};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.right {
    width: 43%;
  }
  &.left {
    width: 29%;
  }
  &.middle {
    width: 26%;
  }
  &.relative {
    position: relative;
  }
  &.bottom {
    margin-bottom: 14px;
  }
`;

export const Row = styled.div`
  display: flex;
  &.wrap {
    flex-wrap: wrap;
  }
  &.gap {
    gap: 10px;
  }
  &.width {
    width: 100%;
  }
  &.space-between {
    justify-content: space-between;
  }
  &.margin-top {
    margin-top: 10px;
  }
  &.margin-top2 {
    margin-top: 20px;
  }
  &.center {
    justify-content: center;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

export const Label = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
  margin-bottom: 12px;
  &.s {
    font-size: 12px;
    margin-bottom: 6px;
  }
  &.no-uppercase {
    text-transform: none;
  }
  &.m {
    font-size: 16px;
  }
  &.same-start-date {
    opacity: 1;
    margin-left: 6px;
  }
`;

export const Img = styled.img`
  &.gray {
    opacity: 0.7;
    padding: 5px 6px;
    cursor: pointer;
  }
  &.currency {
    position: absolute;
    bottom: 30px;
    left: -14px;
  }
  &.promo {
    width: 20px;
  }
  &.remove {
    opacity: 0.7;
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 13px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  background: #080808;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  &.gap {
    gap: 6px;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.7;
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 73px;
  left: -15px;
  z-index: 90;
  background: #3d3d3d;
  box-shadow: 0px 12.5366px 12.5366px 12.5366px rgba(0, 0, 0, 0.27);
  border-radius: 4px;
  padding: 20px;
`;

export const ContainerCheckbox = styled.div<{ checked: boolean }>`
  width: 14px;
  height: 14px;
  border: 1px ${green} solid;
  border-radius: 2px;
  background: ${props => (props.checked ? green : transparent)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Button = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${green};
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid ${green};
  border-radius: 11.0476px;
  cursor: pointer;
  padding: 7px 10px;
  gap: 10px;
`;

export const customSelectStylesNoBorder = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: '#080808',
    border: 'none',
    borderRadius: 4,
    boxShadow: 'none',
    width: '100%',
    minHeight: 0,
    padding: 5,
    '&:hover': {
      border: 'none',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    opacity: 0.7,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: '#3D3D3D',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: '#3D3D3D',
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};
