import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Icon from '../../../lib/components/Icon';
import PromoCodeCard from '../../../lib/components/PromoCodes/PromoCodeCard';
import { Card, Wrapper, Container, Text } from '../../../lib/components/PromoCodes/stylesInfo';
import { ADD_NEW_PROMOCODE } from '../../../utils/strings';

interface Props {
  ticketTypes: any;
  eventId: number;
}

const PromoCodes = ({ ticketTypes, eventId }: Props) => {
  const [promoId, setPromoId] = useState<number>(null);
  const history = useHistory();

  const promoCodes = useMemo(
    () =>
      ticketTypes?.reduce((finalPromoCodes, ticket) => {
        ticket?.Promocodes?.forEach(pc => {
          if (!finalPromoCodes?.find(c => c.ID === pc.ID)) finalPromoCodes.push(pc);
        });
        return finalPromoCodes;
      }, []),
    [ticketTypes]
  );

  return (
    <Wrapper className='grid margin'>
      <Card
        className='addPromo'
        onClick={() => history.push('/promo-code-steps', { active: 1, fromEvent: true, eventId })}
      >
        <Container className='promo'>
          <Container className='space-between'>
            <Container className='iconAdd'>
              <Icon icon='addIcon' />
              <Text className='newPromo'>{ADD_NEW_PROMOCODE}</Text>
            </Container>
          </Container>
        </Container>
      </Card>
      {promoCodes?.map(
        ({ code, percentage, discount, coupon_type, start_date, end_date, ID, active }) => (
          <PromoCodeCard
            code={code}
            percentage={percentage}
            discount={discount}
            coupon_type={coupon_type}
            start_date={start_date}
            end_date={end_date}
            ID={ID}
            active={active}
            setPromoId={setPromoId}
            newPromo={promoId === ID}
            fromEvent
            eventId={eventId}
          />
        )
      )}
    </Wrapper>
  );
};

export default PromoCodes;
