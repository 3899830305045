import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getData, post } from '../../../api/api';
import {
  Container,
  ContainerOrganization,
  Text,
  Img,
  Profile,
  Wrapper,
  Image,
} from './stylesSelectOrganization';

export default function SelectOrganization() {
  const history = useHistory();
  const userId = localStorage.getItem('userId');

  const { mutate: loginPartner } = useMutation(
    (ID: number) =>
      post('/login/partner', {
        partner_id: parseInt(ID.toString()),
      }),
    {
      onSuccess: data => {
        localStorage.setItem('accessToken', data);
      },
    }
  );

  const userWithoutPartner = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const { data: users } = useQuery('getUsers', () => getData(`/users`));

  return (
    <Wrapper>
      <Container>
        <Text className='title'> Select an organization </Text>
        <ContainerOrganization>
          {users
            ?.find(u => u?.ID.toString() === userId)
            ?.Partners?.map(({ name, ID, image }) => (
              <Profile
                onClick={() => {
                  loginPartner(ID.toString());
                  localStorage.setItem('partnerId', ID.toString());
                  localStorage.setItem('partnerLogo', image);
                  history.push('/event-list');
                }}
              >
                <Image>
                  <Img src={image}></Img>
                </Image>
                <Text>{name}</Text>
              </Profile>
            ))}
          {users?.find(u => u?.ID.toString() === userId)?.Partners?.length === 0 &&
            userWithoutPartner()}
        </ContainerOrganization>
      </Container>
    </Wrapper>
  );
}
