import React from 'react';

import { queryClient } from '../../../App';
import { NAME } from '../../../utils/strings';
import { Input, Wrapper, Span } from './styles';

interface Props {
  name: string;
}

export const ModalName = ({ name }: Props) => {
  return (
    <Wrapper>
      <Span>{NAME}</Span>
      <Input
        value={name}
        onChange={e =>
          queryClient.setQueryData(['getPartner'], (oldData: any) => ({
            ...oldData,
            name: e.target.value,
          }))
        }
      />
    </Wrapper>
  );
};
