import { Wrapper, RowContainer, Text, ItemContainer } from './styles';
import { OPENS, SOLD, TOTAL_FACE_VALUE } from '../../../utils/strings';
import { salesInfo } from './data';

interface RowType {
  options: any;
}

const SalesAnalytics = ({ options }: RowType) => {
  const totals = [
    {
      title: TOTAL_FACE_VALUE,
      information: options?.sales?.total_face_value || '-',
    },
  ];
  return (
    <Wrapper>
      {totals.map(element => (
        <RowContainer className='container'>
          <Text className='title'>{element.title}</Text>
          <Text className='data'>{element.information}</Text>
        </RowContainer>
      ))}
      <Wrapper className='wrapColumns'>
        <RowContainer className='column'>
          <Text className={'title'}>{SOLD}</Text>
          {options &&
            Object.entries(options?.sales?.sold).map((element, index) => (
              <ItemContainer style={{ marginLeft: index === 0 ? 0 : 25 }}>
                <Text className={'title-s'}>{element ? `${element[0]}` : '-'}</Text>
                <Text className={'data-s'}>{element ? `${element[1] as number}` : '-'}</Text>
              </ItemContainer>
            ))}
        </RowContainer>
        <RowContainer className='column'>
          <Text className={'title'}>{OPENS}</Text>
          {options &&
            Object.entries(options?.sales?.opens).map((element, index) => (
              <ItemContainer style={{ marginLeft: index === 0 ? 0 : 25 }}>
                <Text className={'title-s'}>{element ? `${element[0]}` : '-'}</Text>
                <Text className={'data-s'}>{element ? `${element[1] as number}` : '-'}</Text>
              </ItemContainer>
            ))}
        </RowContainer>
      </Wrapper>
      <RowContainer className='sales'>
        {options &&
          salesInfo().second.map(element => (
            <ItemContainer>
              <Text className='title'>{element.title}</Text>
              <Text className='data'>{element.information}</Text>
            </ItemContainer>
          ))}
      </RowContainer>
    </Wrapper>
  );
};

export default SalesAnalytics;
