import styled from 'styled-components';

import { green } from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 16px;
  margin-top: 32px;
`;

export const AddBox = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${green};
  height: 297px;
  padding: 26px;
  cursor: pointer;
`;

export const Img = styled.img`
  &.add {
    margin-right: 10px;
  }
`;
