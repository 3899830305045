import React from 'react';
import { Modal } from '@mui/material';

import { modalStyle } from '../../../WidgetCustomization/styles';
import { alertCircle } from '../../../../static/img';
import { Img } from '../styles';
import { ContainerModal, Row, Button, Text, Column, Line } from '../stylesModal';

interface Props {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  isTimeSlot?: boolean;
}

const ModalCancel = ({ isOpen, setIsOpen, isTimeSlot, setIsConfirmation }: Props) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <ContainerModal className='cancel'>
      <Column className='top delete'>
        <Img className='trash' src={alertCircle} />
        <Text>
          You have already sold tickets for this {`${isTimeSlot ? 'timeslot' : 'day'}.`} You cannot
          delete {`${isTimeSlot ? 'timeslots' : 'days'}`} with existing tickets.
        </Text>
      </Column>
      <Line />
      <Row className='bottom'>
        <Button
          onClick={() => {
            setIsOpen(false);
            setIsConfirmation(false);
          }}
          className='correct'
        >
          Okay
        </Button>
      </Row>
    </ContainerModal>
  </Modal>
);

export default ModalCancel;
