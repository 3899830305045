import { useState } from 'react';

import { AnalyticsEvent, EventType } from '../../../types';
import Icon from '../Icon';
import { Tab, Drop, Header } from './styles';

type Props = {
  period: string;
  events: EventType[] | AnalyticsEvent[];
  open: boolean;
  Component: any;
  props?: any;
};

export default function DropdownTab({ period, events, open, Component, props }: Props) {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <>
      <Tab>
        <Header>{period}</Header>
        <Icon
          icon={isOpen ? 'arrow-button-up' : 'arrow-button-down'}
          onClick={() => setIsOpen(!isOpen)}
          className='cursor'
          height={30}
        />
      </Tab>
      {isOpen && (
        <Drop>
          {events.map(event => (
            <Component item={event} key={event.id} props={props} />
          ))}
        </Drop>
      )}
    </>
  );
}
