import React, { useEffect, useRef } from 'react';

import { Container, Row, Button, Text, Column } from '../stylesModal';

interface Props {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  isTimeSlot?: boolean;
}

export default function ModalDelete({ setIsConfirmation, setIsOpen, isTimeSlot }: Props) {
  const refDelete = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (refDelete.current && !refDelete.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refDelete]);

  return (
    <Container onClick={e => e.preventDefault()} ref={refDelete}>
      <Column className='top'>
        <Text>Delete this {`${isTimeSlot ? 'timeslot' : 'day'}?`}</Text>
      </Column>
      <Row className='bottom'>
        <Button
          onClick={() => {
            setIsConfirmation(true);
          }}
          className='remove'
        >
          Delete
        </Button>
      </Row>
    </Container>
  );
}
