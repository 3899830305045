import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';

import { getData } from '../../../api/api';
import { ADD_NEW_PROMOCODE } from '../../../utils/strings';
import Icon from '../Icon';
import { Card, Container, Text, Wrapper, ContainerInfo } from './stylesInfo';
import PromoCodeCard from './PromoCodeCard';

type Props = {
  promoId: number;
  setPromoId: React.Dispatch<React.SetStateAction<number>>;
};

const PromoCodeInfo = ({ promoId, setPromoId }: Props) => {
  const partner_id = localStorage.getItem('partnerId');
  const history = useHistory();

  const { data: promoCodes } = useQuery('getAllPromoCodes', () =>
    getData(`promocodes?partner_id=${partner_id}`)
  );

  return (
    <ContainerInfo>
      <Text className='titlePromo'>Promo Codes</Text>
      <Wrapper className='grid'>
        <Card className='addPromo' onClick={() => history.push('/promo-code-steps', { active: 1 })}>
          <Container className='promo'>
            <Container className='space-between'>
              <Container className='iconAdd'>
                <Icon icon='addIcon' />
                <Text className='newPromo'>{ADD_NEW_PROMOCODE}</Text>
              </Container>
            </Container>
          </Container>
        </Card>
        {promoCodes?.map(
          ({
            code,
            percentage,
            discount,
            coupon_type,
            start_date,
            end_date,
            TicketTypes,
            ID,
            active,
          }) => (
            <PromoCodeCard
              code={code}
              percentage={percentage}
              discount={discount}
              coupon_type={coupon_type}
              start_date={start_date}
              end_date={end_date}
              TicketTypes={TicketTypes}
              ID={ID}
              active={active}
              setPromoId={setPromoId}
              newPromo={promoId === ID}
            />
          )
        )}
      </Wrapper>
    </ContainerInfo>
  );
};

export default PromoCodeInfo;
