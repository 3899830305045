import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Modal } from '@mui/material';

import { modalStyle } from '../../../../pages/WidgetCustomization/styles';
import ExistingPromoCodes from './ExistingPromoCodes';
import { getData } from '../../../../api/api';
import { TicketsType } from '../../../../types';
import SearchBar from './SearchBar';
import { Container, Column, Row, Title, Line, Button } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  index: number;
}

export const ExistingPCModal = ({ isOpen, setIsOpen, setTickets, tickets, index }: Props) => {
  const [search, setSearch] = useState<string>('');
  const partner_id = localStorage.getItem('partnerId');

  const { data: promoCodes } = useQuery('getAllPromoCodes', () =>
    getData(`promocodes?partner_id=${partner_id}`)
  );

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column>
          <Row className='center top modal-top'>
            <Title>Select Promo Codes to apply</Title>
          </Row>
          <Line />
          <Row className='center'>
            <SearchBar setValue={setSearch} value={search} />
          </Row>
          <ExistingPromoCodes
            promoCodes={promoCodes}
            setTickets={setTickets}
            tickets={tickets}
            search={search}
            index={index}
          />
          <Line />
          <Row className='center bottom modal-bottom'>
            <Button className='cancel' onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              className={tickets[index].attachedPromoCodes.length > 0 ? '' : 'disable'}
              onClick={() => setIsOpen(false)}
            >
              Attach Promo Code
            </Button>
          </Row>
        </Column>
      </Container>
    </Modal>
  );
};
