import React from 'react';
import { Modal } from '@mui/material';

import { ContainerModal, Row, Button, Text, Column, Line } from '../stylesModal';
import { modalStyle } from '../../../WidgetCustomization/styles';
import { alertCircle } from '../../../../static/img';
import { Img } from '../styles';

interface Props {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ModalOneSlot = ({ isOpen, setIsOpen }: Props) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <ContainerModal className='slot'>
      <Column className='top delete'>
        <Img className='trash' src={alertCircle} />
        <Text>
          Unable to delete timeslot. Events must have at least one timeslot, you may not delete this
          slot.
        </Text>
      </Column>
      <Line />
      <Row className='bottom'>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
          className='correct'
        >
          Okay
        </Button>
      </Row>
    </ContainerModal>
  </Modal>
);

export default ModalOneSlot;
