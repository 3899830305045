import React from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';

import { ContainerModal, Row, Button, Text, Column, Line } from '../stylesModal';
import { queryClient } from '../../../../App';
import { deleteSlot } from '../services';
import { modalStyle } from '../../../WidgetCustomization/styles';
import { trashIcon } from '../../../../static/img';
import { Img } from '../styles';

interface Props {
  isOpen?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCancel: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOneSlot: React.Dispatch<React.SetStateAction<boolean>>;
  dayId?: number;
  isTimeSlot?: boolean;
  sold?: number;
  event_days?: number;
}

export default function ModalDeleteConfirmation({
  isOpen,
  setIsOpen,
  dayId,
  isTimeSlot,
  sold,
  setIsCancel,
  setIsOneSlot,
  event_days,
}: Props) {
  const { mutate: mutateDelete } = useMutation(deleteSlot, {
    onSuccess: () => {
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        const newArray = oldData.event_days.filter(t => t.ID !== dayId);
        oldData.event_days = newArray;
        return oldData;
      });
      setIsOpen(false);
    },
  });
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ContainerModal>
        <Column className='top delete'>
          <Img className='trash' src={trashIcon} />
          <Text>Are you sure you want to delete this {`${isTimeSlot ? 'timeslot' : 'day'}?`}</Text>
        </Column>
        <Line />
        <Row className='bottom'>
          <Button
            onClick={() => {
              setIsOpen(false);
            }}
            className='cancel'
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (event_days === 1) {
                setIsOneSlot(true);
                setIsOpen(false);
              } else {
                if (sold > 0) {
                  setIsCancel(true);
                } else {
                  mutateDelete({ dayId });
                }
              }
            }}
            className='remove'
          >
            Delete
          </Button>
        </Row>
      </ContainerModal>
    </Modal>
  );
}
