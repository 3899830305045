import moment from 'moment';
import { useHistory } from 'react-router-dom';

import { AnalyticsEvent } from '../../../../types';
import {
  STILL_ON_SALE,
  TICKETS_SOLD,
  TICKETS_OPEN,
  TOTAL_GROSS,
  TICKETS_COMPS,
} from '../../../../utils/strings';
import { Card, Container, Text } from './styles';

type Props = {
  item: AnalyticsEvent;
};

export default function AnalyticsEventCard({ item }: Props) {
  const history = useHistory();

  return (
    <Card
      className='analytic'
      onClick={() => history.push(`event-analysis/${item.event_id}`)}
    >
      <Container className='column full space-between'>
        <Container className='space-between'>
          <Container className='column'>
            <Text className='header padding'>{item.name}</Text>

            <Container>
              <Text className='padding uppercase normal'>
                {moment(item.start_date).format('MMMM DD, YYYY')}
              </Text>
              <Text className='padding normal'>
                {moment(item.start_date).format('h:mm A')}
              </Text>
              {item.onSale && (
                <Text className='padding uppercase green normal'>
                  {STILL_ON_SALE}
                </Text>
              )}
            </Container>
          </Container>
        </Container>

        <Container>
          <Container className='column center'>
            <Text className='no-padding-bottom uppercase normal'>
              {TICKETS_SOLD}
            </Text>
            <Text className='no-padding-top content'>{item.tickets_sold}</Text>
          </Container>
          <Container className='column center'>
            <Text className='no-padding-bottom uppercase normal'>
              {TICKETS_OPEN}
            </Text>
            <Text className='no-padding-top content'>{item.tickets_open}</Text>
          </Container>
        </Container>

        <Container>
          <Container className='column center'>
            <Text className='no-padding-bottom uppercase normal'>
              {TOTAL_GROSS}
            </Text>
            <Text className='no-padding-top content'>
              {item.total_gross_potential}
            </Text>
          </Container>
          <Container className='column center'>
            <Text className='no-padding-bottom uppercase normal'>
              {TICKETS_COMPS}
            </Text>
            <Text className='no-padding-top content'>{item.tickets_comps}</Text>
          </Container>
        </Container>
      </Container>
    </Card>
  );
}
