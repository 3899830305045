export default function Logo() {
  return (
    <>
      <svg
        width={52}
        height={52}
        viewBox='0 0 52 52'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M27.0602 1.9908C27.0602 0.891313 27.9515 0 29.051 0H49.0144C50.1139 0 51.0052 0.891309 51.0052 1.99079V34.738L42.2846 19.6336L40.0865 20.9344L39.3694 19.7227L41.5806 18.4141L34.4911 6.13482C34.185 5.60455 33.5069 5.42287 32.9767 5.72902L27.0602 9.14491V1.9908ZM38.2527 47.5919L51.0052 40.2293V49.014C51.0052 50.1135 50.1139 51.0048 49.0144 51.0048H29.051C27.9515 51.0048 27.0602 50.1135 27.0602 49.014V30.4232L36.7383 47.1861C37.0444 47.7164 37.7225 47.8981 38.2527 47.5919ZM28.2185 27.9578L27.0602 28.6433V27.0072L27.5015 26.7461L28.2185 27.9578ZM21.8987 23.8899C22.336 23.8899 22.7403 23.7489 23.0688 23.5099L18.4007 15.4246C18.0946 14.8944 18.2763 14.2163 18.8065 13.9101L23.8895 10.9755V1.99113C23.8895 0.891642 22.9982 0.000333208 21.8987 0.000333208H1.99079C0.891309 0.000333208 0 0.891641 0 1.99113V21.8991C0 22.9985 0.891308 23.8899 1.99079 23.8899H21.8987ZM27.6942 26.6307L27.0602 25.5325V12.1072L31.7577 9.39509C32.2879 9.08894 32.966 9.27062 33.2721 9.80089L39.1652 20.008L37.0545 21.2266L37.7076 22.3301L39.9085 21.0594L48.9802 36.7721C49.2863 37.3023 49.1047 37.9804 48.5744 38.2865L45.966 39.7925L44.6431 37.4043L40.5229 39.6867L41.8863 42.1479L39.0749 43.7711C38.5446 44.0772 37.8666 43.8955 37.5604 43.3653L28.4887 27.6526L30.5414 26.4674L29.8884 25.3639L27.6942 26.6307ZM23.8895 20.0407V13.9378L22.207 14.9092C21.6767 15.2153 21.495 15.8934 21.8012 16.4237L23.8895 20.0407ZM34.6658 22.6057L32.2771 23.9848L32.9301 25.0883L35.3188 23.7092L34.6658 22.6057Z'
          fill='url(#paint0_linear)'
        />
        <defs>
          <linearGradient
            id='paint0_linear'
            x1='43.6679'
            y1='-7.80172'
            x2='43.3642'
            y2='89.7202'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#37FB6E' />
            <stop offset='0.967006' stopColor='#0A50EB' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
