import { Container, EventContainer, EventImage, EventInfo, Label } from './styles';
import { eventImage } from '../../../../static/img';
import { queryClient } from '../../../../App';

// TODO: delete mock data when back finishes
const events = [
  {
    image: eventImage,
    name: 'Innovation Submit',
    date: 'February 26, 2022',
    id: 1,
  },
  {
    image: eventImage,
    name: 'Innovation Submit',
    date: 'February 26, 2022',
    id: 2,
  },
  {
    image: eventImage,
    name: 'Innovation Submit',
    date: 'February 26, 2022',
    id: 3,
  },
  {
    image: eventImage,
    name: 'Innovation Submit',
    date: 'February 26, 2022',
    id: 4,
  },
];

export default function EventList() {
  const data: any = queryClient.getQueryData('getWidgetCustomization');
  const primaryColor = data?.primary_brand_color;
  const primaryFontColor = data?.title_brand_font;

  return (
    <Container>
      {events.map(({ image, name, date, id }) => (
        <EventContainer key={id} primaryColor={primaryColor} primaryFontColor={primaryFontColor}>
          <EventImage src={image} />
          <EventInfo>
            <Label>{name}</Label>
            <Label>{date}</Label>
          </EventInfo>
        </EventContainer>
      ))}
    </Container>
  );
}
