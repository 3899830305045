import { Popover } from '@mui/material';
import styled from 'styled-components';

import {
  green,
  white,
  darkGray,
  lightGray,
  grayTitle,
  lightblack,
  grayRGBA,
  grayRGBAopacity,
} from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    background: 'none',
    borderRadius: 8,
    boxShadow: 'none',
    width: 'fit-content',
    minHeight: 0,
    '&:hover': {
      border: 'none',
      background: lightblack,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 26,
    color: white,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: '#3D3D3D',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 26,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: '#3D3D3D',
    width: 'fit-content',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};

export const Wrapper = styled.div`
  padding: 0px 48px 48px 48px;
  &.ticketTitle {
    display: flex;
    margin-top: 60px;
    cursor: pointer;
    padding: 0;
    width: fit-content;
  }
  &.buttons {
    padding: 0;
    display: flex;
    align-items: center;
    width: 400px;
    justify-content: space-evenly;
    margin-top: 60px;
  }
  &.center {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  &.middle {
    font-size: 28px;
  }
  &.right {
    font-size: 28px;
    font-weight: 600;
  }
  &.subTitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: ${grayTitle};
    margin-right: 24px;
    cursor: pointer;
  }
  &.focus {
    text-decoration: underline;
    text-underline-offset: 5px;
    color: ${green};
  }
`;

export const Information = styled.div<{ multiday: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.multiday ? '2fr 3fr 3fr' : '2fr 2fr 2fr 3fr 2fr')};
  width: ${props => (props.multiday ? '80%' : '100%')};
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10%;
`;

export const Img = styled.img`
  &.arrow {
    margin-left: 20px;
    margin-right: 20px;
  }
  &.edit {
    cursor: pointer;
    width: 20px;
    margin-bottom: 5px;
  }
  &.editSlot {
    cursor: pointer;
    width: 20px;
    margin-bottom: 15px;
    margin-right: 5px;
  }
  &.add {
    width: 16px;
    margin-right: 10px;
  }
  &.trash {
    width: 20%;
  }
`;

export const DayContainer = styled.div`
  display: flex;
  flex-direction: column;
  &.hover {
    background: #4f4f4f;
    border-radius: 8px;
    position: relative;
  }
  &.edit {
    background: ${lightblack};
    border-radius: 8px;
  }
  &.relative {
    position: relative;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.time {
    width: 80%;
  }
  &.date {
    width: 100%;
  }

  &.days {
    margin-top: 20px;
  }
`;

export const Row = styled.div`
  display: flex;
  &.borderBottom {
    border-bottom: 1px solid ${lightGray};
    padding-bottom: 10px;
  }
  &.relative {
    position: relative;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  padding: 0px 8px;
  width: fit-content;
  cursor: default;
  &.edit {
    background: ${lightblack};
    border-radius: 8px;
  }
  &.relative {
    position: relative;
  }
  &.new {
    background: #080808;
    border: 1px solid ${grayRGBAopacity};
    border-radius: 4px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 48px;
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  text-transform: capitalize;
  margin-left: 10px;
  &.title {
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
    opacity: 0.5;
    margin-top: 32px;
    margin-left: 10px;
  }
  &.button {
    margin-top: 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0;
  }
  &.date {
    margin-left: 0px;
  }
  &.cursor {
    cursor: default;
  }
`;

export const StyledInput = {
  fontFamily: primaryFont,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 26,
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '-0.03em',
  color: white,
  textTransform: 'capitalize',
  outline: 'none',
  border: 'none',
  background: 'none',
};

export const StyledTextArea = {
  fontFamily: primaryFont,
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: 26,
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '-0.03em',
  color: white,
  padding: 8,
  outline: 'none',
  border: 'none',
  width: '100%',
};

export const Arrow = styled.img<{ ticketsType: boolean }>`
  margin-top: 38px;
  margin-left: 20px;
  width: 28px;
  height: 28px;
  transform: ${props => (props.ticketsType ? 'none' : 'rotate(180deg)')};
`;

export const LongArrow = styled.img`
  margin-left: 7px;
  width: 16px;
`;

export const Button = styled.div`
  padding: 16px 32px;
  background-color: ${green};
  width: fit-content;
  border-radius: 100px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.secondary {
    background-color: ${darkGray};
    border: 1px solid ${lightGray};
    padding: 16px 60px;
    font-family: ${primaryFont};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: ${lightGray};
    text-transform: uppercase;
  }
`;

export const AddButton = styled.div`
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${green};
  width: fit-content;
  padding: 10px 30px;
  margin-top: 20px;
  cursor: pointer;
  &.margin-left {
    margin-left: 10px;
  }
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.5;
  &.absolute {
    position: absolute;
    top: 35px;
    margin-left: 10px;
  }
`;

export const MyPopover = styled(Popover)`
  & .MuiPaper-root {
    height: 165px;
    width: 240px;
    border-radius: 8px;
  }
`;
