import { useState } from 'react';
import { useQuery } from 'react-query';

import { getData } from '../../../api/api';
import { Button } from '../../../lib/components/Button';
import { ButtonVariants } from '../../../lib/components/Button/Button';
import { Title } from '../styles';
import AddMember from './AddMember/AddMember';
import { EditMember } from './EditMember/EditMember';
import InviteSentModal from './InviteSent/InviteSent';
import List from './List/List';
import { Header, Wrapper } from './styles';

interface Props {
  isAdmin: boolean;
}

export default function OrganizationMembers({ isAdmin }: Props) {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showSendInvite, setShowSendInvite] = useState(false);
  const [showEditMember, setShowEditMember] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const partnerId = localStorage.getItem('partnerId');

  const { data: roles } = useQuery('getRoles', () => getData('/roles'), {
    refetchOnWindowFocus: false,
  });

  const { data: members } = useQuery(
    'getMembers',
    () => getData(`members?partner_id=${partnerId}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Wrapper>
        <Header>
          <Title>Organization members</Title>
          {isAdmin && (
            <Button
              variant={ButtonVariants.outlined}
              onClick={() => setShowAddMemberModal(true)}
              size='large'
            >
              ADD MEMBER
            </Button>
          )}
        </Header>
        <List
          openEditModal={member => setShowEditMember(member)}
          members={members}
          isAdmin={isAdmin}
        />

        {showEditMember && (
          <EditMember
            member={showEditMember}
            showModal={showEditMember}
            onClose={() => {
              setShowEditMember(null);
              setTimeout(() => setRefetch(!refetch), 300);
            }}
            roles={roles}
          />
        )}

        <AddMember
          showModal={showAddMemberModal}
          onSuccess={() => {
            setShowSendInvite(true);
          }}
          onClose={() => {
            setShowAddMemberModal(null);
            setTimeout(() => setRefetch(!refetch), 300);
          }}
          roles={roles}
        />

        <InviteSentModal
          showModal={showSendInvite}
          onClose={() => setShowSendInvite(null)}
        />
      </Wrapper>
    </>
  );
}
