import React from 'react';
import Select from 'react-select';

import { Text } from '../../PromoCodes/styles';
import { Column, customSelectStyles, RepeatInput, Row } from './styles';
import { handleDayOfWeek } from './utils';

interface Props {
  value: {
    label: string;
    value: string;
  };
  setValue: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  numberOfWeek: number;
  weekday: string;
  dayOfMonth: string;
  setInterval: React.Dispatch<React.SetStateAction<string>>;
  interval: string;
  repeatEvery: {
    label: string;
    value: string;
  };
  setRepeatEvery: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
}

export const RepeatRecurrence = ({
  value,
  setValue,
  dayOfMonth,
  numberOfWeek,
  weekday,
  setInterval,
  interval,
  repeatEvery,
  setRepeatEvery,
}: Props) => {
  const options = [
    { label: 'Week', value: 'weekly' },
    { label: 'Day', value: 'daily' },
    { label: 'Year', value: 'yearly' },
    { label: 'Month', value: 'monthly' },
  ];

  const options2 = [
    { label: `Monthly on day ${dayOfMonth}`, value: 'day' },
    {
      label: handleDayOfWeek(numberOfWeek, weekday),
      value: 'monthly',
    },
  ];

  return (
    <Column>
      <Text className='small repeat'>Repeat every</Text>
      <Row className='bottom'>
        <RepeatInput
          value={interval}
          onChange={e => setInterval(e.target.value)}
          type='number'
          min={1}
        />
        <Row className='select'>
          <Select
            value={value}
            onChange={setValue}
            options={options}
            styles={customSelectStyles('100%')}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Row>
      </Row>
      {value.value === 'monthly' && (
        <Select
          value={repeatEvery}
          onChange={setRepeatEvery}
          options={options2}
          styles={customSelectStyles('fit-content')}
          components={{
            IndicatorSeparator: () => null,
          }}
        />
      )}
    </Column>
  );
};
