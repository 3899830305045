import api from '../../../api/api';

interface CreateSponsorProps {
  eventId: number;
  name: string;
  image: string;
}

interface EditSponsorProps {
  eventId: number;
  sponsorId: number;
  name: string;
  image: string;
}

export const deleteSponsor = ({ id }: { id: number }) => api.delete(`sponsors/${id}`);

export const createSponsor = ({ eventId, name, image }: CreateSponsorProps) =>
  api.post('/sponsors', {
    event_id: eventId,
    name,
    image,
  });

export const editSponsor = ({ eventId, sponsorId, name, image }: EditSponsorProps) =>
  api.put(`/sponsors/${sponsorId}`, {
    event_id: eventId,
    name,
    image,
  });
