import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';

import { modalStyle } from '../../../../../pages/WidgetCustomization/styles';
import { PromoCodeInfo } from './PromoCodeInfo';
import { createPromoCode } from './services';
import { Container, Column, Row, Title, Line, Button } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreatePromoCodeModal = ({ isOpen, setIsOpen }: Props) => {
  const partnerId = localStorage.getItem('partnerId');
  const today = new Date();
  const [promoCodeForm, setPromoCodeForm] = useState({
    code: '',
    discount: 0,
    percentage: 0,
    coupon_type: 'percentage',
    start_date: today,
    end_date: new Date('1/1/1970'),
    draft: false,
    active: true,
    partner_id: parseInt(partnerId),
  });

  const { mutate: mutatePromoCodes } = useMutation(createPromoCode);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column>
          <Row className='center top modal-top'>
            <Title>New Promo Code</Title>
          </Row>
          <Line />
          <PromoCodeInfo promoCodeForm={promoCodeForm} setPromoCodeForm={setPromoCodeForm} />
          <Line />
          <Row className='center bottom modal-bottom'>
            <Button className='cancel' onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button
              onClick={() => {
                mutatePromoCodes({ promoCodeForm });
                setIsOpen(false);
              }}
            >
              Create Promo Code
            </Button>
          </Row>
        </Column>
      </Container>
    </Modal>
  );
};

export default CreatePromoCodeModal;
