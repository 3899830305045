import React, { useState } from 'react';
import { useMutation } from 'react-query';
import Select from 'react-select';

import { editField } from './services';
import { customSelectStyles } from './styles';

interface Props {
  options: any;
  description: string;
  item: string;
  id: number;
}

export const DropDown = ({ options, description, item, id }: Props) => {
  const [value, setValue] = useState<any>(description);

  const { mutate: mutateEdit } = useMutation(editField);

  return (
    <Select
      value={value}
      onChange={value => {
        setValue(value);
        mutateEdit({ value: value.value, item, id });
      }}
      options={options}
      styles={customSelectStyles}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
