import React from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';

import { CANCEL, EDIT_NAME, SAVE_CHANGES } from '../../utils/strings';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../WidgetCustomization/styles';
import { ModalName } from './Organization/ModalName';
import { updatePartner } from './services';

interface PalleteProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name: string;
}

export const PartnerName = ({ isOpen, setIsOpen, name }: PalleteProps) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const { mutate: mutateRequestName } = useMutation(updatePartner);
  const handleUpdatePartnerName = () => {
    const partnerId = localStorage.getItem('partnerId');
    mutateRequestName({
      partnerName: name,
      id: partnerId,
    });
  };

  return (
    <Modal open={isOpen} onClose={handleClose} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>{EDIT_NAME}</Title>
        </Row>
        <Row className='centerBorder'>
          <ModalName name={name} />
        </Row>
        <Row className='center'>
          <ButtonModal
            className='cancel'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            {CANCEL}
          </ButtonModal>
          <ButtonModal
            onClick={() => {
              handleUpdatePartnerName();
              setIsOpen(false);
            }}
          >
            {SAVE_CHANGES}
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
