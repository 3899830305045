import React, { useEffect, useState } from 'react';

import { promoCodeFormType } from '../../../../../../types';
import { Container, Column, Label, StyledInput, Row, Button } from './styles';

type Props = {
  promoCodeForm: promoCodeFormType;
  setPromoCodeForm: Function;
};

export const PCName = ({ promoCodeForm, setPromoCodeForm }: Props) => {
  const [isPercent, setIsPercent] = useState<boolean>(true);

  useEffect(() => {
    if (isPercent) setPromoCodeForm({ ...promoCodeForm, coupon_type: 'percentage' });
    else setPromoCodeForm({ ...promoCodeForm, coupon_type: 'flat' });
  }, [isPercent]);

  return (
    <Container>
      <Column>
        <Label>Promo code name</Label>
        <StyledInput
          placeholder='Title Promo Code'
          className='margin-bottom'
          value={promoCodeForm.code}
          onChange={e => setPromoCodeForm({ ...promoCodeForm, code: e.target.value })}
        />
      </Column>
      <Column>
        <Label>Discount</Label>
        <Row className='width margin-bottom'>
          <Button
            onClick={() => setIsPercent(true)}
            className={`left ${isPercent ? 'active' : ''}`}
          >
            By Percent %
          </Button>
          <Button onClick={() => setIsPercent(false)} className={isPercent ? '' : 'active'}>
            By Dollars $
          </Button>
        </Row>
        <StyledInput
          className='center'
          placeholder='0'
          value={
            promoCodeForm.coupon_type === 'percentage'
              ? promoCodeForm.percentage
              : promoCodeForm.discount
          }
          onChange={e => {
            if (promoCodeForm.coupon_type === 'percentage')
              setPromoCodeForm({ ...promoCodeForm, percentage: e.target.value });
            else setPromoCodeForm({ ...promoCodeForm, discount: e.target.value });
          }}
        />
      </Column>
    </Container>
  );
};
