import { useState } from 'react';

import { InputContainer, Section, ButtonContainer, Button, ContainerInfo, Text } from './styles';
import { Input } from '../Inputs/Input';
import { BY_DOLLAR, BY_PERCENT } from '../../../utils/strings';

export default function PromoCodeInformationForm({ value, setValue, error, setError }) {
  const [greenButton, setGreenButton] = useState(true);
  const [discountSign, setDiscountSign] = useState('$');
  const [number, setNumber] = useState('');

  const handleFloat = () => {
    if (value?.coupon_type === 'flat') {
      setValue({
        ...value,
        discount: parseFloat(number) || 0,
      });
    } else {
      setValue({
        ...value,
        percentage: parseFloat(number) || 0,
      });
    }
  };
  return (
    <Section>
      <InputContainer>
        <ContainerInfo>
          <div style={{ position: 'relative' }}>
            <Input
              label='Promo Code name'
              value={value?.code}
              setValue={code => setValue({ ...value, code })}
              id='code-name'
              placeholder='Title Promo Code'
              className={value?.code === '' ? 'border' : 'info'}
              error={error}
              setError={setError}
              touched={true}
            />
            {error && <Text className='small error'>Promocode name already used</Text>}
          </div>
          <Text className='discount'>Discount</Text>
          <ButtonContainer className='container'>
            <Button
              className='primary'
              onClick={() => {
                setGreenButton(false);
                setValue({ ...value, coupon_type: 'percentage' });
                setDiscountSign('%');
              }}
              greenButton={greenButton}
            >
              {BY_PERCENT}
            </Button>
            <Button
              className='secondary'
              onClick={() => {
                setGreenButton(true);
                setValue({ ...value, coupon_type: 'flat' });
                setDiscountSign('$');
              }}
              greenButton={greenButton}
            >
              {BY_DOLLAR}
            </Button>
          </ButtonContainer>
          <Input
            type='text'
            className={'centerInfo'}
            placeholder={discountSign}
            value={number}
            setValue={
              value?.coupon_type === 'flat'
                ? discount => {
                    if (discount.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                      setNumber(discount || 0);
                    }
                  }
                : percentage => {
                    if (percentage.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                      setNumber(percentage || 0);
                    }
                  }
            }
            id='discount'
            onBlur={handleFloat}
          />
        </ContainerInfo>
      </InputContainer>
    </Section>
  );
}
