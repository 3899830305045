import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';

import { saveTemplate } from '../../../../../pages/EventCreation/services';
import { ticketLibrary, tixologiWhiteLogo } from '../../../../../static/icons';
import { TicketsType } from '../../../../../types';
import { TIXOLOGI_TICKET_DESIGNER, UPLOAD_IMAGE } from '../../../../../utils/strings';
import { remove } from '../../../../../utils/theme';
import { getBase64Ticket } from '../../../../../utils/utils';
import file from '../../Tickets/DesignGuide.pdf';
import { LibraryModal } from '../../Tickets/LibraryModal';
import { Link } from '../../Tickets/TicketPreview.style';
import styles from '../../Tickets/TicketTypesForm.module.scss';
import { Container, Label } from '../styles';

interface Props {
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
  ticketError: boolean[];
}

export const TicketDesign = ({ tickets, setTickets, index, ticketError }: Props) => {
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [image, setImage] = useState(null);

  const { mutateAsync: mutateTemplate } = useMutation(saveTemplate, {
    onSuccess: ({ data }) => {
      let newTickets = [...tickets];
      newTickets[index].templateId = data.ID;
      setTickets(newTickets);
    },
  });

  useEffect(() => {
    ticketError.forEach((e, i) => {
      if (!!e && i === index) setError(true);
    });
  }, [ticketError]);

  return (
    <Container className='no-input'>
      <Label>Ticket Design</Label>
      <Label className='s no-uppercase'>You can always add ticket designs later.</Label>
      <div>
        <Link
          onClick={() => {
            const link = document.createElement('a');
            link.href = file;
            link.rel = 'noopener noreferrer';
            link.download = `DesignGuide.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          See ticket design guide
        </Link>
        <div className={styles.buttons}>
          <button
            className={`${styles.button} ${styles.library}`}
            onClick={() => setIsLibraryOpen(true)}
          >
            Library
            <img src={ticketLibrary} className={styles.img} />
          </button>
          <label className={styles.button}>
            {UPLOAD_IMAGE}
            <input
              type='file'
              onChange={e => {
                if (e.target.files && e.target.files[0]) {
                  setImage(URL.createObjectURL(e.target.files[0]));
                  let newTickets = [...tickets];
                  newTickets[index].ticketFileName = e.target.files[0].name;
                  setTickets(newTickets);
                  getBase64Ticket(e.target.files[0], tickets, setTickets, index, mutateTemplate);
                } else setImage('');
              }}
            />
          </label>
          {/* INTEGRATE IN THE FUTURE */}
          {/* {!tickets[index].ticketFile && (
            <>
              <a
                target='_blank'
                href={`${process.env.URL || process.env.PUBLIC_URL}/ticket-designer`}
                style={{ textDecoration: 'none' }}
              >
                <button className={styles.button}>
                  {TIXOLOGI_TICKET_DESIGNER}
                  <img src={tixologiWhiteLogo} className={styles.img} />
                </button>
              </a>
            </>
          )} */}
        </div>
        <div style={{ marginTop: 5 }}>
          {tickets[index].ticketFileName || (tickets[index].templateId && 'tixologiticket.png')}
        </div>
        <div style={{ color: remove, fontSize: 14 }}>
          {error && 'You need to add a ticket image first.'}
        </div>
      </div>
      {isLibraryOpen && (
        <LibraryModal
          isOpen={isLibraryOpen}
          setIsOpen={setIsLibraryOpen}
          tickets={tickets}
          setTickets={setTickets}
          index={index}
        />
      )}
    </Container>
  );
};
