import { useState } from 'react';

import Button from '../../lib/components/Button/Button';
import {
  ATTENDANCE,
  PURCHASES,
  TICKETS_SOLD,
  TICKET_HISTORY,
  TRANSFERS,
} from '../../utils/strings';
import { ButtonContainer, Text, Wrapper } from './style';
import { TablePurchase } from './TablePurchase';
import { TableAttendance } from './TableAttendance';
import { TableTransfers } from './TableTransfers';
import { TableTicketsSold } from './TableTicketsSold';

type Props = {
  fanData: any;
};
export function TicketHistory({ fanData }: Props) {
  const [activeTable, setActiveTable] = useState<string>('purchases');
  const switchTable = () => {
    switch (activeTable) {
      case 'purchases':
        return <TablePurchase info={fanData?.TicketHistory?.Purchases} />;
      case 'attendance':
        return <TableAttendance info={fanData?.TicketHistory?.Attendance} />;
      case 'transfers':
        return <TableTransfers info={fanData?.TicketHistory?.Transfers} />;
      case 'ticketsSold':
        return <TableTicketsSold info={fanData?.TicketHistory?.TicketsSolds} />;
    }
  };

  const data = [
    {
      name: PURCHASES,
      setState: () => setActiveTable('purchases'),
    },
    {
      name: ATTENDANCE,
      setState: () => setActiveTable('attendance'),
    },
    {
      name: TRANSFERS,
      setState: () => setActiveTable('transfers'),
    },
    {
      name: TICKETS_SOLD,
      setState: () => setActiveTable('ticketsSold'),
    },
  ];
  return (
    <>
      <Text className='title_section'>{TICKET_HISTORY}</Text>
      <Wrapper className='section'>
        <ButtonContainer className='table'>
          {data.map(({ name, setState }) => (
            <Button onClick={setState} className='option'>
              {name}
            </Button>
          ))}
        </ButtonContainer>
        {switchTable()}
      </Wrapper>
    </>
  );
}
