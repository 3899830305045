import * as Yup from 'yup';

import {
  NEW_PASSWORD,
  PASSWORDS_DONT_MATCH,
  PASSWORD_IS_REQUIRED,
  REPEAT_NEW_PASSWORD,
} from '../../utils/strings';

export const FORGOT_PASSWORD_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const FORGOT_PASSWORD_INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

export const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
  confirmPassword: Yup.string()
    .required(PASSWORD_IS_REQUIRED)
    .oneOf(
      [Yup.ref(FORGOT_PASSWORD_FIELDS.password), null],
      PASSWORDS_DONT_MATCH
    ),
});

export const forgotPasswordInputData = [
  {
    name: FORGOT_PASSWORD_FIELDS.password,
    label: NEW_PASSWORD,
    placeholder: 'New password*',
  },
  {
    name: FORGOT_PASSWORD_FIELDS.confirmPassword,
    label: REPEAT_NEW_PASSWORD,
    placeholder: 'Repeat new password*',
  },
];
