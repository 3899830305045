import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { getData } from '../../../api/api';
import Button from '../../../lib/components/Button/Button';
import Icon from '../../../lib/components/Icon/Icon';
import { Container, Wrap, Text, ButtonRefund } from '../style';
import {
  STable,
  STableBody,
  STableCell,
  STableContainer,
  STableHead,
  STableRow,
} from '../tableStyle';
import { RefundModal } from './RefundModal';
import { RefundRow } from './RefundRow';
import { RefundSuccessModal } from './RefundSuccessModal';

export function RefundOption() {
  const history = useHistory();
  const [isRefundOpen, setIsRefundOpen] = useState<boolean>(false);
  const [isRefundConfirmation, setRefundConfirmation] = useState<boolean>(false);
  const [ticketsIdSelected, setTicketsIdSelected] = useState([]);
  const [priceAverageSelected, setPriceAverageSelected] = useState([]);
  const { pathname } = useLocation();
  const pathnameSplit = pathname.split('/');
  const userId = pathnameSplit[2];
  const ticketType = pathnameSplit[3];

  const {
    state: { name },
  }: any = useLocation();

  const title = [
    '',
    'Ticket Name',
    'Date Event',
    'Event Avenue',
    'Tickets Owned',
    'Value (Per Ticket)',
  ];

  const { data: ticketTypeRefund } = useQuery('getTicketTypeRefund', () =>
    getData(`transactions/tickets_for_refund/ticket_type/${ticketType}/${userId}`)
  );

  return (
    <Container>
      <Wrap className='text'>
        <Container className='containerText'>
          <Container className='containerIcon'>
            <Icon
              icon={'arrow-left-background'}
              onClick={() => history.push(`/fan-refund/${userId}`, { name })}
              className='cursor'
              height={45}
              width={35}
            />
            <Text className='titleRefund'>Refund Ticket</Text>
          </Container>
          <Text className='subtitlesRefund'>
            Please select the tickets you would like to refund to {name}
          </Text>
        </Container>
      </Wrap>
      <STableContainer>
        <STable className='history'>
          <STableHead>
            <STableRow index>
              {title.map(title => (
                <STableCell>{title}</STableCell>
              ))}
            </STableRow>
          </STableHead>
          <STableBody>
            {ticketTypeRefund?.map(({ index, TicketType, DateEvent, Venue, Price, TicketID }) => (
              <RefundRow
                ticketName={TicketType}
                index={index}
                date={moment(DateEvent).format('DD/MM/YYYY')}
                eventAvenue={Venue}
                ticketID={TicketID}
                ticketsOwned={'1'}
                valuePerTicket={Price}
                setIsSelected={setTicketsIdSelected}
                isSelected={ticketsIdSelected}
                setPriceAverageSelected={setPriceAverageSelected}
                priceAverageSelected={priceAverageSelected}
              />
            ))}
          </STableBody>
        </STable>
      </STableContainer>
      <ButtonRefund className='refund'>
        <Button color='primary' size='medium' onClick={() => setIsRefundOpen(true)}>
          Refund {ticketsIdSelected.length} Tickets
        </Button>
      </ButtonRefund>
      <RefundModal
        isOpen={isRefundOpen}
        setIsOpen={setIsRefundOpen}
        setRefundConfirmation={setRefundConfirmation}
        ticketsIdSelected={ticketsIdSelected}
        priceAverageSelected={priceAverageSelected}
      />
      <RefundSuccessModal
        isOpen={isRefundConfirmation}
        setIsOpen={setRefundConfirmation}
        name={name}
      />
    </Container>
  );
}
