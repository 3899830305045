import React from 'react';

import {
  enabledStatus,
  pendingStatus,
  rejectedStatus,
  restrictedSoonStatus,
  restrictedStatus,
} from '../../static/img';
import { Column, Container, Img, Status, Row } from './styles';

const status = {
  enabled: {
    description:
      'The account is in good standing, but threshold may be reached soon.',
    img: enabledStatus,
  },

  pending: {
    description: 'Stripe is verifying your account information.',
    img: pendingStatus,
  },

  restrictedSoon: {
    description:
      'Your account may be restricted if you do not provide the required information.',
    img: restrictedSoonStatus,
  },

  restricted: {
    description: 'Transfers or payments are disabled for this account.',
    img: restrictedStatus,
  },

  rejected: {
    description: 'Fraudulent activity is suspected on your account.',
    img: rejectedStatus,
  },
};

interface Props {
  info: string;
}

export const StatusCode = ({ info }: Props) => (
  <Container info={info}>
    <Row className='statusHover'>
      <Img src={status[info].img} className='statusHover' />
    </Row>
    <Column className='statusHover'>
      <Status className='hoverTitle'>
        {info === 'restrictedSoon' ? 'restricted soon' : info}
      </Status>
      <Status className='hoverSpan'>{status[info].description}</Status>
    </Column>
  </Container>
);
