import React from 'react';

import { CutomOptions } from '../../../../types';
import Radio from '../../Inputs/Radio/Radio';
import { Column, Container, Row, Text, ToggleCustom, Wrapper } from './styles';

interface Props {
  isCustomEvent: boolean;
  setIsCustomEvent: React.Dispatch<React.SetStateAction<boolean>>;
  customOptions: CutomOptions;
  setCustomOptions: React.Dispatch<React.SetStateAction<CutomOptions>>;
}

const EventOptions = ({
  isCustomEvent,
  setIsCustomEvent,
  customOptions,
  setCustomOptions,
}: Props) => (
  <Container>
    <Row className='align-center margin-top'>
      <Wrapper onClick={() => setIsCustomEvent(!isCustomEvent)}>
        <Radio checked={!isCustomEvent} />
      </Wrapper>
      <Column>
        <Text>This is a standard event</Text>
        <Text className='info'>
          Standard events are a single day with a start time and end time{' '}
        </Text>
      </Column>
    </Row>

    <Row className='align-center margin-top'>
      <Radio checked={isCustomEvent} setChecked={setIsCustomEvent} />
      <Column>
        <Text>This is a custom event </Text>
        <Text className='info'>Custom events can have multiple days or timeslots </Text>
      </Column>
    </Row>
    {isCustomEvent && (
      <Wrapper className='options'>
        <Column className='w94'>
          <Row className='space margin-top'>
            <Text className='sub'>Multiday event</Text>
            <ToggleCustom
              icons={false}
              checked={customOptions.isMultiday}
              onChange={e => {
                setCustomOptions(prevState => ({
                  ...prevState,
                  isMultiday: !prevState.isMultiday,
                }));
              }}
              disabled={customOptions.isTimeSlot || customOptions.isRecurring}
            />
          </Row>
          <Text className='info'>Events with multiday enabled cannot have timeslots</Text>
          <Row className='space margin-top'>
            <Text className='sub'>Timeslots </Text>
            <ToggleCustom
              icons={false}
              checked={customOptions.isTimeSlot}
              onChange={e => {
                setCustomOptions(prevState => ({
                  ...prevState,
                  isTimeSlot: !prevState.isTimeSlot,
                }));
              }}
              disabled={customOptions.isMultiday}
            />
          </Row>
          <Text className='info'>Events with timeslots enabled cannot be multiday</Text>
          <Row className='space margin-top'>
            <Text className='sub'>Recurring event</Text>
            <ToggleCustom
              icons={false}
              checked={customOptions.isRecurring}
              onChange={e => {
                setCustomOptions(prevState => ({
                  ...prevState,
                  isRecurring: !prevState.isRecurring,
                }));
              }}
              disabled={customOptions.isMultiday}
            />
          </Row>
          {customOptions.isRecurring && (
            <Text className='info'>We’ll help you set a recurring schedule on the next screen</Text>
          )}
        </Column>
      </Wrapper>
    )}
  </Container>
);

export default EventOptions;
