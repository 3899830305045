import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Footer } from './lib/components/layout';
import './app.scss';
import NavBar from './lib/components/layout/Navigation/Navbar/NavBar';
import Profile from './pages/Profile';
import EventCreationPage from './pages/EventCreation/EventCreation';
import EventList from './pages/EventList/EventList';
import BillingHistory from './pages/BillingHistory';
import EventSummary from './pages/EventSummary';
import EventAnalysis from './pages/EventAnalysis/EventAnalysis';
import Analytics from './pages/Analytics/Analytics';
import Login from './pages/Login/Login';
import WidgetCustomization from './pages/WidgetCustomization';
import ProtectedRoute from './common/ProtectedRoute';
import { MemberLogin } from './pages/MemberLogin';
import { ForgotPasswordRequest } from './pages/ForgotPasswordRequest';
import { ResetPassword } from './pages/ResetPassword';
import Orchestor from './pages/FirebaseOrchestor';
import FanDatabase from './pages/FanDatabase/FanDatabase';
import FanHistory from './pages/FanDatabase/FanHistory';
import PromoCodeMenu from './pages/PromoCodes/PromoCodes';
import PromoCodeSteps from './pages/PromoCodes/PromoCodeSteps';
import { RefundListing } from './pages/FanDatabase/RefundTickets.tsx/RefundListing';
import { RefundOption } from './pages/FanDatabase/RefundTickets.tsx/RefundOption';
import SelectOrganization from './pages/Login/Organization/SelectOrganization';
import { Orders } from './pages/Orders';

const paths = [
  {
    path: '/profile',
    name: 'Profile',
    Page: Profile,
  },
  {
    path: '/event-creation',
    name: 'Event Creation',
    Page: EventCreationPage,
  },
  {
    path: '/promo-code',
    name: 'Promo Codes',
    Page: PromoCodeMenu,
  },
  {
    path: '/promo-code-steps',
    name: 'Promo Code Steps',
    Page: PromoCodeSteps,
  },
  {
    path: '/billing-history',
    name: 'Billing History',
    Page: BillingHistory,
  },
  {
    path: '/event-list',
    name: 'Event List',
    Page: EventList,
  },
  {
    path: '/event-summary/:id',
    name: 'Event Summary',
    Page: EventSummary,
  },
  {
    path: '/event-analysis/:id',
    name: 'Event Analysis',
    Page: EventAnalysis,
  },
  {
    path: '/analytics',
    name: 'Analytics',
    Page: Analytics,
  },
  {
    path: '/widget-customization',
    name: 'Widget Customization',
    Page: WidgetCustomization,
  },
  {
    path: '/fan-database',
    name: 'Fan Database',
    Page: FanDatabase,
  },
  {
    path: '/fan-history/:id',
    name: 'Fan History',
    Page: FanHistory,
  },
  {
    path: '/orders',
    name: 'Orders',
    Page: Orders,
  },
  {
    path: '/fan-refund/:id',
    name: 'Fan Refund',
    Page: RefundListing,
  },
  {
    path: '/fan-refund/:fanId/:ticketId',
    name: 'Refund Options',
    Page: RefundOption,
  },
  {
    path: '/organization',
    name: 'Select Organization',
    Page: SelectOrganization,
  },
];

export const queryClient = new QueryClient();

function App() {
  const isAuthenticated = localStorage.getItem('accessToken');

  const renderUnprotectedRoute = Component => (
    <>
      <NavBar isAuthenticated={false} />
      <Component />
    </>
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <main className='App'>
          <Switch>
            <Route path='/member-login' component={() => renderUnprotectedRoute(MemberLogin)} />
            <Route exact path='/login' component={() => renderUnprotectedRoute(Login)}>
              {isAuthenticated && <Redirect to='/event-list' />}
            </Route>
            <Route
              exact
              path='/forgot-password'
              component={() => renderUnprotectedRoute(ForgotPasswordRequest)}
            >
              {isAuthenticated && <Redirect to='/event-list' />}
            </Route>
            <Route path='/reset-password' component={() => renderUnprotectedRoute(ResetPassword)}>
              {isAuthenticated && <Redirect to='/event-list' />}
            </Route>
            <Route path='/usermgmt' component={() => renderUnprotectedRoute(Orchestor)}>
              {isAuthenticated && <Redirect to='/event-list' />}
            </Route>
            {paths.map(({ name, path, Page }) => (
              <ProtectedRoute key={name} path={path} exact component={Page} />
            ))}
            <Route path='*' render={() => <Redirect to='/event-list' />} />
          </Switch>
        </main>
        <Footer />
      </Router>
    </QueryClientProvider>
  );
}

export default App;
