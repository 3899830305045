import styled from 'styled-components';

import {
  grayRGBA,
  grayRGBAopacity,
  inputBackgroundSecondary,
  white,
} from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Input = styled.input`
  background: ${inputBackgroundSecondary};
  border: 1px solid ${grayRGBAopacity};
  border-radius: 4px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.03em;
  color: ${white};
  padding: 12px;
  outline: none;
  margin-top: 10px;
`;

export const Line = styled.div`
  border-top: 1px solid ${grayRGBA};
  margin-top: 14px;
  width: 100%;
  &.uploaded {
    margin-top: 28px;
  }
  &.library {
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;
