import { useState } from 'react';

import PromoCodeInfo from '../../lib/components/PromoCodes/PromoCodeInfo';
import { Wrapper } from './styles';

const PromoCodes = () => {
  const [promoId, setPromoId] = useState<number>(null);

  return (
    <Wrapper>
      <PromoCodeInfo promoId={promoId} setPromoId={setPromoId} />
    </Wrapper>
  );
};

export default PromoCodes;
