import { configureStore } from '@reduxjs/toolkit';

import colorsSlice from './colorsSlice';
import seatmapSlice from './seatmapSlice';
import userSlice from './userSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    seatmap: seatmapSlice,
    colors: colorsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
