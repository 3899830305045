import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';

import { queryClient } from '../../../../App';
import { kebabWhite } from '../../../../static/icons';
import { arrowWhite, ticketFrame } from '../../../../static/img';
import { TicketsType } from '../../../../types';
import ConfirmationModal from '../../ConfirmartionModal/ConfirmationModal';
import { deleteTicketType } from '../../ConfirmartionModal/services';
import TicketTypeModal from '../TicketTypeModal';
import { Container, Text, Column, Img, BlurWrapper, Button } from './styles';

interface Props {
  ticket: any;
  eventOnSaleTime: Date;
}

const REMOVE_TITLE = 'Are you sure you want to remove';

const TicketTypeCard = ({ ticket, eventOnSaleTime }) => {
  const [isBlur, setIsBlur] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [ticketsType, setTicketsType] = useState<TicketsType[]>([
    {
      ticketPrice: ticket.initial_price,
      ticketName: ticket.name,
      ticketDescription: ticket.description,
      ticketFile: '',
      ticketFileName: '',
      ticketId: uuid(),
      ticket64: '',
      templateId: ticket.template_id,
      ticketAmount: ticket.amount,
      ticketSecondaryRoyalty: ticket.secondary_royalty * 100,
      ticketOnSaleTime: new Date(ticket.selling_time),
      attachedPromoCodes: ticket.Promocodes,
    },
  ]);

  const items = [
    { title: 'Ticket Type', description: 'GA' },
    { title: 'Total Seats', description: ticket.amount },
    { title: 'Holds', description: ticket.holds_amount },
  ];

  const { mutate: mutateDelete } = useMutation(deleteTicketType, {
    onSuccess: () => {
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        const newArray = oldData.ticket_types.filter(t => t.ID !== ticket.ID);
        oldData.ticket_types = newArray;
        return oldData;
      });
      setIsRemove(false);
      setIsBlur(false);
    },
  });

  return (
    <Container className='relative'>
      <Img className='kebab' src={kebabWhite} onClick={() => setIsBlur(true)} />
      <Img className='ticket' src={ticketFrame} />
      <Text className='title'>{ticket.name}</Text>
      <Text className='title'>${ticket.initial_price}</Text>
      {items.map(({ title, description }) => (
        <Column className='margin' key={title}>
          <Text className='subTitle'>{title}</Text>
          <Text className='description'>{description}</Text>
        </Column>
      ))}
      {isBlur && (
        <BlurWrapper>
          <Text className='title bold'>{ticket.name}</Text>
          <Img className='arrow' src={arrowWhite} onClick={() => setIsBlur(false)} />
          <Button onClick={() => setIsOpen(true)}>View/Edit</Button>
          <Button onClick={() => setIsRemove(true)}>Remove</Button>
        </BlurWrapper>
      )}
      {isRemove && (
        <ConfirmationModal
          isOpen={isRemove}
          setIsBlur={setIsBlur}
          setIsOpen={setIsRemove}
          title={REMOVE_TITLE}
          label={`${ticket.name} Ticket?`}
          isRemove
          handleClick={() => mutateDelete({ id: ticket.ID })}
        />
      )}
      {isOpen && (
        <TicketTypeModal
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          ticket={ticket}
          setTickets={setTicketsType}
          tickets={ticketsType}
          eventOnSaleTime={eventOnSaleTime}
        />
      )}
    </Container>
  );
};

export default TicketTypeCard;
