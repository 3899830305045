import Radio from '../../../../lib/components/Inputs/Radio/Radio';
import { checkMark } from '../../../../static/icons';
import { RolesType } from '../../../../types';
import { Container, PermsDropdown } from './styles';

type Props = {
  setRole: Function;
  role?: number;
  roles: RolesType[];
};

export default function RolesDropdown({ setRole, role, roles }: Props) {
  return (
    <div>
      {roles?.map(({ value, ID, Permissions }) => (
        <div key={ID}>
          <Radio checked={role === ID} text={value} textSize={16} setChecked={() => setRole(ID)} />
          {role === ID && (
            <PermsDropdown>
              {Permissions?.map(({ value, ID }) => (
                <Container key={ID}>
                  <img src={checkMark} alt='check' />
                  {value}
                </Container>
              ))}
            </PermsDropdown>
          )}
        </div>
      ))}
    </div>
  );
}
