import styled from 'styled-components';

import { black, palette, shadow, white } from '../../../../utils/theme';

export const Container = styled.div`
  padding: 20px 80px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const EventInfo = styled.div`
  background-color: ${palette.gray[0]};
  width: 100%;
  height: 25%;
  position: absolute;
  bottom: 0;
  border-radius: 0 0 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EventContainer = styled.div<{
  primaryColor: string;
  primaryFontColor: string;
}>`
  position: relative;
  width: 280px;
  height: 280px;
  border-radius: 8px;
  box-shadow: ${shadow};
  cursor: pointer;
  color: ${black};

  &:hover ${EventInfo} {
    color: ${white};
    background: ${props => props.primaryColor}};
  }
`;

export const EventImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;

export const Label = styled.div`
  font-weight: 300;
  font-size: 14px;
  text-align: center;

  &.title {
    font-size: 18px;
  }
`;
