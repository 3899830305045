import React, { useState } from 'react';
import moment from 'moment';

import { Row, Text, Span } from './styles';

interface Props {
  description: string;
  isTime: boolean;
}

export const SaleText = ({ description, isTime }: Props) => {
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Row
      className='relative'
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text className='cursor'>
        {isTime ? moment(description).utc().format('LT') : moment(description).utc().format('L')}
      </Text>
      {hover && (
        <Span className='absolute'>{`One or more of your ticket types has a custom ${
          isTime ? 'time' : 'date'
        }.`}</Span>
      )}
    </Row>
  );
};
