import Icon from '../../Icon';
import { CheckIcon, Wrapper } from './Checkbox.style';

type Props = {
  text?: string;
  textSize?: number;
  checked: boolean;
  setChecked: Function;
  className?: string;
};

export default function Checkbox({
  text,
  textSize = 12,
  checked,
  setChecked,
  className,
}: Props) {
  return (
    <Wrapper onClick={() => setChecked(!checked)}>
      <CheckIcon className={className}>
        {checked && <Icon icon='check' height={15} />}
      </CheckIcon>
      <span style={{ fontSize: textSize }}>{text}</span>
    </Wrapper>
  );
}
