import { useEffect, useState } from 'react';

import Checkbox from '../../../lib/components/Inputs/Checkbox/Checkbox';
import { RowWrapper, STableCell } from '../tableStyle';
interface Props {
  index?: number;
  ticketName?: string;
  date?: any;
  eventAvenue?: string;
  ticketsOwned?: string;
  ticketID?: number;
  valuePerTicket?: string;
  setIsSelected?: React.Dispatch<React.SetStateAction<number[]>>;
  setPriceAverageSelected?: React.Dispatch<React.SetStateAction<number[]>>;
  isSelected: any;
  priceAverageSelected: any;
}

export function RefundRow({
  index,
  ticketName,
  date,
  eventAvenue,
  ticketsOwned,
  valuePerTicket,
  ticketID,
  setIsSelected,
  setPriceAverageSelected,
  isSelected,
  priceAverageSelected,
}: Props) {
  const [isSelectRefund, setIsSelectRefund] = useState<boolean>(false);

  useEffect(() => {
    if (!isSelectRefund) setIsSelected(isSelected.filter(e => e !== ticketID));
    else setIsSelected([...isSelected, ticketID]);
  }, [isSelectRefund]);

  useEffect(() => {
    if (!isSelectRefund) setPriceAverageSelected(isSelected.filter(e => e !== ticketID));
    else setPriceAverageSelected([...priceAverageSelected, valuePerTicket]);
  }, [isSelectRefund]);

  return (
    <RowWrapper index={index}>
      <STableCell>
        <Checkbox
          checked={isSelectRefund}
          setChecked={checked => {
            setIsSelectRefund(checked);
          }}
        />
      </STableCell>
      <STableCell>{ticketName}</STableCell>
      <STableCell>{date}</STableCell>
      <STableCell>{eventAvenue}</STableCell>
      <STableCell>{ticketsOwned}</STableCell>
      <STableCell>{valuePerTicket}</STableCell>
    </RowWrapper>
  );
}
