import { useState } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';

import { Wrapper, Title, Row, Text, InputContainer, Input, Search } from './styles';
import {
  BILLING_HISTORY,
  YOUR_PERCENTAGE,
  EVENT_NAME,
  TIXOLOGI_PERCENTAGE,
  DATE_M,
  SEARCH_EVENT,
} from '../../utils/strings';
import { cellOne, cellTwo } from '../../utils/theme';
import searchIcon from '../../static/icons/searchIcon.svg';
import { getData } from '../../api/api';

const subTitles = [EVENT_NAME, YOUR_PERCENTAGE, TIXOLOGI_PERCENTAGE, DATE_M];

const BillingHistory = () => {
  const partner_id = localStorage.getItem('partnerId');
  const [eventsToSearch, setEventToSearch] = useState('');

  const { data: billingAnalytics } = useQuery('getBillingAnalytics', () =>
    getData(`analytics/billing/${partner_id}`)
  );
  return (
    <Wrapper>
      <Title>{BILLING_HISTORY}</Title>
      <InputContainer>
        <Search src={searchIcon} alt='search' />
        <Input
          placeholder={SEARCH_EVENT}
          value={eventsToSearch}
          onChange={e => {
            setEventToSearch(e.target.value);
          }}
        />
      </InputContainer>
      <Row className='header'>
        {subTitles.map(title => (
          <Text key={title} className='header'>
            {title}
          </Text>
        ))}
      </Row>
      {billingAnalytics
        ?.filter(e => e.event_name.includes(eventsToSearch))
        ?.map(({ ID, event_name, partner_percentage, tixologi_percentage, date }) => (
          <Row key={`${ID[0]}-${ID}`} style={{ background: ID % 2 === 0 ? cellOne : cellTwo }}>
            <Text>{event_name}</Text>
            <Text>{partner_percentage}</Text>
            <Text>{tixologi_percentage}</Text>
            <Text>{moment(date).format('DD/MM/YY')}</Text>
          </Row>
        ))}
    </Wrapper>
  );
};

export default BillingHistory;
