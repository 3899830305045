import styled from 'styled-components';

export const Background = styled.img`
  display: flex;
  height: 75vh;
  object-fit: contain;
  align-self: flex-end;
`;

export const SuccessImage = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  margin: 20px 0px;
`;

export const Wrapper = styled.section<{ isMobile?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: ${props => (props.isMobile ? 'space-between' : 'center')};
  flex-direction: column;
  align-items: center;
  background-image: url('../static/img/multi-ticket-background.png');
  background-size: 100vw;
  background-color: ${props => (props.isMobile ? 'var(--color-bg-1)' : 'var(--color-bg-2)')};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  margin: auto;
  width: ${props => (props.isMobile ? '100%' : 'calc(min(90vw, 47rem))')};
  border-radius: 10px;
  padding: 2rem;
`;

export const Text = styled.div<{ isMobile?: boolean }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${props => (props.isMobile ? '2rem' : '3rem')};
  letter-spacing: -0.03em;
  margin: 0;
  &.mobile {
    font-size: 2rem;
    display: flex;
    text-align: center;
  }
`;

export const SuccessWrapper = styled.section<{ isMobile?: boolean }>`
  position: absolute;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-image: url('../static/img/multi-ticket-background.png');
  background-size: 100vw;
  background-color: ${props => (props.isMobile ? 'var(--color-bg-1)' : 'var(--color-bg-2)')};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50%;
  margin: auto;
  width: ${props => (props.isMobile ? '100%' : 'calc(min(90vw, 47rem))')};
  border-radius: 10px;
  padding: 2rem;
`;

export const Form = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => (props.isMobile ? '' : '33rem')};
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 48%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 2rem;
  flex-direction: row;
  justify-content: space-between;
`;

export const ErrorMessage = styled.label`
  color: var(--color-danger-default);
`;

export const ForgotPasswordLink = styled.button`
  all: unset;
  text-decoration: underline;
  cursor: pointer;
  align-self: flex-start;
  margin-top: 1rem;
`;
