import styled from 'styled-components';
import { black, darkBackground, hoverBlack } from '../../../../utils/theme';

export const Container = styled.div``;

export const MembersContainer = styled.div`
  background: ${darkBackground};
  border: 1px solid rgba(185, 185, 185, 0.2);
  border-radius: 8px;
  box-sizing: border-box;
  width: 200px;
`;

export const Img = styled.img`
  width: 30px;
  border-radius: 50%;
`;

export const Icon = styled.img`
  width: 20px;
`;

export const Member = styled.div`
  display: flex;
  padding: 10px;
  cursor: pointer;
  gap: 8px;
  &:hover {
    background: ${hoverBlack};
  }
`;

export const Text = styled.div`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  padding: 6px;
`;
