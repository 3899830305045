import React, { useEffect, useState } from 'react';

import { EventFormType } from '../../../../types';
import Icon from '../../Icon/Icon';
import { ContainerCheckbox, Row, Label } from '../TicketTypes/styles';
import { TimeRow } from './TimeRow';
import { Text, TimeInput } from '../../PromoCodes/styles';
import { Container, Img, InnerContainer } from './styles';
import DatePickerComponent from './DatePicker';
import { cross } from '../../../../static/icons';

interface Props {
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  index: number;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  setSameStartTime: Function;
  sameStartTime: any;
  setSameEndTime: Function;
  sameEndTime: any;
  dateError: boolean[];
  isTimeSlot: boolean;
  slotDate: Date;
}

const Multiday = ({
  value,
  setValue,
  index,
  setChecked,
  checked,
  setSameStartTime,
  sameStartTime,
  setSameEndTime,
  sameEndTime,
  dateError,
  isTimeSlot,
  slotDate,
}: Props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [error, setError] = useState<boolean>(false);
  const [isSameAsStartDate, setIsSameAsStartDate] = useState<boolean>(true);
  const [startTime, setStartTime] = useState({
    hour: 10,
    min: '00',
    period: 'pm',
  });
  const [endTime, setEndTime] = useState({
    hour: 10,
    min: '00',
    period: 'pm',
  });

  useEffect(() => {
    dateError.forEach((e, i) => {
      if (!!e && i === index) setError(true);
    });
  }, [dateError]);

  useEffect(() => {
    if (isSameAsStartDate) {
      let event = { ...value };
      const dateArray = startDate.toString().split(' ');
      const finalSameStartDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${startTime.hour}: ${startTime.min} ${startTime.period}`;
      const finalSameEndDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${endTime.hour}: ${endTime.min} ${endTime.period}`;
      event.dates[index].startDate = new Date(finalSameStartDate);
      event.dates[index].endDate = new Date(finalSameEndDate);
      setValue(event);
      setEndDate(new Date(finalSameEndDate));
    }
  }, [isSameAsStartDate]);

  useEffect(() => {
    let event = { ...value };
    const dateArraySD = startDate.toString().split(' ');
    const dateArrayED = endDate.toString().split(' ');
    if (checked) {
      const finalSameStartDate = `${dateArraySD[0]} ${dateArraySD[1]} ${dateArraySD[2]} ${dateArraySD[3]} ${sameStartTime.hour}: ${sameStartTime.min} ${sameStartTime.period}`;
      const finalSameEndDate = `${dateArrayED[0]} ${dateArrayED[1]} ${dateArrayED[2]} ${dateArrayED[3]} ${sameEndTime.hour}: ${sameEndTime.min} ${sameEndTime.period}`;
      event.dates[index].startDate = new Date(finalSameStartDate);
      event.dates[index].endDate = new Date(finalSameEndDate);
    } else {
      const finalStartDate = `${dateArraySD[0]} ${dateArraySD[1]} ${dateArraySD[2]} ${dateArraySD[3]} ${startTime.hour}: ${startTime.min} ${startTime.period}`;
      const finalEndDate = `${dateArrayED[0]} ${dateArrayED[1]} ${dateArrayED[2]} ${dateArrayED[3]} ${endTime.hour}: ${endTime.min} ${endTime.period}`;
      event.dates[index].startDate = new Date(finalStartDate);
      event.dates[index].endDate = new Date(finalEndDate);
    }
    setValue(event);
  }, [checked]);

  const handleSameDateChange = (newDate: Date) => {
    const { hour, min, period } = startTime;
    const { hour: endHour, min: endMin, period: endPeriod } = endTime;
    const newStartTime = `${hour}:${min} ${period}`;
    const newEndTime = `${endHour}:${endMin} ${endPeriod}`;
    const dateArray = new Date(newDate).toString().split(' ');
    const finalStartDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newStartTime}`;
    const finalEndDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newEndTime}`;
    setStartDate(newDate);
    setEndDate(newDate);
    let event = { ...value };
    event.dates[index].startDate = new Date(finalStartDate);
    event.dates[index].endDate = new Date(finalEndDate);
    setValue(event);
  };

  const handleDateChange = (newDate: Date, item: string) => {
    let finalStartDate, finalEndDate;
    const dateArray = new Date(newDate).toString().split(' ');
    if (item === 'startDate') {
      const { hour, min, period } = startTime;
      const newStartTime = `${hour}:${min} ${period}`;
      finalStartDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newStartTime}`;
      setStartDate(newDate);
    } else {
      const { hour: endHour, min: endMin, period: endPeriod } = endTime;
      const newEndTime = `${endHour}:${endMin} ${endPeriod}`;
      finalEndDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newEndTime}`;
      setEndDate(newDate);
    }
    let event = { ...value };
    event.dates[index][item] = new Date(item === 'startDate' ? finalStartDate : finalEndDate);
    setValue(event);
  };

  const handleRemove = () => {
    let newArray = { ...value };
    newArray.dates.splice(index, 1);
    setValue(newArray);
  };

  const handleLabel = () => {
    if (!isTimeSlot) {
      if (value.dates.length === 1) return 'Date';
      else return `Day ${index + 1}`;
    } else {
      return `Slot ${index + 1}`;
    }
  };

  return (
    <Container>
      <InnerContainer>
        <Row>
          <Text className='small'>{handleLabel()}</Text>
          {index > 0 && <Img src={cross} onClick={handleRemove} />}
        </Row>
        {!isTimeSlot && (
          <>
            <Text className='small margin-bottom'>Start date</Text>
            <DatePickerComponent
              activeDays={true}
              date={startDate}
              handleChange={e =>
                isSameAsStartDate ? handleSameDateChange(e) : handleDateChange(e, 'startDate')
              }
              setDate={setStartDate}
              startTime={startTime}
              endTime={endTime}
              value={value}
              setValue={setValue}
              index={index}
              setSameDate={setEndDate}
              isSameAsStartDate={isSameAsStartDate}
              item='startDate'
            />
          </>
        )}
        <Text className='small margin-bottom'>Start time</Text>
        <TimeRow
          value={value}
          setValue={setValue}
          time={checked ? sameStartTime : startTime}
          setTime={checked ? setSameStartTime : setStartTime}
          item='startDate'
          date={isTimeSlot ? slotDate : startDate}
          index={index}
          checked={checked}
          error={error}
          setError={setError}
        />
        {!isTimeSlot && (
          <>
            <Text className='small margin-bottom'>End date</Text>
            <Row>
              <ContainerCheckbox
                checked={isSameAsStartDate}
                onClick={() => setIsSameAsStartDate(prev => !prev)}
              >
                {isSameAsStartDate && <Icon icon='check' width={20} />}
              </ContainerCheckbox>
              <Label className='s no-uppercase same-start-date'>Same as start date</Label>
            </Row>
            {!isSameAsStartDate && (
              <DatePickerComponent
                activeDays={true}
                date={endDate}
                handleChange={e => handleDateChange(e, 'endDate')}
                setDate={setEndDate}
                startTime={startTime}
                endTime={endTime}
                value={value}
                setValue={setValue}
                index={index}
                setSameDate={setStartDate}
                isSameAsStartDate={isSameAsStartDate}
                item='endDate'
                className='margin-top-same'
              />
            )}
          </>
        )}
        <Text className='small margin-bottom'>End time</Text>
        <TimeRow
          value={value}
          setValue={setValue}
          time={checked ? sameEndTime : endTime}
          setTime={checked ? setSameEndTime : setEndTime}
          item='endDate'
          date={isTimeSlot ? slotDate : endDate}
          index={index}
          checked={checked}
          error={error}
          setError={setError}
        />
        {isTimeSlot && (
          <>
            <Text className='small margin-bottom'>Ticket Quantity</Text>
            <TimeInput
              value={value.dates[index].quantity}
              onChange={e => {
                let event = { ...value };
                event.dates[index].quantity = e.target.value;
                setValue(event);
              }}
              type='number'
              min={1}
              id='event-start-time-hour'
              className='modal quantity'
            />
          </>
        )}
        {!isTimeSlot && value.dates.length > 1 && index === 0 && (
          <Row className='gap margin-top'>
            <ContainerCheckbox checked={checked} onClick={() => setChecked(!checked)}>
              {checked && <Icon icon='check' width={20} />}
            </ContainerCheckbox>
            <Label className='s no-uppercase'>
              Apply same start/end time for all days across this event
            </Label>
          </Row>
        )}
      </InnerContainer>
    </Container>
  );
};

export default Multiday;
