import React from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { EndingRecurrence } from './EndingRecurrence';
import { RepeatRecurrence } from './RepeatRecurrence';
import { Weekly } from './Weekly';
import { EndOptions } from '../../../../types';
import { handleRRule } from './utils';
import { editReccurence } from './services';
import { Container, ContainerBox, Column, Button, Row } from './styles';

interface Props {
  numberOfWeek: number;
  weekday: string;
  dayOfMonth: string;
  endOptions: EndOptions;
  setEndOptions: React.Dispatch<React.SetStateAction<EndOptions>>;
  valueOcurrences: string;
  setValueOcurrences: React.Dispatch<React.SetStateAction<string>>;
  setInterval: React.Dispatch<React.SetStateAction<string>>;
  interval: string;
  daysSelected: string[];
  setDaysSelected: React.Dispatch<React.SetStateAction<string[]>>;
  frecuency: {
    label: string;
    value: string;
  };
  setFrecuency: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  untilDate: Date;
  setUntilDate: React.Dispatch<React.SetStateAction<Date>>;
  repeatEvery: {
    label: string;
    value: string;
  };
  setRepeatEvery: React.Dispatch<
    React.SetStateAction<{
      label: string;
      value: string;
    }>
  >;
  isFromEventSummary?: boolean;
  onClose?: () => void;
}

const RecurringEvent = ({
  numberOfWeek,
  weekday,
  dayOfMonth,
  endOptions,
  setEndOptions,
  valueOcurrences,
  setValueOcurrences,
  setInterval,
  interval,
  daysSelected,
  setDaysSelected,
  frecuency,
  setFrecuency,
  untilDate,
  setUntilDate,
  repeatEvery,
  setRepeatEvery,
  isFromEventSummary,
  onClose,
}: Props) => {
  const { id }: any = useParams();
  const { mutate: mutateRecurrence } = useMutation(editReccurence);

  return (
    <Container>
      <ContainerBox className={isFromEventSummary ? 'noMargin' : ''}>
        <Column>
          <RepeatRecurrence
            value={frecuency}
            setValue={setFrecuency}
            numberOfWeek={numberOfWeek}
            weekday={weekday}
            dayOfMonth={dayOfMonth}
            interval={interval}
            setInterval={setInterval}
            repeatEvery={repeatEvery}
            setRepeatEvery={setRepeatEvery}
          />
          {frecuency.value === 'weekly' && (
            <Weekly daysSelected={daysSelected} setDaysSelected={setDaysSelected} />
          )}
          <EndingRecurrence
            endOptions={endOptions}
            setEndOptions={setEndOptions}
            valueOcurrences={valueOcurrences}
            setValueOcurrences={setValueOcurrences}
            untilDate={untilDate}
            setUntilDate={setUntilDate}
          />
        </Column>
        {isFromEventSummary && (
          <Row className='done'>
            <Button
              onClick={() => {
                onClose();
                mutateRecurrence({
                  id,
                  rrule: handleRRule(
                    frecuency.value,
                    interval,
                    daysSelected,
                    endOptions.isOn,
                    endOptions.isAfter,
                    valueOcurrences,
                    moment(untilDate).format('YYYYMMDD'),
                    repeatEvery.value,
                    dayOfMonth,
                    weekday.slice(0, 2),
                    numberOfWeek
                  ),
                });
              }}
            >
              Done
            </Button>
          </Row>
        )}
      </ContainerBox>
    </Container>
  );
};

export default RecurringEvent;
