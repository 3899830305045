import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { modalStyle } from '../../../WidgetCustomization/styles';
import { Sponsor } from '../../../../types';
import { ButtonContainer } from '../../../../lib/components/EventCreation/styles';
import SquareButton, { SquareButtonVariants } from '../../../../lib/components/Button/SquareButton';
import Icon from '../../../../lib/components/Icon';
import { createSponsor, editSponsor } from '../services';
import { queryClient } from '../../../../App';
import { imageUpload } from '../utils';
import { Container, Row, Button, Text, Column, InnerContainer, StyledInput, Img } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sponsor?: Sponsor;
}

const SponsorModal = ({ isOpen, setIsOpen, sponsor }: Props) => {
  const [value, setValue] = useState<string>(sponsor?.name || '');
  const [sponsorImg, setSponsorImg] = useState<string>(sponsor?.image || '');
  const { id }: any = useParams();

  const { mutate: mutateCreateSponsor } = useMutation(createSponsor, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        oldData.sponsors = [
          ...oldData.sponsors,
          { ID: data.ID, image: data.image, name: data.name },
        ];
        return oldData;
      });
      setIsOpen(false);
    },
  });
  const { mutate: mutateEditSponsor } = useMutation(editSponsor, {
    onSuccess: ({ data }) => {
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        const newSponsors = oldData.sponsors.map(s => {
          if (s.ID === sponsor.ID) {
            s.name = data.name;
            s.ID = data.ID;
            s.image = data.image;
          }
          return s;
        });
        oldData.sponsors = newSponsors;
        return oldData;
      });
      setIsOpen(false);
    },
  });

  const handleSave = () => {
    if (!!sponsor)
      mutateEditSponsor({
        eventId: Number(id),
        sponsorId: sponsor.ID,
        name: value,
        image: sponsorImg,
      });
    else mutateCreateSponsor({ eventId: Number(id), name: value, image: sponsorImg });
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column className='center'>
          <Text className='title'>{!!sponsor ? 'Sponsor' : 'Add new Sponsor'}</Text>
          <Text className='span margin30'>
            {!!sponsor
              ? 'You can edit your sponsor’s information entered before.'
              : 'Please complete the next fields with all the information needed.'}
          </Text>
        </Column>
        <InnerContainer>
          <Text className='span uppercase margin12'>Sponsor Name (Optional)</Text>
          <Row className='width'>
            <StyledInput
              placeholder='Enter Sponsor Name'
              value={value}
              onChange={e => setValue(e.target.value)}
            />
          </Row>
          <Text className='span uppercase margin12'>Sponsor Logo (Optional)</Text>
          {!!sponsorImg ? (
            <>
              <Img src={sponsorImg} />

              <ButtonContainer>
                <SquareButton variant={SquareButtonVariants.red} onClick={() => setSponsorImg('')}>
                  Delete
                </SquareButton>
                <SquareButton
                  onClick={() => imageUpload(setSponsorImg)}
                  variant={SquareButtonVariants.green}
                  decoratorRight={<Icon icon='upload' height={24} className='fill-text-button' />}
                >
                  Replace
                </SquareButton>
              </ButtonContainer>
            </>
          ) : (
            <SquareButton
              onClick={() => imageUpload(setSponsorImg)}
              variant={SquareButtonVariants.green}
              decoratorRight={<Icon icon='upload' height={24} />}
            >
              Upload Image
            </SquareButton>
          )}
        </InnerContainer>
        <Row className='center bottom'>
          <Button className='cancel' onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={handleSave}>{!!sponsor ? 'Save Changes' : 'Add Sponsor'}</Button>
        </Row>
      </Container>
    </Modal>
  );
};

export default SponsorModal;
