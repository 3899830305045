import { getTimeZones } from '@vvo/tzdb';

export const TZ_LIST = (() => {
  let USATimeZones = [];
  let WorldTimeZones = [];

  getTimeZones().forEach(tz => {
    if (tz.countryCode === 'US') {
      USATimeZones.push({
        label: `(${tz.rawFormat.substring(0, 6)}) ${tz.alternativeName} - ${tz.name}`,
        value: `(${tz.rawFormat.substring(0, 6)}) ${tz.alternativeName} - ${tz.name}`,
      });
    } else
      WorldTimeZones.push({
        label: `(${tz.rawFormat.substring(0, 6)}) ${tz.alternativeName} - ${tz.name}`,
        value: `(${tz.rawFormat.substring(0, 6)}) ${tz.alternativeName} - ${tz.name}`,
      });
  });

  return USATimeZones.concat(WorldTimeZones);
})();
