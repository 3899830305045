import { ALL_EVENTS } from '../../../utils/strings';
import Icon from '../Icon';
import { Button, ButtonEvents } from './styles';

type Props = {
  allEvents: boolean;
  name?: string;
  id?: number;
  setSelectedEvents?: any;
  setAllEvents?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedEvents?: number[];
};
const ButtonEventsForm = ({
  allEvents,
  name,
  id,
  setSelectedEvents,
  setAllEvents,
  selectedEvents,
}: Props) => (
  <ButtonEvents>
    {allEvents ? (
      <Button
        className='check'
        onClick={() => {
          setAllEvents(!allEvents);
        }}
      >
        {' '}
        {ALL_EVENTS} <Icon icon='cancel' height={20} />
      </Button>
    ) : (
      ''
    )}
    {!allEvents && (
      <>
        <Button
          onClick={() => {
            if (selectedEvents.includes(id)) {
              setSelectedEvents(selectedEvents.filter(ID => id !== ID));
            } else {
              setSelectedEvents([...selectedEvents, id]);
            }
          }}
          className={selectedEvents.includes(id) ? 'check' : 'events'}
        >
          {name}
          {selectedEvents.includes(id) && <Icon icon='cancel' height={20} />}
        </Button>
      </>
    )}
  </ButtonEvents>
);

export default ButtonEventsForm;
