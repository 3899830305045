import styled from 'styled-components';

import { darkGray, remove } from './../../../utils/theme';
import {
  border,
  cards,
  davyGray,
  emailBackground,
  gray,
  grayFile,
  grayRGBA,
  green,
  headerCell,
  lightblack,
  mediumDarkGray,
  white,
  whiteRGBA,
} from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const ContainerTime = styled.div``;

export const WrapperIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
  cursor: default;
  color: ${white};
  font-family: ${primaryFont};

  &.title {
    margin-bottom: 10px;
    color: ${white};
    font-weight: 300;
    text-transform: uppercase;
  }
  &.discount {
    font-size: 14px;
    text-transform: uppercase;
    color: ${white};
    opacity: 1;
  }
  &.small {
    font-size: 14px;
    text-transform: uppercase;
    color: ${white};
    height: 30px;
  }

  &.error {
    color: ${remove};
    position: absolute;
    top: 55px;
    text-transform: lowercase;
  }

  &.green {
    color: ${green};
    cursor: pointer;
    font-size: 14px;
    justify-content: flex-end;
  }

  &.description {
    font-size: 15px;
    margin-bottom: 16px;
  }

  &.name {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }

  &.promo {
    margin-left: 55px;
    width: fit-content;
  }

  &.subtitle {
    font-size: 16px;
    opacity: 0.7;
    text-transform: uppercase;
  }
  &.margin-bottom {
    margin-bottom: 10px;
  }

  &.header {
    width: 40%;
  }
  &.repeat {
    margin-bottom: 8px;
  }
  &.no-upper {
    text-transform: none;
    margin-top: 0;
    width: auto;
    min-width: 40px;
  }
`;

export const Search = styled.img`
  width: 20px;
  margin-left: 8px;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-end;

  &.checkbox {
    margin-bottom: 15px;
  }
`;

export const CheckboxContainer = styled.div`
  margin-bottom: 40px;
  border-bottom: 1px solid ${grayFile};
`;

export const DateContainer = styled.div`
  display: flex;
  gap: 1rem;

  &.activeDays {
    width: 110%;
  }
`;
export const TimeInput = styled.input`
  border-radius: 4px;
  background: ${emailBackground};
  border: none;
  padding: 14px 65px;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.modal {
    padding: 0.5rem;
    width: 100%;
    text-align: center;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
  &.quantity {
    min-height: 31px;
    width: 22%;
  }
  &.repeat {
    max-width: 67px;
  }
`;

export const TimeButton = styled.div`
  background: ${emailBackground};
  border-radius: 4px;
  border: none;
  padding: 14px 65px;
  outline: none;
  color: ${grayRGBA};
  text-transform: uppercase;
  cursor: pointer;
  &.modal {
    padding: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 400px;
  justify-content: center;

  &.section2 {
    justify-content: flex-start;
  }
`;

export const SummarySection = styled.div`
  background-color: ${cards};
  border: 1px solid ${green};
  border-radius: 8px;
  width: 280px;
  height: 360px;
  padding: 10px 0px 10px 10px;
`;

export const TextGroup = styled.div`
  margin-left: 21px;
`;

export const ContainerInfo = styled.div``;

export const InputContainer = styled.div`
  &.checkbox {
    display: flex;
  }
  &.checkboxContainer {
    max-width: 100%;
  }
  &.search {
    display: flex;
    background: rgba(51, 51, 51, 0.5);
    border-radius: 10px;
    height: 36px;
    width: 515px;
  }
  &.width50 {
    width: 60%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  &.container {
    min-width: 400px;
    margin-top: 5px;
  }

  &.copy {
    gap: 8px;
  }

  &.eventsButton {
    gap: 8px;
    overflow: auto;
    display: -webkit-box;
    margin-bottom: 40px;
    margin-top: 20px;
    padding-bottom: 10px;
  }
  &.eventsButton::-webkit-scrollbar {
    height: 8px;
  }

  &.eventsButton::-webkit-scrollbar-thumb {
    background-color: ${darkGray};
  }
`;

export const ButtonEvents = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${white};
  padding: 7px 0px 7px 8px;

  &.search {
    background: rgba(51, 51, 51, 0.5);
  }
`;

export const Wrapper = styled.div`
  padding: 0px 48px 48px 48px;
`;

export const Row = styled.div`
  border-radius: 12px 12px 0px 0px;
  height: 60px;
  background: ${headerCell};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0px 48px 0px 48px;
  align-items: center;
  &.header {
    margin-top: 24px;
  }

  &.relative {
    position: relative;
  }
`;

export const ContainerText = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;

  &.container {
    align-items: center;
  }

  &.icon {
    gap: 0px;
    align-items: center;
  }
`;

export const ModalRow = styled.div`
  justify-content: center;
  padding: 24px 0px;
  width: 470px;
  height: 200px;
  display: flex;
  row-gap: 24px;
  flex-direction: column;
  margin-left: 40px;
  z-index: 99;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: baseline;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: white;
`;

export const ModalContainer = styled.div`
  background: ${lightblack};
  background: linear-gradient(0deg, ${border}, ${border}), ${cards};
  border: 1px solid ${davyGray};
  box-shadow: 24px 4px 24px ${whiteRGBA};
  border-radius: 0px 16px 16px 16px;
  outline: none;
  position: absolute;
  top: 47px;
  z-index: 99;
`;

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ContainerIcon = styled.div<{ isCopy?: boolean }>`
  display: ${props => (props.isCopy ? 'flex' : 'none')};
  background: ${lightblack};
  padding: 3px 0px 7px 8px;
`;

export const ButtonInput = styled.input<{
  clickButton?: boolean;
  isCopy?: boolean;
}>`
  all: unset;
  width: ${props => (!props.isCopy ? '128px' : '97px')};
  color: ${white};
  padding: 6px 0px 7px 8px;
  background: ${lightblack};
  border-radius: 4px 0px 0px 4px;
  text-align: center;

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const ContainerLimit = styled.div<{
  clickButton?: boolean;
}>`
  border: ${props => (props.clickButton ? '' : '1px solid #ffffff')};
  display: flex;
  opacity: ${props => (!props.clickButton ? 0.9 : 0.5)};
`;

export const Button = styled.div<{
  greenButton?: boolean;
  clickButton?: boolean;
}>`
  padding: 12px 32px;
  background-color: rgba(89, 206, 99, 0.1);
  width: fit-content;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.events {
    border: 1px solid ${green};
    opacity: 0.7;
  }

  &.cancel {
    background: ${mediumDarkGray};
    border: 0px;
  }

  &.confirm {
    background: ${green};
    border: 0px;
  }
  &.primary {
    border-radius: 100px 0px 0px 100px;
    background-color: ${props => (!props.greenButton ? green : gray)};
    color: ${white};
    width: 100%;
  }
  &.secondary {
    border-radius: 0px 100px 100px 0px;
    background-color: ${props => (props.greenButton ? green : gray)};
    color: ${white};
    width: 100%;
  }
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  &.unlimited {
    background: ${lightblack};
    border: ${props => (props.clickButton ? '1px solid #ffffff' : '')};
    border-radius: 0px 4px 4px 0px;
    height: 10px;
    opacity: ${props => (props.clickButton ? 0.9 : 0.5)};
  }
  &.check {
    background: ${green};
    gap: 3px;
  }

  &.event {
    margin-bottom: 4px;
  }
`;

export const Img = styled.img`
  .tick {
    margin-left: -1pc;
  }
  &.back {
    cursor: pointer;
    width: 35px;
  }
  &.close {
    cursor: pointer;
    height: 10px;
    margin-top: 8px;
  }
`;
