import { useHistory } from 'react-router-dom';

import { Button } from '../../lib/components/Button';
import { Background, SuccessWrapper, SuccessImage, Center, SuccessTitle } from './styles';
import { logoBackground, emailSent } from '../../static/img';
import { Divider } from '../../lib/components/Divider';
import useMobileView from '../../hooks/useMobileView';

export default function EmailSentModal() {
  const history = useHistory();
  const isMobile = useMobileView();

  return (
    <>
      <Background src={logoBackground} />
      <SuccessWrapper isMobile={isMobile}>
        <SuccessTitle>Reset Password</SuccessTitle>
        {!isMobile && <Divider />}
        <SuccessImage src={emailSent} />
        <Center>
          <div>We have sent you an email to reset your password.</div>
          <div>Please check your email to continue.</div>
        </Center>
        {!isMobile && <Divider />}
        <Button onClick={() => history.push('/login')}>Okay</Button>
      </SuccessWrapper>
    </>
  );
}
