import styled from 'styled-components';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const ToggleCustom = styled(Toggle)`
  .react-toggle-thumb {
    box-shadow: none;
    width: 18px;
    height: 18px;
  }
  .react-toggle-track {
    width: 46px;
    height: 20px;
  }
`;

export const Container = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Wrapper = styled.div`
  &.options {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Row = styled.div`
  display: flex;
  &.align-center {
    align-items: center;
  }
  &.margin-top {
    margin-top: 10px;
  }
  &.space {
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.w94 {
    width: 94%;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.7;
  &.info {
    font-size: 12px;
    opacity: 0.5;
  }
  &.sub {
    font-size: 16px;
  }
`;
