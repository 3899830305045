import React from 'react';
import DatePicker from 'react-date-picker';

import Icon from '../../Icon';
import { DateInput, Wrapper, DateBox, IconContainer } from '../../Inputs/DatePicker/styles';
import { EventFormType } from '../../../../types';

type Props = {
  date?: Date;
  handleChange?: (date: Date) => void;
  className?: string;
  activeDays?: boolean;
  setDate: React.Dispatch<React.SetStateAction<Date>>;
  startTime: any;
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
};

const twoDigits = (num: number) => ('0' + num).slice(-2);

export default function DatePickerComponent({
  date,
  handleChange,
  className,
  activeDays,
  setDate,
  startTime,
  value,
  setValue,
}: Props) {
  const handleChangeDate = (e, i) => {
    const { hour, min, period } = startTime;
    const newStartTime = `${hour}:${min} ${period}`;
    const dateArray = date.toString().split(' ');
    dateArray.splice(i, 1, e.target.value);
    setDate(new Date(dateArray.join(' ')));
    const finalStartDate = `${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newStartTime}`;
    let event = { ...value };
    event.onSaleTime = new Date(finalStartDate);
    setValue(event);
  };

  return (
    <Wrapper className={className}>
      <DateInput
        className='modal'
        value={twoDigits(date.getMonth() + 1)}
        onChange={e => {
          if (Number(e.target.value) <= 12 && Number(e.target.value) >= 1) {
            handleChangeDate(e, 1);
          }
        }}
        type='number'
        min={1}
        max={12}
      />
      <DateInput
        className='modal'
        value={twoDigits(date.getDate())}
        onChange={e => {
          if (Number(e.target.value) <= 31 && Number(e.target.value) >= 1) {
            handleChangeDate(e, 2);
          }
        }}
        type='number'
        min={1}
        max={31}
      />
      <DateInput
        className='modal'
        value={date.getFullYear()}
        onChange={e => handleChangeDate(e, 3)}
        type='number'
      />
      <DateBox className='picker height'>
        <IconContainer>
          <Icon icon={activeDays ? `${'white-calendar'}` : `${'green-calendar'}`} />
        </IconContainer>
        <DatePicker value={date} onChange={handleChange} minDetail='month' />
      </DateBox>
    </Wrapper>
  );
}
