import styled from 'styled-components';

import {
  grayRGBA,
  grayRGBAopacity,
  inputBackgroundSecondary,
  remove,
  white,
  select,
} from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Container = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  border-radius: 0.25rem;
  margin-top: 0;

  &:focus {
    border: 2px solid var(--color-bg-input) !important;
  }

  label {
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 1rem;
    display: block;
  }
`;

export const ContainerBox = styled.div`
  padding: 14px;
  background: rgba(22, 22, 22, 0.5);
  border-radius: 16px;
  margin-top: 20px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
`;

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: inputBackgroundSecondary,
    border: `1px solid ${grayRGBAopacity}`,
    borderRadius: 4,
    boxShadow: 'none',
    width: '100%',
    minHeight: 0,
    padding: 4,
    '&:hover': {
      border: `1px solid ${grayRGBAopacity}`,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    opacity: 0.7,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: select,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: select,
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};

export const customErrorStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    background: inputBackgroundSecondary,
    border: `1px solid ${remove}`,
    borderRadius: 4,
    boxShadow: 'none',
    width: '100%',
    minHeight: 0,
    padding: 4,
    '&:hover': {
      border: `1px solid ${remove}`,
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    opacity: 0.7,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: select,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: select,
    width: '100%',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};
