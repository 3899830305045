import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 595px;
  &.scroll {
    overflow-y: scroll;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  font-size: 24px;
  text-align: center;
`;
