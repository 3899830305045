import moment from 'moment';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';

import { getData } from '../../../api/api';
import Button from '../../../lib/components/Button/Button';
import Icon from '../../../lib/components/Icon/Icon';
import { REFUND } from '../../../utils/strings';
import { Container, Wrap, Text } from '../style';
import {
  RowWrapper,
  STable,
  STableBody,
  STableCell,
  STableContainer,
  STableHead,
  STableRow,
} from '../tableStyle';
import { RefundSuccessModal } from './RefundSuccessModal';

export function RefundListing() {
  const history = useHistory();
  const [isRefundConfirmation, setRefundConfirmation] = useState<boolean>(false);
  const [isEventToRefund, setIsEventsToRefund] = useState([]);
  const { pathname } = useLocation();
  const pathnameSplit = pathname.split('/');
  const userId = pathnameSplit[2];
  const partner_id = localStorage.getItem('partnerId');

  const {
    state: { name },
  }: any = useLocation();

  const { data: eventsRefund } = useQuery(
    'getEventsRefund',
    () => getData(`/transactions/tickets_for_refund/${partner_id}/${userId}`),
    { onSuccess: data => setIsEventsToRefund(Object.entries?.(data)) }
  );
  const title = [
    'EventName',
    'Date Event',
    'Event Avenue',
    'Tickets Owned',
    'Value (Per Ticket)',
    'Option',
  ];

  return (
    <Container>
      <Wrap className='text'>
        <Container className='containerText'>
          <Container className='containerIcon'>
            <Icon
              icon={'arrow-left-background'}
              onClick={() => history.push(`/fan-history/${userId}`)}
              className='cursor'
              height={45}
              width={35}
            />
            <Text className='titleRefund'>Refund Ticket</Text>
          </Container>
          <Text className='subtitlesRefund'>
            Please select the tickets you would like to refund to {name}
          </Text>
        </Container>
      </Wrap>
      <STableContainer>
        <STable className='history'>
          <STableHead>
            <STableRow index>
              {title.map(title => (
                <STableCell>{title}</STableCell>
              ))}
            </STableRow>
          </STableHead>
          <STableBody>
            {isEventToRefund?.map((eventInfo, index) => (
              <RowWrapper index={index}>
                <STableCell>{eventInfo[1]?.EventName}</STableCell>
                <STableCell>{moment(eventInfo[1]?.DateEvent).format('DD/MM/YYYY')}</STableCell>
                <STableCell>{eventInfo[1]?.Venue}</STableCell>
                <STableCell>{eventInfo[1]?.TicketsOwned}</STableCell>
                <STableCell>
                  {eventInfo[1]?.MinPrice} - {eventInfo[1]?.MaxPrice}
                </STableCell>
                <STableCell className='buttons'>
                  <Button
                    color='gray'
                    onClick={() => history.push(`/fan-refund/${userId}/${eventInfo[0]}`, { name })}
                  >
                    {REFUND}
                  </Button>
                </STableCell>
              </RowWrapper>
            ))}
          </STableBody>
        </STable>
      </STableContainer>
      <RefundSuccessModal isOpen={isRefundConfirmation} setIsOpen={setRefundConfirmation} />
    </Container>
  );
}
