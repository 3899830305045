import { getBase64 } from '../../../utils/utils';

export async function imageUpload(setSponsorImg) {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  fileInput.name = 'image-upload';
  fileInput.id = 'hidden-image-upload';
  fileInput.style.position = 'fixed';
  fileInput.style.top = '-100vh';
  fileInput.addEventListener('change', async () => {
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      getBase64(file, setSponsorImg);
    }
    fileInput.remove();
  });
  document.body.appendChild(fileInput);
  fileInput.click();
}
