import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCurrentVenue } from '../api/auth';

const initialState = {
  user: {},
  loading: false,
  error: '',
};

// First, create the thunk
export const updateUser = createAsyncThunk(
  'users/updateUser',
  async (userId, thunkAPI) => {
    const { admin } = await getCurrentVenue();
    return admin;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    reset: state => {
      state.user = initialState.user;
    },
    request: state => {
      state.loading = true;
      state.error = '';
      state.user = initialState.user;
    },
    failure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.user = initialState.user;
    },
    success: state => {
      state.loading = false;
      state.error = '';
    },
    FORCE_USER_SESSION_DANGEROUSLY: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state = action.payload;
    });
  },
});

export const {
  setUser,
  reset,
  request,
  failure,
  success,
  FORCE_USER_SESSION_DANGEROUSLY,
} = userSlice.actions;
export default userSlice.reducer;
