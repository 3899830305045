import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const Circle = styled.span`
  width: 216px;
  height: 216px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;

  background: var(--color-level-2);
  margin-bottom: 30px;
  padding: 2px;
`;

export const Image = styled.img<{ src: string }>`
  margin: 0 auto;
  min-height: 100%;
  min-width: 100%;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
`;

export const Img = styled.img`
  margin-left: 5px;
`;

export const Avatar = styled.img`
  margin-bottom: 30px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const SelectFileButton = styled.label`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 166px;

  background: var(--color-info-default);

  &:hover {
    background: var(--color-info-dark);
  }
`;

export const DeleteFileButton = styled.label`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  width: 166px;
  margin-right: 12px;

  background: var(--color-danger-default);

  &:hover {
    background: var(--color-danger-dark);
  }
`;
