import Input from '../Inputs/Input/Input';
import { Container } from './AdditionalDetailsForm.styles';

export default function AdditionalDetailsForm({ value, setValue }) {
  return (
    <Container>
      <Input
        label='description (optional)'
        textarea
        value={value.description}
        setValue={description => setValue({ ...value, description })}
        id='event-description'
        placeholder='Enter a brief description of your event to be shown to fans as they are buying tickets.'
      />
    </Container>
  );
}
