import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Modal } from '@mui/material';

import { put } from '../../../api/api';
import useModal from '../../../hooks/useModal';
import { tixologiAvatar } from '../../../static/img';
import { Circle, Image } from './styles';
import UploadButton from './UploadButton/UploadButton';
import { UploadImage } from './UploadImage/UploadImage';
import { modalStyle } from '../../../pages/WidgetCustomization/styles';
import { queryClient } from '../../../App';
import { getBase64 } from '../../../utils/utils';

type Props = {
  url?: string;
};

export default function Avatar({ url }: Props) {
  const [isShowing, toggle] = useModal();
  const [modalAvatar, setModalAvatar] = useState<string>(url);
  const partnerId = localStorage.getItem('partnerId');

  const { mutate: mutateRequestAvatar } = useMutation(
    () =>
      put(`/partners/${partnerId}`, {
        image: modalAvatar,
      }),
    {
      onSuccess: data => localStorage.setItem('partnerLogo', data.image),
    }
  );

  const confirmModal = () => {
    mutateRequestAvatar();
    queryClient.setQueryData(['getPartner'], (oldData: any) => ({
      ...oldData,
      image: modalAvatar,
    }));
  };

  const cancelModal = () => {
    toggle();
  };

  const handleInput = e => {
    const files = e.target.files;
    if (!files || !files.length) return;
    getBase64(files[0], setModalAvatar);
  };

  return (
    <>
      <div onClick={() => toggle()}>
        <UploadButton />
      </div>
      {url ? (
        <Circle>
          <Image src={url} />
        </Circle>
      ) : (
        <img src={tixologiAvatar} alt='avatar' />
      )}
      <Modal open={isShowing} onClose={cancelModal} sx={modalStyle}>
        <div style={{ margin: '0 auto' }}>
          <UploadImage
            image={modalAvatar}
            onSuccess={confirmModal}
            onCancel={cancelModal}
            removeFile={() => setModalAvatar('')}
            handleInput={handleInput}
          />
        </div>
      </Modal>
    </>
  );
}
