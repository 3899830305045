import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 640px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(6px);
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Header = styled.h2`
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  letter-spacing: -0.03em;
`;

export const Description = styled.p`
  width: 90%;
  max-width: 600px;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.03em;
  color: var(--color-text);
`;

export const IPhoneContainer = styled.div`
  position: relative;
  height: 70%;
  margin: 10px;
`;

export const IPhone = styled.img`
  height: 100%;
  position: relative;
  z-index: 10;
`;

export const Preview = styled.img`
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5%;
  border-radius: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
`;

export const Link = styled.button`
  all: unset;
  cursor: pointer;
  display: flex;
  color: var(--color-primary-default);
  text-decoration: underline;
  font-size: 14px;
  text-transform: capitalize;
`;
