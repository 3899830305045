import styled, { css } from 'styled-components';

import { Button } from '../../../../lib/components/Button';
import { ButtonVariants } from '../../../../lib/components/Button/Button';
import Icon from '../../../../lib/components/Icon';
import { MemberType } from '../../../../types';

type Props = {
  index?: number;
  name: string;
  role: { value: string };
  openEditModal: Function;
  member: MemberType;
  isAdmin: boolean;
};

export function Row({
  index,
  name,
  role,
  openEditModal,
  member,
  isAdmin,
}: Props) {
  return (
    <RowWrapper
      className={`${member?.User?.status ? '' : 'inactive'}`}
      index={index}
    >
      <Name>{name}</Name>
      {isAdmin && (
        <Button
          variant={ButtonVariants.outlined}
          height={45}
          decoratorRight={<Icon height={12} icon='pencil' />}
          onClick={() => openEditModal(member)}
          size='large'
        >
          {role?.value}
        </Button>
      )}
    </RowWrapper>
  );
}
const RowWrapper = styled.div<{ index }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #292828;
  height: 81px;
  padding: 10px 40px;

  &.inactive {
    opacity: 0.5;
  }

  button {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600px;
    background-color: #636363 0.4;
    border: solid 1px #636363;
    margin: 0px;
  }

  ${props =>
    props.index % 2 &&
    css`
      background-color: #191818;
    `}
`;

const Name = styled.p`
  color: white;
  font-weight: 700;
  margin: 0px;
`;
