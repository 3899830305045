import ReactCodeInput from 'react-verification-code-input';
import styled from 'styled-components';

import {
  black,
  blueLink,
  grayFile,
  grayRGBA,
  grayRGBAopacity,
  green,
  inputBackgroundSecondary,
  lightblack,
  lightGray,
  palette,
  white,
} from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: 100vw;
  background-color: var(--color-bg-2);
  margin: 20px auto;
  width: calc(min(90vw, 47rem));
  border-radius: 10px;
  padding: 2rem;
  &.profile {
    background-size: unset;
    margin: 20px 60px;
    width: unset;
    padding: 0 5rem;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
  cursor: default;
`;
export const Row = styled.div`
  display: flex;
  &.statusHover {
    align-items: flex-start;
  }
  &.status {
    position: relative;
  }
  &.fitContent {
    width: fit-content;
  }
  &.terms {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.wrapper {
    width: 100%;
    padding: 0 0 20px 0;
  }
  &.centerBorder {
    align-items: center;
    padding: 24px 0px;
    border-bottom: 1px solid ${grayRGBA};
  }
  &.royality {
    align-items: center;
    padding: 60px 0px;
    border-bottom: 1px solid ${grayRGBA};
  }
  &.width {
    width: 200px;
  }
  &.statusHover {
    margin-left: 10px;
  }
`;

export const AddFile = styled.div`
  background: ${grayFile};
  border-radius: 5px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 11.2px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  width: fit-content;
  padding: 12px;
  margin-top: 5px;
  cursor: pointer;
`;

export const AddInfoButton = styled.div`
  background: rgba(99, 99, 99, 0.3);
  border: 1px solid #636363;
  box-sizing: border-box;
  border-radius: 23px;
  width: fit-content;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  padding: 10px 20px;
  margin-top: 5px;
  cursor: pointer;
  &.manage {
    margin-top: 15px;
  }
`;

export const Input = styled.input`
  background: ${inputBackgroundSecondary};
  border: 1px solid ${grayRGBAopacity};
  border-radius: 4px;
  width: 132px;
  height: 46px;
  outline: none;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  padding: 0 0 0 15px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Background = styled.img`
  display: flex;
  height: 75vh;
  object-fit: contain;
  align-self: flex-end;
`;

export const SuccessImage = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  margin: 20px 0px;
`;

export const Line = styled.div`
  color: black;
  display: flex;
  align-items: center;
  font-size: 40px;
`;

export const ReactCodeInputStyled = styled(ReactCodeInput)`
  width: 100% !important;
  text-align: center;
  &.custom-class {
    display: flex;
    flex-direction: row;
  }
  & input {
    color: white !important;
    background-color: ${black} !important;
    font-family: Open Sans;
    border-radius: 3.25104px !important;
    font-size: 40.39px;
    height: 90px !important;
    width: 68px !important;
    margin-right: 8px;
    border-right: solid 1px #a8adb7;
  }
  & input:focus {
    border: 1px solid ${green};
    caret-color: ${green};
  }
`;

export const ContainerCode = styled.div`
  display: flex;
  gap: 10px;
  &.pack {
    gap: 4px;
  }
`;
export const NumberCode = styled.input`
  width: 70px;
  height: 85px;
  background: ${inputBackgroundSecondary};
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 3.25104px;
  font-size: 40px;
  text-align: center;
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  &.char {
    margin-left: 10px;
  }
  &.title {
    margin-bottom: 10px;
  }
  &.error {
    font-size: 9px;
    margin-top: 5px;
    color: red;
  }
  &.subTitle {
    font-weight: 400;
    font-size: 16px;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.row {
    flex-direction: row;
  }
`;

export const stylesPhone = {
  container: {
    background: palette.black,
    border: `1px solid rgba(255,255,255,0.75)`,
    borderRadius: 4,
    padding: '8px',
  },
  search: {
    background: palette.black,
  },
  input: {
    border: 'none',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 20,
    color: palette.white,
    paddingLeft: 68,
    height: 30,
    background: palette.black,
    width: '100%',
  },
  button: {
    background: palette.black,
    boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: 3,
    height: '80%',
    top: 4,
    width: 58,
    paddingLeft: 8,
  },
};

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  &.subTitle {
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-top: 10px;
  }
  &.modal {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 16px;
  }
  &.or {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    padding: 0px 30px;
  }
  &.pdf {
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
  }
  &.marginTop {
    margin-top: 35px;
  }

  &.two_factor {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
  }

  &.title {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: -0.03em;
  }

  &.resend {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 16px;
    margin-top: 8px;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Image = styled.img`
  cursor: pointer;
  margin-left: 20px;
`;

export const Link = styled.a`
  color: ${blueLink};
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const Status = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  text-transform: capitalize;
  cursor: default;
  &.hoverTitle {
    font-weight: 700;
    font-size: 14px;
  }
  &.hoverSpan {
    font-weight: 400;
    font-size: 14px;
    margin-top: 5px;
  }
`;

export const Img = styled.img`
  &.status {
    margin-left: 15px;
    width: 26px;
  }
  &.statusHover {
    width: 20px;
  }
  &.trash {
    margin-left: 10px;
    cursor: pointer;
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  gap: 4px;
  &.title {
    flex-direction: column;
    align-items: center;
  }
  &.image {
    width: 360px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const ContainerText = styled.div`
  border-radius: 8px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 15px;
  &.green {
    background: rgba(89, 206, 99, 0.05);
    border: 1px solid rgba(89, 206, 99, 0.7);
  }
  &.white {
    background: rgba(155, 155, 155, 0.05);
    border: 1px solid rgba(155, 155, 155, 0.7);
  }
`;

export const Container = styled.div<{ info: string }>`
  display: flex;
  background: ${lightblack};
  border-radius: 8px;
  padding: 10px 15px;
  position: absolute;
  left: ${props => (props.info !== 'restrictedSoon' ? '330px' : '430px')};
`;

export const UploadButton = styled.div`
  display: flex;
  background: ${lightGray};
  border-radius: 4px;
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 16px;
  &.oneMore {
    margin-top: 32px;
  }
`;
