import { Redirect, Route } from 'react-router-dom';

import useLoading from '../hooks/useLoading';
import { NavBar } from '../lib/components/layout';
import { Div, Loading } from '../lib/components/layout/Navigation/Navbar/styles';
import { loading } from '../static/animations';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const isLoading = useLoading();
  const isAuthenticated = localStorage.getItem('accessToken');

  return (
    <Route
      {...restOfProps}
      render={props =>
        isAuthenticated ? (
          <>
            <NavBar isAuthenticated={true} />
            <Component {...props} />
            {!!isLoading && (
              <>
                <Div />
                <Loading src={loading} alt='loading...' />
              </>
            )}
          </>
        ) : (
          <>
            <NavBar isAuthenticated={false} />
            <Redirect to='/login' />
          </>
        )
      }
    />
  );
}

export default ProtectedRoute;
