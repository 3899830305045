import moment from 'moment';

import {
  RowWrapper,
  STable,
  STableBody,
  STableCell,
  STableContainer,
  STableHead,
  STableRow,
} from './tableStyle';

type Props = {
  info: any;
};

export function TablePurchase({ info }: Props) {
  const title = [
    'Event',
    'Date | Time',
    'Ticket type',
    'Primary or Secondary',
    'Price paid',
    'Date of purchase',
  ];

  return (
    <STableContainer>
      <STable className='tableTicket'>
        <STableHead>
          <STableRow index>
            {title.map(title => (
              <STableCell>{title}</STableCell>
            ))}
          </STableRow>
        </STableHead>
        <STableBody>
          {info?.map(
            ({
              index,
              Event,
              EventDate,
              TicketType,
              PrimaryOrSecondary,
              Price,
              TransactionDate,
              IsSlot,
              EndDate,
            }) => (
              <RowWrapper index={index} className='no-pointer'>
                <STableCell>{Event}</STableCell>
                <STableCell className='upperCase'>
                  {moment(EventDate).utc().format('l')} | {moment(EventDate).utc().format('LT')}
                  {IsSlot && `-${moment(EndDate).utc().format('LT')}`}
                </STableCell>
                <STableCell>{TicketType}</STableCell>
                <STableCell className='upperCase'>{PrimaryOrSecondary}</STableCell>
                <STableCell>{Price}</STableCell>
                <STableCell className='upperCase'>
                  {moment(TransactionDate).format('MMMM DD, YYYY')}
                </STableCell>
              </RowWrapper>
            )
          )}
        </STableBody>
      </STable>
    </STableContainer>
  );
}
