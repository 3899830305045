import styled from 'styled-components';

import { borderLine, grayFile, white } from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Wrapper = styled.div`
  &.wrapColumns {
    display: flex;
    width: 43%;
  }
`;

export const RowContainer = styled.div`
  border-bottom: 1px solid ${borderLine};
  width: 100%;
  padding: 18px 48px;
  display: flex;
  align-items: center;
  &.container {
    flex-direction: column;
    align-items: flex-start;
  }
  &.sales {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr;
    border-top: 1px solid ${grayFile};
  }
  &.column {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 0px;
  }
  &.containerInventory {
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-right: 15px;
  width: fit-content;
  &.title {
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-right: 0px;
  }
  &.data {
    font-weight: normal;
    margin-right: 0px;
  }
  &.title-s {
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-left: 15px;
  }
  &.data-s {
    font-weight: normal;
    margin-left: 15px;
    font-size: 24px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
