import styled, { css } from 'styled-components';

import { lighterGray, green } from '../../../../utils/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  &.no-margin {
    margin: 0;
  }
  &.padding {
    padding: 10px;
    margin-bottom: 0px;
  }
`;

export const CheckIcon = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
      width: ${props.height}px;
    `}
  border: 2px solid ${lighterGray};
  cursor: pointer;
  margin-right: 8px;
  border-radius: 50%;

  &:hover {
    border-color: var(--color-primary-dark);
  }

  svg {
    fill: var(--color-primary-default);
  }

  &.checked {
    border-color: ${green};
  }
`;

export const Circle = styled.div<{ height?: number }>`
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
      width: ${props.height}px;
    `}
  border-radius: 50%;
  background-color: var(--color-primary-default);

  &:hover {
    background-color: var(--color-primary-dark);
  }
`;
