import React from 'react';
import { useMutation } from 'react-query';
import { detachPromoCode } from '../../../../pages/EventCreation/services';

import { promoCodesWhite, removeCircle } from '../../../../static/icons';
import { TicketsType } from '../../../../types';
import { TagContainer, Label, Img } from './styles';

interface Props {
  label: string;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  index: number;
  id: number;
  ticketTypeId: number;
}

export const PromoCodeTag = ({ label, id, tickets, setTickets, index, ticketTypeId }: Props) => {
  const handleRemove = () => {
    let newTickets = [...tickets];
    newTickets[index].attachedPromoCodes = tickets[index].attachedPromoCodes.filter(
      item => item.ID !== id
    );
    setTickets(newTickets);
  };

  const { mutate: mutateAttachPromoCode } = useMutation(detachPromoCode, {
    onSuccess: handleRemove,
  });

  return (
    <TagContainer>
      <Label className='tag'>{label}</Label>
      <Img src={promoCodesWhite} className='tag' />
      <Img
        src={removeCircle}
        className='delete'
        onClick={() => mutateAttachPromoCode({ promoCodeID: id, ticketTypeID: ticketTypeId })}
      />
    </TagContainer>
  );
};
