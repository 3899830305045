import { Button } from '../../lib/components/Button';
import { ButtonVariants } from '../../lib/components/Button/Button';
import Icon from '../../lib/components/Icon';

type Props = {
  onClick?: Function;
  children?: any;
};
export default function GoogleButton({ onClick, children }: Props) {
  const googleIcon = <Icon icon='google-g'></Icon>;
  return (
    <>
      <Button
        decoratorLeft={googleIcon}
        onClick={onClick}
        variant={ButtonVariants.outlined}
        size='large'
        className='full-width'
      >
        {children}
      </Button>
    </>
  );
}
