import styled from 'styled-components';
import {
  white,
  headerCell,
  grayRGBA,
  inputBackground,
} from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-left: 48px;
`;

export const Row = styled.div`
  width: 100%;
  height: 60px;
  background: ${headerCell};
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  padding: 0px 48px 0px 48px;
  &.header {
    margin-top: 24px;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${grayRGBA};
  &.header {
    font-weight: 600;
    color: ${white};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  background: ${inputBackground};
  border-radius: 10px;
  height: 36px;
  width: 332px;
  margin-left: 48px;
  margin-top: 28px;
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${white};
  padding: 7px 0px 7px 8px;
`;

export const Search = styled.img`
  width: 20px;
  margin-left: 8px;
`;
