import { queryClient } from '../../../App';
import {
  AVERAGE_SOLD_PER_DAY,
  COMPS,
  HOLDS,
  REQUIRED_PER_DAY,
  SALES_TODAY,
  SOLD,
} from '../../../utils/strings';

export const inventoryInfo = () => {
  const options: any = queryClient.getQueryData('getEventAnalyticsFull');
  const inventory = {
    infoInventory: [
      { title: SOLD, information: options?.inventory.sold || '-' },
      { title: COMPS, information: options?.inventory.comps || '-' },
      { title: HOLDS, information: options?.inventory.holds || '-' },
    ],
  };
  return inventory;
};

export const salesInfo = () => {
  const options: any = queryClient.getQueryData('getEventAnalyticsFull');
  const sales = {
    second: [
      {
        title: SALES_TODAY,
        information: options?.sales.sales_today || '-',
      },
      {
        title: AVERAGE_SOLD_PER_DAY,
        information: options?.sales.average_sold_per_day || '-',
      },
      {
        title: REQUIRED_PER_DAY,
        information: options?.sales.required_per_day_for_sellout || '-',
      },
    ],
  };
  return sales;
};
