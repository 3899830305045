import { Dispatch } from '@reduxjs/toolkit';
import { initializeApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  getAdditionalUserInfo,
} from 'firebase/auth';

import { failure, request, setUser, success } from '../stores/userSlice';
import api from './index';
import { api as API } from './api';

const BASE_URL = '/partner/v1/auth';

export async function login(email, password, venue) {
  return api.get(`${BASE_URL}/login`, { email, password, venue });
}

export async function getCurrentVenue() {
  return api.get(`${BASE_URL}/me`);
}

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const provider = new GoogleAuthProvider();

const signup =
  (email: string, password: string, successCallback: () => void) => (dispatch: Dispatch) => {
    dispatch(request());
    createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        dispatch(success());
        successCallback();
      })
      .catch(error => {
        dispatch(failure(error.message));
      });
  };

const loginRequest = (userId: string, successCallback: () => void) => {
  API.post('/login', {
    firebase_id: userId,
  }).then(({ data }) => {
    localStorage.setItem('userId', data?.ID);
    successCallback();
  });
};

// add two factor authentication: setIsOpenModalAuthentication(true)
//use getAdditionalUserInfo(userCredential).isNewUser to know if is a new user
const signin =
  (email: string, password: string, successCallback: () => void) => (dispatch: Dispatch) => {
    dispatch(request());
    signInWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        const user = userCredential.user;
        localStorage.setItem('accessToken', (user as any).accessToken);
        dispatch(setUser(user));
        loginRequest(user.uid, () => {
          successCallback();
          dispatch(success());
        });
      })
      .catch(error => {
        dispatch(failure('Account information incorrect. Try again'));
      });
  };

const googleSignin = (successCallback: () => void) => (dispatch: Dispatch) => {
  dispatch(request());
  signInWithPopup(auth, provider)
    .then(result => {
      const user = result.user;
      localStorage.setItem('accessToken', (user as any).accessToken);
      dispatch(setUser(user));
      loginRequest(user.uid, () => {
        successCallback();
        dispatch(success());
      });
    })
    .catch(error => {
      dispatch(failure(error.message));
    });
};

const logout = (successCallback: () => void) => (dispatch: Dispatch) => {
  dispatch(request());
  signOut(auth)
    .then(() => {
      dispatch(success());
      successCallback();
    })
    .catch(error => {
      dispatch(failure(error.message));
    });
};

export { auth, googleSignin, signin, signup, logout };
