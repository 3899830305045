import React from 'react';

import { queryClient } from '../../../../App';
import { Navigation, Label } from './styles';

const navigationOptions = [
  { name: 'Buy Tickets', value: '' },
  { name: 'Sell and Transfer', value: '' },
  { name: 'Existing Tickets', value: '' },
];

export default function Navbar() {
  const data: any = queryClient.getQueryData('getWidgetCustomization');
  const primaryColor = data?.primary_brand_color;
  const secondaryFontColor = data?.text_brand_color;

  return (
    <Navigation>
      {navigationOptions.map(({ name, value }) => (
        <Label
          className='apply-font-1'
          key={name}
          primaryColor={primaryColor}
          secondaryFontColor={secondaryFontColor}
        >
          {name}
        </Label>
      ))}
    </Navigation>
  );
}
