import React from 'react';
import { Modal } from '@mui/material';

import { Container, Row, Button, Line, Text, Column } from './stylesModal';
import { modalStyle } from '../../../../pages/WidgetCustomization/styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  title: string;
  id?: number;
  handleClick?: () => void;
}

export default function DeleteEventModal({
  isOpen,
  setIsOpen,
  label,
  title,
  id,
  handleClick,
}: Props) {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column className='top'>
          <Text>Are you sure you want to delete {title}? </Text>
          <Text className='bold'>{label}</Text>
        </Column>
        <Line />
        <Row className='bottom'>
          <Button className='cancel' onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleClick();
              setIsOpen(false);
            }}
            className='remove'
          >
            Yes, Delete
          </Button>
        </Row>
      </Container>
    </Modal>
  );
}
