import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &.center {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  z-index: 100;
  background-color: var(--color-gray-1);
  position: relative;
  margin: 1.75rem auto;
  border-radius: 3px;
  max-width: 900px;
  padding: 2rem 0;
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 20px;
  padding-bottom: 2rem;
  border-bottom: 1px solid gray;
`;

export const ModalChildren = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
`;

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  border-top: 1px solid gray;
  padding-top: 2rem;

  button {
    width: fit-content;
    min-width: 200px;
  }

  button:first-child {
    margin-right: 20px;
  }
`;
