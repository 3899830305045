import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import RecurringEvent from '../../../../lib/components/EventCreation/RecurringEvent';
import {
  handleDay,
  handleDayOfWeek,
} from '../../../../lib/components/EventCreation/RecurringEvent/utils';
import { Wrapper } from './styles';
import { EndOptions } from '../../../../types';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  rrule: string;
  date: string;
}

const RecurringEventModal = ({ setIsOpen, rrule, date }: Props) => {
  const ref = useRef(null);
  const [frecuency, setFrecuency] = useState({ label: 'Week', value: 'weekly' });
  const [daysSelected, setDaysSelected] = useState<string[]>(['SU']);
  const [interval, setInterval] = useState<string>('1');
  const [endOptions, setEndOptions] = useState<EndOptions>({
    isNever: true,
    isAfter: false,
    isOn: false,
  });
  const [valueOcurrences, setValueOcurrences] = useState<string>('20');
  const [untilDate, setUntilDate] = useState(new Date());
  const [repeatEvery, setRepeatEvery] = useState({
    label: `Monthly on day 17`,
    value: 'day',
  });

  useEffect(() => {
    const rruleArray = rrule.split(';');
    rruleArray.forEach(element => {
      if (element.split('=')[0] === 'FREQ') {
        switch (element.split('=')[1]) {
          case 'MONTHLY':
            {
              setFrecuency({ label: 'Month', value: element.split('=')[1].toLowerCase() });
              let numberOfWeek, weekday;
              rruleArray.forEach(element => {
                if (element.split('=')[0] === 'BYDAY') weekday = element.split('=')[1];
                if (element.split('=')[0] === 'BYSETPOS') numberOfWeek = element.split('=')[1];
              });
              setRepeatEvery({
                label: handleDayOfWeek(Number(numberOfWeek), handleDay(weekday)),
                value: 'monthly',
              });
            }
            break;
          case 'DAILY':
            setFrecuency({ label: 'Day', value: element.split('=')[1].toLowerCase() });
            break;
          case 'WEEKLY':
            {
              setFrecuency({ label: 'Week', value: element.split('=')[1].toLowerCase() });
              rruleArray.forEach(element => {
                if (element.split('=')[0] === 'BYDAY') {
                  setDaysSelected(element.split('=')[1].split(','));
                }
              });
            }
            break;
          case 'YEARLY':
            setFrecuency({ label: 'Year', value: element.split('=')[1].toLowerCase() });
            break;
          default:
            setFrecuency({ label: 'Month', value: element.split('=')[1].toLowerCase() });
        }
      }

      if (element.split('=')[0] === 'INTERVAL') {
        setInterval(element.split('=')[1]);
      }

      if (element.split('=')[0] === 'BYMONTHDAY')
        setRepeatEvery({
          label: `Monthly on day ${element.split('=')[1]}`,
          value: 'day',
        });

      //ENDS
      if (element.split('=')[0] === 'COUNT') {
        setValueOcurrences(element.split('=')[1]);
        setEndOptions({
          isNever: false,
          isAfter: true,
          isOn: false,
        });
      }

      if (element.split('=')[0] === 'UNTIL') {
        setUntilDate(moment(element.split('=')[1]).toDate());
        setEndOptions({
          isNever: false,
          isAfter: false,
          isOn: true,
        });
      }
    });
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return (
    <Wrapper ref={ref}>
      <RecurringEvent
        numberOfWeek={Math.ceil(moment(date).date() / 7)}
        weekday={moment(date).format('dddd')}
        dayOfMonth={moment(date).format('D')}
        endOptions={endOptions}
        setEndOptions={setEndOptions}
        valueOcurrences={valueOcurrences}
        setValueOcurrences={setValueOcurrences}
        frecuency={frecuency}
        setFrecuency={setFrecuency}
        interval={interval}
        setInterval={setInterval}
        daysSelected={daysSelected}
        setDaysSelected={setDaysSelected}
        untilDate={untilDate}
        setUntilDate={setUntilDate}
        repeatEvery={repeatEvery}
        setRepeatEvery={setRepeatEvery}
        isFromEventSummary
        onClose={() => setIsOpen(false)}
      />
    </Wrapper>
  );
};

export default RecurringEventModal;
