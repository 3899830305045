import styled from 'styled-components';

import { green } from '../../../../utils/theme';

export const PermsDropdown = styled.div`
  margin-left: 30px;
  margin-bottom: 14px;
  padding-top: 14px;
  border-top: 1px solid ${green};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Permission = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
`;
