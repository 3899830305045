import { createSlice } from '@reduxjs/toolkit';

interface colorsState {
  primaryColor: string;
  secondaryColor: string;
  primaryFontColor: string;
  secondaryFontColor: string;
}

const intitialColorState: colorsState = {
  primaryColor: '',
  secondaryColor: '',
  primaryFontColor: '#FFFFFF',
  secondaryFontColor: '#808080',
};

const colors = createSlice({
  name: 'colors',
  initialState: intitialColorState,
  reducers: {
    setPrimaryColor(state, action) {
      state.primaryColor = action.payload;
    },
    setSecondaryColor(state, action) {
      state.secondaryColor = action.payload;
    },
    setPrimaryFontColor(state, action) {
      state.primaryFontColor = action.payload;
    },
    setSecondaryFontColor(state, action) {
      state.secondaryFontColor = action.payload;
    },
  },
});

export const {
  setPrimaryColor,
  setSecondaryColor,
  setPrimaryFontColor,
  setSecondaryFontColor,
} = colors.actions;

export default colors.reducer;
