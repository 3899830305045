import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { useMutation } from 'react-query';

import Icon from '../../../lib/components/Icon/Icon';
import { DateBox, IconContainer } from '../../../lib/components/Inputs/DatePicker/styles';
import { editDay } from './services';
import { InputContainer, Text } from './styles';

interface Props {
  description: string;
  eventId: number;
  dayId: number;
  index: number;
  eventDaysArray?: any[];
  isTimeSlot?: boolean;
  startDate: any;
  endDate: any;
}

export const DateSelector = ({
  description,
  eventId,
  dayId,
  index,
  eventDaysArray,
  isTimeSlot,
  startDate,
  endDate,
}: Props) => {
  const [value, setValue] = useState<string>(description);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const calendarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsEdit(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [calendarRef]);

  const { mutate: mutateEdit } = useMutation(editDay);

  return (
    <InputContainer
      className={isEdit ? 'edit' : ''}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text className='date'>{moment(value).utc().format('L')}</Text>
      {(hover || isEdit) && (
        <DateBox className='picker summary' onClick={() => setIsEdit(true)} ref={calendarRef}>
          <IconContainer>
            <Icon icon={'white-calendar'} />
          </IconContainer>
          <DatePicker
            value={new Date(value)}
            onChange={value => {
              setValue(value);
              const timeStart = moment(startDate).format('LT');
              const timeEnd = moment(endDate).format('LT');
              const dateArray = value.toString().split(' ');
              const finalDateStart = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${timeStart}`;
              const finalDateEnd = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${timeEnd}`;
              if (isTimeSlot) {
                eventDaysArray.map(({ ID, start_date, end_date }) => {
                  const timeStart = moment(start_date).format('LT');
                  const timeEnd = moment(end_date).format('LT');
                  const finalDateStart = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${timeStart}`;
                  const finalDateEnd = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${timeEnd}`;
                  mutateEdit({
                    valueStart: moment(finalDateStart).format(),
                    valueEnd: moment(finalDateEnd).format(),
                    eventId,
                    dayId: ID,
                  });
                });
              } else
                mutateEdit({
                  valueStart: moment(finalDateStart).format(),
                  valueEnd: moment(finalDateEnd).format(),
                  eventId,
                  dayId,
                });
            }}
            minDetail='month'
          />
        </DateBox>
      )}
    </InputContainer>
  );
};
