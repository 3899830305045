export const ANALYTICS = 'Analytics';

export const BOTTOM_SELLING = 'Bottom selling events';

export const CREATE_EVENT = 'Create Event';
export const CREATE = 'Create';
export const COLORS = 'Colors';
export const CONFIRM_PASSWORD = 'Confirm password';
export const CONFIRM = 'Confirm';

export const DATE = 'date';

export const EDIT_EVENT = 'Edit Event';
export const EDIT_NAME = 'Edit Name';
export const EMAIL = 'email';
export const EMAIL_IS_INVALID = 'invalid email';
export const EMAIL_IS_REQUIRED = 'email is required';
export const END_DATE = 'end date';
export const END_TIME = 'end time';
export const ENTER_PASSWORD = 'Enter password';

export const FOR_ALL_EVENTS = 'For all your events';

export const GROSS_REVENUE = 'Gross revenue';
export const GROSS_TICKETS_SOLD = 'Gross tickets sold';

export const MULTIDAY_EVENT = 'multiday event';

export const NAME = 'name';
export const NAME_IS_INVALID = 'invalid name';
export const NAME_IS_REQUIRED = 'name is required';
export const NEW_PASSWORD = 'new password';

export const OVERALL_SUMMARY = 'Overall Summary';

export const PASSWORD = 'password';
export const PASSWORDS_DONT_MATCH = "Passwords don't match";
export const PASSWORD_IS_REQUIRED = 'Password is required';

export const REPEAT_NEW_PASSWORD = 'repeat new password';

export const START_DATE = 'start date';
export const START_TIME = 'start time';
export const STILL_ON_SALE = 'Still on sale';

export const TICKET_LIBRARY = 'Ticket library';
export const TICKETS_COMPS = 'Tickets comps';
export const TICKETS_OPEN = 'Tickets open';
export const TICKETS_SOLD = 'Tickets sold';
export const TIMEZONE = 'timezone';
export const TIXOLOGI_TICKET_DESIGNER = 'Tixologi ticket designer';
export const TOP_SELLING = 'Top selling events';
export const TOTAL_EVENT_BUDGET = 'TOTAL EVENT BUDGET';
export const TOTAL_EVENTS = 'Total events';
export const TOTAL_GROSS = 'Total gross potential';
export const UPLOAD_IMAGE = 'Upload Image';
export const VIEW_EDIT = 'View/Edit';
export const BILLING_HISTORY = 'Billing history';
export const EVENT_NAME = 'Event Name';
export const YOUR_PERCENTAGE = 'Your Percentage';
export const TIXOLOGI_PERCENTAGE = 'Tixologi Percentage';
export const DATE_M = 'Date';
export const SEARCH_EVENT = 'Search for an event';
export const SEARCH_EVENT_TO_VIEW = ' Search and select an event to view its tickets below';
export const EVENT_SUMMARY = 'Event Summary';
export const DATE_TIME = 'Event Date and Time';
export const VENUE_NAME = 'Venue Name';
export const GATE_TIME = 'Gate time';
export const SUPPORT_CONTACT = 'Support Contact';
export const SPONSORS = 'Sponsors';
export const TICKET_TYPES = 'Ticket types';
export const TICKET_TYPE = 'Ticket Type';
export const TOTAL_SEATS = 'Total Seats';
export const BACK = 'Back';
export const TOTAL_GROSS_POTENTIAL = 'Total Gross Potential';
export const TICKET_COMPS = 'Ticket Comps';
export const TOTAL_FACE_VALUE = 'Total Gross';
export const SOLD = 'Sold';
export const COMPS = 'Comps';
export const HOLDS = 'Holds';
export const OPENS = 'Opens';
export const GA_TICKETS = 'Ga Tickets';
export const VIP_TICKETS = 'Vip Tickets';
export const GA_STANDARD = 'Ga Standard';
export const VIP = 'Vip';
export const GA = 'Ga';
export const VIP_FOOD_BEV = 'Vip+ Food & Bev';
export const TICKETS_REMAINING = 'Tickets Remaining';
export const TICKETS_DISTRIBUTED = 'Distributed';
export const UNIQUE_ATTENDANCES = 'Unique Attendances';
export const TOTAL_ATTENDANCE = 'Total Attendance';
export const ATTENDANCE_TYPE = 'Attendance by Ticket Type';
export const ATHLETES = 'Athletes';
export const SUPPORT_STAFF = 'Support Staff';
export const MEDIA_STAFF = 'Media Staff';
export const TOTAL_SPONSORS = 'Total Sponsors';
export const VOLUNTEERS = 'Volunteers';
export const PROMO_CODE_TICKETS = 'Promo Code Tickets';
export const SALES_TODAY = 'Sales Today';
export const AVERAGE_SOLD_PER_DAY = 'Average Sold Per Day';
export const REQUIRED_PER_DAY = 'Required per day for sellout';
export const CHANNELS = 'Channels';
export const MOBILE = 'Mobile';
export const WEB = 'Web';
export const BOX_OFFICE = 'Box Office';
export const CONVERSION_RATES = 'Conversion Rates';
export const TRAFFIC_TRENDS = 'Traffic trends';
export const TITLE = 'Widget Customization';
export const SUBTITLE =
  'The widget is where users will view, buy and sell tickets on your website. Customize your personalized widget below.';
export const WEBSITE_ADRESS = 'Website address';
export const WEBSITE_URL = 'Website url';
export const BRANDING = 'Branding';
export const BRAND_COLOR = 'Brand color';
export const BRAND_FONTS = 'Brand fonts';
export const BRAND_COLOR_DESCRIPTION =
  'This will be the color of your buttons and call to action items';
export const BRAND_FONTS_DESCRIPTION = 'Save your licensed fonts for title and body text';
export const PRIMARY = 'Primary';
export const SECONDARY = 'Secondary';
export const TERTIARY = 'Tertiary';
export const PALETTE = 'Palette';
export const CANCEL = 'Cancel';
export const CONTINUE = 'Continue ';
export const SAVE_AS_DRAFT = 'Save as Draft';
export const SUBMIT = 'Submit';
export const VIEW_MORE = 'View More';
export const SAVE_CHANGES = 'Save Changes';
export const SELECT_COLOR = 'Select a color';
export const UPLOAD_FONT = 'Upload font';
export const EVENTS = 'Events';
export const SELECT_EVENT = 'Select the events that you would like to show on your website';
export const PREVIEW = 'Preview';
export const SAVE = 'Save';
export const ALLOW_TRANSFERING = 'Allow transfering tickets';
export const ALLOW_SELLING = 'Allow selling tickets';
export const SAVED_CHANGES = 'Your changes have been saved';
export const CHANGES_ON_SITE = 'These changes are now live on your site.';
export const OKAY = 'Okay';
export const LEARN_ABOUT_TICKETS = 'Learn about our tickets';
export const VISIONS_TOUR = 'Visions tour';
export const TICKETS = 'Tickets';
export const ALL_EVENTS = 'All Events';
export const SELECT_ALL_EVENTS = 'Select All Events';
export const APPLY_ALL_EVENTS = 'Apply to all tickets types across all events';
export const PREVIEW_WIDGET = 'PREVIEW WIDGET';
export const CLOSE_PREVIEW = 'Close preview';
export const DEFAULT_ROYALITIES = 'Default royalities';
export const ADD_SECONDARY = 'Add Secondary';
export const TERMS = 'Terms of service';
export const ADD_DOCUMENT = 'Add Document';
export const BANK_INFO = 'Bank Info';
export const ADD_BANK_INFO = 'Add bank info';
export const ADD_DEFAULT_ROYALITIES = 'Add Default Royalties';
export const ADD_ROYALITY = 'Add Royalty';
export const SECONDARY_ROYALITY = 'Secondary Royalty';
export const FAN_DATABASE = 'Fan Database';
export const PERSONAL_INFORMATION = 'Personal Information';
export const NOTES_HISTORY = 'Notes History';
export const TICKET_HISTORY = 'Ticket History';
export const ADD_NEW = 'Add new';
export const ADD_NEW_PROMOCODE = 'Add new Promo Code';
export const RECENTS = 'Recents';
export const LAST_WEEK = 'Last Week';
export const RESET_PASSWORD = 'Reset Password';
export const VERIFICATION = 'Two step verification';
export const CONFIRM_IDENTITY = 'Confirm Identity';
export const TWO_FA = '2-FA';
export const REFUND_TICKET = 'Refund ticket';
export const REFUND = 'Refund';
export const SWAP_TICKET = 'Swap ticket';
export const PURCHASES = 'Purchases';
export const ATTENDANCE = 'Attendance';
export const TRANSFERS = 'Transfers';
export const UNLIMITED = 'Unlimited';
export const LIMITED_DAYS = 'Limited days';
export const ACTIVE_DAYS = 'Active days';
export const SET_ACTIVE_DAYS = 'Set Active days';
export const COPY_PROMOCODE = 'Copy this promo code quantity to the following tickets';
export const ALL_TICKETS = 'All ticket types in this event';
export const ALL_TICKETS_FOR_EVENTS = 'All ticket types for all selected events';
export const ACTIVATE = 'Activate';
export const DISCOUNT = 'Discount';
export const DELETE = 'Delete';
export const APPLIED_TO = 'Applied to';
export const DISABLE = 'Disable';
export const BY_PERCENT = 'By Percent %';
export const BY_DOLLAR = 'By Dollar $';
export const PROMO_CODE_INFO = 'Promo Code Information';
export const ATTACH_TO_TICKET = 'Attach to Ticket Type';
export const SUMMARY = 'Summary/Preview';
export const ATTENDEE_NAME = 'Attendee name';
export const ATTENDEE_EMAIL = 'Attendee email';
export const EVENT = 'Event';
export const QUANTITY = 'Quantity';
export const VALUE = 'Value';
export const DATETIME = 'Date | Time';
export const STATUS = 'Status';
