import { useState } from 'react';

import { cellOne, cellTwo } from '../../../utils/theme';
import {
  Button,
  ButtonContainer,
  ButtonInput,
  ContainerColumn,
  ContainerIcon,
  ContainerLimit,
  Row,
  Text,
} from './styles';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import Icon from '../Icon';
import { UNLIMITED } from '../../../utils/strings';
import { EventFromBack, TicketPromoCode } from '../../../types';
import { CopyPromoModal } from './CopyPromoModal';

type Props = {
  allEvents: boolean;
  name?: string;
  id: number;
  setSelectedTickets: React.Dispatch<React.SetStateAction<TicketPromoCode[]>>;
  selectedTickets: TicketPromoCode[];
  quantity: string;
  index: number;
  eventIndex: number;
  setNewEvents: React.Dispatch<React.SetStateAction<EventFromBack[]>>;
  newEvents: EventFromBack[];
  selectedEvents: number[];
  eventId: number;
  checked: boolean;
};

const RowTickets = ({
  name,
  id,
  selectedTickets,
  setSelectedTickets,
  quantity,
  index,
  eventIndex,
  setNewEvents,
  newEvents,
  selectedEvents,
  eventId,
  checked,
}: Props) => {
  const [clickButton, setClickButton] = useState(true);
  const [isOpenPromo, setIsOpenPromo] = useState<boolean>(false);

  return (
    <Row
      className='relative'
      key={`${name[0]}-${id}`}
      style={{ background: index % 2 === 0 ? cellOne : cellTwo }}
    >
      <ContainerColumn>
        <div
          onClick={() => {
            if (checked) setSelectedTickets(selectedTickets.filter(ticket => ticket.ID !== id));
            else
              setSelectedTickets([
                ...selectedTickets,
                { ID: id, quantity, code: name, checked: true },
              ]);
          }}
        >
          <Checkbox
            checked={checked}
            setChecked={checked => {
              let onChangeEvents = [...newEvents];
              onChangeEvents[eventIndex].ticket_types[index].checked = checked;
              setNewEvents(onChangeEvents);
            }}
            className={checked ? 'green' : 'grayCheckbox'}
          />
        </div>
        <Text>{name}</Text>
      </ContainerColumn>
      <ButtonContainer>
        <ContainerLimit clickButton={clickButton}>
          <ButtonInput
            type='number'
            placeholder='#'
            clickButton={clickButton}
            value={quantity === '-1' ? '' : quantity}
            onChange={e => {
              let onChangeEvents = [...newEvents];
              onChangeEvents[eventIndex].ticket_types[index].quantity = e.target.value;
              setNewEvents(onChangeEvents);
              setClickButton(false);
              setSelectedTickets(
                selectedTickets.map((ticket: TicketPromoCode) => {
                  if (ticket.ID === id) {
                    ticket.quantity = e.target.value;
                  }
                  return ticket;
                })
              );
            }}
            isCopy={quantity.length > 0 && quantity !== '-1'}
          />
          <ContainerIcon isCopy={quantity.length > 0 && quantity !== '-1'}>
            {quantity.length > 0 && quantity !== '-1' && (
              <Icon
                icon='copy'
                className='cursor'
                onClick={() => {
                  setIsOpenPromo(true);
                }}
              />
            )}
          </ContainerIcon>
        </ContainerLimit>
        {isOpenPromo && (
          <CopyPromoModal
            setIsOpen={setIsOpenPromo}
            selectedEvents={selectedEvents}
            newEvents={newEvents}
            setNewEvents={setNewEvents}
            eventId={eventId}
            eventIndex={eventIndex}
            index={index}
          />
        )}
        <Button
          className='unlimited'
          onClick={() => {
            let onChangeEvents = [...newEvents];
            onChangeEvents[eventIndex].ticket_types[index].quantity = '-1';
            setNewEvents(onChangeEvents);
            setClickButton(true);
          }}
          clickButton={clickButton}
        >
          {UNLIMITED}
        </Button>
      </ButtonContainer>
    </Row>
  );
};

export default RowTickets;
