import * as Yup from 'yup';

import {
  EMAIL,
  EMAIL_IS_INVALID,
  EMAIL_IS_REQUIRED,
  NAME,
  NAME_IS_REQUIRED,
} from '../../../../utils/strings';

export const INVITE_MEMBER_FIELDS = {
  name: 'name',
  email: 'email',
};

export const INVITE_MEMBER_INIT_VALUES = {
  name: '',
  email: '',
};

export const InviteMemberSchema = Yup.object().shape({
  name: Yup.string().required(NAME_IS_REQUIRED),
  email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
});

export const inviteMemberInputData = [
  {
    name: INVITE_MEMBER_FIELDS.email,
    label: EMAIL,
    placeholder: 'john.smith@gmail.com',
  },
  {
    name: INVITE_MEMBER_FIELDS.name,
    label: NAME,
    placeholder: 'John Smith',
  },
];
