import React from 'react';

import { EndOptions } from '../../../../types';
import Radio from '../../Inputs/Radio/Radio';
import { Text } from '../../PromoCodes/styles';
import { CalendarPicker } from './CalendarPicker';
import { Ocurrences } from './Ocurrences';
import { Column, Row } from './styles';

interface Props {
  endOptions: EndOptions;
  setEndOptions: React.Dispatch<React.SetStateAction<EndOptions>>;
  valueOcurrences: string;
  setValueOcurrences: React.Dispatch<React.SetStateAction<string>>;
  untilDate: Date;
  setUntilDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const EndingRecurrence = ({
  endOptions,
  setEndOptions,
  valueOcurrences,
  setValueOcurrences,
  untilDate,
  setUntilDate,
}: Props) => (
  <Column>
    <Text className='small repeat'>Ends </Text>
    <Row className='center top'>
      <Row
        onClick={() => {
          setEndOptions({
            isNever: false,
            isAfter: false,
            isOn: true,
          });
        }}
      >
        <Radio checked={endOptions.isOn} noMargin />
      </Row>
      <Text className='small no-upper'>On</Text>
      <CalendarPicker untilDate={untilDate} setUntilDate={setUntilDate} />
    </Row>
    <Row className='center top'>
      <Row
        onClick={() => {
          setEndOptions({
            isNever: false,
            isAfter: true,
            isOn: false,
          });
        }}
      >
        <Radio checked={endOptions.isAfter} noMargin />
      </Row>
      <Text className='small no-upper'>After</Text>
      <Ocurrences valueOcurrences={valueOcurrences} setValueOcurrences={setValueOcurrences} />
    </Row>
  </Column>
);
