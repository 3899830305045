import { useState } from 'react';
import { useMutation } from 'react-query';

import { MemberType, RolesType } from '../../../../types';
import { Button } from '../../../../lib/components/Button';
import { Modal } from '../../../../lib/components/Modal';
import RolesDropdown from '../RolesDropdown/RolesDropdown';
import { AdminActions, Wrapper } from './EditMember.style';
import { deleteData, put } from '../../../../api/api';
import { queryClient } from '../../../../App';

type Props = {
  member: MemberType;
  showModal: boolean;
  onClose: Function;
  roles: Array<RolesType>;
};

export function EditMember({ member, showModal, onClose, roles }: Props) {
  const [role, setRole] = useState(member.Roles?.[0]?.ID);
  const currentMember = (
    queryClient.getQueryData('getMembers') as Array<any>
  ).find(({ UserID }) => UserID.toString() === localStorage.getItem('userId'));

  const { mutate: deleteMemberMutation } = useMutation(
    () => deleteData(`/members/${member?.MemberID}`),
    {
      onSuccess: data => {
        queryClient.setQueryData(['getMembers'], (oldData: Array<any>) =>
          oldData?.filter(
            newMember => parseInt(data, 10) !== newMember.MemberID
          )
        );
        onClose();
      },
    }
  );

  const { mutate: updateMemberMutation } = useMutation(
    () =>
      put(`/members/${member?.MemberID}?roles=true`, {
        roles: [role],
      }),
    {
      onSuccess: data => {
        queryClient.setQueryData(['getMembers'], (oldData: Array<any>) =>
          oldData?.map(newMember =>
            data.MemberID === newMember.MemberID ? data : newMember
          )
        );
        onClose();
      },
    }
  );

  return (
    <Modal
      isShowing={showModal}
      title='Member Role and Permissions'
      textSuccess='Save'
      textClose='Cancel'
      onSuccess={updateMemberMutation}
      onCancel={onClose}
      hide={onClose}
    >
      <Wrapper>
        <h3>{member.User?.full_name}</h3>
        <RolesDropdown roles={roles} setRole={setRole} role={role} />
        <AdminActions>
          {currentMember?.Roles[0].name === 'admin' && (
            <Button
              onClick={deleteMemberMutation}
              shape='block'
              size='medium'
              color='danger'
            >
              Remove user from team
            </Button>
          )}
        </AdminActions>
      </Wrapper>
    </Modal>
  );
}
