import React from 'react';
import { useQuery } from 'react-query';
import { getData } from '../../api/api';

import { EVENTS, SELECT_EVENT } from '../../utils/strings';
import Event from './Event';
import { ContentContainer, Span, Text, Column } from './styles';

export const Events = () => {
  const partner_id = localStorage.getItem('partnerId');
  const { data: events } = useQuery(
    'getEvents',
    () => getData(`/events?partner_id=${partner_id}`),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <ContentContainer className='event'>
      <Span>{EVENTS}</Span>
      <Text>{SELECT_EVENT}</Text>
      <Column>
        {events?.map(
          (
            { name, ID, active_on_widget, transfering_allowed, selling_allowed, event_days },
            index
          ) => (
            <Event
              title={name}
              id={ID}
              active_on_widget={active_on_widget}
              transfering_allowed={transfering_allowed}
              selling_allowed={selling_allowed}
              start_date={event_days?.[0]?.start_date}
              end_date={event_days?.[0]?.end_date}
            />
          )
        )}
      </Column>
    </ContentContainer>
  );
};
