import React from 'react';

import { BrandColor } from './BrandColor';
import { BRANDING } from '../../utils/strings';
import { Container, Span } from './styles';
import { Website } from './Website';
import { BrandFonts } from './BrandFonts';
import { Events } from './Events';

interface BrandingProps {
  setIsOpenPrimary: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenSecondary: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenPrimaryFont: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenSecondaryFont: React.Dispatch<React.SetStateAction<boolean>>;
  refetchWidget: any;
}

export const Branding = ({
  setIsOpenPrimary,
  setIsOpenSecondary,
  setIsOpenPrimaryFont,
  setIsOpenSecondaryFont,
  refetchWidget,
}: BrandingProps) => {
  return (
    <Container>
      <Span className='margin'>{BRANDING}</Span>
      <Website refetchWidget={refetchWidget} />
      <BrandColor setIsOpenPrimary={setIsOpenPrimary} setIsOpenSecondary={setIsOpenSecondary} />
      <BrandFonts
        setIsOpenPrimary={setIsOpenPrimaryFont}
        setIsOpenSecondary={setIsOpenSecondaryFont}
      />
      <Events />
    </Container>
  );
};
