import { useState } from 'react';
import { v4 as uuid } from 'uuid';

import { addGreen } from '../../../static/icons';
import { CutomOptions, EventFormType } from '../../../types';
import EventOptions from './EventOptions';
import DatePickerComponent from './Scheduling/DatePicker';
import { DropDown } from './Scheduling/DropDown';
import Multiday from './Scheduling/Multiday';
import { Text } from '../PromoCodes/styles';
import { AddSponsor, Container, Img, InnerContainer, Wrapper, Div } from './styles';

type Props = {
  value: EventFormType;
  setValue: React.Dispatch<React.SetStateAction<EventFormType>>;
  error?: any;
  setError?: React.Dispatch<React.SetStateAction<boolean[]>>;
  dateError: boolean[];
  customOptions: CutomOptions;
  setCustomOptions: React.Dispatch<React.SetStateAction<CutomOptions>>;
};

const SchedulingForm = ({
  value,
  setValue,
  error,
  setError,
  dateError,
  customOptions,
  setCustomOptions,
}: Props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [isCustomEvent, setIsCustomEvent] = useState<boolean>(false);

  const [sameStartTime, setSameStartTime] = useState({ hour: 10, min: '00', period: 'pm' });
  const [sameEndTime, setSameEndTime] = useState({ hour: 10, min: '00', period: 'pm' });
  const [slotDate, setSlotDate] = useState(new Date());

  const handleDateChange = (newCalendarDate: Date) => {
    setSlotDate(newCalendarDate);
    const updatedDateArray = newCalendarDate.toString().split(' ');
    const newDate = `${updatedDateArray[0]} ${updatedDateArray[1]} ${updatedDateArray[2]} ${updatedDateArray[3]}`;
    let event = { ...value };
    event.dates.map(({ startDate, endDate }, index) => {
      const startDateArray = startDate.toString().split(' ');
      const endDateArray = endDate.toString().split(' ');
      event.dates[index].startDate = new Date(`${newDate} ${startDateArray[4]}`);
      event.dates[index].endDate = new Date(`${newDate} ${endDateArray[4]}`);
    });
    setValue(event);
  };

  return (
    <Container className='width center gap'>
      <InnerContainer className='margin-top column'>
        <DropDown
          label='timezone'
          value={value}
          setValue={setValue}
          error={error}
          setError={setError}
        />
        <EventOptions
          isCustomEvent={isCustomEvent}
          setIsCustomEvent={setIsCustomEvent}
          customOptions={customOptions}
          setCustomOptions={setCustomOptions}
        />
      </InnerContainer>
      {customOptions.isTimeSlot && (
        <InnerContainer className='margin-top column'>
          <Div className='w70'>
            <Text className='small margin-bottom'>First day of event</Text>
            <DatePickerComponent
              activeDays={true}
              date={slotDate}
              handleChange={handleDateChange}
              setDate={setSlotDate}
              value={value}
              setValue={setValue}
              isTimeSlot={customOptions.isTimeSlot}
            />
          </Div>
        </InnerContainer>
      )}
      <Wrapper isMultiday={value.dates.length > 1}>
        {value.dates.map((date, index) => (
          <InnerContainer key={date.id}>
            <Multiday
              value={value}
              setValue={setValue}
              index={index}
              checked={checked}
              setChecked={setChecked}
              sameStartTime={sameStartTime}
              setSameStartTime={setSameStartTime}
              sameEndTime={sameEndTime}
              setSameEndTime={setSameEndTime}
              dateError={dateError}
              isTimeSlot={customOptions.isTimeSlot}
              slotDate={slotDate}
            />
          </InnerContainer>
        ))}
      </Wrapper>
      {isCustomEvent && (customOptions.isMultiday || customOptions.isTimeSlot) && (
        <Container className='width'>
          <AddSponsor
            onClick={() =>
              setValue({
                ...value,
                dates: [
                  ...value.dates,
                  { id: uuid(), startDate: new Date(), endDate: new Date(), quantity: '1' },
                ],
              })
            }
          >
            <Img src={addGreen} />
            add {customOptions.isMultiday ? 'day' : 'slot'}
          </AddSponsor>
        </Container>
      )}
    </Container>
  );
};

export default SchedulingForm;
