import styled from 'styled-components';

import { cards, emailBackground, grayRGBA, green, lightGray, white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: 'none',
    background: emailBackground,
    borderRadius: 8,
    boxShadow: 'none',
    maxWidth: 365,
    minWidth: 365,
    minHeight: 0,
    padding: '2px 0px',
    '&:hover': {
      border: 'none',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    fontFamily: primaryFont,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    color: white,
    opacity: 0.5,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    background: emailBackground,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    color: white,
    borderBottom: `1px solid ${grayRGBA}`,
    opacity: state.isFocused ? 1 : 0.5,
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    borderRadius: '0px 0px 8px 8px',
    background: emailBackground,
    width: 'fit-content',
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: '0px 8px',
  }),
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -10px;
  right: -504px;
  z-index: 90;
  background: ${cards};
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
  padding: 36px 71px;
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
  margin-top: 20px;
  margin-bottom: 8px;
`;

export const Row = styled.div`
  display: flex;
  &.space {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
  }
  &.mTop {
    margin-top: 34px;
  }
`;

export const Button = styled.div`
  background: ${green};
  border-radius: 100px;
  padding: 10px 30px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
    opacity: 0.5;
    padding: 10px 36px;
    margin-right: 16px;
  }
`;
