import { Wrapper } from './styles';

type Props = {
  children?: any;
};

export default function Divider({ children }: Props) {
  return (
    <>
      <Wrapper>{children}</Wrapper>
    </>
  );
}
