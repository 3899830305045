import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useMutation } from 'react-query';

import { ButtonContainer, Column, Container, InputRefund } from '../style';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../../WidgetCustomization/styles';

import { post } from '../../../api/api';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setRefundConfirmation?: React.Dispatch<React.SetStateAction<boolean>>;
  ticketsIdSelected?: number[];
  priceAverageSelected?: number[];
}

export const RefundModal = ({
  isOpen,
  setIsOpen,
  setRefundConfirmation,
  ticketsIdSelected,
  priceAverageSelected,
}: Props) => {
  const [priceAverage, setPriceAverage] = useState<string>('');
  const [totalValue, setTotalValue] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const {
    state: { name },
  }: any = useLocation();

  const { mutate: refundMutation } = useMutation(
    () =>
      post('/financial/refund', {
        ticket_ids: ticketsIdSelected,
        amount: parseFloat(totalValue) * ticketsIdSelected?.length,
        description,
      }),
    {
      onSuccess: data => {
        setRefundConfirmation(true);
      },
    }
  );

  useEffect(() => {
    const total = priceAverageSelected?.reduce((acc, number) => acc + number, 0);
    const average = total / priceAverageSelected?.length;
    setPriceAverage(average.toFixed(2));
    setTotalValue(average.toString());
  }, [priceAverageSelected]);

  useEffect(() => {
    if (parseFloat(totalValue) > parseFloat(priceAverage) * ticketsIdSelected?.length) {
      setTotalValue(parseFloat(priceAverage).toString());
    }
  }, [totalValue]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='refund'>
        <Row className='centerBorder'>
          <Title className='modal'>Refund Tickets</Title>
        </Row>
        <Column className='refund'>
          <Column className='widthRefund'>
            <Column className='widthContainer'>
              <Container className='text'>
                <Container className='containerText'>
                  <Title className='refund'>number of tickets to refund</Title>
                  <Row>
                    <InputRefund
                      className='gray'
                      type='number'
                      disabled
                      value={ticketsIdSelected?.length}
                    />
                  </Row>
                </Container>
                <Container className='containerText'>
                  <Title className='refund'>value to refund per ticket</Title>
                  <Row>
                    <InputRefund
                      type='number'
                      min={0}
                      max={100}
                      value={totalValue}
                      placeholder={priceAverage}
                      onChange={e => setTotalValue(e.target.value)}
                    />
                  </Row>
                </Container>
              </Container>
              <Container className='text description'>
                <Container className='containerText'>
                  <Title className='refund'>Description for refunding (optional)</Title>
                  <Row>
                    <InputRefund
                      className='description'
                      placeholder='Enter a message'
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                    />
                  </Row>
                </Container>
                <Container className='containerText'>
                  <Title className='refund'>total value to refund</Title>
                  <Row>
                    <InputRefund
                      className='gray'
                      type='number'
                      disabled
                      value={(parseFloat(totalValue) * ticketsIdSelected?.length).toFixed(2)}
                    />
                  </Row>
                </Container>
              </Container>
            </Column>
          </Column>
        </Column>
        <Row className='center'>
          <ButtonContainer>
            <ButtonModal className='cancel' onClick={() => setIsOpen(false)}>
              Cancel
            </ButtonModal>
            <ButtonModal
              className='okay'
              onClick={() => {
                setIsOpen(false);
                refundMutation();
              }}
            >
              Refund
            </ButtonModal>
          </ButtonContainer>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
