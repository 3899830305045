import React from 'react';
import { cross } from '../../../../static/icons';

import { TicketsType } from '../../../../types';
import { OnSale } from './Components/OnSale';
import { TicketTypePromoCode } from './Components/PromoCode';
import { TicketDesign } from './Components/TicketDesign';
import { TicketTypeInfo } from './Components/TicketTypeInfo';
import { Wrapper, Img } from './styles';

interface Props {
  fromEvent?: boolean;
  index: number;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  setIsExisting?: React.Dispatch<React.SetStateAction<boolean>>;
  ticketError: boolean[];
  eventOnSaleTime: Date;
  ticketTypeId: number;
}

const TicketTypeForm = ({
  fromEvent,
  index,
  tickets,
  setTickets,
  setIsExisting,
  ticketError,
  eventOnSaleTime,
  ticketTypeId,
}: Props) => {
  const handleRemove = () => {
    let newArray = [...tickets];
    newArray.splice(index, 1);
    setTickets(newArray);
  };

  return (
    <Wrapper fromEvent={fromEvent}>
      {index > 0 && <Img src={cross} className='remove' onClick={handleRemove} />}
      <TicketTypeInfo tickets={tickets} setTickets={setTickets} index={index} />
      <OnSale
        tickets={tickets}
        setTickets={setTickets}
        index={index}
        eventOnSaleTime={eventOnSaleTime}
      />
      <TicketTypePromoCode
        fromEvent={fromEvent}
        tickets={tickets}
        setTickets={setTickets}
        index={index}
        setIsExisting={setIsExisting}
        ticketTypeId={ticketTypeId}
      />
      <TicketDesign
        tickets={tickets}
        setTickets={setTickets}
        index={index}
        ticketError={ticketError}
      />
    </Wrapper>
  );
};

export default TicketTypeForm;
