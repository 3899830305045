export const white = '#ffffff';
export const black = '#000000';
export const light = '#f2f2f2';
export const gray = '#292929';
export const darkgray = '#1D1D1D';
export const darkGray = '#161616';
export const darkBackground = '#303030';
export const darkerGray = '#838383';
export const veryDarkGray = '#484848';
export const mediumDarkGray = '#494949';
export const green = '#59CE63';
export const greenBackground = '#2ebd85';
export const lightGray = '#9B9B9B';
export const lighterGray = '#808080';
export const lighterblack = '#232323';
export const lightblack = '#333333';
export const grayFile = '#474747';
export const blueLink = '#2f80ed';
export const ticketBorder = '#37FB6E';
export const davyGray = '#555555';
export const grayTitle = '#CECECE';

export const headerCell = '#292929';
export const grayRGBA = 'rgba(255, 255, 255, 0.5)';
export const grayRGBAopacity = 'rgba(255, 255, 255, 0.75)';
export const whiteRGBA = 'rgba(0, 0, 0, 0.25)';

export const cellOne = '#0E0E0E';
export const cellTwo = '#161616';

export const inputBackground = '#1b1b1b';
export const hoverBlack = '#1c1b1b;';
export const inputBackgroundSecondary = '#080808';
export const emailBackground = '#090909';
export const contentBackground = '#1d1d1d';

export const cards = '#1F1F1F';
export const borderLine = '#474747';
export const paletteBorder = '#d8d8d8';
export const transparent = 'transparent';
export const lightGrayTwo = '#EFEFEF';
export const remove = '#EB5757';
export const redBackground = '#f6465d';
export const yellowBackground = '#f2c94c';

export const palette = {
  white: '#FFFFFF',
  black: '#000000',
  gray: {
    0: '#D7D7D7',
    30: '#F3F3F3',
    50: '#F5F5F5',
    100: '#BBBBBB',
  },
  pink: '#C47551',
};

export const shadow = '0px 4px 14px rgba(0, 0, 0, 0.2)';

export const gradient = 'linear-gradient(96.2deg, #A75873 0.99%, #C8794C 96.46%)';

export const shadowW = 'rgba(0, 0, 0, 0.25)';

export const shadowContainer = 'rgba(221, 221, 221, 0.35)';

export const border = 'rgba(0, 0, 0, 0.2)';

export const select = '#3D3D3D';
