import moment from 'moment';
import { useQuery } from 'react-query';
import { CSVLink } from 'react-csv';

import { getData } from '../../api/api';
import DropdownTab from '../../lib/components/DropdownTab/DropdownTab';
import EventCard from '../../lib/components/EventList/EventCard/EventCard';
import { downloadGreen } from '../../static/icons';
import { Wrapper, Row, Title, Img, PdfButton, csvStyled } from './styles';

export default function EventList() {
  const partnerId = localStorage.getItem('partnerId');

  const { data: eventsPartner } = useQuery('getEventsPartner', () =>
    getData(`events?partner_id=${partnerId}&ordered=true`)
  );

  const { data: csvData } = useQuery('getCSV', () =>
    getData(`/analytics/slot_quantity/${partnerId}}`)
  );

  return (
    <Wrapper>
      <Row className='space-between'>
        <Title>Events</Title>
      </Row>
      {eventsPartner &&
        Object.keys(eventsPartner)
          ?.sort((a, b) => {
            if (moment(a, 'MMMM YYYY').isBefore(moment(b, 'MMMM YYYY'))) {
              return -1;
            }
            return 1;
          })
          ?.map((period, index) => (
            <DropdownTab
              key={period}
              period={period}
              events={eventsPartner[period]}
              open={index === 0}
              props={{ key: period }}
              Component={EventCard}
            />
          ))}
    </Wrapper>
  );
}
