import moment from 'moment';

import { promoCodeFormType, TicketPromoCode } from '../../../types';
import { ACTIVE_DAYS, APPLIED_TO, DISCOUNT } from '../../../utils/strings';
import { Text, Section, Button, SummarySection, TextGroup } from './styles';

type Props = {
  value: promoCodeFormType;
  setValue: Function;
  arrayEvents: number[];
  arrayTickets: TicketPromoCode[];
};

const SummaryPromoCode = ({ value, arrayTickets }: Props) => {
  return (
    <Section>
      <SummarySection>
        <TextGroup>
          <Text className='name'>{value?.code}</Text>
          <Text className='subtitle'>{DISCOUNT}</Text>
          <Text className='small'>
            {' '}
            {value.coupon_type === 'percentage' ? `${value?.percentage} %` : `${value?.discount} $`}
          </Text>
          <Text className='subtitle'>{ACTIVE_DAYS}</Text>
          {moment(value?.end_date).format('YYYY') === '1970' ? (
            <Text className='small'> Unlimited</Text>
          ) : (
            <Text className='small'>
              {moment(value?.start_date).format('MM/DD/YYYY')} to{' '}
              {moment(value?.end_date).format('MM/DD/YYYY')}
            </Text>
          )}
          <Text className='subtitle'>{APPLIED_TO}</Text>
          {arrayTickets?.map((ticket: TicketPromoCode) => (
            <Text className='small'>{ticket.code}</Text>
          ))}
        </TextGroup>
      </SummarySection>
    </Section>
  );
};

export default SummaryPromoCode;
