import styled from 'styled-components';

import { grayRGBA, green, lightblack, lightGray, remove, white } from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Container = styled.div`
  width: 40%;
  background: #2d2d2d;
  border-radius: 16px;
  outline: none;
`;

export const Row = styled.div`
  display: flex;
  &.bottom {
    justify-content: center;
    padding: 20px;
    gap: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.top {
    align-items: center;
    padding: 40px;
  }
`;

export const Button = styled.div`
  border-radius: 100px;
  background: ${green};
  justify-content: center;
  padding: 5px 16px;
  min-width: 122px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  cursor: pointer;
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
  }
  &.remove {
    background: ${remove};
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grayRGBA};
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
`;
