import React from 'react';
import { Modal } from '@mui/material';

import BuyTickets from '../../lib/components/WidgetPreview/BuyTickets';
import { CLOSE_PREVIEW, PREVIEW_WIDGET } from '../../utils/strings';
import { WidgetPrevWrapper, Title, Text, modalStyle } from './styles';

interface WidgetProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WidgetPreview = ({ isOpen, setIsOpen }: WidgetProps) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <WidgetPrevWrapper>
      <Title className='widget apply-font-0'>{PREVIEW_WIDGET}</Title>
      <BuyTickets />
      <Text className='widget apply-font-1' onClick={() => setIsOpen(false)}>
        {CLOSE_PREVIEW}
      </Text>
    </WidgetPrevWrapper>
  </Modal>
);
