import React, { useState } from 'react';

import { DateSelector } from './DateSelector';
import { TimeSelector } from './TimeSelector';
import { Line } from '../PromoCodes/styles';
import { Input } from './Input';
import { Text, Row, Column, Img, DayContainer, TextContainer, MyPopover } from './styles';
import { editPencil } from '../../../static/icons';
import ModalDelete from './DeleteModal/ModalDelete';
import ModalDeleteConfirmation from './DeleteModal/ModalDeleteConfirmation';
import ModalCancel from './DeleteModal/ModalCancel';
import ModalOneSlot from './DeleteModal/ModalOneSlot';

interface Props {
  startDate: any;
  endDate: any;
  index: number;
  timezone: string;
  eventId: number;
  dayId: number;
  isTimeSlot: boolean;
  quantity: string;
  eventDaysArray: any[];
  sold: number;
}

const dateRow = [
  { label: 'Start', item: 'start_date' },
  { label: 'End', item: 'end_date' },
];

export const Day = ({
  startDate,
  endDate,
  index,
  timezone,
  eventId,
  dayId,
  isTimeSlot,
  quantity,
  eventDaysArray,
  sold,
}: Props) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const [isCancel, setIsCancel] = useState<boolean>(false);
  const [isOneSlot, setIsOneSlot] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsEdit(true);
  };

  const handlePopoverClose = () => {
    setIsEdit(false);
    setAnchorEl(null);
  };

  return (
    <Column className={isEdit ? 'edit relative days' : 'days'}>
      <DayContainer
        className={isEdit ? 'edit ' : ''}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <TextContainer>
          <Text className='title'>{`${isTimeSlot ? 'SLOT' : 'DAY'} ${index + 1}`}</Text>
          {(hover || isEdit) && (
            <Img className='editSlot' src={editPencil} onClick={handlePopoverOpen} />
          )}
        </TextContainer>
        <Line />
      </DayContainer>
      {dateRow.map(({ label, item }) => (
        <Row>
          <Column className='date'>
            <Text className='title'>{label} Date</Text>
            <DateSelector
              description={label === 'Start' ? startDate : endDate}
              eventId={eventId}
              dayId={dayId}
              index={index}
              eventDaysArray={eventDaysArray}
              isTimeSlot={isTimeSlot}
              startDate={startDate}
              endDate={endDate}
            />
          </Column>
          <Column className='date'>
            <Text className='title'>{label} Time</Text>
            <TimeSelector
              description={label === 'Start' ? startDate : endDate}
              item={item}
              eventId={eventId}
              title={`${label} Time`}
              timezone={timezone}
              dayId={dayId}
              index={index}
            />
          </Column>
        </Row>
      ))}
      {isTimeSlot && (
        <>
          <Row>
            <Column className='date'>
              <Text className='title'>Quantity</Text>
              <Input description={quantity} fromQuantity dayId={dayId} />
            </Column>
            <Column className='date'>
              <Text className='title'>Sold</Text>
              <Text>{sold}</Text>
            </Column>
          </Row>
        </>
      )}
      {isEdit && (
        <MyPopover
          open={isEdit}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ModalDelete
            isOpen={isEdit}
            setIsOpen={setIsEdit}
            setIsConfirmation={setIsConfirmation}
            isTimeSlot={isTimeSlot}
          />
        </MyPopover>
      )}
      {isConfirmation && (
        <ModalDeleteConfirmation
          isOpen={isConfirmation}
          dayId={dayId}
          setIsOpen={setIsConfirmation}
          setIsCancel={setIsCancel}
          sold={sold}
          setIsOneSlot={setIsOneSlot}
          event_days={eventDaysArray.length}
        />
      )}
      {isCancel && (
        <ModalCancel
          setIsOpen={setIsCancel}
          isOpen={isCancel}
          isTimeSlot={isTimeSlot}
          setIsConfirmation={setIsConfirmation}
        />
      )}
      {isOneSlot && <ModalOneSlot setIsOpen={setIsOneSlot} isOpen={isOneSlot} />}
    </Column>
  );
};
