import { Input } from '../../../lib/components/Inputs/Input';
import { InputContainer } from './styles';

type Props = {
  name?: string;
  setName?: Function;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function OrganizationName({ name, setName, setIsOpen }: Props) {
  return (
    <>
      <InputContainer onClick={() => setIsOpen(true)}>
        <Input
          touched={null}
          placeholder='Organization Name'
          value={name}
          setValue={setName}
          id='email'
          disabled
        />
      </InputContainer>
    </>
  );
}
