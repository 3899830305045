import React from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';

import { modalStyle } from '../../WidgetCustomization/styles';
import { deleteTicketType } from './services';
import { queryClient } from '../../../App';
import { Container, Row, Button, Line, Text, Column } from './styles';
import { Link } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsBlur: React.Dispatch<React.SetStateAction<boolean>>;
  label?: string;
  title: string;
  isRemove?: boolean;
  id?: number;
  handleClick?: () => void;
  itemId?: number;
  isBeingSold?: boolean;
}

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  label,
  isRemove,
  title,
  id,
  setIsBlur,
  handleClick,
  isBeingSold,
  itemId,
}: Props) => (
  <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
    <Container>
      <Column className='top'>
        <Text>{title}</Text>
        <Text className='bold'>{label}</Text>
      </Column>
      <Line />
      <Row className='bottom'>
        <Button className='cancel' onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        {!isBeingSold ? (
          <Button className={isRemove ? 'remove' : ''} onClick={handleClick}>
            Yes,{isRemove ? ' remove' : ' Attach'}
          </Button>
        ) : (
          <Link to={`event-summary/${itemId}`} style={{ textDecoration: 'none' }}>
            <Button className={isRemove ? 'remove' : ''}>Yes,edit</Button>
          </Link>
        )}
      </Row>
    </Container>
  </Modal>
);

export default ConfirmationModal;
