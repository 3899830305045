import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import api, { getData } from '../../../api/api';
import { TicketPromoCode } from '../../../types';

interface AttachPromoCode {
  promoId: number;
  arrayTickets: TicketPromoCode[];
}

export const attachPromoCode = ({ promoId, arrayTickets }: AttachPromoCode) => {
  const newArrayTickets = arrayTickets.map(ticket => ({
    id: ticket.ID,
    quantity: Number(ticket.quantity),
  }));
  return api.post(`promocodes/${promoId}/attach`, newArrayTickets);
};

export const getPromoCode = (
  data,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  setPromoCodeForm
) => {
  const today = new Date();
  var startDate = new Date(data?.start_date);
  var endDate = new Date(data?.end_date);
  if (endDate.getFullYear() === 1970) endDate = today;
  setStartTime({
    date: startDate,
    hour: startDate.getHours(),
    min: startDate.getMinutes(),
    period: startTime.period,
  });
  setEndTime({
    date: endDate,
    hour: endDate.getHours(),
    min: endDate.getMinutes(),
    period: endTime.period,
  });
  setPromoCodeForm({
    code: data?.code,
    discount: data.discount,
    percentage: data?.percentage,
    coupon_type: data?.coupon_type,
    start_date: data?.start_date,
    end_date: data?.end_date,
    draft: data?.draft,
    active: data?.active,
    partner_id: data?.partner_id,
  });
};
