import { ChildrenContainer, Decorator, SquareWrapper } from './styles';

export enum SquareButtonVariants {
  'green' = 'green',
  'red' = 'red',
  'grey' = 'grey',
}

type Props = {
  children?: any;
  disabled?: boolean;
  onClick?: any;
  decoratorLeft?: any;
  decoratorRight?: any;
  variant?: SquareButtonVariants;
  isBlock?: boolean;
};

export default function SquareButton({
  children = null,
  disabled = false,
  isBlock = false,
  onClick = () => {},
  decoratorLeft = null,
  decoratorRight = null,
  variant = SquareButtonVariants.green,
}: Props) {
  let textSize = 16;
  return (
    <>
      <SquareWrapper
        onClick={onClick}
        variant={variant}
        textSize={textSize}
        isBlock={isBlock}
      >
        <Decorator>{decoratorLeft}</Decorator>
        <ChildrenContainer>{children}</ChildrenContainer>
        <Decorator>{decoratorRight}</Decorator>
      </SquareWrapper>
    </>
  );
}
