import React from 'react';
import { Modal } from '@mui/material';

import { Container, SuccessImage, Text } from '../style';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../../WidgetCustomization/styles';

import { successMessage } from '../../../static/img';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ConfirmSuccessModal = ({ isOpen, setIsOpen }: Props) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='twofactor'>
        <Row className='centerBorder'>
          <Title className='modal'>Confirm Identity</Title>
        </Row>
        <Row className='centerBorder overflow'>
          <Container className='modal'>
            <SuccessImage src={successMessage} />
            <Text className='two_factor'>Identity confirmed.</Text>
          </Container>
        </Row>
        <Row className='center'>
          <ButtonModal className='okay' onClick={() => setIsOpen(false)}>
            Dismiss
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
