import * as Yup from 'yup';

import {
	EMAIL,
	EMAIL_IS_INVALID,
	EMAIL_IS_REQUIRED,
	PASSWORD,
	PASSWORD_IS_REQUIRED,
} from '../../utils/strings';

export const LOGIN_FIELDS = {
	email: 'email',
	password: 'password',
};

export const LOGIN_INITIAL_VALUES = {
	email: '',
	password: '',
};

export const LoginSchema = Yup.object().shape({
	email: Yup.string().required(EMAIL_IS_REQUIRED).email(EMAIL_IS_INVALID),
	password: Yup.string().required(PASSWORD_IS_REQUIRED),
});

export const loginInputData = [
	{
		name: LOGIN_FIELDS.email,
		label: EMAIL,
		placeholder: 'john.smith@gmail.com',
	},
	{
		name: LOGIN_FIELDS.password,
		label: PASSWORD,
	},
];
