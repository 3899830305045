import styled from 'styled-components';
import ReactCodeInput from 'react-verification-code-input';

import {
  cellOne,
  darkgray,
  grayRGBA,
  grayRGBAopacity,
  green,
  headerCell,
  inputBackground,
  inputBackgroundSecondary,
  lightblack,
  lightGray,
  veryDarkGray,
  white,
} from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const ContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ReactCodeInputStyled = styled(ReactCodeInput)`
  width: 100% !important;
  &.custom-class {
    display: flex;
    flex-direction: row;
  }
  & input {
    color: white !important;
    background-color: ${inputBackgroundSecondary} !important;
    font-family: Open Sans;
    border-radius: 3.25104px !important;
    font-size: 40.39px;
    border-color: ${inputBackgroundSecondary} !important;
    height: 80px !important;
    margin-right: 8px;
  }
  & input:focus {
    border: 1px solid ${green};
    caret-color: ${green};
  }

  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;

export const ContainerText = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
`;
export const RadioButton = styled.div`
  margin-left: 15px;
  display: flex;
`;

export const ContainerCheckbox = styled.div<{ isSelect: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 12px;
  border: ${props => (props.isSelect ? '1px solid #59ce63' : '1px solid')};
  border-radius: 8px;
  width: 450px;
`;

export const TextWrapper = styled.div``;

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  margin-left: 40px;
  margin-right: 42px;
  padding: 15px;
  &.section {
    background-color: ${darkgray};
    margin-top: 5px;
  }
  &.section1 {
    display: flex;
    flex-direction: column;
  }
  &.icon {
    display: flex;
  }
  &.iconClose {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  &.char {
    margin-left: 10px;
  }
  &.title {
    margin-bottom: 10px;
  }
  &.error {
    font-size: 9px;
    margin-top: 5px;
    color: red;
  }
  &.subTitle {
    font-weight: 400;
    font-size: 16px;
  }
`;
export const WrapperElements = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputRefund = styled.input`
  background: ${inputBackgroundSecondary};
  border: 1px solid ${grayRGBAopacity};
  border-radius: 4px;
  width: 100px;
  height: 46px;
  outline: none;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  padding: 0 0 0 15px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.description {
    width: 280px;
  }

  &.gray {
    background: ${lightblack};
  }
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${white};
  padding: 7px 0px 7px 8px;
  &.notes {
    background-color: ${headerCell};
    width: 850px;
    margin: 20px;
    border-radius: 16px;
    height: 100px;
  }
  &.AddNotes {
    display: block;
    align-content: flex-start;
    text-align: start;
    background-color: ${headerCell};
    width: 90%;
    margin: 20px;
    border-radius: 16px;
    height: 100px;
    &::placeholder {
      height: 100px;
    }
  }
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  all: unset;
  width: 100%;
  color: ${white};
  padding: 7px 0px 7px 8px;
  &.notes {
    background-color: ${headerCell};
    width: 850px;
    border-radius: 16px;
    height: 100px;
  }
  &.AddNotes {
    display: block;
    align-content: flex-start;
    text-align: start;
    background-color: ${headerCell};
    border-radius: 16px;
    height: 100px;
    &::placeholder {
      height: 100px;
    }
  }
`;

export const ContainerNote = styled.div`
  position: relative;
  width: 857px;
  margin: 20px;
`;

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ModalContainer = styled.div`
  background: ${lightblack};
  border-radius: 4px;
  outline: none;
  height: 70vh;
  overflow-y: auto;
  &.Note {
    height: fit-content;
    width: 850px;
    margin: 20px;
    border-radius: 16px;
    background-color: ${headerCell};
  }
`;

export const RowModal = styled.div`
  display: flex;
  &.centerBorder {
    border-bottom: 1px solid ${grayRGBA};
    width: 850px;
    margin-left: 20px;
  }
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-bottom: 25px;
  &.titleNote {
    margin-top: 20px;
    margin-left: 20px;
  }
`;

export const ButtonFanDatabase = styled.button`
  width: 120px;
  padding: 0.5rem;
  margin-top: 4px;
  font-style: Open Sans;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.submit {
    background-color: ${green};
    border-radius: 0px 0px 16px 0px;
    color: white;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  &.cancel {
    border-radius: 0px;
    background-color: ${lightGray};
    color: white;
    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:hover {
      background-color: ${lightGray};
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: 18px;
  gap: 10px;

  button {
    font-size: 14px;
    color: ${lightGray};
    font-weight: 200px;
    width: 170px;
    background-color: ${cellOne};
    border: solid 1px ${veryDarkGray};
    margin: 0px;
  }
  &.ButtonOptions {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 25px;
  }
  &.table {
    width: 850px;
    gap: 2px;
  }
  &.addNotesButtons {
    position: absolute;
    right: -8px;
    bottom: 0px;
    gap: 0px;
  }
  &.addNotes {
    position: absolute;
    right: 0;
    bottom: 5px;
    gap: 0px;
  }
`;

export const ButtonRefund = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const InputContainer = styled.div`
  display: flex;
  background: ${inputBackground};
  border-radius: 10px;
  height: 36px;
  width: 332px;

  label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    display: block;
  }
`;

export const ContainerIcon = styled.div`
  width: 100%;
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  margin: 20px;
  &.containerIcon {
    display: flex;
    margin: 0px;
  }
  &.containerText {
    margin: 6px;
  }
  &.text {
    display: flex;
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: 0px;
  }
  &.history {
    margin: 0px;
  }

  &.modal {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  &.description {
    gap: 8px;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: -20px;

  &.text {
    margin-left: -16px;
  }
`;

export const ContainerCode = styled.div`
  display: flex;
  gap: 10px;
  &.pack {
    gap: 4px;
  }
`;
export const NumberCode = styled.input`
  width: 70px;
  height: 85px;
  background: ${inputBackgroundSecondary};
  border-radius: 3.25104px;
  font-size: 40px;
  text-align: center;
  border: 1px solid ${inputBackgroundSecondary};
`;
export const Line = styled.div`
  color: black;
  display: flex;
  align-items: center;
  font-size: 40px;
`;

export const TicketName = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-right: 15px;
  width: fit-content;
  margin-bottom: 15px;
  margin-left: 20px;
  &.title {
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    opacity: 0.7;
    margin-right: 0px;
  }
  &.two_factor {
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
    margin-left: 0px;
  }

  &.email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 8px;
    margin-left: 0px;
  }
  &.info {
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: -0.03em;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  &.titleElements {
    font-size: 20px;
    line-height: 22px;
    opacity: 0.7;
    margin-right: 0px;
  }
  &.subtitles {
    font-size: 18px;
  }
  &.subtitlesRefund {
    font-size: 25px;
    margin-left: 55px;
    color: ${lightGray};
  }
  &.notes {
    margin-left: 20px;
    font-size: 18px;
  }
  &.elementsNotes {
    margin-left: 20px;
    font-size: 16px;
  }
  &.title_section {
    margin-left: 40px;
  }
  &.titleRefund {
    font-size: 30px;
    font-weight: 300px;
  }
`;

export const Elements = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-size: 16px;
  font-weight: normal;
  line-height: 44px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  width: fit-content;
  margin-right: 0px;
  &.elements1 {
    flex: 3;
  }
  &.elements2 {
    font-size: 18px;
    flex: 12;
  }
  &.elements3 {
    flex: 3;
  }
`;
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Search = styled.img`
  width: 20px;
  margin-left: 8px;
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
`;

export const SuccessImage = styled.img`
  height: 150px;
  width: 150px;
  object-fit: contain;
  margin: 20px 0px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  &.column2 {
    flex: 3;
  }
  &.refund {
    padding: 20px 85px;
    border-bottom: 1px solid ${grayRGBA};
  }
  &.widthRefund {
    background: #1f1f1f;
    border-radius: 8px;
    padding: 20px;
  }
  &.widthContainer {
    width: 96%;
    background: #2d2d2d;
    border-radius: 4px;
    padding: 10px;
  }
`;
