import { useEffect, useRef } from 'react';

import Radio from '../../lib/components/Inputs/Radio/Radio';
import { RadioContainer, Line } from './styles';

type Props = {
  dateFilter?: any;
  setDateFilter: React.Dispatch<React.SetStateAction<any>>;
  isOpenDropdown?: boolean;
  setOpenDropdown: React.Dispatch<React.SetStateAction<any>>;
  setDateFilterOption: React.Dispatch<React.SetStateAction<any>>;
};

export const RadioOptions = ({
  dateFilter,
  setDateFilter,
  isOpenDropdown,
  setOpenDropdown,
  setDateFilterOption,
}: Props) => {
  const options = [
    { option: 'Today', value: 'today' },
    { option: 'Last Week', value: 'last_week' },
    { option: 'Last Month', value: 'last_month' },
    { option: 'Last Year', value: 'last_year' },
    { option: 'All time', value: 'all_time' },
  ];
  const container = useRef(null);

  useEffect(() => {
    options?.map(({ option, value }) => {
      if (value === dateFilter) {
        setDateFilterOption(option);
      }
    });
  }, [dateFilter, options]);

  useEffect(() => {
    if (isOpenDropdown && container.current) {
      container.current.focus();
    }
  }, [isOpenDropdown, container]);

  return (
    <RadioContainer ref={container} onMouseLeave={() => setOpenDropdown(false)}>
      {options?.map(({ option, value }) => (
        <>
          <Radio
            checked={dateFilter === value}
            text={option}
            textSize={14}
            padding={true}
            setChecked={() => setDateFilter(value)}
          />
          <Line />
        </>
      ))}
    </RadioContainer>
  );
};
