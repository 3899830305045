import React from 'react';
import { useMutation } from 'react-query';

import { put } from '../../api/api';
import {
  BRAND_COLOR,
  BRAND_COLOR_DESCRIPTION,
  PALETTE,
  PRIMARY,
  SECONDARY,
} from '../../utils/strings';
import {
  ContentContainer,
  Span,
  Text,
  Column,
  PaletteContainer,
  Row,
  AddImg,
  DeletePalette,
} from './styles';
import { plusIcon, editPalette, deletePalette } from '../../static/icons';
import { paletteBorder, transparent } from '../../utils/theme';
import { queryClient } from '../../App';

const palette = [PRIMARY, SECONDARY];
const partnerId = localStorage.getItem('partnerId');

interface BrandColorProps {
  setIsOpenPrimary: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenSecondary: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BrandColor = ({
  setIsOpenPrimary,
  setIsOpenSecondary,
}: BrandColorProps) => {
  const data: any = queryClient.getQueryData('getWidgetCustomization');

  const { mutate: handleMutation } = useMutation((color: string) =>
    put(`partners/${partnerId}/widget`, color)
  );

  const renderPalette = title => (
    <Column className='margin' key={title}>
      <Text className='palette'>
        {title} {PALETTE}
      </Text>
      <Column className='fit'>
        <PaletteContainer
          className='large'
          style={{
            backgroundColor:
              title === PRIMARY && data?.primary_brand_color !== ''
                ? data?.primary_brand_color
                : title === SECONDARY && data?.secondary_brand_color !== ''
                ? data?.secondary_brand_color
                : transparent,
            border:
              title === PRIMARY && data?.primary_brand_color === ''
                ? `1.5px dashed ${paletteBorder}`
                : title === SECONDARY && data?.secondary_brand_color === ''
                ? `1.5px dashed ${paletteBorder}`
                : 'none',
          }}
          onClick={() =>
            title === PRIMARY
              ? setIsOpenPrimary(true)
              : setIsOpenSecondary(true)
          }
        >
          {(title === PRIMARY && data?.primary_brand_color === '') ||
          (title === SECONDARY && data?.secondary_brand_color === '') ? (
            <AddImg src={plusIcon} alt='add' />
          ) : (
            <AddImg src={editPalette} alt='edit' />
          )}
        </PaletteContainer>
        {((title === PRIMARY && data?.primary_brand_color !== '') ||
          (title === SECONDARY && data?.secondary_brand_color !== '')) && (
          <Row className='margin'>
            <DeletePalette
              src={deletePalette}
              alt='delete'
              onClick={() => {
                if (title === PRIMARY) {
                  handleMutation({
                    primary_brand_color: '',
                  } as unknown as string);
                } else {
                  handleMutation({
                    secondary_brand_color: '',
                  } as unknown as string);
                }
              }}
            />
          </Row>
        )}
      </Column>
    </Column>
  );

  return (
    <ContentContainer>
      <Span>{BRAND_COLOR}</Span>
      <Text>{BRAND_COLOR_DESCRIPTION}</Text>
      <Row>{palette.map(title => renderPalette(title))}</Row>
    </ContentContainer>
  );
};
