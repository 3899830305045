import React from 'react';
import { Modal } from '@mui/material';

import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../../WidgetCustomization/styles';
import {
  ContainerCheckbox,
  ContainerInfo,
  ContainerText,
  RadioButton,
  TextWrapper,
  Text,
} from '../style';
import Radio from '../../../lib/components/Inputs/Radio/Radio';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRequiredPin: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPressButton: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSelectEmail: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectEmail?: boolean;
  index?: number;
  email?: string;
  ID?: number;
  phone_number?: string;
}

export const ConfirmIdentityModal = ({
  isOpen,
  setIsOpen,
  index,
  setIsRequiredPin,
  email,
  ID,
  setIsPressButton,
  setIsSelectEmail,
  isSelectEmail,
  phone_number,
}: Props) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='twofactor'>
        <Row className='centerBorder'>
          <Title className='modal'>Confirm Identity</Title>
        </Row>
        <Row className='centerBorder overflow'>
          <ContainerInfo>
            <Text className='two_factor'>Text or email a code to confirm the user’s identity.</Text>
            <ContainerText>
              <ContainerCheckbox isSelect={isSelectEmail}>
                <RadioButton>
                  <Radio
                    textSize={14}
                    setChecked={() => {
                      setIsSelectEmail(true);
                    }}
                    checked={isSelectEmail}
                  />
                </RadioButton>
                <TextWrapper>
                  <Text className='info'>Email</Text>
                  <Text className='email'>{email}</Text>
                </TextWrapper>
              </ContainerCheckbox>
              {phone_number && (
                <ContainerCheckbox isSelect={!isSelectEmail}>
                  <RadioButton>
                    <Radio
                      textSize={14}
                      setChecked={() => {
                        setIsSelectEmail(false);
                      }}
                      checked={!isSelectEmail}
                    />
                  </RadioButton>
                  <TextWrapper>
                    <Text className='info'>mobile number</Text>
                    <Text className='two_factor'>{phone_number}</Text>
                  </TextWrapper>
                </ContainerCheckbox>
              )}
            </ContainerText>
          </ContainerInfo>
        </Row>
        <Row className='center'>
          <ButtonModal className='cancel' onClick={() => setIsOpen(false)}>
            Cancel
          </ButtonModal>
          <ButtonModal
            className='okay'
            onClick={() => {
              setIsRequiredPin(true);
              setIsOpen(false);
              setIsPressButton(true);
            }}
          >
            Next
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
