import React from 'react';
import { Modal } from '@mui/material';

import { ButtonModal, ModalContainer, Row, Title, modalStyle } from '../WidgetCustomization/styles';
import { ContainerTitle, SuccessImage, Text } from '../Profile/styles';
import { email } from '../../static/img';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  index?: number;
}

export const ResetPasswordModal = ({ isOpen, setIsOpen, index }: Props) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='twofactor'>
        <Row className='centerBorder'>
          <Title className='modal'>Reset Password</Title>
        </Row>

        <Row className='centerBorder overflow'>
          <ContainerTitle className='image'>
            <SuccessImage src={email} />
            <Text className='two_factor'>
              We have sent this user an email with a link to reset their password.
            </Text>
          </ContainerTitle>
        </Row>
        <Row className='center'>
          <ButtonModal className='okay' onClick={() => setIsOpen(false)}>
            Dismiss
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
