import Icon from '../../Icon';
import { IconContainer, Wrapper } from './UploadButton.style';

export default function UploadButton() {
  return (
    <Wrapper>
      <IconContainer>
        <Icon height={25} icon='plus' />
      </IconContainer>
    </Wrapper>
  );
}
