import React from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';

import { DateBox, IconContainer } from '../../Inputs/DatePicker/styles';
import Icon from '../../Icon';
import { DateText, Row, DateContainer } from './styles';

interface Props {
  untilDate: Date;
  setUntilDate: React.Dispatch<React.SetStateAction<Date>>;
}

export const CalendarPicker = ({ untilDate, setUntilDate }: Props) => (
  <Row>
    <DateContainer>
      <DateText>{moment(untilDate).format('ll')}</DateText>
    </DateContainer>
    <DateBox className='picker height'>
      <IconContainer>
        <Icon icon='white-calendar' />
      </IconContainer>
      <DatePicker value={untilDate} onChange={setUntilDate} minDetail='month' />
    </DateBox>
  </Row>
);
