import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import NavLink from '../NavLink/NavLink';
import Logo from '../Logo';
import { Nav, ProfilePic, Wrapper, ProfileCircle, Column, SwitchTeamText } from './styles';
import SwitchTeams from '../../../../../pages/Profile/MembersSection/SwitchTeam/SwitchTeams';

export default function NavBar({ isAuthenticated }) {
  const history = useHistory();
  const [newRoute, setNewRoute] = useState(false);
  const [isTeamsOpen, setTeamsOpen] = useState(false);
  const selectedRoute = `/${window.location.pathname.split('/')[1]}`;

  useEffect(() => {
    setNewRoute(prevState => !prevState);
  }, [selectedRoute]);

  const options = [
    {
      name: 'event-list',
      to: '/event-list',
      icon: null,
      title: 'My Events',
    },
    {
      name: 'event-creation',
      to: '/event-creation',
      icon: null,
      title: 'Create Event',
    },
    {
      name: 'promo-code',
      to: '/promo-code',
      icon: null,
      title: 'Promo Codes',
    },
    {
      name: 'analytics',
      to: '/analytics',
      icon: null,
      title: 'Analytics',
    },
    {
      name: 'fan-database',
      to: '/fan-database',
      icon: null,
      title: 'Fan Database',
    },
    // TO DO when backend is ready
    // {
    //   name: 'billing-history',
    //   to: '/billing-history',
    //   icon: null,
    //   title: 'Billing History',
    // },
    {
      name: 'orders',
      to: '/orders',
      icon: null,
      title: 'Orders',
    },
    {
      name: 'widget-customization',
      to: '/widget-customization',
      icon: null,
      title: 'Settings',
    },
  ];

  return (
    <Wrapper>
      {!isAuthenticated && <Logo />}
      {isAuthenticated && selectedRoute !== '/organization' && (
        <Nav aria-label='main-navigation'>
          <Column className='team'>
            <ProfileCircle
              className={selectedRoute === '/profile' ? 'active' : ''}
              onClick={() => {
                setNewRoute(!newRoute);
                history.push('/profile');
              }}
            >
              <ProfilePic
                src={localStorage.getItem('partnerLogo')}
                onClick={() => {
                  setNewRoute(!newRoute);
                  history.push('/profile');
                }}
              />
            </ProfileCircle>

            <SwitchTeamText onClick={() => setTeamsOpen(true)}>Team </SwitchTeamText>
            {isTeamsOpen && <SwitchTeams setTeamsOpen={setTeamsOpen} />}
          </Column>
          {options.map(opt => (
            <div key={opt.name}>
              <NavLink
                key={opt.name}
                active={selectedRoute === opt.to}
                onClick={() => {
                  setNewRoute(!newRoute);
                  history.push(opt.to || '/');
                }}
              >
                {opt.title}
              </NavLink>
            </div>
          ))}
        </Nav>
      )}
    </Wrapper>
  );
}
