import styled from 'styled-components';

import { white } from '../../../../../utils/theme';
import { primaryFont } from '../../../../../utils/typography';

export const Container = styled.div<{ fromPopUp: boolean }>`
  background: #1b1b1b;
  border-radius: 10px;
  width: ${props => (props.fromPopUp ? '100%' : '50%')};
  display: flex;
  padding: 6px;
  margin-top: 20px;
  margin-bottom: 20px;
  gap: 10px;
`;

export const Img = styled.img``;

export const StyledInput = styled.input`
  all: unset;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  color: ${white};
`;
