import React, { useRef, useState } from 'react';

import { Row, Column } from '../../WidgetCustomization/styles';
import { Img, Text, UploadButton } from '../styles';
import { Input, Line } from './styles';
import { uploadIcon } from '../../../static/icons';
import { File } from '../../../types';

interface Props {
  setFiles: React.Dispatch<React.SetStateAction<Object[]>>;
  files: File[];
  index: number;
}

export const TermsItem = ({ setFiles, files, index }: Props) => {
  const [fileName, setFileName] = useState<string>('');
  const inputRef = useRef(null);

  const handlePdf = file => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let newFiles = [...files];
      newFiles[index].baseURL = reader.result;
      setFiles(newFiles);
    };
  };

  const handleUpload = e => {
    const files = e.target.files;
    setFileName(files[0].name);
    handlePdf(files[0]);
  };

  const handleChange = (e, key: any) => {
    let newFiles = [...files];
    newFiles[index][key] = e.target.value;
    setFiles(newFiles);
  };

  return !fileName ? (
    <>
      <Text className='modal'>Add via link</Text>
      <Input
        placeholder='https//:tixologi.com'
        value={files[index].link}
        onChange={e => handleChange(e, 'link')}
      />
      {!files[index].link ? (
        <>
          <Row className='terms'>
            <Line />
            <Text className='or'>OR</Text>
            <Line />
          </Row>
          <Text className='modal'>Upload</Text>
          <UploadButton onClick={() => inputRef.current.click()}>
            Upload
            <input
              type='file'
              id='pdf'
              ref={inputRef}
              onChange={handleUpload}
            />
            <Img className='trash' src={uploadIcon} />
          </UploadButton>
        </>
      ) : (
        <>
          <Text className='modal marginTop'>Name for file</Text>
          <Input
            placeholder='Example.pdf'
            value={files[index].fileName}
            onChange={e => handleChange(e, 'fileName')}
          />
          <Line className='uploaded' />
        </>
      )}
    </>
  ) : (
    <>
      <Row className='space'>
        <Column>
          <Text className='modal'>File to upload</Text>
          <Text className='pdf'>{fileName}</Text>
        </Column>
        <UploadButton onClick={() => inputRef.current.click()}>
          Browse...
          <input type='file' id='pdf' ref={inputRef} onChange={handleUpload} />
        </UploadButton>
      </Row>
      <Text className='modal marginTop'>Name for file</Text>
      <Input
        placeholder='Example.pdf'
        value={files[index].fileName}
        onChange={e => handleChange(e, 'fileName')}
      />
      <Line className='uploaded' />
    </>
  );
};
