import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import Step from './Step/Step';
import { ButtonContainer, Wrapper, Button, ButtonRight } from './styles';
import ActiveDaysForm from '../../lib/components/PromoCodes/ActiveDaysForm';
import ApplyToTicketsForm from '../../lib/components/PromoCodes/ApplyToTicketsForm';
import ProgressPromoCode from './ProgressPromoCode';
import SummaryPromoCode from '../../lib/components/PromoCodes/SummaryPromoCode';
import PromoCodeInformationForm from '../../lib/components/PromoCodes/PromoCodeInformationForm';
import { CANCEL, CONTINUE, CREATE, SAVE_AS_DRAFT } from '../../utils/strings';
import { getData, post, put } from '../../api/api';
import { TicketPromoCode } from '../../types';
import { attachPromoCode, getPromoCode } from '../../lib/components/PromoCodes/services';
import Icon from '../../lib/components/Icon';

const PromoCodeSteps = () => {
  const today = new Date();
  const {
    state: { active, ID, eventId, fromEvent, isEdit },
  }: any = useLocation();
  const history = useHistory();
  const [error, setError] = useState<boolean>();
  const partnerId = localStorage.getItem('partnerId');
  const [activeStep, setActiveStep] = useState(1);
  const [promoId, setPromoId] = useState<number>(ID);
  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<TicketPromoCode[]>([]);
  const { mutate: handleMutationTicket } = useMutation(attachPromoCode);
  const [startTime, setStartTime] = useState({
    date: today,
    hour: 12,
    min: 0,
    period: 'pm',
  });
  const [endTime, setEndTime] = useState({
    date: today,
    hour: 12,
    min: 0,
    period: 'pm',
  });

  useQuery('getPromo', () => getData(`promocodes/${ID}`), {
    onSuccess: data => {
      getPromoCode(data, startTime, setStartTime, endTime, setEndTime, setPromoCodeForm);
    },
    enabled: !!ID,
  });

  const [promoCodeForm, setPromoCodeForm] = useState({
    code: '',
    discount: 0,
    percentage: 0,
    coupon_type: 'flat',
    start_date: today,
    end_date: new Date('1/1/1970'),
    draft: true,
    active: true,
    partner_id: parseInt(partnerId),
  });

  const { mutate: handleMutation } = useMutation(
    (promoCodeForm: any) => {
      const newPromoCodesForm = {
        ...promoCodeForm,
        end_date: moment(promoCodeForm.end_date).format('L').includes('1970')
          ? moment.utc(`${moment(promoCodeForm.end_date).format('L')} 00:00`)
          : promoCodeForm.end_date,
      };
      return post(`promocodes`, { ...newPromoCodesForm, percentage: promoCodeForm.percentage });
    },
    {
      onSuccess: ({ ID }) => {
        setActiveStep(activeStep + 1);
        setPromoId(ID);
      },
      onError: () => {
        setError(true);
      },
    }
  );

  const { mutate: mutateCreatePromo } = useMutation((ID: number) => {
    const newPromoCodesForm = {
      ...promoCodeForm,
      end_date: moment(promoCodeForm.end_date).format('L').includes('1970')
        ? moment.utc(`${moment(promoCodeForm.end_date).format('L')} 00:00`)
        : promoCodeForm.end_date,
    };
    return put(`promocodes/${ID}`, {
      ...newPromoCodesForm,
      draft: false,
    });
  });

  const { mutate: mutationChange } = useMutation((ID: number) => {
    const newPromoCodesForm = {
      ...promoCodeForm,
      end_date: moment(promoCodeForm.end_date).format('L').includes('1970')
        ? moment.utc(`${moment(promoCodeForm.end_date).format('L')} 00:00`)
        : promoCodeForm.end_date,
    };
    return put(`promocodes/${ID}`, {
      ...newPromoCodesForm,
      percentage: promoCodeForm?.percentage,
    });
  });

  return (
    <Wrapper>
      <ProgressPromoCode value={activeStep} setValue={setActiveStep} activeStep={activeStep} />
      {activeStep === 1 ? (
        <Step
          value={activeStep}
          setValue={setActiveStep}
          canGoBack={true}
          label='Promo Code Information'
          component={
            <PromoCodeInformationForm
              value={promoCodeForm}
              setValue={setPromoCodeForm}
              error={error}
              setError={setError}
            />
          }
        />
      ) : activeStep === 2 ? (
        <Step
          label='Set Active Days'
          canGoBack={true}
          value={activeStep}
          setValue={setActiveStep}
          component={
            <ActiveDaysForm
              value={promoCodeForm}
              setValue={setPromoCodeForm}
              startTime={startTime}
              setStartTime={setStartTime}
              endTime={endTime}
              setEndTime={setEndTime}
            />
          }
        />
      ) : activeStep === 3 ? (
        <Step
          value={activeStep}
          setValue={setActiveStep}
          canGoBack={true}
          label='Select Tickets Types / Event'
          component={
            <ApplyToTicketsForm
              selectedEvents={selectedEvents}
              setSelectedEvents={setSelectedEvents}
              promoId={promoId}
              value={promoCodeForm}
              setValue={setPromoCodeForm}
              selectedTickets={selectedTickets}
              setSelectedTickets={setSelectedTickets}
              fromEvent={fromEvent}
              eventId={eventId}
            />
          }
        />
      ) : activeStep === 4 ? (
        <Step
          value={activeStep}
          setValue={setActiveStep}
          canGoBack={true}
          label='Summary/Preview'
          component={
            <SummaryPromoCode
              arrayTickets={selectedTickets}
              arrayEvents={selectedEvents}
              value={promoCodeForm}
              setValue={setPromoCodeForm}
            />
          }
        />
      ) : null}
      {activeStep > 0 && activeStep < 4 && (
        <>
          <ButtonContainer>
            <Button
              className='secondaryUpper'
              onClick={() => {
                history.push('/promo-code');
              }}
            >
              {CANCEL}
            </Button>
            <ButtonRight>
              <Button
                className='secondary'
                onClick={() => {
                  mutationChange(promoId);
                }}
              >
                {SAVE_AS_DRAFT}
              </Button>
              <Button
                className={
                  activeStep === 1 &&
                  (promoCodeForm?.code === '' ||
                    (promoCodeForm?.discount === 0 && promoCodeForm?.percentage === 0))
                    ? 'disable'
                    : 'continue'
                }
                onClick={() => {
                  if (activeStep === 1 && !isEdit) {
                    handleMutation(promoCodeForm);
                  } else {
                    mutationChange(promoId);
                    setActiveStep(activeStep + 1);
                  }
                  if (activeStep === 3) {
                    handleMutationTicket({ promoId, arrayTickets: selectedTickets });
                  }
                  if (activeStep === 1 && isEdit) {
                    mutationChange(promoId);
                    setActiveStep(activeStep + 1);
                  }
                }}
              >
                {CONTINUE}
                <Icon icon='arrow-continue' />
              </Button>
            </ButtonRight>
          </ButtonContainer>
        </>
      )}
      {activeStep === 4 && (
        <>
          <ButtonContainer>
            <Button
              className='secondaryUpper'
              onClick={() => {
                history.push('/promo-code');
              }}
            >
              {CANCEL}
            </Button>
            <ButtonRight>
              <Button
                className='secondary'
                onClick={() => {
                  mutationChange(promoId);
                }}
              >
                {SAVE_AS_DRAFT}
              </Button>
              <Button
                onClick={() => {
                  setPromoId(ID);
                  mutateCreatePromo(promoId);
                  history.push(`${fromEvent ? `/event-summary/${eventId}` : '/promo-code'}`);
                }}
              >
                {CREATE}
              </Button>
            </ButtonRight>
          </ButtonContainer>
        </>
      )}
    </Wrapper>
  );
};

export default PromoCodeSteps;
