import Select from 'react-select';
import { Country, State, City } from 'country-state-city';

import { DropDown } from './DropDown';
import { Input } from './Input';
import { Container, ContainerBox, customSelectStyles, Grid } from './EventAndVenueForm.styles';
import { Column, Label } from './TicketTypes/styles';

const venueTop = [
  { label: 'venue name', item: 'venue', placeholder: 'Name your venue' },
  { label: 'street address', item: 'primaryAddress', placeholder: 'Street address' },
  { label: 'secondary address', item: 'secondaryAddress', placeholder: 'Secondary address' },
];

const venueBottom = [
  { label: 'country', item: 'country', placeholder: 'Country' },
  { label: 'state', item: 'state', placeholder: 'State' },
  { label: 'city', item: 'city', placeholder: 'City' },
  { label: 'zip code', item: 'zip', placeholder: 'Code' },
];

export default function EventAndVenueForm({ value, setValue, error, setError }) {
  const updatedCountries = Country.getAllCountries().map(country => ({
    label: country.name,
    value: country.isoCode,
    ...country,
  }));
  const updatedStates = (countryId: any) =>
    State.getStatesOfCountry(countryId).map(state => {
      return {
        label: state.name,
        value: state.isoCode,
        ...state,
      };
    });
  const updatedCities = (countryId: any, stateId: any) =>
    City.getCitiesOfState(countryId, stateId).map(city => ({
      label: city.name,
      value: city.name,
      ...city,
    }));

  return (
    <Container>
      <ContainerBox>
        <Input
          label='event name'
          item='name'
          setValue={setValue}
          value={value}
          placeholder='Name your event'
          error={error}
          setError={setError}
        />
        <DropDown
          label='event type'
          item='type'
          setValue={setValue}
          value={value}
          placeholder='Select an event type'
          error={error}
          setError={setError}
        />
      </ContainerBox>
      <ContainerBox>
        {venueTop.map(({ label, item, placeholder }) => (
          <Input
            key={label}
            label={label}
            item={item}
            setValue={setValue}
            value={value}
            placeholder={placeholder}
            error={error}
            setError={setError}
          />
        ))}
        <Grid>
          {venueBottom.map(({ label, item, placeholder }) => {
            if (item === 'zip') {
              return (
                <Input
                  key={label}
                  label={label}
                  item={item}
                  setValue={setValue}
                  value={value}
                  placeholder={placeholder}
                  error={error}
                  setError={setError}
                />
              );
            } else {
              return (
                <Column className='bottom'>
                  <Label className='m'>{label}</Label>
                  <Select
                    id={label}
                    name={label}
                    options={
                      item === 'country'
                        ? updatedCountries
                        : item === 'state'
                        ? updatedStates(value.country ? value.country.value : null)
                        : value.state
                        ? updatedCities(value.country.value, value.state.value)
                        : updatedCities(null, null)
                    }
                    value={
                      item === 'country'
                        ? value.country
                        : item === 'state'
                        ? value.state
                        : value.city
                    }
                    onChange={newValue => {
                      setValue({ ...value, [item]: newValue });
                    }}
                    styles={customSelectStyles}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                </Column>
              );
            }
          })}
        </Grid>
      </ContainerBox>
    </Container>
  );
}
