import { v4 as uuid } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import Icon from '../../Icon';
import { Container, Label, Span, DropdownContainer, Button } from './styles';

export default function Dropdown({
  id = uuid(),
  options = [],
  value,
  setValue,
  placeholder = 'Select One',
  disabled = options.length <= 0,
  label = '',
  error,
}) {
  const [selected, setSelected] = useState(undefined);
  const [open, setOpen] = useState(false);
  const container = useRef(null);

  useEffect(() => {
    let selection = options.filter(o => o.value === value);
    if (selection[0] != null) {
      setSelected(selection[0]);
    } else {
      setSelected({ value: '', option: 'no selection' });
    }
  }, [options, value]);

  useEffect(() => {
    if (open && container.current) {
      container.current.focus();
    }
  }, [open, container]);

  return (
    <>
      <Label>{label.toUpperCase()}</Label>
      <Container
        ref={container}
        className={`${disabled ? 'disabled' : ''} ${value || open ? '' : 'selected'} ${
          error ? 'error' : ''
        }`}
        onClick={() => {
          if (!disabled) {
            setOpen(!open);
          }
        }}
        onMouseLeave={() => setOpen(false)}
      >
        <Span>{value ? selected?.label : placeholder}</Span>
        <Button className={error ? 'error' : ''}>
          <Icon
            icon={open ? 'white-triangle-up' : 'white-triangle-down'}
            height={20}
            className='fill-text'
          />
        </Button>
        {open && (
          <DropdownContainer>
            {options.map((option, i) => (
              <Span className='option' key={i} onClick={() => setValue(option.value)}>
                {option.label}
              </Span>
            ))}
          </DropdownContainer>
        )}
      </Container>
    </>
  );
}
