import styled from 'styled-components';
export const Wrapper = styled.button<{ active?: boolean }>`
  all: unset;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 1.5rem;
  color: var(--color-text);
  color: ${prop =>
    prop['active'] ? 'var(--color-primary-default)' : 'var(--color-text)'};
  text-decoration: none;

  &:hover {
    color: var(--color-primary-dark);
    cursor: pointer;
  }

  &:active {
    color: var(--color-primary-default);
  }
`;
