import { Button } from '../../lib/components/Button';
import { Background, SuccessWrapper, SuccessImage, Text } from './styles';
import { logoBackground, resetPasswordSuccess } from '../../static/img';
import useMobileView from '../../hooks/useMobileView';

export default function ResetPasswordSuccess() {
  const isMobile = useMobileView();
  const urlTixologist = `${process.env.REACT_APP_TIXOLOGIST_URL}`;

  return (
    <>
      <Background src={logoBackground} />
      <SuccessWrapper isMobile={isMobile}>
        <Text className={isMobile ? 'mobile' : ''}>New password confirmation</Text>
        <SuccessImage src={resetPasswordSuccess} />
        <div>Your new password was succesfully registered!</div>
        <Button onClick={() => (window.location.href = urlTixologist)}>Log In</Button>
      </SuccessWrapper>
    </>
  );
}
