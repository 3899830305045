import { TablePagination, Pagination } from '@mui/material';
import styled from 'styled-components';
import {
  white,
  headerCell,
  grayRGBA,
  inputBackground,
  green,
  greenBackground,
  redBackground,
  yellowBackground,
  transparent,
  lightGray,
} from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div``;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-left: 48px;
`;

export const PdfButton = styled.div`
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: ${green};
  padding: 8px 16px;
  justify-content: space-evenly;
  cursor: pointer;
`;

export const csvStyled = {
  display: 'flex',
  textDecoration: 'none',
};

export const modalStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ContainerTime = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
  border: 1px solid #434343;
  border-radius: 8px;
  background: #1f1f1f;
  width: 430px;
  height: 430px;
`;

export const ButtonModal = styled.div`
  background: ${green};
  border-radius: 100px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  padding: 15px 32px;
  cursor: pointer;
  &.cancel {
    padding: 15px 52px;
    margin-right: 10px;
    background: ${transparent};
    border: 1px solid ${grayRGBA};
    color: ${lightGray};
  }
  &.okay {
    padding: 15px 52px;
  }
  &.min {
    padding-left: 50px;
    padding-right: 50px;
  }
`;

export const TextPicker = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  opacity: 0.7;
  width: 100%;
  margin-top: 10px;
  cursor: default;
  color: ${white};
  font-family: ${primaryFont};

  &.small {
    font-size: 14px;
    text-transform: uppercase;
    color: ${white};
    height: 30px;
  }
  &.margin-bottom {
    margin-bottom: 10px;
  }
`;

export const Row = styled.div`
  width: 100%;
  height: 60px;
  background: ${headerCell};
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr 2fr 2fr;
  padding: 0px 48px 0px 48px;
  &.header {
    margin-top: 24px;
  }
  &.center {
    background: '';
  }
`;

export const RowTitle = styled.div`
  display: flex;
  &.space-between {
    justify-content: space-between;
    padding: 0px 40px;
  }
  &.center {
    align-items: center;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0px;
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: -0.03em;
  color: ${grayRGBA};

  &.header {
    font-weight: 600;
    color: ${white};
  }

  &.status {
    color: ${white};
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 12px;
  }
  &.filter {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${white};
  }
  &.rows {
    color: #888888;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #888888;
  }

  &.title {
    font-size: 25px;
    color: ${white};
    font-family: 'Open Sans';
    font-weight: 700;
    line-height: 12px;
  }

  &.filterBy {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  &.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 160px;
    margin-top: 17px;
    display: inline-block;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  background: ${inputBackground};
  border-radius: 10px;
  height: 36px;
  width: 332px;
  margin-left: 48px;
  margin-top: 28px;
`;

export const Input = styled.input`
  all: unset;
  width: 100%;
  color: ${white};
  padding: 7px 0px 7px 8px;
`;

export const InputRow = styled.input`
  border: 1px solid #888888;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #888888;
  width: 50px;
  height: 30px;
  background: ${inputBackground};
  font-family: ${primaryFont};
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Search = styled.img`
  width: 20px;
  margin-left: 8px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px;
  gap: 10px;
  width: 87px;
  height: 24px;
  margin-top: 14px;
  background: ${greenBackground};
  border-radius: 4px;
  &.cancelled {
    background: ${redBackground};
  }
  &.refunded {
    background: ${yellowBackground};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownContainer = styled.div`
  width: 128px;
  height: 40px;
  margin-right: 40px;
  cursor: pointer;
`;

export const FilterDropdown = styled.div`
  border: 1px solid ${white};
  border-radius: 8px;
  width: 112px;
  height: 35px;
  margin-top: 10px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;

export const RadioContainer = styled.div`
  border: 1px solid ${white};
  border-radius: 8px;
  width: 130px;
  margin-top: 5px;
  background: #292929;
  position: relative;
`;

export const Line = styled.div`
  width: 100%;
  border: 1px solid #5e5e5e;
  &.table {
    margin-top: 20px;
  }
`;

export const Img = styled.img``;

export const CustomizedTablePagination = styled(TablePagination)`
  .MuiTablePagination-select {
    color: ${white};
  }
  .MuiTablePagination-root {
    display: flex;
    justify-content: flex-end;
  }
  .MuiTablePagination-selectRoot {
    display: flex;
    justify-content: flex-end;
  }
  .MuiTablePagination-selectIcon {
    color: ${white};
  }

  .MuiTablePagination-actions {
    color: ${white};
  }
  .MuiButtonBase-root {
    color: ${white};
  }
`;

export const CustomizedPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    color: ${white};
  }
  .MuiPagination-ul {
    justify-content: flex-end;
  }
  .MuiButtonBase-root.Mui-selected {
    border: 1px solid #888888;
    border-radius: 4px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 45px;
  gap: 50px;
  margin-top: 60px;
`;
export const RowPerPage = styled.div`
  display: flex;
  gap: 20px;
`;
