import styled from 'styled-components';

import { cards } from '../../../../utils/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 570px;
  left: 213px;
  z-index: 90;
  background: ${cards};
  box-shadow: 0px 16px 16px 16px rgba(0, 0, 0, 0.27);
  border-radius: 16px;
`;
