import React from 'react';
import moment from 'moment';
import DatePicker from 'react-date-picker';

import { TicketsType } from '../../../../../types';
import { DateBox, IconContainer } from '../../../Inputs/DatePicker/styles';
import { calendarGray } from '../../../../../static/icons';
import { Column, Label, InputContainer, Img, Text } from '../styles';

interface Props {
  label: string;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
  checked: boolean;
  eventOnSaleTime: Date;
}

export const DateSelector = ({
  label,
  tickets,
  index,
  setTickets,
  checked,
  eventOnSaleTime,
}: Props) => (
  <Column className='left'>
    <Label className='s'>{label}</Label>
    <InputContainer>
      <Text>{moment(checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime).format('L')}</Text>
      <DateBox className='picker summary'>
        <IconContainer>
          <Img src={calendarGray} />
        </IconContainer>
        <DatePicker
          value={checked ? eventOnSaleTime : tickets[index].ticketOnSaleTime}
          onChange={value => {
            const time = moment(tickets[index].ticketOnSaleTime).format('LT');
            const dateArray = value.toString().split(' ');
            const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${time}`;
            let newTickets = [...tickets];
            newTickets[index].ticketOnSaleTime = new Date(finalDate);
            setTickets(newTickets);
          }}
          minDetail='month'
        />
      </DateBox>
    </InputContainer>
  </Column>
);
