import api from '../../../api/api';

interface EditFieldProps {
  id: number;
  item: string;
  value: string | Date;
}

interface EditSupportProps {
  eventId: number;
  supportId: number;
  contact: string;
}
interface EditQuantityProps {
  dayId: number;
  quantity: number;
}

export const editField = ({ id, item, value }: EditFieldProps) =>
  api.put(`events/${id}`, {
    [item]: value,
  });

export const editDay = ({
  eventId,
  dayId,
  valueStart,
  valueEnd,
}: {
  eventId: number;
  dayId: number;
  valueStart: Date | string;
  valueEnd: Date | string;
}) =>
  api.put(`/events/days/${dayId}`, {
    event_id: eventId,
    start_date: valueStart,
    end_date: valueEnd,
  });

export const editTime = ({
  eventId,
  dayId,
  value,
  item,
}: {
  eventId: number;
  dayId: number;
  value: moment.Moment;
  item: string;
}) =>
  api.put(`/events/days/${dayId}`, {
    event_id: eventId,
    [item]: value,
  });

export const editSupport = ({ eventId, supportId, contact }: EditSupportProps) =>
  api.put(`support/${supportId}`, {
    event_id: eventId,
    contact,
  });

export const createSupport = ({ eventId, contact }: { eventId: number; contact: string }) =>
  api.post(`support`, {
    event_id: eventId,
    contact,
  });

export const editQuantity = ({ dayId, quantity }: EditQuantityProps) =>
  api.put(`events/days/${dayId}`, {
    quantity,
  });

export const deleteSlot = ({ dayId }: { dayId: number }) => api.delete(`/events/days/${dayId}`);
