import styled from 'styled-components';

import { emailBackground, green, white } from '../../../utils/theme';
import { primaryFont } from '../../../utils/typography';

export const Button = styled.div`
  box-sizing: content-box;
  height: 1.75rem;
  width: 100%;
  padding: 0.5rem;
  margin-top: 4px;
  background-color: var(--color-bg-input);
  border: solid 2px var(--color-bg-input);
  color: var(--color-text-input);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  cursor: pointer;

  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  &.max-height {
    height: 48vh;
    min-height: 420px;
    overflow-y: auto;
    padding-right: 1rem;
  }
  &.width {
    width: 100%;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.gap {
    gap: 10px;
  }
`;
export const Wrapper = styled.div<{ isMultiday: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: ${props => (props.isMultiday ? 'scroll' : 'none')};
  width: 100%;
  max-height: ${props => (props.isMultiday ? '450px' : 'none')};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const InnerContainer = styled.div`
  background: rgba(22, 22, 22, 0.5);
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  &.margin-top {
    margin-top: 20px;
  }
  &.column {
    flex-direction: column;
  }
`;

export const CardContainer = styled.div`
  flex: 1 1 auto;
  max-height: 27rem;
  overflow: auto;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollButtonContainer = styled.div`
  margin-top: 0.5rem;
  width: 100%;
  position: sticky;
  bottom: 0;
  padding-top: 4rem;
  background: linear-gradient(#0000, 50%, var(--color-bg-2));
`;

export const SponsorCard = styled.div`
  background: rgba(22, 22, 22, 0.5);
  padding: 2rem;
  border-radius: 0.5rem;
  margin: 10px 0px;
  position: relative;
`;

export const RemoveSponsorCard = styled.div`
  cursor: pointer;
  position: absolute;
  padding: 0.25rem;
  top: 10px;
  right: 10px;

  &:hover {
    :global(svg) {
      fill: var(--color-danger-default);
      stroke: var(--color-danger-default);
    }
  }
`;

export const SponsorInput = styled.div`
  :global(input) {
    border-radius: 0.25rem;

    &:focus {
      border: 2px solid var(--color-info-default) !important;
    }
  }

  label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;
  }

  &:last-child > label {
    margin-top: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;

  :global(button + button) {
    margin-left: 1rem;
  }
`;

export const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  color: var(--color-info-default);

  &.margin-bottom {
    margin-bottom: 1.5rem;
  }
`;

export const Image = styled.img`
  width: 8rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.25rem;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

export const LoadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5rem auto;
  width: 8rem;
  margin: 0 0 0.5rem 0;
  border-radius: 0.25rem;
  aspect-ratio: 1 / 1;

  &.tall {
    margin: 10rem auto;
  }
`;

export const Label = styled.label`
  text-transform: uppercase;
  display: block;
  font-size: 14px;
  margin-bottom: 0.25rem;

  &.margin-top {
    margin-top: 0.5rem;
  }

  &.small {
    font-size: 12px;
  }
`;

export const TimeInput = styled.input`
  background: ${emailBackground};
  border-radius: 4px;
  border: none;
  padding: 14px 65px;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &.modal {
    padding: 10px 45px;
    text-align: center;
    color: ${white};
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
  &.small {
    padding: 10px 35px;
  }
`;

export const TimeButton = styled.div`
  background: ${emailBackground};
  border-radius: 4px;
  border: none;
  padding: 14px 65px;
  outline: none;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  cursor: pointer;
  &.modal {
    padding: 10px 45px;
    color: ${white};
  }
  &.small {
    padding: 10px 35px;
  }
  &.error {
    border: 1px solid var(--color-danger-default);
  }
`;

export const AddSponsor = styled.div`
  width: auto;
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: ${green};
  text-transform: uppercase;
  padding: 12px 0px;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
`;

export const Img = styled.img`
  width: 16px;
`;

export const Div = styled.div`
  &.w70 {
    width: 70%;
  }
`;
