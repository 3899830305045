import styled from 'styled-components';

import { MemberType } from '../../../../types';
import { Row } from './Row';

type Props = {
  openEditModal: Function;
  members: MemberType[];
  isAdmin: boolean;
};
export default function List({ openEditModal, members, isAdmin }: Props) {
  const rows = members?.map((member, index) => (
    <Row
      key={member?.MemberID}
      name={member?.User.full_name}
      index={index}
      role={member?.Roles?.[0]}
      openEditModal={openEditModal}
      member={member}
      isAdmin={isAdmin}
    />
  ));
  return <Wrapper>{rows}</Wrapper>;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
