import DatePicker from 'react-date-picker';

import { DateBox, Wrapper, IconContainer } from './styles';
import Icon from '../../Icon';

type Props = {
  date?: Date;
  setDate?: (date: Date) => void;
  className?: string;
  error?: boolean;
  activeDays?: boolean;
};

const twoDigits = (num: number) => ('0' + num).slice(-2);

export default function DatePickerComponent({
  date,
  setDate,
  className,
  error,
  activeDays,
}: Props) {
  return (
    <Wrapper className={className}>
      <DateBox className={error ? 'error' : ''}>{twoDigits(date.getMonth() + 1)}</DateBox>
      <DateBox className={error ? 'error' : ''}>{twoDigits(date.getDate())}</DateBox>
      <DateBox className={error ? 'error' : ''}>{date.getFullYear()}</DateBox>
      <DateBox className='picker'>
        <IconContainer>
          <Icon icon={activeDays ? `${'white-calendar'}` : `${'green-calendar'}`} />
        </IconContainer>
        <DatePicker value={date} onChange={setDate} minDetail='month' />
      </DateBox>
    </Wrapper>
  );
}
