import React from 'react';

import { TicketsType } from '../../../../types';
import { ExistingPCRow } from './ExistingPCRow';
import { Wrapper } from './styles';

interface Props {
  promoCodes: any[];
  search?: string;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  index: number;
}

const ExistingPromoCodes = ({ promoCodes, search, tickets, setTickets, index }: Props) => (
  <Wrapper className='existingPC'>
    {promoCodes
      ?.filter(item => item.code.includes(search))
      .map(({ code, end_date, discount, ID }) => (
        <ExistingPCRow
          name={code}
          expire={end_date}
          discount={discount}
          setTickets={setTickets}
          tickets={tickets}
          id={ID}
          attachedIndex={tickets[index].attachedPromoCodes.findIndex(item => item.ID === ID)}
          index={index}
        />
      ))}
  </Wrapper>
);

export default ExistingPromoCodes;
