import React from 'react';

import {
  ADD_SECONDARY,
  DEFAULT_ROYALITIES,
  SECONDARY_ROYALITY,
} from '../../utils/strings';
import { Row } from '../WidgetCustomization/styles';
import { Column, Title, AddInfoButton, Text, Image } from './styles';
import { editPencil } from '../../static/icons';

interface Props {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  royalty: string;
}

export const Royalties = ({ setIsOpen, royalty }: Props) => (
  <Column className='wrapper'>
    <Title>{DEFAULT_ROYALITIES}</Title>
    {royalty === '-1' ? (
      <AddInfoButton onClick={() => setIsOpen(true)}>
        {ADD_SECONDARY}
      </AddInfoButton>
    ) : (
      <Column>
        <Text className='subTitle'>{SECONDARY_ROYALITY}</Text>
        <Row>
          <Text>{royalty} %</Text>
          <Image src={editPencil} alt='edit' onClick={() => setIsOpen(true)} />
        </Row>
      </Column>
    )}
  </Column>
);
