import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { modalStyle } from '../../../WidgetCustomization/styles';
import TicketTypeForm from '../../../../lib/components/EventCreation/TicketTypes';
import { TicketsType } from '../../../../types';
import { ExistingPCPopup } from '../../PromoCodes/ExistingPCPopup';
import { attachPromoCode, createTickets, editTicket } from '../../../EventCreation/services';
import { queryClient } from '../../../../App';
import { Column, Button, Title, Line, Row, Container } from './styles';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  ticket?: any;
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  eventOnSaleTime: Date;
}

const TicketTypeModal = ({
  isOpen,
  setIsOpen,
  ticket,
  tickets,
  setTickets,
  eventOnSaleTime,
}: Props) => {
  const [isExisting, setIsExisting] = useState<boolean>(false);
  const [ticketError, setTicketError] = useState<boolean[]>([]);
  const { id }: any = useParams();

  const { mutate: mutateAttachPromoCode } = useMutation(attachPromoCode);

  const { mutate: mutateEditTicket } = useMutation(editTicket, {
    onSuccess: ({ data }) => {
      tickets.map(ticket =>
        ticket.attachedPromoCodes.map(pc =>
          mutateAttachPromoCode({
            promoCodeID: pc.ID,
            quantity: Number(pc.quantity),
            ticketTypeID: data.ID,
          })
        )
      );
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        const newTicketTypes = oldData.ticket_types.map(ticket => {
          if (ticket.ID === data.ID) {
            ticket.name = data.name;
            ticket.initial_price = data.initial_price;
            ticket.amount = data.amount;
            ticket.holds_amount = data.holds_amount;
            ticket.description = data.description;
            ticket.template_id = data.template_id;
            ticket.secondary_royalty = data.secondary_royalty;
            ticket.selling_time = new Date(data.selling_time);
          }
          return ticket;
        });
        return {
          ...oldData,
          ticket_types: newTicketTypes,
        };
      });
      setIsOpen(false);
    },
  });

  const { mutate: mutateCreateTicket } = useMutation(createTickets, {
    onSuccess: ({ data }) => {
      tickets.map((ticket, index) =>
        ticket.attachedPromoCodes.map(pc =>
          mutateAttachPromoCode({
            promoCodeID: pc.ID,
            quantity: Number(pc.quantity),
            ticketTypeID: data[index].ID,
          })
        )
      );
      queryClient.setQueryData(['getEvent'], (oldData: any) => ({
        ...oldData,
        ticket_types: [
          ...oldData.ticket_types,
          {
            name: data[0].name,
            initial_price: data[0].initial_price,
            amount: data[0].amount,
            holds_amount: data[0].holds_amount,
            description: data[0].description,
            template_id: data[0].template_id,
            secondary_royalty: data[0].secondary_royalty,
            selling_time: new Date(data[0].selling_time),
            Promocodes: [],
          },
        ],
      }));
      setIsOpen(false);
    },
  });

  const handleFinish = () => {
    setTicketError(tickets.map(({ templateId }) => !templateId));
    tickets.every(({ templateId }) => !!templateId) &&
      mutateCreateTicket({ tickets, eventId: Number(id) });
  };

  const handleSave = () => {
    if (!!ticket) mutateEditTicket({ tickets, eventId: Number(id), ticketTypeID: ticket.ID });
    else handleFinish();
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <Container>
        <Column>
          <Row className='center top'>
            <Title>{ticket?.name || 'Add new ticket type'}</Title>
          </Row>
          <Line />
          <TicketTypeForm
            index={0}
            tickets={tickets}
            setTickets={setTickets}
            setIsExisting={setIsExisting}
            ticketError={ticketError}
            eventOnSaleTime={eventOnSaleTime}
            ticketTypeId={ticket?.ID}
          />
          <Line />
          <Row className='center bottom'>
            <Button className='cancel' onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button onClick={handleSave}>{!!ticket ? 'Save Changes' : 'Add Ticket Type'}</Button>
          </Row>
        </Column>
        {isExisting && (
          <ExistingPCPopup
            setIsOpen={setIsExisting}
            setTickets={setTickets}
            tickets={tickets}
            index={0}
          />
        )}
      </Container>
    </Modal>
  );
};

export default TicketTypeModal;
