import styled from 'styled-components';

export const Wrapper = styled.button`
  position: relative;
  top: 40px;
  left: 60px;
  width: 45px;
  height: 45px;

  background: var(--color-primary-default);
  border-radius: 50%;
  margin-right: 9px;
  margin-top: 9px;

  &:hover {
    background: var(--color-primary-dark);
  }
`;

export const IconContainer = styled.div`
  margin: auto;
`;
