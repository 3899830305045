import styled from 'styled-components';

export const Tab = styled.div`
  display: flex;
  border-bottom: 1px solid #474747;
  padding: 16px 40px;
  align-items: center;
  gap: 16px;
`;

export const Header = styled.div`
  font-size: 30px;
`;

export const Drop = styled.div`
  box-sizing: border-box;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  padding: 18px 40px;
`;
