import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useMutation } from 'react-query';

import { Input } from '../../lib/components/Inputs/Input';
import { Background, ErrorMessage, Form, Wrapper, Text } from './styles';
import { logoBackground } from '../../static/img';
import {
  forgotPasswordInputData,
  ForgotPasswordSchema,
  FORGOT_PASSWORD_INITIAL_VALUES,
} from './utils';
import { reset } from '../../stores/userSlice';
import { post } from '../../api/api';
import EmailSentModal from './EmailSentModal';
import Buttons from './Buttons';
import useLoading from '../../hooks/useLoading';
import { Div, Loading } from '../../lib/components/layout/Navigation/Navbar/styles';
import { loading } from '../../static/animations';
import useMobileView from '../../hooks/useMobileView';

export default function Login() {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const isAuthenticated = localStorage.getItem('accessToken');
  const isMobile = useMobileView();

  const {
    mutate: sendResetPasswordEmail,
    isSuccess,
    isError,
  } = useMutation(() =>
    post('/public/users/forgot', {
      email: values.email,
    })
  );

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: FORGOT_PASSWORD_INITIAL_VALUES,
    validationSchema: ForgotPasswordSchema,
    onSubmit: () => sendResetPasswordEmail(),
  });

  useEffect(() => {
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  return isSuccess ? (
    <EmailSentModal />
  ) : (
    <>
      <Background src={logoBackground} />
      <Wrapper isMobile={isMobile}>
        <Text isMobile={isMobile}>Forgot password?</Text>
        <Form isMobile={isMobile}>
          <div>We'll rather send you an email to reset your password.</div>
          <div>Please enter the email associated with your account.</div>
          {forgotPasswordInputData.map(({ placeholder, name, label }) => (
            <Input
              value={values[name]}
              setValue={
                handleChange(name) as unknown as (e: string | React.ChangeEvent<any>) => void
              }
              id={name}
              placeholder={placeholder}
              label={label}
              type='text'
              error={errors[name]}
              touched={touched[name]}
            />
          ))}
          <Buttons isLoading={isLoading} handleSubmit={handleSubmit} />
          <ErrorMessage>{isError && 'Request failed'}</ErrorMessage>
        </Form>
      </Wrapper>
      {!!isLoading && (
        <>
          <Div />
          <Loading src={loading} alt='loading...' />
        </>
      )}
    </>
  );
}
