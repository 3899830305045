import React, { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import Select from 'react-select';
import moment from 'moment';

import { TimeInput, TimeButton } from '../../../../lib/components/EventCreation/styles';
import { TZ_LIST } from '../../../../utils/time';
import { editField, editTime } from '../services';
import { queryClient } from '../../../../App';
import { Wrapper, Title, Span, customSelectStyles, Row, Button } from './styles';

interface Props {
  title: string;
  timezone: string;
  eventId: number;
  description: string;
  hour: string;
  min: string;
  period: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  item: string;
  dayId: number;
  index: number;
}

const Modal = ({
  title,
  timezone,
  eventId,
  description,
  hour,
  min,
  period,
  setIsOpen,
  item,
  dayId,
  index,
}: Props) => {
  const [timeZone, setTimeZone] = useState<any>({ label: timezone, value: timezone });
  const [timezoneChanged, setTimezoneChanged] = useState(false);
  const [timeChanged, setTimeChanged] = useState(false);
  const [time, setTime] = useState({
    hour: Number(hour),
    min: Number(min),
    period,
  });
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const { mutate: mutateEdit } = useMutation(editField);
  const { mutate: mutateEditTime } = useMutation(editTime);

  const handleTime = () => {
    const { hour, min, period } = time;
    const newTime = `${hour}:${min} ${period}`;
    const dateArray = new Date(description).toString().split(' ');
    const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;

    if (timezoneChanged) mutateEdit({ id: eventId, value: timeZone.value, item: 'time_zone' });
    if (timeChanged)
      mutateEditTime({
        value: moment.utc(
          `${moment(new Date(finalDate)).format('L')} ${moment(new Date(finalDate)).format('LT')}`
        ),
        eventId,
        dayId,
        item,
      });

    queryClient.setQueryData(['getEvent'], (oldData: any) => ({
      ...oldData,
      event_days: oldData.event_days.map((element, i) => {
        if (index === i) {
          element[item] = moment.utc(
            `${moment(new Date(finalDate)).format('L')} ${moment(new Date(finalDate)).format('LT')}`
          );
        }
        return element;
      }),
    }));
    setTimeChanged(false);
    setTimeChanged(false);
  };

  return (
    <Wrapper onClick={e => e.preventDefault()} ref={ref}>
      <Title>{title}</Title>
      <Span>Timezone</Span>
      <Select
        value={timeZone}
        onChange={value => {
          setTimezoneChanged(true);
          setTimeZone(value);
        }}
        options={TZ_LIST}
        styles={customSelectStyles}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
      <Span>{title}</Span>
      <Row className='space'>
        <TimeInput
          className='modal'
          value={time.hour}
          onChange={e => {
            setTimeChanged(true);
            setTime({ ...time, hour: Number(e.target.value) });
          }}
          type='number'
          min={1}
          max={12}
          id='event-start-time-hour'
        />
        <TimeInput
          className='modal'
          value={time.min}
          onChange={e => {
            setTimeChanged(true);
            setTime({ ...time, min: Number(e.target.value) });
          }}
          type='number'
          min={0}
          max={59}
          id='event-start-time-minute'
        />
        <TimeButton
          className='modal'
          id='event-start-time-period'
          onClick={() => {
            setTimeChanged(true);
            setTime({
              ...time,
              period: time.period === 'am' ? 'pm' : 'am',
            });
          }}
        >
          {time.period}
        </TimeButton>
      </Row>
      <Row className='mTop center'>
        <Button className='cancel' onClick={() => setIsOpen(false)}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleTime();
            setIsOpen(false);
          }}
        >
          Confirm
        </Button>
      </Row>
    </Wrapper>
  );
};

export default Modal;
