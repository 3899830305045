import styled from 'styled-components';

import { borderLine, darkgray, white } from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Wrapper = styled.div`
  &.infoDate {
    display: flex;
    margin-top: 20px;
    gap: 20px;
  }
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
`;

export const RowContainer = styled.div`
  border-bottom: 1px solid ${borderLine};
  width: 100%;
  padding: 18px 48px;
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  padding: 0px 48px;
`;

export const Span = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
`;

export const Information = styled.div`
  flex-wrap: wrap;
  display: flex;
  gap: 90px;
  background-color: ${darkgray};
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  margin-top: 4px;
  &.title {
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.03em;
    text-transform: uppercase;
    opacity: 0.7;
    margin-top: 20px;
    margin-left: 45px;
  }
  &.infoDateText {
    font-size: 16px;
  }
  &.elements {
    margin-left: 45px;
    margin-bottom: 20px;
  }
  &.titleIcon {
    margin-right: 12px;
  }
`;
