import moment from 'moment';

import { promoCodeFormType, TimeType } from '../../../../../../types';
import { END_DATE, END_TIME, START_DATE, START_TIME } from '../../../../../../utils/strings';
import { DatePickerComponent } from '../../../../Inputs/DatePicker';
import {
  Text,
  DateContainer,
  TimeInput,
  TimeButton,
  ContainerTime,
} from '../../../../PromoCodes/styles';

type Props = {
  value: promoCodeFormType;
  setValue: Function;
  startTime: TimeType;
  setStartTime: Function;
  endTime: TimeType;
  setEndTime: Function;
};

const DaysAndHoursForm = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  value,
  setValue,
}: Props) => (
  <ContainerTime>
    <Text className='small margin-bottom'>{START_DATE}</Text>
    <DatePickerComponent
      activeDays={true}
      date={startTime.date}
      setDate={(newDate: Date) => {
        const { hour, min, period } = startTime;
        const newTime = `${hour}:${min} ${period}`;
        const dateArray = new Date(newDate).toString().split(' ');
        const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
        setStartTime({ ...startTime, date: newDate });
        setValue({ ...value, start_date: new Date(finalDate) });
      }}
    />
    <Text className='small margin-bottom'>{START_TIME}</Text>
    <DateContainer id='event-start-time'>
      <TimeInput
        value={startTime.hour}
        onChange={e => {
          const { date, min, period } = startTime;
          const newTime = `${e.target.value}:${min} ${period}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            start_date: new Date(finalDate),
          });
          setStartTime({
            ...startTime,
            hour: Number(e.target.value),
          });
        }}
        type='number'
        min={1}
        max={12}
        id='event-start-time-hour'
        className='modal'
      />
      <TimeInput
        value={startTime.min}
        onChange={e => {
          const { date, hour, period } = startTime;
          const newTime = `${hour}:${e.target.value} ${period}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            start_date: new Date(finalDate),
          });
          setStartTime({ ...startTime, min: Number(e.target.value) });
        }}
        type='number'
        min={0}
        max={59}
        id='event-start-time-minute'
        className='modal'
      />
      <TimeButton
        id='event-start-time-period'
        onClick={() => {
          const { date, hour, min } = startTime;
          const newTime = `${hour}:${min} ${startTime.period === 'am' ? 'pm' : 'am'}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            start_date: new Date(finalDate),
          });
          setStartTime({
            ...startTime,
            period: startTime.period === 'am' ? 'pm' : 'am',
          });
        }}
        className='modal'
      >
        {startTime.period}
      </TimeButton>
      <div style={{ height: 47, width: 31, marginRight: 24 }} />
    </DateContainer>
    <Text className='small margin-bottom'>{END_DATE}</Text>
    <DatePickerComponent
      activeDays={true}
      date={endTime.date}
      setDate={(newDate: Date) => {
        const { hour, min, period } = endTime;
        const newTime = `${hour}:${min} ${period}`;
        const dateArray = new Date(newDate).toString().split(' ');
        const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
        setEndTime({ ...endTime, date: newDate });
        setValue({ ...value, end_date: new Date(finalDate) });
      }}
    />

    <Text className='small margin-bottom'>{END_TIME}</Text>
    <DateContainer id='event-end-time'>
      <TimeInput
        value={endTime.hour}
        onChange={e => {
          const { date, hour, min, period } = endTime;
          const newTime = `${e.target.value}:${min} ${period}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            end_date: new Date(finalDate),
          });
          setEndTime({ ...endTime, hour: Number(e.target.value) });
        }}
        type='number'
        min={1}
        max={12}
        id='event-end-time-hour'
        className='modal'
      />
      <TimeInput
        value={endTime.min}
        onChange={e => {
          const { date, hour, period } = endTime;
          const newTime = `${hour}:${e.target.value} ${period}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            end_date: new Date(finalDate),
          });
          setEndTime({ ...endTime, min: Number(e.target.value) });
        }}
        type='number'
        min={0}
        max={59}
        id='event-end-time-minute'
        className='modal'
      />
      <TimeButton
        id='event-end-time-period'
        onClick={() => {
          const { date, hour, min } = endTime;
          const newTime = `${hour}:${min} ${startTime.period === 'am' ? 'pm' : 'am'}`;
          const dateArray = new Date(date).toString().split(' ');
          const finalDate = `${dateArray[0]} ${dateArray[1]} ${dateArray[2]} ${dateArray[3]} ${newTime}`;
          setValue({
            ...value,
            end_date: new Date(finalDate),
          });
          setEndTime({
            ...endTime,
            period: endTime.period === 'am' ? 'pm' : 'am',
          });
        }}
        className='modal'
      >
        {endTime.period}
      </TimeButton>
      <div style={{ height: 47, width: 31, marginRight: 24 }} />
    </DateContainer>
  </ContainerTime>
);

export default DaysAndHoursForm;
