import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import moment from 'moment';

import { EVENT_SUMMARY } from '../../utils/strings';
import { getData } from '../../api/api';
import { arrowRight } from '../../static/icons';
import { EventInformation } from './EventInformation/EventInformation';
import { TicketTypes } from './TicketTypes/TicketTypes';
import { Wrapper, Title, Row, Img } from './EventInformation/styles';
import PromoCodes from './PromoCodes';
import Sponsors from './Sponsors';

const subTitles = ['Event information', 'Ticket Types', 'Promo Codes', 'Event Sponsors'];

const EventSummary = () => {
  const [selected, setSelected] = useState('Event information');
  const { id }: any = useParams();

  const { data: event } = useQuery('getEvent', () =>
    getData(`events/${id as string}?promocode=true`)
  );

  const { data: support } = useQuery('getSupport', () => getData(`/support/events/${id}`));

  const renderEventSummary = () => {
    switch (selected) {
      case 'Event information':
        return <EventInformation event={event} support={support} />;
      case 'Ticket Types':
        return (
          <TicketTypes
            ticketTypes={event.ticket_types}
            eventOnSaleTime={new Date(event.selling_time)}
          />
        );
      case 'Promo Codes':
        return <PromoCodes ticketTypes={event.ticket_types} eventId={event.ID} />;
      case 'Event Sponsors':
        return <Sponsors sponsors={event.sponsors} />;
      default:
        return <EventInformation event={event} support={support} />;
    }
  };

  return (
    <Wrapper>
      <Row>
        <Title>{EVENT_SUMMARY}</Title>
        <Img className='arrow' src={arrowRight} />
        <Title className='middle'>
          {moment(event?.event_days[0].start_date).format('MMMM YYYY')}
        </Title>
        <Img className='arrow' src={arrowRight} />
        <Title className='right'>{event?.name}</Title>
      </Row>
      <Row className='borderBottom'>
        {subTitles.map(item => (
          <Title
            className={`subTitle ${item === selected ? 'focus' : ''}`}
            onClick={() => setSelected(item)}
          >
            {item}
          </Title>
        ))}
      </Row>
      {event && renderEventSummary()}
    </Wrapper>
  );
};

export default EventSummary;
