import { Column } from '../../../../pages/Profile/styles';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
} from '../../../../pages/WidgetCustomization/styles';
import { uploadIcon } from '../../../../static/icons';
import { tixologiAvatar } from '../../../../static/img';
import { CANCEL } from '../../../../utils/strings';
import { Circle } from '../styles';
import {
  Block,
  ButtonWrap,
  DeleteFileButton,
  Image,
  SelectFileButton,
  Img,
  Avatar,
} from './UploadImage.style';

type Props = {
  image?: any;
  onSuccess?: () => void;
  onCancel?: () => void;
  removeFile?: () => void;
  handleInput?: (e: any) => void;
};

export const UploadImage = ({
  image,
  onSuccess,
  onCancel,
  removeFile,
  handleInput,
}: Props) => (
  <ModalContainer>
    <Row className='centerBorder'>
      <Title className='modal'>Upload A Profile Image</Title>
    </Row>
    <Column className='centerBorder'>
      {image ? (
        <Circle>
          <Image src={image} />
        </Circle>
      ) : (
        <Avatar src={tixologiAvatar} alt='avatar' />
      )}

      <Block>
        {image && (
          <DeleteFileButton onClick={removeFile}>Remove</DeleteFileButton>
        )}
        <ButtonWrap>
          <SelectFileButton htmlFor='uploadAvatar'>
            Upload Image
            <Img src={uploadIcon} />
          </SelectFileButton>
          <input
            id='uploadAvatar'
            type='file'
            style={{ display: 'none' }}
            onChange={handleInput}
          />
        </ButtonWrap>
      </Block>
    </Column>
    <Row className='center'>
      <ButtonModal
        className='cancel'
        onClick={() => {
          onCancel();
          removeFile();
        }}
      >
        {CANCEL}
      </ButtonModal>
      <ButtonModal
        className='min'
        onClick={() => {
          onSuccess();
          onCancel();
        }}
      >
        Confirm
      </ButtonModal>
    </Row>
  </ModalContainer>
);
