import React from 'react';
import { Modal } from '@mui/material';

import { Container, SuccessImage } from '../style';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
} from '../../WidgetCustomization/styles';

import { refundSuccess } from '../../../static/img';

interface Props {
  isOpen: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
}

export const RefundSuccessModal = ({ isOpen, setIsOpen, name }: Props) => {
  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer className='twofactor'>
        <Row className='centerBorder'>
          <Title className='modal'>Refund Tickets</Title>
        </Row>
        <Row className='centerBorder overflow'>
          <Container className='modal'>
            <SuccessImage src={refundSuccess} />
            <Title className='modal'>Refund tickets succesfully!</Title>
            <Title className='modalSubtitle'>
              The user {name} will recieve an email with the following steps for refunding tickets
              properly.
            </Title>
          </Container>
        </Row>
        <Row className='center'>
          <ButtonModal className='okay' onClick={() => setIsOpen(false)}>
            Back to list
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
