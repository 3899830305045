import styled from 'styled-components';

import { green, white } from '../../../../../../utils/theme';
import { primaryFont } from '../../../../../../utils/typography';

export const Container = styled.div`
  background: #1f1f1f;
  border-radius: 4px;
  padding: 13px 24px;
  margin-bottom: 10px;
`;

export const Row = styled.div`
  display: flex;
  &.width {
    width: 100%;
  }
  &.margin-bottom {
    margin-bottom: 10px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: ${white};
  opacity: 0.7;
  margin-bottom: 10px;
`;
export const StyledInput = styled.input`
  all: unset;
  background: #080808;
  border: 1px solid rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  padding: 11px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  &.center {
    text-align: center;
  }
  &.margin-bottom {
    margin-bottom: 10px;
  }
`;

export const Button = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  background: #494949;
  border-radius: 0px 100px 100px 0px;
  padding: 10px;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  &.active {
    background: ${green};
  }
  &.left {
    border-radius: 100px 0px 0px 100px;
  }
`;

export const QuantityInput = styled.input`
  outline: none;
  background: #525252;
  border-radius: 4px 0px 0px 4px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color: ${white};
  border: none;
  width: 50%;
  padding: 11px;
`;

export const QuantityButton = styled.div<{ selected: boolean }>`
  cursor: pointer;
  background: #2e2e2e;
  border: ${props => (props.selected ? `1px solid ${white}` : 'none')};
  border-radius: 0px 4px 4px 0px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: -0.03em;
  color: ${white};
  opacity: 0.5;
  width: 50%;
  padding: 11px;
`;
