import styled from 'styled-components';

export const Background = styled.img`
  display: flex;
  height: 75vh;
  object-fit: contain;
  align-self: flex-end;
`;

export const PartnerLogo = styled.img`
  display: flex;
  height: 100px;
  width: 100px;
  object-fit: cover;
  align-self: center;
  border-radius: 100%;
  margin: 10px;
`;

export const Wrapper = styled.section`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url('../static/img/multi-ticket-background.png');
  background-size: 100vw;
  background-color: var(--color-gray-1);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  margin: auto;
  width: calc(min(90vw, 47rem));
  border-radius: 10px;
  /* padding: 2rem; */
`;

export const Form = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => (props.isMobile ? '' : '33rem')};
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 1rem 0;
  flex-direction: row;
  justify-content: space-between;
`;

export const ErrorMessage = styled.label`
  color: var(--color-danger-default);
`;

export const Label = styled.div`
  display: flex;
  align-self: flex-start;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 30px;
`;

export const Email = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 20px;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 15px;
`;
