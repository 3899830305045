import api from '../../api/api';

export const updatePartner = ({
  partnerName,
  colorA,
  colorB,
  colorC,
  avatar,
  royalty,
  id,
}: {
  partnerName?: string;
  colorA?: string;
  colorB?: string;
  colorC?: string;
  avatar?: string;
  royalty?: number;
  id: string;
}) =>
  api.put(`/partners/${id}`, {
    name: partnerName,
    primary_color: colorA,
    secondary_color: colorB,
    tertiary_color: colorC,
    image: avatar,
    secondary_royalty: royalty,
    status: true,
  });

export const createPdf = ({
  pdf,
  partnerId,
  fileName,
  link,
}: {
  pdf: string | ArrayBuffer;
  partnerId: string;
  fileName: string;
  link: string;
}) =>
  api.post(`/partners/${partnerId}/tos?file=${!link}`, {
    file: link || pdf,
    file_name: fileName,
  });

export const deletePdf = ({
  partnerId,
  id,
}: {
  partnerId: string;
  id: number;
}) => api.delete(`/partners/${partnerId}/tos/${id}`);
