import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getSeatmapById, getSeatmapsByVenue } from "../api/seatmap";
import { keyBy as _keyBy } from "lodash";

const initialState = {
  data: {},
};

export const fetchSeatmapById = createAsyncThunk(
  "seatmap/fetchSeatmapById",
  async (seatmapId) => {
    const seatmap = await getSeatmapById(seatmapId);
    return seatmap;
  }
);

export const fetchSeatmapsByVenue = createAsyncThunk(
  "seatmap/fetchSeatmapsByVenue",
  async () => {
    const seatmaps = await getSeatmapsByVenue();
    return seatmaps;
  }
);

export const seatmapSlice = createSlice({
  name: "seatmap",
  initialState,
  reducers: {
    reset: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSeatmapById.fulfilled, (state, action) => {
      const seatmap = action.payload;
      const newState = { ...state };
      if (newState.data[seatmap.venueID] == null) {
        newState.data = { [seatmap.ID]: seatmap };
      } else {
        newState.data[seatmap.ID] = seatmap;
      }
      state.data = newState.data;
    });
    builder.addCase(fetchSeatmapsByVenue.fulfilled, (state, action) => {
      state.data = { ...state.data, ..._keyBy(action.payload, "ID") };
    });
  },
});

export const { reset } = seatmapSlice.actions;
export default seatmapSlice.reducer;
