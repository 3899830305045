import { ATTENDANCE_TYPE, TOTAL_ATTENDANCE } from '../../../utils/strings';
import { Text, Wrapper, RowContainer, ItemContainer } from './styles';

interface RowType {
  options: any;
}

const AttendanceAnalytics = ({ options }: RowType) => {
  return (
    <Wrapper>
      {options && (
        <RowContainer className='column'>
          <Text className='title'>{TOTAL_ATTENDANCE}</Text>
          <Text className='data'>
            {options?.attendance.total_attendance ? options?.attendance.total_attendance : '-'}
          </Text>
        </RowContainer>
      )}
      <RowContainer className='column'>
        {options?.attendance?.attendance_by_ticket_type === null ? (
          <Text className='title'>{ATTENDANCE_TYPE}</Text>
        ) : (
          ''
        )}
        {options &&
          Object.entries(options?.attendance?.attendance_by_ticket_type).map((element, index) => (
            <ItemContainer style={{ marginLeft: index === 0 ? 0 : 25 }}>
              <Text className={'title-s'}>{element[0]}</Text>
              <Text className={'data-s'}>{element[1] as number}</Text>
            </ItemContainer>
          ))}
      </RowContainer>
    </Wrapper>
  );
};

export default AttendanceAnalytics;
