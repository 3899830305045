
import styled from "styled-components";

export const Wrapper = styled.div`

  .memberCheck {
    padding-left: 50px;
  }
`;

export const Form = styled.div`
`;

export const InformationSection = styled.div`
  margin-bottom: 40px;
`;
export const RoleSection = styled.div`
  margin-bottom: 20px;
`;
