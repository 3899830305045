import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { useMutation } from 'react-query';
import { v4 as uuid } from 'uuid';

import { CANCEL } from '../../../utils/strings';
import {
  ButtonModal,
  ModalContainer,
  Row,
  Title,
  modalStyle,
  Column,
} from '../../WidgetCustomization/styles';
import { Img, Span, UploadButton } from '../styles';
import { uploadIcon } from '../../../static/icons';
import { createPdf } from '../services';
import { TermsItem } from './TermsItem';
import { File } from '../../../types';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fetchFiles: () => void;
}

const partnerId = localStorage.getItem('partnerId');

export const TermsModal = ({ isOpen, setIsOpen, fetchFiles }: Props) => {
  const [files, setFiles] = useState<File[]>([
    {
      fileName: '',
      baseURL: '',
      link: '',
      id: uuid(),
    },
  ]);

  const { mutate: mutatePdfCreation } = useMutation(createPdf, {
    onSuccess: () => fetchFiles(),
  });

  const addFile = () => {
    setFiles([
      ...files,
      {
        fileName: '',
        baseURL: '',
        link: '',
        id: uuid(),
      },
    ]);
  };

  const handlePdfData = () => {
    files.map(file => {
      mutatePdfCreation({
        pdf: file.baseURL,
        partnerId,
        fileName: file.fileName,
        link: file.link,
      });
    });
  };

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>Add Document</Title>
        </Row>

        <Row className='centerBorder overflow'>
          <Column className='width'>
            {!files[0].baseURL && (
              <Span className='subTitle'>
                The documentation here will show up in the terms of service
                section of your events. Feel free to add privacy policies, terms
                of service or any other documentation you would like to.
              </Span>
            )}

            {files.map(item => (
              <TermsItem
                setFiles={setFiles}
                files={files}
                index={files.findIndex(f => f.id === item.id)}
                key={item.id}
              />
            ))}
            {(files[files.length - 1].baseURL ||
              files[files.length - 1].link) && (
              <UploadButton className='oneMore' onClick={addFile}>
                Upload another file
                <Img className='trash' src={uploadIcon} />
              </UploadButton>
            )}
          </Column>
        </Row>

        <Row className='center'>
          <ButtonModal className='cancel' onClick={() => setIsOpen(false)}>
            {CANCEL}
          </ButtonModal>
          <ButtonModal
            className='okay'
            onClick={() => {
              handlePdfData();
              setIsOpen(false);
            }}
          >
            Confirm
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
