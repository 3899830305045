import { Modal } from '@mui/material';

import { inviteSent } from '../../../../static/img';
import {
  ButtonModal,
  ModalContainer,
  modalStyle,
  Row,
  Title,
} from '../../../WidgetCustomization/styles';
import { Column } from '../../styles';
import { Image, Span } from './InviteSent.style';

type Props = {
  showModal: boolean;
  onClose: () => void;
};

export default function InviteSentModal({ showModal = false, onClose }: Props) {
  return (
    <Modal open={showModal} onClose={() => onClose()} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>Invite Sent</Title>
        </Row>
        <Column className='centerBorder'>
          <Image src={inviteSent} />
          <Span>
            New team members will show up in your profile once they have
            accepted the invitation.
          </Span>
        </Column>
        <Row className='center'>
          <ButtonModal onClick={onClose}>Okay</ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
}
