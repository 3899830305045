import * as Yup from 'yup';

import {
  CONFIRM_PASSWORD,
  ENTER_PASSWORD,
  PASSWORDS_DONT_MATCH,
  PASSWORD_IS_REQUIRED,
} from '../../utils/strings';

export const LOGIN_FIELDS = {
  password: 'password',
  confirmPassword: 'confirmPassword',
};

export const LOGIN_INITIAL_VALUES = {
  password: '',
  confirmPassword: '',
};

export const LoginSchema = Yup.object().shape({
  password: Yup.string().required(PASSWORD_IS_REQUIRED),
  confirmPassword: Yup.string()
    .required(PASSWORD_IS_REQUIRED)
    .oneOf([Yup.ref(LOGIN_FIELDS.password), null], PASSWORDS_DONT_MATCH),
});

export const memberLoginInputData = [
  {
    name: LOGIN_FIELDS.password,
    label: ENTER_PASSWORD,
  },
  {
    name: LOGIN_FIELDS.confirmPassword,
    label: CONFIRM_PASSWORD,
  },
];
