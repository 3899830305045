import styled from 'styled-components';

import { green } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Wrapper = styled.div`
  background: #2d2d2d;
  border-radius: 8px;
`;

export const Button = styled.div`
  background: rgba(89, 206, 99, 0.05);
  border: 1px solid rgba(89, 206, 99, 0.7);
  border-radius: 8px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-transform: uppercase;
  padding: 10px;
  cursor: pointer;
  color: ${green};
`;

export const Img = styled.img`
  width: 18px;
`;
