import styled from 'styled-components';

import { white } from '../../../../utils/theme';
import { primaryFont } from '../../../../utils/typography';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #1f1f1f;
  border-radius: 8px;
  height: 297px;
  padding: 26px;
  &.relative {
    position: relative;
  }
  &.gap {
    gap: 10px;
  }
  &.gap2 {
    gap: 20px;
  }
`;

export const BlurWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(71, 71, 71, 0.9);
  backdrop-filter: blur(4px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.margin {
    margin-top: 20px;
  }
`;

export const Text = styled.div`
  font-family: ${primaryFont};
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: ${white};
  font-style: normal;
  &.title {
    font-weight: 600;
    font-size: 24px;
  }
  &.subTitle {
    font-weight: 400;
    font-size: 16px;
    opacity: 0.7;
  }
  &.description {
    font-weight: 400;
    font-size: 16px;
    margin-top: 8px;
  }
  &.bold {
    font-weight: 700;
  }
  &.uppercase {
    text-transform: uppercase;
  }
`;

export const Img = styled.img`
  &.kebab {
    position: absolute;
    right: 26px;
    cursor: pointer;
  }
  &.arrow {
    position: absolute;
    top: 26px;
    left: 26px;
    cursor: pointer;
  }
  &.ticket {
    position: absolute;
    right: 26px;
    bottom: 26px;
  }
  &.sponsorCard {
    width: 150px;
  }
`;

export const Button = styled.div`
  border: 1px solid #f2f2f2;
  border-radius: 100px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #f2f2f2;
  min-width: 100px;
  padding: 5px 40px;
  cursor: pointer;
`;
