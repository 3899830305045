import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Description = styled.p`
  text-align: center;
  font-size: 1rem;
  color: var(--color-info-default);
`;

export const InputContainer = styled.div`
  label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    display: block;
  }

  &:last-child > label {
    margin-top: 1rem;
  }
`;
