import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { Divider } from '../../lib/components/Divider';
import Avatar from '../../lib/components/Avatar/Avatar';
import OrganizationName from './Organization/Name';
import { Section, Wrapper, Title } from './styles';
import { OrganizationMembers } from './MembersSection';
import { Row } from '../WidgetCustomization/styles';
import { COLORS, PRIMARY, SECONDARY, TERTIARY } from '../../utils/strings';
import { ConfirmationModal } from '../WidgetCustomization/ConfirmationModal';
import { PaletteModal } from './PaletteModal';
import { PartnerName } from './PartnerName';
import { getData, put } from '../../api/api';
import { Terms } from './Terms/Terms';
import { BankInfo } from './BankInfo';
import { Royalties } from './Royalties';
import { RoyaltiesModal } from './RoyaltiesModal';
import { RenderPalette } from './Palette';
import { queryClient } from '../../App';
import { TwoFactorAuthentication } from './TwoFactorAuthentication/TwoFactorAuthentication';

export default function Profile() {
  const userId = localStorage.getItem('userId');
  const partnerId = localStorage.getItem('partnerId');

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenPrimary, setIsOpenPrimary] = useState<boolean>(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState<boolean>(false);
  const [isOpenTertiary, setIsOpenTertiary] = useState<boolean>(false);
  const [isOpenName, setIsOpenName] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(true);
  const [isOpenRoyalities, setIsOpenRoyalities] = useState<boolean>(false);

  const { data: partnerData } = useQuery('getPartner', () => getData(`partners/${partnerId}`), {
    refetchOnWindowFocus: false,
  });

  const { mutate: handleMutation } = useMutation(
    (newData: any) => put(`partners/${partnerId}`, newData),
    {
      onSuccess: newWidget => queryClient.setQueryData('getPartner', newWidget),
    }
  );

  useQuery('getMember', () => getData(`members?partner_id=${partnerId}&user_id=${userId}`), {
    onSuccess: data => setIsAdmin(data[0].Roles[0].value === 'Admin'),
  });

  const colors = [
    {
      title: PRIMARY,
      data: partnerData?.primary_color,
      isOpen: isOpenPrimary,
      setOpen: setIsOpenPrimary,
      label: 'primary_color',
    },
    {
      title: SECONDARY,
      data: partnerData?.secondary_color,
      isOpen: isOpenSecondary,
      setOpen: setIsOpenSecondary,
      label: 'secondary_color',
    },
    {
      title: TERTIARY,
      data: partnerData?.tertiary_color,
      isOpen: isOpenTertiary,
      setOpen: setIsOpenTertiary,
      label: 'tertiary_color',
    },
  ];

  return (
    <>
      {partnerData && (
        <Wrapper className='profile'>
          <Section>
            <Avatar url={partnerData.image || ''} />
            <OrganizationName name={partnerData.name} setIsOpen={setIsOpenName} />
          </Section>
          <Divider />
          <Title>{COLORS}</Title>
          <Row className='width padding'>
            {colors?.map(({ title, data, setOpen, isOpen, label }) => (
              <div key={label}>
                <RenderPalette
                  key={title}
                  title={title}
                  color={data}
                  setIsOpen={setOpen}
                  label={label}
                  handleDelete={handleMutation}
                />
                {partnerData && (
                  <PaletteModal
                    data={data}
                    setOpen={setOpen}
                    isOpen={isOpen}
                    label={label}
                    handleSave={handleMutation}
                  />
                )}
              </div>
            ))}
          </Row>
          {isAdmin && (
            <>
              <Divider />
              <BankInfo status={partnerData.stripe_status} />
              <Divider />
              <Royalties
                setIsOpen={setIsOpenRoyalities}
                royalty={String(partnerData.partner_secondary_default_royalty)}
              />
            </>
          )}
          <Divider />
          <Terms />
          <Divider />
          <OrganizationMembers isAdmin={isAdmin} />
          {/* need backend */}
          {/* <TwoFactorAuthentication /> */}
        </Wrapper>
      )}
      {isOpen && <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} />}

      {isOpenName && (
        <PartnerName isOpen={isOpenName} setIsOpen={setIsOpenName} name={partnerData.name} />
      )}
      {isOpenRoyalities && (
        <RoyaltiesModal
          isOpen={isOpenRoyalities}
          setIsOpen={setIsOpenRoyalities}
          royalty={String(partnerData.partner_secondary_default_royalty)}
        />
      )}
    </>
  );
}
