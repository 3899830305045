import { v4 as uuid } from 'uuid';

import TicketTypeForm from '../../EventCreation/TicketTypes/TicketTypeForm';
import styles from './TicketTypesForm.module.scss';
import {Button, Wrapper,Img} from './styles'
import { addGreen } from '../../../../static/icons';


export default function TicketTypesForm({ tickets, setTickets, eventOnSaleTime,ticketError }) {
  const addTicket = () =>
    setTickets([
      ...tickets,
      {
        ticketPrice: '',
        ticketName: '',
        ticketDescription: '',
        ticketFile: '',
        ticketFileName: '',
        ticketId: uuid(),
        ticket64: '',
        newTicket: true,
        templateId: null,
        ticketAmount: '',
        ticketSecondaryRoyalty: '',
        ticketOnSaleTime: new Date(),
        attachedPromoCodes: [],
      },
    ]);

  return (
    <div className={styles.containerStep}>
      {tickets.map((ticket, index) => (
        <Wrapper key={ticket.ticketId}>
          <TicketTypeForm fromEvent index={index} tickets={tickets} setTickets={setTickets} eventOnSaleTime={eventOnSaleTime} ticketError={ticketError} />
        </Wrapper>
      ))}
      <Button onClick={addTicket}>
        <Img src={addGreen} />
        Add ticket type
      </Button>
    </div>
  );
}
