import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';

import { Input } from '../../lib/components/Inputs/Input';
import { Background, ErrorMessage, Form, Wrapper, Text } from './styles';
import { logoBackground } from '../../static/img';
import {
  forgotPasswordInputData,
  ForgotPasswordSchema,
  FORGOT_PASSWORD_INITIAL_VALUES,
} from './utils';
import { auth } from '../../api/auth';
import { request, reset, success } from '../../stores/userSlice';
import ResetPasswordSuccess from './ResetPasswordSuccess';
import Buttons from './Buttons';
import useLoading from '../../hooks/useLoading';
import { Div, Loading } from '../../lib/components/layout/Navigation/Navbar/styles';
import { loading } from '../../static/animations';
import useMobileView from '../../hooks/useMobileView';

export default function ResetPassword() {
  const isLoading = useLoading();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isAuthenticated = localStorage.getItem('accessToken');
  const [passwordWasReset, setPasswordWasReset] = useState(false);
  const [error, setError] = useState('');
  const isMobile = useMobileView();

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: FORGOT_PASSWORD_INITIAL_VALUES,
    validationSchema: ForgotPasswordSchema,
    onSubmit: () => {
      handleSignIn();
    },
  });

  const handleSignIn = () => {
    const code = new URLSearchParams(search).get('oobCode');
    verifyPasswordResetCode(auth, code).then(() => {
      dispatch(request());
      confirmPasswordReset(auth, code, values.password)
        .then(() => {
          dispatch(success());
          setPasswordWasReset(true);
        })
        .catch(() => setError('Request failed'));
    });
  };

  useEffect(() => {
    !isAuthenticated && dispatch(reset());
  }, [dispatch, isAuthenticated]);

  return passwordWasReset ? (
    <ResetPasswordSuccess />
  ) : (
    <>
      <Background src={logoBackground} />
      <Wrapper isMobile={isMobile}>
        <Text isMobile={isMobile}> Forgot password?</Text>
        <Form isMobile={isMobile}>
          <div>Enter your new password.</div>
          {forgotPasswordInputData.map(({ placeholder, name, label }) => (
            <Input
              value={values[name]}
              setValue={
                handleChange(name) as unknown as (e: string | React.ChangeEvent<any>) => void
              }
              id={name}
              placeholder={placeholder}
              label={label}
              type='password'
              error={errors[name]}
              touched={touched[name]}
            />
          ))}
          <Buttons handleSubmit={handleSubmit} />
          <ErrorMessage>{error}</ErrorMessage>
        </Form>
      </Wrapper>
      {!!isLoading && (
        <>
          <Div />
          <Loading src={loading} alt='loading...' />
        </>
      )}
    </>
  );
}
