export const handleRRule = (
  freq,
  interval,
  daysSelected,
  isOn,
  isAfter,
  ocurrences,
  toISOstring,
  repeatEvery,
  monthDay,
  weekday,
  pos
) => {
  let rrule = `FREQ=${freq};INTERVAL=${interval}`;
  if (freq === 'weekly') rrule = `${rrule};BYDAY=${daysSelected.join(',')}`;
  if (freq === 'monthly') {
    if (repeatEvery === 'day') rrule = `${rrule};BYMONTHDAY=${monthDay}`;
    else rrule = `${rrule};BYSETPOS=${pos};BYDAY=${weekday}`;
  }
  if (isAfter) rrule = `${rrule};COUNT=${ocurrences}`;
  if (isOn) rrule = `${rrule};UNTIL=${toISOstring}`;
  return rrule.toUpperCase();
};

export const handleDayOfWeek = (numberOfWeek, weekday) => {
  switch (numberOfWeek) {
    case 1:
      return `Monthly on the first ${weekday}`;
    case 2:
      return `Monthly on the second ${weekday}`;
    case 3:
      return `Monthly on the third ${weekday}`;
    case 4:
      return `Monthly on the fourth ${weekday}`;
    case 5:
      return `Monthly on the fifth ${weekday}`;
    default:
      return `Monthly on the first ${weekday}`;
  }
};

export const handleDay = key => {
  switch (key) {
    case 'SU':
      return 'Sunday';
    case 'MO':
      return 'Monday';
    case 'TU':
      return 'Tuesday';
    case 'WE':
      return 'Wenesday';
    case 'TH':
      return 'Thursday';
    case 'FR':
      return 'Friday';
    case 'SA':
      return 'Saturday';
    default:
      return 'Sunday';
  }
};
