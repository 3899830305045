import { useQuery } from 'react-query';

import { getData } from '../../api/api';
import AnalyticsEventCard from '../../lib/components/Analytics/AnalyticsEventCard/EventCard';
import DropdownTab from '../../lib/components/DropdownTab/DropdownTab';
import {
  OVERALL_SUMMARY,
  FOR_ALL_EVENTS,
  GROSS_TICKETS_SOLD,
  GROSS_REVENUE,
  TOP_SELLING,
  BOTTOM_SELLING,
  TOTAL_EVENTS,
} from '../../utils/strings';
import { Wrapper, Title, Row, ItemTitle, ItemText, EventItem } from './styles';

const Analytics = () => {
  const partnerId = localStorage.getItem('partnerId');

  const { data: eventsPartner } = useQuery('getAnalyticsPartner', () =>
    getData(`events/analytics/${partnerId}`)
  );

  const length = eventsPartner?.ordered_by_sells.length;

  return (
    <Wrapper>
      <Title>{OVERALL_SUMMARY}</Title>
      <Title className='sub'>{FOR_ALL_EVENTS}</Title>

      <Row>
        <Wrapper className='padding'>
          <Wrapper className='padding'>
            <ItemTitle>{GROSS_TICKETS_SOLD}</ItemTitle>
            <ItemText>{eventsPartner?.gross_tickets_sold}</ItemText>
          </Wrapper>
          <Wrapper className='padding'>
            <ItemTitle>{TOP_SELLING}</ItemTitle>
            <EventItem>{eventsPartner?.ordered_by_sells[0]?.name}</EventItem>
            <EventItem>{eventsPartner?.ordered_by_sells[1]?.name}</EventItem>
          </Wrapper>
          <Wrapper className='padding'>
            <ItemTitle>{TOTAL_EVENTS}</ItemTitle>
            <ItemText>{eventsPartner?.total_events}</ItemText>
          </Wrapper>
        </Wrapper>

        <Wrapper className='padding'>
          <Wrapper className='padding'>
            <ItemTitle>{GROSS_REVENUE}</ItemTitle>
            <ItemText>{eventsPartner?.gross_revenue}</ItemText>
          </Wrapper>
          <Wrapper className='padding'>
            <ItemTitle>{BOTTOM_SELLING}</ItemTitle>
            <EventItem>{eventsPartner?.ordered_by_sells[length - 1]?.name}</EventItem>
            <EventItem>{eventsPartner?.ordered_by_sells[length - 2]?.name}</EventItem>
          </Wrapper>
        </Wrapper>
      </Row>

      {eventsPartner?.event_analytics &&
        Object.keys(eventsPartner?.event_analytics).map((period, index) => (
          <DropdownTab
            key={period}
            period={period}
            events={eventsPartner.event_analytics[period]}
            open={index === 0}
            Component={AnalyticsEventCard}
          />
        ))}
    </Wrapper>
  );
};

export default Analytics;
