import React, { useEffect } from 'react';
import { useMutation } from 'react-query';

import { BRAND_FONTS, BRAND_FONTS_DESCRIPTION, UPLOAD_FONT } from '../../utils/strings';
import {
  ContentContainer,
  Span,
  Text,
  Row,
  Column,
  PaletteContainer,
  AddImg,
  UploadFont,
  MyFontPicker,
} from './styles';
import editPalette from '../../static/icons/editPalette.svg';
import { queryClient } from '../../App';
import { put } from '../../api/api';

interface BrandFontsProps {
  setIsOpenPrimary: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOpenSecondary: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BrandFonts = ({ setIsOpenPrimary, setIsOpenSecondary }: BrandFontsProps) => {
  const data: any = queryClient.getQueryData('getWidgetCustomization');
  const partnerId = localStorage.getItem('partnerId');
  const { mutate: handleMutation } = useMutation((newWidgetData: any) =>
    put(`partners/${partnerId}/widget`, newWidgetData)
  );

  useEffect(() => {
    const primaryElement = document.querySelector("div[id='font-picker-0'] p");
    primaryElement.classList.add('apply-font-0');
    (primaryElement as any).style.color = (data as any)?.title_brand_color;

    const secondaryElement = document.querySelector("div[id='font-picker-1'] p");
    secondaryElement.classList.add('apply-font-1');
    (secondaryElement as any).style.color = (data as any)?.text_brand_color;
  }, [data]);

  const renderFont = index => (
    <Row className='top'>
      <MyFontPicker
        apiKey='AIzaSyC0tcoMtdSu8SspbD2U0aBac-V-wpXfhCQ'
        activeFontFamily={index ? data?.text_brand_font : data?.title_brand_font}
        onChange={nextFont => {
          queryClient.setQueryData('getWidgetCustomization', (oldData: Object) =>
            index
              ? { ...oldData, text_brand_font: nextFont.family }
              : { ...oldData, title_brand_font: nextFont.family }
          );
          handleMutation(
            index ? { text_brand_font: nextFont.family } : { title_brand_font: nextFont.family }
          );
        }}
        limit={700}
        pickerId={index.toString()}
        variants={index ? ['regular'] : ['800']}
      />
      <PaletteContainer
        className='font'
        style={{
          backgroundColor: index ? data?.text_brand_color : data?.title_brand_color,
        }}
      />
      <AddImg
        src={editPalette}
        alt='edit'
        className='font'
        onClick={() => (index ? setIsOpenSecondary(true) : setIsOpenPrimary(true))}
      />
    </Row>
  );

  return (
    <ContentContainer>
      <Span>{BRAND_FONTS}</Span>
      <Row className='space'>
        <Text>{BRAND_FONTS_DESCRIPTION}</Text>
        <UploadFont>{UPLOAD_FONT}</UploadFont>
      </Row>
      <Column>
        {renderFont(0)}
        {renderFont(1)}
      </Column>
    </ContentContainer>
  );
};
