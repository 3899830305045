import styled from 'styled-components';
import { borderLine, darkGray, green, lightGray } from '../../utils/theme';
import { primaryFont } from '../../utils/typography';

export const Text = styled.div<{ progress?: boolean }>`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 33px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  opacity: 0.5;
  margin-top: 10px;
  cursor: default;
  color: white;

  &.progress {
    color: green;
    margin-bottom: 10px;
    opacity: 1;
  }
  &.done {
    color: white;
    opacity: 1;
    margin-bottom: 10px;
  }
`;

export const LineItem = styled.div``;

export const Header = styled.div`
  width: 100%;
  font-size: 24px;
  text-align: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Img = styled.img`
  .tick {
    margin-left: -1pc;
  }
  &.back {
    cursor: pointer;
    width: 35px;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 365px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 48px 48px 48px;
  border-top: 1px solid ${borderLine};
`;

export const Input = styled.input`
  border: 1px solid;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: black;
  opacity: 0.5;
  padding: 12px;
  margin-top: 12px;
  outline: none;
`;

export const Button = styled.div`
  padding: 12px 32px;
  background-color: ${green};
  width: fit-content;
  border-radius: 100px;
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
  &.secondary {
    background-color: ${darkGray};
    border: 1px solid ${lightGray};
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    color: ${lightGray};
    margin-left: 650px;
  }
  &.secondaryUpper {
    background-color: ${darkGray};
    border: 1px solid ${lightGray};
    font-family: ${primaryFont};
    font-style: normal;
    font-size: 16px;
    color: ${lightGray};
    text-transform: uppercase;
  }

  &.continue {
    gap: 4px;
    min-width: 83px;
    max-height: 28px;
  }

  &.disable {
    gap: 4px;
    opacity: 0.5;
    cursor: not-allowed;
    min-width: 83px;
    max-height: 28px;
  }
`;

export const ButtonRight = styled.div`
  display: flex;
  gap: 10px;
`;

export const ContainerText = styled.div`
  &.progress {
    border-bottom: 1px solid white;
  }
`;

export const Line = styled.div`
  max-width: 100px;
  border: 0.5px solid white;
  width: 100%;
`;

export const Wrapper = styled.div`
  padding: 0px 48px 20px 48px;

  &.left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    justify-content: center;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: white;
`;
