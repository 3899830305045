import React, { useState } from 'react';
import 'react-color-palette/lib/css/styles.css';
import { useMutation, useQuery } from 'react-query';

import { CANCEL, PREVIEW, SUBTITLE, TITLE } from '../../utils/strings';
import { Branding } from './Branding';
import { Wrapper, Title, Row, Button } from './styles';
import { PaletteModal } from './PaletteModal';
import { ConfirmationModal } from './ConfirmationModal';
import { WidgetPreview } from './WidgetPreview';
import { getData, put } from '../../api/api';
import { queryClient } from '../../App';

const WidgetCustomization = () => {
  const { refetch, data } = useQuery(
    'getWidgetCustomization',
    () => getData(`partners/${partnerId}/widget`),
    { refetchOnWindowFocus: false }
  );

  const { mutate: handleMutation } = useMutation(
    (newData: any) => put(`partners/${partnerId}/widget`, newData),
    {
      onSuccess: newWidget => queryClient.setQueryData('getWidgetCustomization', newWidget),
    }
  );

  const [isOpenPrimary, setIsOpenPrimary] = useState<boolean>(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState<boolean>(false);
  const [isOpenPrimaryFont, setIsOpenPrimaryFont] = useState<boolean>(false);
  const [isOpenSecondaryFont, setIsOpenSecondaryFont] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [widgetPreview, setWidgetPreview] = useState<boolean>(false);
  const partnerId = localStorage.getItem('partnerId');

  return (
    <Wrapper>
      <Wrapper className='column'>
        <Title>{TITLE}</Title>
        <Title className='subtitle'>{SUBTITLE}</Title>
        <Branding
          setIsOpenPrimary={setIsOpenPrimary}
          setIsOpenSecondary={setIsOpenSecondary}
          setIsOpenPrimaryFont={setIsOpenPrimaryFont}
          setIsOpenSecondaryFont={setIsOpenSecondaryFont}
          refetchWidget={refetch}
        />
        <Row className='space width top'>
          <Button>{CANCEL}</Button>
          <Row>
            <Button className='border' onClick={() => setWidgetPreview(true)}>
              {PREVIEW}
            </Button>
          </Row>
        </Row>
      </Wrapper>
      {data && (
        <PaletteModal
          data={data?.primary_brand_color}
          isOpen={isOpenPrimary}
          setOpen={setIsOpenPrimary}
          handleChange={handleMutation}
          toChange='primary_brand_color'
        />
      )}
      {data && (
        <PaletteModal
          data={data?.secondary_brand_color}
          isOpen={isOpenSecondary}
          setOpen={setIsOpenSecondary}
          handleChange={handleMutation}
          toChange='secondary_brand_color'
        />
      )}
      {data && (
        <PaletteModal
          data={data?.title_brand_color}
          isOpen={isOpenPrimaryFont}
          setOpen={setIsOpenPrimaryFont}
          handleChange={handleMutation}
          toChange='title_brand_color'
        />
      )}
      {data && (
        <PaletteModal
          data={data?.text_brand_color}
          isOpen={isOpenSecondaryFont}
          setOpen={setIsOpenSecondaryFont}
          handleChange={handleMutation}
          toChange='text_brand_color'
        />
      )}
      <ConfirmationModal isOpen={isOpen} setIsOpen={setIsOpen} isWidget={true} />
      <WidgetPreview isOpen={widgetPreview} setIsOpen={setWidgetPreview} />
    </Wrapper>
  );
};

export default WidgetCustomization;
