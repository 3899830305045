import React, { useState } from 'react';

import { addGreen } from '../../../static/icons';
import { Sponsor } from '../../../types';
import SponsorCard from './SponsorCard';
import SponsorModal from './SponsorModal';
import { Grid, AddBox, Img } from '../TicketTypes/styles';

interface Props {
  sponsors: Sponsor[];
}

const Sponsors = ({ sponsors }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Grid>
      <AddBox onClick={() => setIsOpen(true)}>
        <Img className='add' src={addGreen} />
        Add Sponsor
      </AddBox>
      {sponsors?.map(sponsor => (
        <SponsorCard sponsor={sponsor} />
      ))}
      {isOpen && <SponsorModal setIsOpen={setIsOpen} isOpen={isOpen} />}
    </Grid>
  );
};

export default Sponsors;
