import { useHistory } from 'react-router-dom';

import { arrowBack } from '../../../static/img';
import { Img } from '../styles';
import { Wrapper, Header, ContainerTop, Container } from './StepStyles';

type Props = {
  label: string;
  component: React.ReactNode;
  canGoBack: boolean;
  value: number;
  setValue: Function;
};

export default function Step({ label, component, canGoBack, value, setValue }: Props) {
  const history = useHistory();

  return (
    <Wrapper>
      <ContainerTop>
        {canGoBack ? (
          <>
            <Img
              className='back'
              src={arrowBack}
              alt='back'
              onClick={() => {
                value === 1 ? history.push('/promo-code') : setValue(value - 1);
              }}
            />
          </>
        ) : (
          ''
        )}
        <Header className='title'>{label}</Header>
      </ContainerTop>
      <Container>{component}</Container>
    </Wrapper>
  );
}
