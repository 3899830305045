import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 365px;

  label {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    display: block;
  }
`;
