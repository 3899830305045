import React from 'react';

import { promoCodeFormType } from '../../../../../types';
import { PCActiveDays } from './Components/PCActiveDays';
import { PCName } from './Components/PCName';
import { Wrapper } from './styles';

type Props = {
  promoCodeForm: promoCodeFormType;
  setPromoCodeForm: Function;
};

export const PromoCodeInfo = ({ promoCodeForm, setPromoCodeForm }: Props) => (
  <Wrapper className='info'>
    <PCName promoCodeForm={promoCodeForm} setPromoCodeForm={setPromoCodeForm} />
    <PCActiveDays promoCodeForm={promoCodeForm} setPromoCodeForm={setPromoCodeForm} />
  </Wrapper>
);
