import React, { useState } from 'react';

import { promoCodesGreen } from '../../../../../static/icons';
import { TicketsType } from '../../../../../types';
import CreatePCModal from '../../PromoCodes/CreatePromoCode/CreatePCModal';
import { ExistingPCModal } from '../../PromoCodes/ExistingPCModal';
import { PromoCodeTag } from '../../PromoCodes/PromoCodeTag';
import { Container, Label, Button, Row, Img } from '../styles';

interface Props {
  fromEvent?: boolean;
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  tickets: TicketsType[];
  index: number;
  setIsExisting?: React.Dispatch<React.SetStateAction<boolean>>;
  ticketTypeId: number;
}

export const TicketTypePromoCode = ({
  fromEvent,
  setTickets,
  tickets,
  index,
  setIsExisting,
  ticketTypeId,
}: Props) => {
  const [isExistingOpen, setIsExistingOpen] = useState<boolean>(false);
  const [newPCOpen, setNewPCOpen] = useState<boolean>(false);

  return (
    <Container className={`no-input ${!fromEvent ? 'relative' : ''}`}>
      <Label>Promo code</Label>
      <Label className='s no-uppercase'>
        Add an existing promo code or create a new one to apply to this ticket.
      </Label>
      <Row className='wrap gap margin-top'>
        {tickets[index].attachedPromoCodes.map(({ code, ID }) => (
          <PromoCodeTag
            label={code}
            setTickets={setTickets}
            tickets={tickets}
            index={index}
            id={ID}
            ticketTypeId={ticketTypeId}
          />
        ))}
      </Row>
      <Row className='gap margin-top'>
        {fromEvent && (
          <Button onClick={() => setNewPCOpen(true)}>
            Add New Promo Code
            <Img src={promoCodesGreen} className='promo' />
          </Button>
        )}
        <Button
          onClick={() => {
            if (fromEvent) setIsExistingOpen(true);
            else setIsExisting(true);
          }}
        >
          Add Existing Promo Code
          <Img src={promoCodesGreen} className='promo' />
        </Button>
      </Row>
      {isExistingOpen && fromEvent && (
        <ExistingPCModal
          isOpen={isExistingOpen}
          setIsOpen={setIsExistingOpen}
          setTickets={setTickets}
          tickets={tickets}
          index={index}
        />
      )}
      {newPCOpen && fromEvent && <CreatePCModal isOpen={newPCOpen} setIsOpen={setNewPCOpen} />}
    </Container>
  );
};
