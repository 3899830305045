import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { queryClient } from '../../../../App';

import { kebabWhite, sponsors } from '../../../../static/icons';
import { arrowWhite } from '../../../../static/img';
import { Sponsor } from '../../../../types';
import ConfirmationModal from '../../ConfirmartionModal';
import { BlurWrapper, Button, Container, Img, Text } from '../../TicketTypes/TicketTypeCard/styles';
import { deleteSponsor } from '../services';
import SponsorModal from '../SponsorModal';
import { Column } from './styles';

interface Props {
  sponsor: Sponsor;
}

const REMOVE_TITLE = 'Are you sure you want to remove';

const SponsorCard = ({ sponsor }: Props) => {
  const [isBlur, setIsBlur] = useState<boolean>(false);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { mutate: mutateDelete } = useMutation(deleteSponsor, {
    onSuccess: () => {
      queryClient.setQueryData(['getEvent'], (oldData: any) => {
        const newArray = oldData.sponsors.filter(t => t.ID !== sponsor.ID);
        oldData.sponsors = newArray;
        return oldData;
      });
      setIsRemove(false);
      setIsBlur(false);
    },
  });

  return (
    <Container className='relative gap2'>
      <Img className='kebab' src={kebabWhite} onClick={() => setIsBlur(true)} />
      <Img className='ticket' src={sponsors} />
      <Column>
        <Text className='title'>Sponsor Name</Text>
        <Text className='title uppercase'>{sponsor.name}</Text>
      </Column>

      <Text className='subTitle'>IMAGE LOGO (OPTIONAL)</Text>
      <Img className='sponsorCard' src={sponsor.image} />

      {isBlur && (
        <BlurWrapper>
          <Text className='title bold'>{sponsor.name}</Text>
          <Img className='arrow' src={arrowWhite} onClick={() => setIsBlur(false)} />
          <Button onClick={() => setIsOpen(true)}>View/Edit</Button>
          <Button onClick={() => setIsRemove(true)}>Remove</Button>
        </BlurWrapper>
      )}
      {isRemove && (
        <ConfirmationModal
          isOpen={isRemove}
          setIsBlur={setIsBlur}
          setIsOpen={setIsRemove}
          title={REMOVE_TITLE}
          label={`${sponsor.name} Sponsor?`}
          isRemove
          handleClick={() => mutateDelete({ id: sponsor.ID })}
        />
      )}
      {isOpen && <SponsorModal setIsOpen={setIsOpen} isOpen={isOpen} sponsor={sponsor} />}
    </Container>
  );
};

export default SponsorCard;
