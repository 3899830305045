import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { auth } from '../../api/auth';

const Orchestor = () => {
  const history = useHistory();
  const { search } = useLocation();
  const mode = new URLSearchParams(search).get('mode');
  useEffect(() => {
    if (mode === 'resetPassword') {
      const oobCode = new URLSearchParams(search).get('oobCode');
      history.push(`/reset-password?oobCode=${oobCode}`);
    } else {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const continueURL = new URLSearchParams(search).get('continueUrl');
        const searchParams = new URLSearchParams(new URL(continueURL).search);
        const email = searchParams.get('email');
        const userId = searchParams.get('user_id');
        const partnerId = searchParams.get('partner_id');
        const partnerLogo = searchParams.get('partner_logo');
        const invitedBy = searchParams.get('invited_by');
        const partnerName = searchParams.get('partner_name');

        signInWithEmailLink(auth, email, window.location.href).then(
          async userCredential => {
            const user = userCredential.user;
            localStorage.setItem('accessToken', (user as any).accessToken);
            localStorage.setItem('uid', (user as any).uid);
            localStorage.setItem('partnerId', partnerId);
            localStorage.setItem('partnerLogo', partnerLogo);
            localStorage.setItem('userId', userId);
            history.push(
              `/member-login?user_id=${userId}&partner_id=${partnerId}&email=${email}&invited_by=${invitedBy}&partner_name=${partnerName}&partner_logo=${partnerLogo}`
            );
          }
        );
      }
    }
  }, [history, mode, search]);

  return null;
};

export default Orchestor;
