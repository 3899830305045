import { useEffect, useRef } from 'react';

import { tick } from '../../static/img';
import { ATTACH_TO_TICKET, PROMO_CODE_INFO, SET_ACTIVE_DAYS, SUMMARY } from '../../utils/strings';
import { ContainerText, Img, Line, Text, Wrapper } from './styles';

type Props = {
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
};

const classNamePage = (pageNumber: Number, value) => {
  if (value > pageNumber) {
    return 'done';
  }
  if (value === pageNumber) {
    return 'progress';
  }
};

const ProgressPromoCode = ({ value, setValue, activeStep }: Props) => {
  const titleRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (titleRef) window.scrollTo(0, titleRef.current.offsetTop);
  }, [activeStep]);

  return (
    <Wrapper className='top' ref={titleRef}>
      {value > 1 ? <Img src={tick} /> : ''}
      <ContainerText className={value === 1 ? 'progress' : ''}>
        <Text className={classNamePage(1, value)} onClick={() => setValue(1)}>
          {PROMO_CODE_INFO}
        </Text>
      </ContainerText>
      <Line />
      {value > 2 ? <Img src={tick} /> : ''}
      <ContainerText className={value === 2 ? 'progress' : ''}>
        <Text className={classNamePage(2, value)} onClick={() => setValue(2)}>
          {SET_ACTIVE_DAYS}
        </Text>
      </ContainerText>
      <Line />
      {value > 3 ? <Img src={tick} /> : ''}
      <ContainerText className={value === 3 ? 'progress' : ''}>
        <Text className={classNamePage(3, value)} onClick={() => setValue(3)}>
          {ATTACH_TO_TICKET}
        </Text>
      </ContainerText>
      <Line />
      <ContainerText className={value === 4 ? 'progress' : ''}>
        <Text className={classNamePage(4, value)}>{SUMMARY}</Text>
      </ContainerText>
    </Wrapper>
  );
};

export default ProgressPromoCode;
