import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Button } from '../../lib/components/Button';
import { ButtonVariants } from '../../lib/components/Button/Button';
import AdditionalDetailsForm from '../../lib/components/EventCreation/AdditionalDetailsForm';
import AddSponsorsForm from '../../lib/components/EventCreation/AddSponsorsForm';
import EventAndVenueForm from '../../lib/components/EventCreation/EventAndVenueForm';
import EventImageForm from '../../lib/components/EventCreation/EventImageForm';
import EventInfo from '../../lib/components/EventCreation/EventInfoForm';
import OnSaleTime from '../../lib/components/EventCreation/OnSaleTime';
import RecurringEvent from '../../lib/components/EventCreation/RecurringEvent';
import { handleRRule } from '../../lib/components/EventCreation/RecurringEvent/utils';
import SchedulingForm from '../../lib/components/EventCreation/SchedulingForm';
import TicketTypesForm from '../../lib/components/EventCreation/Tickets/TicketTypesForm';
import Icon from '../../lib/components/Icon';
import { CutomOptions, EndOptions, EventFormType, TicketsType } from '../../types';
import { CREATE_EVENT } from '../../utils/strings';
import {
  MainSlide,
  LeftSlide,
  RightSlide,
  Wrapper,
  ButtonsSection,
  TitleSection,
  SlidesSection,
} from './EventCreation.style';
import ModalEventCreation from './ModalEventCreation';
import {
  attachPromoCode,
  createEvent,
  createSupport,
  createTickets,
  infoValidation,
  dateValidation,
} from './services';
import Step from './Steps/Step';

const today = new Date();

const EventCreationPage = () => {
  const [error, setError] = useState();
  const [dateError, setDateError] = useState<boolean[]>([]);
  const [ticketError, setTicketError] = useState<boolean[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const [eventForm, setEventForm] = useState<EventFormType>({
    name: '',
    type: null,
    venue: '',
    primaryAddress: '',
    secondaryAddress: '',
    city: { label: null, value: null },
    state: { label: null, value: null },
    country: { label: null, value: null },
    zip: '',
    eventImage: null,
    description: '',
    timezone: '',
    sponsors: [{ name: null, image: null }],
    gateTime: null,
    support: '',
    dates: [{ id: uuid(), startDate: today, endDate: today, quantity: '1' }],
    onSaleTime: today,
  });
  const [eventImage, setEventImage] = useState('');
  const [eventId, setEventId] = useState<number>(null);
  const [eventTickets, setEventTickets] = useState<TicketsType[]>([
    {
      ticketPrice: '',
      ticketName: '',
      ticketFileName: '',
      ticketDescription: '',
      ticketFile: '',
      ticketId: uuid(),
      ticket64: '',
      templateId: null,
      ticketAmount: '1',
      ticketSecondaryRoyalty: '5',
      ticketOnSaleTime: new Date(),
      attachedPromoCodes: [],
    },
  ]);
  const [customOptions, setCustomOptions] = useState<CutomOptions>({
    isMultiday: true,
    isTimeSlot: false,
    isRecurring: false,
  });
  const [frecuency, setFrecuency] = useState({ label: 'Week', value: 'weekly' });
  const [daysSelected, setDaysSelected] = useState<string[]>(['SU']);
  const [isOpenError, setIsOpenError] = useState<boolean>(false);
  const [interval, setInterval] = useState<string>('1');
  const [endOptions, setEndOptions] = useState<EndOptions>({
    isNever: false,
    isAfter: false,
    isOn: true,
  });
  const [valueOcurrences, setValueOcurrences] = useState<string>('20');
  const [untilDate, setUntilDate] = useState(new Date());
  const [repeatEvery, setRepeatEvery] = useState({
    label: `Monthly on day ${moment(eventForm.dates[0].startDate).format('D')}`,
    value: 'day',
  });
  const [recurrenceID, setRecurrenceID] = useState<number>(0);
  const history = useHistory();
  const titleRef = useRef<any>();

  useEffect(() => {
    setRepeatEvery({
      label: `Monthly on day ${moment(eventForm.dates[0].startDate).format('D')}`,
      value: 'day',
    });
  }, [eventForm]);

  useEffect(() => {
    if (titleRef) window.scrollTo(0, titleRef.current.offsetTop);
  }, [activeStep]);

  const { mutate: mutateSupport } = useMutation(createSupport);

  const { mutateAsync: mutateAttachPromoCode } = useMutation(attachPromoCode);

  const { mutate: mutateCreateEvent } = useMutation(createEvent, {
    onSuccess: ({ data }) => {
      if (data.rrule === '') {
        setEventId(data.ID);
        mutateSupport({ id: data.ID, support: eventForm.support });
      } else {
        setEventId(data[0].ID);
        setRecurrenceID(data[0].recurrence_id);
        mutateSupport({ id: data[0].ID, support: eventForm.support });
      }
    },
    onError: () => {
      setIsOpenError(true);
    },
  });

  const handleEventCreation = () => {
    mutateCreateEvent({
      ...eventForm,
      isTimeSlot: customOptions.isTimeSlot,
      rrule: customOptions.isRecurring
        ? handleRRule(
            frecuency.value,
            interval,
            daysSelected,
            endOptions.isOn,
            endOptions.isAfter,
            valueOcurrences,
            moment(untilDate).format('YYYYMMDD'),
            repeatEvery.value,
            moment(eventForm.dates[0].startDate).format('D'),
            moment(eventForm.dates[0].startDate).format('dddd').slice(0, 2),
            Math.ceil(moment(eventForm.dates[0].startDate).date() / 7)
          )
        : '',
    });
  };

  const { mutate: mutateCreateTicket } = useMutation(createTickets, {
    onSuccess: ({ data }) => {
      Promise.all(
        eventTickets.map((ticket, index) =>
          Promise.all(
            ticket.attachedPromoCodes.map(pc =>
              mutateAttachPromoCode({
                promoCodeID: pc.ID,
                quantity: Number(pc.quantity),
                ticketTypeID: data[index].ID,
              })
            )
          )
        )
      ).then(() => {
        setTimeout(() => {
          history.push(`/event-summary/${eventId}`);
        }, 0);
      });
    },
  });

  const handleTicketCreation = () => {
    mutateCreateTicket({ tickets: eventTickets, eventId, recurrenceID });
  };

  const handleFinish = () => {
    setTicketError(eventTickets.map(({ templateId }) => !templateId));
    eventTickets.every(({ templateId }) => !!templateId) && handleTicketCreation();
  };

  return (
    <Wrapper>
      <TitleSection ref={titleRef}>
        <h2>{CREATE_EVENT}</h2>
      </TitleSection>
      <SlidesSection>
        <LeftSlide className={activeStep === 1 ? 'hide' : ''} />
        <MainSlide>
          {activeStep === 1 ? (
            <Step
              label='Event and Venue'
              component={
                <EventAndVenueForm
                  value={eventForm}
                  setValue={setEventForm}
                  error={error}
                  setError={setError}
                />
              }
            />
          ) : activeStep === 2 ? (
            <Step
              label='Scheduling'
              component={
                <SchedulingForm
                  value={eventForm}
                  setValue={setEventForm}
                  error={error}
                  setError={setError}
                  dateError={dateError}
                  customOptions={customOptions}
                  setCustomOptions={setCustomOptions}
                />
              }
            />
          ) : activeStep === 3 && customOptions.isRecurring ? (
            <Step
              label='Custom Recurrence'
              component={
                <RecurringEvent
                  numberOfWeek={Math.ceil(moment(eventForm.dates[0].startDate).date() / 7)}
                  weekday={moment(eventForm.dates[0].startDate).format('dddd')}
                  dayOfMonth={moment(eventForm.dates[0].startDate).format('D')}
                  endOptions={endOptions}
                  setEndOptions={setEndOptions}
                  valueOcurrences={valueOcurrences}
                  setValueOcurrences={setValueOcurrences}
                  frecuency={frecuency}
                  setFrecuency={setFrecuency}
                  interval={interval}
                  setInterval={setInterval}
                  daysSelected={daysSelected}
                  setDaysSelected={setDaysSelected}
                  untilDate={untilDate}
                  setUntilDate={setUntilDate}
                  repeatEvery={repeatEvery}
                  setRepeatEvery={setRepeatEvery}
                />
              }
            />
          ) : (activeStep === 3 && !customOptions.isRecurring) ||
            (activeStep === 4 && customOptions.isRecurring) ? (
            <Step
              label='Event Info'
              component={<EventInfo value={eventForm} setValue={setEventForm} />}
            />
          ) : (activeStep === 4 && !customOptions.isRecurring) ||
            (activeStep === 5 && customOptions.isRecurring) ? (
            <Step
              label='On sale time'
              component={<OnSaleTime value={eventForm} setValue={setEventForm} />}
            />
          ) : (activeStep === 5 && !customOptions.isRecurring) ||
            (activeStep === 6 && customOptions.isRecurring) ? (
            <Step
              label='Sponsor'
              component={<AddSponsorsForm value={eventForm} setValue={setEventForm} />}
            />
          ) : (activeStep === 6 && !customOptions.isRecurring) ||
            (activeStep === 7 && customOptions.isRecurring) ? (
            <Step
              label='Event Images'
              component={
                <EventImageForm
                  eventImage={eventImage}
                  setEventImage={setEventImage}
                  value={eventForm}
                  setValue={setEventForm}
                />
              }
            />
          ) : (activeStep === 7 && !customOptions.isRecurring) ||
            (activeStep === 8 && customOptions.isRecurring) ? (
            <Step
              label='Additional Details'
              component={<AdditionalDetailsForm value={eventForm} setValue={setEventForm} />}
            />
          ) : (
            ((activeStep === 8 && !customOptions.isRecurring) ||
              (activeStep === 9 && customOptions.isRecurring)) && (
              <Step
                label='Ticket Types'
                component={
                  <TicketTypesForm
                    tickets={eventTickets}
                    setTickets={setEventTickets}
                    ticketError={ticketError}
                    eventOnSaleTime={eventForm.onSaleTime}
                  />
                }
              />
            )
          )}
        </MainSlide>
        <RightSlide
          className={
            (activeStep === 8 && !customOptions.isRecurring) ||
            (activeStep === 9 && customOptions.isRecurring)
              ? 'hide'
              : ''
          }
        />
        <ButtonsSection>
          {activeStep > 1 && (
            <Button
              size='large'
              variant={ButtonVariants.outlined}
              onClick={() => setActiveStep(activeStep - 1)}
            >
              Back
            </Button>
          )}
          {((activeStep < 7 && !customOptions.isRecurring) ||
            (activeStep < 8 && customOptions.isRecurring)) && (
            <Button
              size='large'
              variant={ButtonVariants.greenOutlined}
              decoratorRight={<Icon icon='arrow-right' />}
              onClick={() => {
                if (activeStep === 1) {
                  infoValidation(setError, eventForm, error);
                  eventForm.name &&
                    eventForm.venue &&
                    eventForm.type &&
                    setActiveStep(activeStep + 1);
                } else if (activeStep === 2) {
                  if (customOptions.isTimeSlot) {
                    setActiveStep(activeStep + 1);
                  } else {
                    setDateError(
                      eventForm.dates.map(({ startDate, endDate }) => {
                        if (startDate < endDate) return false;
                        else return true;
                      })
                    );
                    dateValidation(setError, eventForm, error);
                    eventForm.dates.every(({ startDate, endDate }) => startDate < endDate) &&
                      eventForm.timezone &&
                      setActiveStep(activeStep + 1);
                  }
                } else setActiveStep(activeStep + 1);
              }}
              className='large-width'
            >
              Continue
            </Button>
          )}
          {((activeStep === 7 && !customOptions.isRecurring) ||
            (activeStep === 8 && customOptions.isRecurring)) && (
            <Button
              variant={ButtonVariants.greenOutlined}
              decoratorRight={<Icon icon='arrow-right' />}
              size='large'
              className='large-width'
              onClick={() => {
                handleEventCreation();
                setActiveStep(activeStep + 1);
              }}
            >
              Create Event
            </Button>
          )}
          {((activeStep === 8 && !customOptions.isRecurring) ||
            (activeStep === 9 && customOptions.isRecurring)) && (
            <Button
              variant={ButtonVariants.greenOutlined}
              size='large'
              className='large-width'
              onClick={handleFinish}
            >
              Finish
            </Button>
          )}
        </ButtonsSection>
        {isOpenError && (
          <ModalEventCreation
            isOpen={isOpenError}
            setIsOpen={setIsOpenError}
            setActiveStep={setActiveStep}
          />
        )}
      </SlidesSection>
    </Wrapper>
  );
};

export default EventCreationPage;
