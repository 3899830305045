import { useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';

import { put } from '../../../../api/api';
import useModal from '../../../../hooks/useModal';
import { EventType } from '../../../../types';
import { ANALYTICS, DELETE, TOTAL_EVENT_BUDGET, VIEW_EDIT } from '../../../../utils/strings';
import Icon from '../../Icon';
import { Card, Container, Text, Circle, Button, Input, TextArea } from './styles';
import ConfirmationModal from '../../../../pages/EventSummary/ConfirmartionModal';
import DeleteEventModal from './DeleteEventModal';
import RecurringEventModal from './RecurringEventModal';
import { deleteEvent, deleteRecurrentEvent } from './services';
import { queryClient } from '../../../../App';

type Props = {
  item: EventType;
  props: any;
};

export default function EventCard({ item, props }: Props) {
  const [isShowing, toggle] = useModal();
  const [isEdit, setIsEdit] = useState(false);
  const [isBlur, setIsBlur] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEventToDelete, setIsEventToDelete] = useState<boolean>(false);
  const [name, setName] = useState(item.name);
  const [date, setDate] = useState(moment(item.start_date).utc().format('MMMM DD, YYYY'));
  const [hour, setHour] = useState(moment(item.start_date).utc().format('h:mm A'));
  const [description, setDescription] = useState(item.description);
  const today = moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ');

  const { mutate } = useMutation(() =>
    put(`/events/${item?.id}`, {
      name,
      description,
      start_date: moment(`${date} ${hour}`).format('YYYY-MM-DDTHH:mm:ssZ'),
    })
  );

  const saveEdit = () => {
    mutate();
    setIsEdit(false);
  };

  const { mutate: mutateDelete } = useMutation(deleteEvent, {
    onSuccess: () => {
      queryClient.setQueryData(['getEventsPartner'], (oldData: any) => {
        const newData = {
          ...oldData,
          [props?.key]: oldData[props?.key].filter(t => {
            return t.id !== item.id;
          }),
        };
        return newData;
      });
      setIsOpen(false);
    },
  });

  const { mutate: mutateDeleteRecurrent } = useMutation(deleteRecurrentEvent, {
    onSuccess: () => {
      queryClient.setQueryData(['getEventsPartner'], (oldData: any) => {
        const newData = {
          ...oldData,
          [props?.key]: oldData[props?.key].filter(t => t.id !== item.id),
        };
        return newData;
      });
      setIsOpen(false);
    },
  });

  return (
    <Card>
      <Container className='column full space-between'>
        <Container className='space-between'>
          <Container className='column'>
            {isEdit ? (
              <Input
                className='header padding'
                value={name}
                onChange={e => setName(e.target.value)}
              />
            ) : (
              <Text className='header padding'>{name}</Text>
            )}

            <Container>
              {isEdit ? (
                <Input
                  className='padding'
                  value={date}
                  onChange={e => setDate(e.target.value.toUpperCase())}
                />
              ) : (
                <Text className='padding'>{date}</Text>
              )}
              {isEdit ? (
                <Input className='padding' value={hour} onChange={e => setHour(e.target.value)} />
              ) : (
                <Text className='padding'>{hour}</Text>
              )}
            </Container>
          </Container>

          <Container className='icons'>
            {isEdit ? (
              <Circle className='green' onClick={saveEdit}>
                <Icon icon='tick' className='cursor' />
              </Circle>
            ) : (
              <Icon
                icon='edit-button'
                height={45}
                className='cursor'
                onClick={() => setIsEdit(true)}
              />
            )}
            <Icon icon='three-dots' className='cursor' onClick={() => toggle()} />
          </Container>
        </Container>

        {isEdit ? (
          <TextArea value={description} onChange={e => setDescription(e.target.value)} />
        ) : (
          <Text className='description'>{description}</Text>
        )}
        <Container className='column'>
          <Text className='budget'>{TOTAL_EVENT_BUDGET}</Text>
          <Text className='header budget no-padding'>${item?.total_event_budget}</Text>
        </Container>
      </Container>

      {isShowing && (
        <Container className='full blur'>
          <Text className='header padding'>{name}</Text>
          {item?.selling_time > today ? (
            <Link to={`event-summary/${item.id}`} style={{ textDecoration: 'none' }}>
              <Button>{VIEW_EDIT}</Button>
            </Link>
          ) : (
            <Button
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {VIEW_EDIT}
            </Button>
          )}
          {isOpen && (
            <ConfirmationModal
              isOpen={isOpen}
              setIsBlur={setIsBlur}
              setIsOpen={setIsOpen}
              title='Are you sure you want to edit this event? Your tickets have already been minted on chain and we will need to re-mint them if you make any changes.'
              itemId={item?.id}
              isBeingSold={true}
            />
          )}
          <Link to={`/event-analysis/${item.id}`} style={{ textDecoration: 'none' }}>
            <Button>{ANALYTICS}</Button>
          </Link>
          <Button
            onClick={() => {
              setIsEventToDelete(true);
            }}
          >
            {DELETE}
          </Button>
          {isEventToDelete && item?.recurrence_id <= 0 && (
            <DeleteEventModal
              isOpen={isEventToDelete}
              setIsOpen={setIsEventToDelete}
              title={name}
              handleClick={() => mutateDelete({ id: item?.id })}
            />
          )}
          {isEventToDelete && item?.recurrence_id > 0 && (
            <RecurringEventModal
              isOpen={isEventToDelete}
              setIsOpen={setIsEventToDelete}
              title={name}
              handleClickRecurrent={() =>
                mutateDeleteRecurrent({ id: item?.id, recurrence_id: item?.recurrence_id })
              }
              handleClick={() => mutateDelete({ id: item?.id })}
            />
          )}
          <Button className='go-back' onClick={() => toggle()}>
            <Icon icon='arrow-left' />
          </Button>
        </Container>
      )}
    </Card>
  );
}
