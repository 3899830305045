import { lightGray } from './../../../../utils/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

export const CheckIcon = styled.div`
  height: 17px;
  width: 17px;
  border: 1px solid var(--color-primary-default);
  cursor: pointer;
  margin-right: 8px;
  border-radius: 2px;

  &.gray {
    background-color: var(--color-text-input);
    border: 1px solid var(--color-text-input);
  }

  &.grayCheckbox {
    border: 1px solid ${lightGray};
  }

  &:hover {
    border-color: var(--color-primary-dark);
  }

  svg {
    fill: var(--color-primary-default);
  }
`;
