import styled from 'styled-components';

import { lightblack, green, white, lightGray, remove, grayRGBA } from '../../../../../utils/theme';
import { primaryFont } from '../../../../../utils/typography';

export const Button = styled.div`
  border-radius: 100px;
  background: ${green};
  justify-content: center;
  padding: 10px 16px;
  min-width: 122px;
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  color: ${white};
  cursor: pointer;
  &.disable {
    opacity: 0.5;
  }
  &.cancel {
    border: 1px solid ${lightGray};
    background: none;
  }
  &.remove {
    background: ${remove};
  }
`;

export const Line = styled.div`
  width: 100%;
  border-bottom: 1px solid ${grayRGBA};
`;

export const Title = styled.div`
  font-family: ${primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${white};
  &.existingPC {
    font-size: 20px;
    font-weight: 600;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  &.center {
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  &.gap2 {
    gap: 20px;
  }
  &.a-center {
    align-items: center;
  }
  &.center {
    justify-content: center;
  }
  &.start {
    justify-content: flex-start;
  }
  &.bottom {
    gap: 10px;
    padding: 30px;
  }
  &.top {
    padding: 20px;
  }
`;

export const Container = styled.div`
  width: 60%;
  background: ${lightblack};
  border-radius: 16px;
  outline: none;
`;

export const Wrapper = styled.div`
  &.info {
    padding: 30px 14% 20px 14%;
    max-height: 534px;
    overflow-y: scroll;
  }
`;
