import { Wrapper, Header } from './Step.style';

type Props = {
  label: string;
  component: React.ReactNode;
};

const Step = ({ label, component }: Props) => (
  <Wrapper className={label === 'Event and Venue' ? 'scroll' : ''}>
    <Header>{label}</Header>
    {component}
  </Wrapper>
);
export default Step;
