import moment from 'moment';

import api from '../../api/api';
import { TimeType } from '../../types';

export const infoValidation = (setError: Function, event: any, error: any) => {
  const newErrors = { ...error };

  if (!event?.name) newErrors.name = true;
  if (!event?.venue) newErrors.venue = true;
  if (!event?.type) newErrors.type = true;

  setError(newErrors);
};

export const dateValidation = (setError: Function, event: any, error: any) => {
  const newErrors = { ...error };

  if (!event?.timezone) newErrors.timezone = true;

  setError(newErrors);
};

export const createEvent = ({
  name,
  venue,
  type,
  primaryAddress,
  secondaryAddress,
  city,
  state,
  country,
  zip,
  eventImage,
  description,
  timezone,
  sponsors,
  gateTime,
  onSaleTime,
  dates,
  isTimeSlot,
  rrule,
}: {
  name: string;
  venue: string;
  type: {
    label: string;
    value: string;
  };
  primaryAddress: string;
  secondaryAddress: string;
  city: { label: string; value: string };
  state: { label: string; value: string };
  country: { label: string; value: string };
  zip: string;
  eventImage: string;
  description: string;
  timezone: any;
  sponsors: { name: string; image: string }[];
  gateTime: number;
  onSaleTime: Date;
  dates: { startDate: Date; endDate: Date; quantity: string }[];
  isTimeSlot: boolean;
  rrule: string;
}) => {
  const partnerId = localStorage.getItem('partnerId');

  const newDates = dates.map(({ startDate, endDate, quantity }) => ({
    start_date: moment.utc(`${moment(startDate).format('L')} ${moment(startDate).format('LT')}`),
    end_date: moment.utc(`${moment(endDate).format('L')} ${moment(endDate).format('LT')}`),
    quantity: Number(quantity),
  }));

  return api.post('/events', {
    name,
    venue,
    event_type: type.value,
    street_address: primaryAddress,
    secondary_address: secondaryAddress,
    city: city.label,
    state: state.label,
    country: country.label,
    zip_code: zip,
    time_zone: timezone.value,
    selling_time: moment.utc(
      `${moment(onSaleTime).format('L')} ${moment(onSaleTime).format('LT')}`
    ),
    gate_time: gateTime,
    sponsors,
    image: eventImage,
    description,
    partner_id: Number(partnerId),
    event_days: newDates,
    time_slots: isTimeSlot,
    rrule,
  });
};

export const createTickets = ({
  tickets,
  eventId,
  recurrenceID,
}: {
  tickets: {
    ticketPrice: string | number;
    ticketName: string;
    ticketDescription: string;
    ticket64: string;
    templateId: number;
    ticketAmount: string;
    ticketSecondaryRoyalty: string | number;
    ticketOnSaleTime: Date;
  }[];
  eventId: number;
  recurrenceID?: number;
}) => {
  const bodyArray = tickets.map(ticket => ({
    name: ticket.ticketName,
    initial_price: Number(ticket.ticketPrice),
    description: ticket.ticketDescription,
    amount: Number(ticket.ticketAmount),
    status: true,
    template_id: ticket.templateId,
    event_id: eventId,
    secondary_royalty: Number(ticket.ticketSecondaryRoyalty) / 100,
    selling_time: ticket.ticketOnSaleTime,
  }));
  return api.post(
    `/ticket_types${recurrenceID > 0 ? `?recurrence=${recurrenceID}` : ''}`,
    bodyArray
  );
};

export const saveTemplate = ({ partnerId, image }: { partnerId: number; image: string }) =>
  api.post(`/templates`, {
    partner_id: partnerId,
    image,
  });

export const editTicket = ({
  tickets,
  eventId,
  ticketTypeID,
}: {
  tickets: {
    ticketPrice: string | number;
    ticketName: string;
    ticketDescription: string;
    ticket64: string;
    templateId: number;
    ticketAmount: string;
    ticketSecondaryRoyalty: string | number;
    ticketOnSaleTime: Date;
  }[];
  eventId: number;
  ticketTypeID: number;
}) => {
  const bodyArray = tickets.map(ticket => ({
    name: ticket.ticketName,
    initial_price: Number(ticket.ticketPrice),
    description: ticket.ticketDescription,
    amount: Number(ticket.ticketAmount),
    status: true,
    template_id: ticket.templateId,
    event_id: eventId,
    secondary_royalty: Number(ticket.ticketSecondaryRoyalty) / 100,
    selling_time: ticket.ticketOnSaleTime,
  }));
  return api.put(`/ticket_types/${ticketTypeID}`, bodyArray[0]);
};

export const createSupport = ({ id, support }: { id: number; support: string }) =>
  api.post('/support', {
    event_id: id,
    contact: support,
  });

export const attachPromoCode = ({
  promoCodeID,
  ticketTypeID,
  quantity,
}: {
  promoCodeID: number;
  ticketTypeID: number;
  quantity: number;
}) =>
  api.post(`promocodes/${promoCodeID}/attach`, [
    {
      id: ticketTypeID,
      quantity,
    },
  ]);

export const detachPromoCode = ({
  promoCodeID,
  ticketTypeID,
}: {
  promoCodeID: number;
  ticketTypeID: number;
}) => api.delete(`promocodes/detach/${promoCodeID}/${ticketTypeID}`);
