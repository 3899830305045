import { useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { post } from '../../api/api';
import Button from '../../lib/components/Button/Button';
import { CONFIRM_IDENTITY, REFUND, RESET_PASSWORD } from '../../utils/strings';
import { ConfirmIdentityModal } from './ConfirmIdentity/ConfirmIdentityModal';
import { ConfirmSuccessModal } from './ConfirmIdentity/ConfirmSuccessModal';
import { PinRequestModal } from './ConfirmIdentity/PinRequestModal';
import { ResetPasswordModal } from './ResetPasswordModal';
import { RowWrapper, STableCell } from './tableStyle';

type Props = {
  index?: number;
  email: string;
  name: string;
  ID: number;
  phone_number: string;
};

export function RowFanDatabase({ ID, index, email, name, phone_number }: Props) {
  const history = useHistory();
  const [isResetOpen, setIsResetOpen] = useState<boolean>(false);
  const [isConfirmIdentity, setConfirmIdentity] = useState<boolean>(false);
  const [isSuccessConfirmation, setSuccessConfirmation] = useState<boolean>(false);
  const [isRequiredPin, setIsRequiredPin] = useState(false);
  const [isPressButton, setIsPressButton] = useState(false);
  const [isSelectEmail, setIsSelectEmail] = useState(true);
  const full_name = name?.split(' ');

  const { mutate: sendResetPasswordEmail } = useMutation(() =>
    post('/public/users/forgot', {
      email,
    })
  );

  return (
    <RowWrapper index={index}>
      <STableCell onClick={() => history.push(`/fan-history/${ID}`)} className='elementsEmail'>
        {email}
      </STableCell>
      <STableCell className='database' onClick={() => history.push(`/fan-history/${ID}`)}>
        {full_name?.[0]}
      </STableCell>
      <STableCell className='database' onClick={() => history.push(`/fan-history/${ID}`)}>
        {full_name?.[1]}
      </STableCell>
      <STableCell className='buttons'>
        <Button color='gray' onClick={() => history.push(`/fan-refund/${ID}`, { name })}>
          {REFUND}
        </Button>
        <Button color='gray' onClick={() => setConfirmIdentity(true)}>
          {CONFIRM_IDENTITY}
        </Button>
        <ConfirmIdentityModal
          ID={ID}
          email={email}
          isOpen={isConfirmIdentity}
          setIsOpen={setConfirmIdentity}
          setIsRequiredPin={setIsRequiredPin}
          setIsPressButton={setIsPressButton}
          setIsSelectEmail={setIsSelectEmail}
          isSelectEmail={isSelectEmail}
          phone_number={phone_number}
        />
        <PinRequestModal
          ID={ID}
          isPressButton={isPressButton}
          isOpen={isRequiredPin}
          setIsOpen={setIsRequiredPin}
          setSuccessConfirmation={setSuccessConfirmation}
          isSelectEmail={isSelectEmail}
        />
        <ConfirmSuccessModal isOpen={isSuccessConfirmation} setIsOpen={setSuccessConfirmation} />
        <Button
          color='gray'
          onClick={() => {
            setIsResetOpen(true);
            sendResetPasswordEmail();
          }}
        >
          {RESET_PASSWORD}
        </Button>
        {isResetOpen && (
          <ResetPasswordModal isOpen={isResetOpen} setIsOpen={setIsResetOpen} index={index} />
        )}
      </STableCell>
    </RowWrapper>
  );
}
