import styled from 'styled-components';
import { primaryFont } from '../../../utils/typography';

export const Wrapper = styled.div`
  padding: 0px 48px 48px 48px;
`;

export const Container = styled.div`
  padding: 0px 48px 48px 48px;
`;

export const ContainerTop = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
  margin-bottom: 25px;
`;

export const Header = styled.div`
  width: 100%;
  font-size: 24px;
  align-items: center;

  &.title {
    font-size: 32px;
    font-family: ${primaryFont};
    font-style: normal;
    line-height: 65px;
    font-weight: 300;
  }
`;
