import React from 'react';
import { Modal } from '@mui/material';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';

import { CANCEL, SAVE_CHANGES, SELECT_COLOR } from '../../utils/strings';
import { ModalContainer, Title, Row, ButtonModal, modalStyle } from './styles';

interface PalleteProps {
  data: any;
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleChange: any;
  toChange: string;
}

export const PaletteModal = ({
  data,
  isOpen,
  setOpen,
  handleChange,
  toChange,
}: PalleteProps) => {
  const [color, setColor] = useColor('hex', data);

  return (
    <Modal open={isOpen} onClose={() => setOpen(false)} sx={modalStyle}>
      <ModalContainer>
        <Row className='centerBorder'>
          <Title className='modal'>{SELECT_COLOR}</Title>
        </Row>
        <Row className='centerBorder'>
          <ColorPicker
            width={456}
            height={228}
            color={color}
            onChange={setColor}
            hideHSV
            dark
          />
        </Row>
        <Row className='center'>
          <ButtonModal
            className='cancel'
            onClick={() => {
              setOpen(false);
            }}
          >
            {CANCEL}
          </ButtonModal>
          <ButtonModal
            onClick={() => {
              handleChange({ [toChange]: color.hex });
              setOpen(false);
            }}
          >
            {SAVE_CHANGES}
          </ButtonModal>
        </Row>
      </ModalContainer>
    </Modal>
  );
};
