import { EVENT_NAME, GATE_TIME, SUPPORT_CONTACT, VENUE_NAME } from './utils/strings';

export const handleGateOptions = (value: number) => {
  switch (value) {
    case -3:
      return '3 hours before event start';
    case -2:
      return '2 hours before event start';
    case -1:
      return '1 hour before event start';
    case -0.75:
      return '45 minutes before event start';
    case -0.5:
      return '30 minutes before event start';
    case -0.25:
      return '15 minutes before event start';
  }
};

export const handleTypeOptions = (value: string) => {
  switch (value) {
    case 'sports':
      return 'Sport Event';
    case 'concert':
      return 'Concert';
    case 'festival':
      return 'Festival';
    case 'festival':
      return 'Festival';
    case 'conference':
      return 'Conference';
    case 'comedy':
      return 'Comedy Show';
    case 'speaker':
      return 'Speaker';
    case 'other':
      return 'Other';
  }
};

export const handleTopInfoOneDay = event => [
  { title: EVENT_NAME, description: event?.name, input: true, item: 'name' },
  {
    title: 'Start Date',
    description: event?.event_days[0].start_date,
    item: 'start_date',
    date: true,
    dayId: event?.event_days[0].ID,
    startDate: event?.event_days[0].start_date,
    endDate: event?.event_days[0].end_date,
  },
  {
    title: 'Start Time',
    description: event?.event_days[0].start_date,
    item: 'start_date',
    time: true,
    dayId: event?.event_days[0].ID,
  },
  {
    title: 'Event Type',
    description: {
      label: handleTypeOptions(event?.event_type),
      value: event?.event_type,
    },
    dropdown: true,
    item: 'event_type',
  },
  { title: 'Sale Date', description: event?.selling_time },
  {
    title: VENUE_NAME,
    description: event?.venue,
    input: true,
    item: 'venue',
  },
  {
    title: 'End Date',
    description: event?.event_days[0].end_date,
    item: 'end_date',
    date: true,
    dayId: event?.event_days[0].ID,
    startDate: event?.event_days[0].start_date,
    endDate: event?.event_days[0].end_date,
  },
  {
    title: 'End Time',
    description: event?.event_days[0].end_date,
    item: 'end_date',
    time: true,
    dayId: event?.event_days[0].ID,
  },
  {
    title: GATE_TIME,
    description: {
      label: handleGateOptions(event?.gate_time),
      value: event?.gate_time,
    },
    dropdown: true,
    item: 'gate_time',
  },
  { title: 'Sale Time', description: event?.selling_time },
];

export const handleTopInfoMultiDay = event => [
  { title: EVENT_NAME, description: event?.name, input: true, item: 'name' },
  {
    title: 'Event Type',
    description: {
      label: handleTypeOptions(event?.event_type),
      value: event?.event_type,
    },
    dropdown: true,
    item: 'event_type',
  },
  { title: 'Sale Date', description: event?.selling_time },
  {
    title: VENUE_NAME,
    description: event?.venue,
    input: true,
    item: 'venue',
  },
  {
    title: GATE_TIME,
    description: {
      label: handleGateOptions(event?.gate_time),
      value: event?.gate_time,
    },
    dropdown: true,
    item: 'gate_time',
  },
  { title: 'Sale Time', description: event?.selling_time },
];

export const handleBottomInfo = (event, support) => [
  {
    title: 'Event Description',
    description: event?.description,
    input: true,
    item: 'description',
  },
  {
    title: 'Reccurring Event',
    recurring: true,
  },
  {
    title: SUPPORT_CONTACT,
    description: support,
  },
];
