import React from 'react';

import { Input } from './Input';
import { TicketsType } from '../../../../../types';
import { Container, Row } from '../styles';

const labels = [
  { label: 'number of tickets', item: 'ticketAmount' },
  { label: 'price', item: 'ticketPrice', placeholder: '0' },
  { label: 'secondary royalty', item: 'ticketSecondaryRoyalty' },
];

interface Props {
  tickets: TicketsType[];
  setTickets: React.Dispatch<React.SetStateAction<TicketsType[]>>;
  index: number;
}

export const TicketTypeInfo = ({ tickets, setTickets, index }: Props) => (
  <Container>
    <Input
      label='ticket name'
      item='ticketName'
      placeholder='Enter ticket name'
      tickets={tickets}
      setTickets={setTickets}
      index={index}
    />
    <Row className='space-between'>
      {labels.map(({ label, item, placeholder }) => (
        <Input
          key={label}
          label={label}
          item={item}
          tickets={tickets}
          setTickets={setTickets}
          index={index}
          width={'30%'}
          placeholder={placeholder}
        />
      ))}
    </Row>
    <Input
      label='description'
      item={'ticketDescription'}
      placeholder='Enter description'
      tickets={tickets}
      setTickets={setTickets}
      index={index}
    />
  </Container>
);
